import {useAuthenticator} from "@aws-amplify/ui-react";
import {useEffect} from "react";


function AutoSkip() {
    const {skipVerification} = useAuthenticator()

    useEffect(() => {
        skipVerification()
        // eslint-disable-next-line  react-hooks/exhaustive-deps
    }, []);
    return <></>
}

export default AutoSkip