import logo from "./Pico.png";
import {MouseEventHandler} from "react";
import StandardPageLayout from "./StandardPageLayout";

interface Probs {
    onStart?: MouseEventHandler<HTMLDivElement>
}

function WelcomePage({onStart}: Probs) {
    return (
        <StandardPageLayout header={"Welcome"}
                            footer={{proceed: {children: "start"}}}
                            onClick={onStart}>
            <img src={logo} className="App-logo" alt="logo"/>
        </StandardPageLayout>
    )
}

export default WelcomePage;