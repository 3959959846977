import {ResourcesConfig} from "@aws-amplify/core";

export const outputs: ResourcesConfig = {
    Auth: {
        Cognito: {
            userPoolId: process.env["REACT_APP_USER_POOL_ID"] ?? "TODOeu-central-1_ofv1IjpdB",
            userPoolClientId: process.env["REACT_APP_USER_POOL_WEB_CLIENT_ID"] ?? "TODO7tv71uhiacj8t7vfl7eqq7svig",
            loginWith: {
                username: true,
            },
        },
    },
}