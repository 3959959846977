import {Button, ButtonColorTheme, ButtonProps, Grid, ResponsiveStyle, useTheme, View} from "@aws-amplify/ui-react";
import {Property} from "csstype";
import {MouseEventHandler, ReactNode} from "react";

interface ButtonFooter {
    reject?: ButtonProps
    back?: ButtonProps
    todo?: ButtonProps
    proceed?: ButtonProps
}

interface Probs {
    header?: ReactNode;
    children?: ReactNode;
    footer?: ButtonFooter;
    onClick?: MouseEventHandler<HTMLDivElement>;
}

function StandardPageLayout({header, children, footer, onClick}: Probs) {


    const {tokens} = useTheme();

    function renderButton(columnStart: ResponsiveStyle<Property.GridColumnStart>,
                          colorTheme: ButtonColorTheme,
                          buttonProbs: ButtonProps | undefined
    ) {
        if (buttonProbs === undefined) {
            return <View columnStart={columnStart} columnEnd="auto" backgroundColor={tokens.colors.blue[80]}></View>
        } else {
            return <Button {...buttonProbs} columnStart={columnStart} columnEnd="auto" variation="primary"
                           colorTheme={colorTheme}/>
        }
    }

    return (
        <Grid
            templateColumns="1fr 1fr 1fr 1fr"
            templateRows="3em auto 3em"
            width={"100%"}
            height={"100%"}
            onClick={onClick}
        >
            <View
                columnStart="1"
                columnEnd="-1"
                backgroundColor={tokens.colors.blue[90]}
                color={tokens.colors.white}
            >
                {header}
            </View>
            <View
                columnStart="1"
                columnEnd="-1"
                backgroundColor={tokens.colors.black}
                overflow={"hidden"}
            >
                {children}
            </View>
            {renderButton("1", "error", footer?.reject)}
            {renderButton("2", "warning", footer?.back)}
            {renderButton("3", "info", footer?.todo)}
            {renderButton("4", "success", footer?.proceed)}
        </Grid>
    )
}

export default StandardPageLayout;