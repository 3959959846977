/* tslint:disable */
/* eslint-disable */
/**
 * HecOne 360 WebAPI
 *  The Hectronic HecOne-WebAPI allows third-parties to access the HecOne ecosystem.<br> <p> <b>Terminology</b><br > <ul> <li> <b>Site:</b> A Site is a limited geographical area. Examples: CityCenter (Parking), Refuelling-Station</li> <li> <b>Device:</b> Each Device is a Point of Sale. Customer using this to buy Products</li> <li> <b>Point</b> A Point is used to consume a Product. One Point can only be used by one customer at a time. Example: ParkingLot, Gas-Pump, Charging-Station</li> <li> <b>Product</b> A Product is a consumable resource. Example: Parking-Tariff, Diesel, AC, DC, ... </li> </ul> </p> 
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import type { Configuration } from './configuration';
import type { AxiosPromise, AxiosInstance, RawAxiosRequestConfig } from 'axios';
import globalAxios from 'axios';
// Some imports not used depending on template conditions
// @ts-ignore
import { DUMMY_BASE_URL, assertParamExists, setApiKeyToObject, setBasicAuthToObject, setBearerAuthToObject, setOAuthToObject, setSearchParams, serializeDataIfNeeded, toPathString, createRequestFunction } from './common';
import type { RequestArgs } from './base';
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, BaseAPI, RequiredError, operationServerMap } from './base';

/**
 * 
 * @export
 * @interface Activation
 */
export interface Activation {
    /**
     * 
     * @type {boolean}
     * @memberof Activation
     */
    'active': boolean;
}
/**
 * 
 * @export
 * @interface ActivationRequest
 */
export interface ActivationRequest {
    /**
     * 
     * @type {string}
     * @memberof ActivationRequest
     */
    'mandator_id': string;
}
/**
 * 
 * @export
 * @interface ActivationResponse
 */
export interface ActivationResponse {
    /**
     * 
     * @type {string}
     * @memberof ActivationResponse
     */
    'id': string;
    /**
     * 
     * @type {string}
     * @memberof ActivationResponse
     */
    'mandator_id': string;
}
/**
 * 
 * @export
 * @interface ActiveSitePrices
 */
export interface ActiveSitePrices {
    /**
     * The unique identifier of the site
     * @type {string}
     * @memberof ActiveSitePrices
     */
    'id'?: string;
    /**
     * Name of the site
     * @type {string}
     * @memberof ActiveSitePrices
     */
    'name'?: string;
    /**
     * When was the last time when a price was updated for the site
     * @type {string}
     * @memberof ActiveSitePrices
     */
    'last_update_of_prices'?: string;
    /**
     * The list of prices for each product of the site
     * @type {Array<Price>}
     * @memberof ActiveSitePrices
     */
    'prices'?: Array<Price>;
}
/**
 * 
 * @export
 * @interface AdditionalCard
 */
export interface AdditionalCard {
    /**
     * medium used for authentication e.g. Card
     * @type {string}
     * @memberof AdditionalCard
     */
    'medium'?: AdditionalCardMediumEnum;
    /**
     * card type used for authentication e.g. FleetCard
     * @type {string}
     * @memberof AdditionalCard
     */
    'type'?: AdditionalCardTypeEnum;
    /**
     * schema used for authentication
     * @type {string}
     * @memberof AdditionalCard
     */
    'schema'?: AdditionalCardSchemaEnum;
    /**
     * card type used for authentication e.g. A-VehicleCard
     * @type {string}
     * @memberof AdditionalCard
     */
    'function'?: AdditionalCardFunctionEnum;
    /**
     * Masked PAN
     * @type {string}
     * @memberof AdditionalCard
     */
    'pan'?: string;
    /**
     * Track1 data of the card
     * @type {string}
     * @memberof AdditionalCard
     */
    'track1'?: string;
    /**
     * Track2 data of the card
     * @type {string}
     * @memberof AdditionalCard
     */
    'track2'?: string;
    /**
     * Track3 data of the card
     * @type {string}
     * @memberof AdditionalCard
     */
    'track3'?: string;
}

export const AdditionalCardMediumEnum = {
    Card: 'Card',
    Notes: 'Notes',
    Coin: 'Coin',
    Coupon: 'Coupon',
    Icc: 'ICC',
    Rfid: 'RFID',
    App: 'App',
    ManualInput: 'ManualInput',
    Avr: 'AVR',
    Remote: 'Remote',
    None: 'None'
} as const;

export type AdditionalCardMediumEnum = typeof AdditionalCardMediumEnum[keyof typeof AdditionalCardMediumEnum];
export const AdditionalCardTypeEnum = {
    PspCard: 'PSPCard',
    ChargingCard: 'ChargingCard',
    FleetCard: 'FleetCard',
    StationCard: 'StationCard',
    BankNotes: 'BankNotes',
    Coins: 'Coins',
    NoCard: 'NoCard',
    CashCard: 'CashCard',
    CityCard: 'CityCard',
    GiftCard: 'GiftCard'
} as const;

export type AdditionalCardTypeEnum = typeof AdditionalCardTypeEnum[keyof typeof AdditionalCardTypeEnum];
export const AdditionalCardSchemaEnum = {
    Hks: 'HKS',
    Visa: 'VISA',
    Maestro: 'Maestro',
    MasterCard: 'MasterCard',
    PostFinance: 'PostFinance',
    Vpay: 'VPAY',
    Dkv: 'DKV',
    Cnp: 'CNP',
    Other: 'Other',
    Hfc: 'HFC',
    Uta: 'UTA',
    Iso2: 'ISO-2',
    BayWa: 'BayWa'
} as const;

export type AdditionalCardSchemaEnum = typeof AdditionalCardSchemaEnum[keyof typeof AdditionalCardSchemaEnum];
export const AdditionalCardFunctionEnum = {
    SingleCard: 'SingleCard',
    AVehicleCard: 'A-VehicleCard',
    BDriverCard: 'B-DriverCard',
    CVehicleCard: 'C-VehicleCard',
    CDriverCard: 'C-DriverCard',
    ManagementCard: 'ManagementCard',
    PetroPoint: 'PetroPoint',
    ManualInput: 'ManualInput',
    Technician: 'Technician',
    Loyalty: 'Loyalty',
    SingleWashCard: 'SingleWashCard'
} as const;

export type AdditionalCardFunctionEnum = typeof AdditionalCardFunctionEnum[keyof typeof AdditionalCardFunctionEnum];

/**
 * 
 * @export
 * @enum {string}
 */

export const ArticleCategory = {
    Parking: 'parking',
    Charging: 'charging',
    Refuelling: 'refuelling'
} as const;

export type ArticleCategory = typeof ArticleCategory[keyof typeof ArticleCategory];


/**
 * 
 * @export
 * @enum {string}
 */

export const ArticleState = {
    Reserved: 'reserved',
    Released: 'released',
    Ongoing: 'ongoing',
    Done: 'done',
    Error: 'error',
    Declined: 'declined'
} as const;

export type ArticleState = typeof ArticleState[keyof typeof ArticleState];


/**
 * 
 * @export
 * @interface Articles
 */
export interface Articles {
    /**
     * The unique identifier of the article
     * @type {string}
     * @memberof Articles
     */
    'id'?: string;
    /**
     * The unique identifier of the used product
     * @type {string}
     * @memberof Articles
     */
    'product_id'?: string;
    /**
     * 
     * @type {string}
     * @memberof Articles
     */
    'category': ArticlesCategoryEnum;
    /**
     * 
     * @type {string}
     * @memberof Articles
     */
    'state'?: ArticlesStateEnum;
    /**
     * The date and time when an article has started to be used
     * @type {string}
     * @memberof Articles
     */
    'start_time'?: string;
    /**
     * The date and time when an article ended up being used
     * @type {string}
     * @memberof Articles
     */
    'end_time'?: string;
    /**
     * The unit price for the article
     * @type {number}
     * @memberof Articles
     */
    'unit_price'?: number;
    /**
     * The final amount that the user has to pay
     * @type {number}
     * @memberof Articles
     */
    'amount'?: number;
    /**
     * Tax percentage applied for article price
     * @type {number}
     * @memberof Articles
     */
    'tax_percentage'?: number;
    /**
     * 
     * @type {number}
     * @memberof Articles
     */
    'tax_amount'?: number;
    /**
     * How much of the article was consumed
     * @type {number}
     * @memberof Articles
     */
    'quantity'?: number;
    /**
     * The maximum amount the user wants to spend for an article
     * @type {number}
     * @memberof Articles
     */
    'max_amount'?: number;
    /**
     * The name of the used article
     * @type {string}
     * @memberof Articles
     */
    'name'?: string;
    /**
     * The unit measure used for the article
     * @type {string}
     * @memberof Articles
     */
    'unit_measure'?: ArticlesUnitMeasureEnum;
    /**
     * The currency code used for article
     * @type {string}
     * @memberof Articles
     */
    'currency'?: string;
    /**
     * 
     * @type {Array<LogInner>}
     * @memberof Articles
     */
    'log'?: Array<LogInner>;
    /**
     * 
     * @type {ChargeDetailRecord}
     * @memberof Articles
     */
    'charge_detail_record'?: ChargeDetailRecord;
}

export const ArticlesCategoryEnum = {
    Charging: 'charging',
    Refuelling: 'refuelling',
    Parking: 'parking',
    Wash: 'wash'
} as const;

export type ArticlesCategoryEnum = typeof ArticlesCategoryEnum[keyof typeof ArticlesCategoryEnum];
export const ArticlesStateEnum = {
    Reserved: 'reserved',
    Released: 'released',
    Ongoing: 'ongoing',
    Done: 'done',
    Declined: 'declined',
    Error: 'error'
} as const;

export type ArticlesStateEnum = typeof ArticlesStateEnum[keyof typeof ArticlesStateEnum];
export const ArticlesUnitMeasureEnum = {
    KWh: 'kWh',
    Pc: 'pc',
    Kg: 'kg',
    L: 'l',
    Min: 'min',
    Km: 'km',
    M: 'm',
    Cm: 'cm',
    Mm: 'mm',
    In: 'in',
    Wh: 'Wh',
    A: 'a',
    D: 'd',
    H: 'h',
    S: 's',
    M2: 'm²',
    M3: 'm³',
    P: 'p',
    C: '°C',
    F: '°F'
} as const;

export type ArticlesUnitMeasureEnum = typeof ArticlesUnitMeasureEnum[keyof typeof ArticlesUnitMeasureEnum];

/**
 * 
 * @export
 * @interface AssignProductsToMandator201Response
 */
export interface AssignProductsToMandator201Response {
    /**
     * 
     * @type {ResponseBaseLinks}
     * @memberof AssignProductsToMandator201Response
     */
    '_links'?: ResponseBaseLinks;
    /**
     * 
     * @type {ResponseBaseMeta}
     * @memberof AssignProductsToMandator201Response
     */
    'meta'?: ResponseBaseMeta;
    /**
     * 
     * @type {Array<MandatorProductsResponse>}
     * @memberof AssignProductsToMandator201Response
     */
    'data'?: Array<MandatorProductsResponse>;
}
/**
 * 
 * @export
 * @interface BaseArticle
 */
export interface BaseArticle {
    /**
     * 
     * @type {ArticleCategory}
     * @memberof BaseArticle
     */
    'category': ArticleCategory;
    /**
     * This is a unique ID referencing to this sold article
     * @type {string}
     * @memberof BaseArticle
     */
    'id'?: string;
    /**
     * 
     * @type {ArticleState}
     * @memberof BaseArticle
     */
    'state'?: ArticleState;
    /**
     * This log is used to trace various events and status within this object.
     * @type {Array<ItemlogEntry>}
     * @memberof BaseArticle
     */
    'log'?: Array<ItemlogEntry>;
    /**
     * This is ID is referencing the sold product, which is setup on this site.
     * @type {string}
     * @memberof BaseArticle
     */
    'product_id'?: string;
    /**
     * Amount the user has to pay including VAT.in currency unit.
     * @type {number}
     * @memberof BaseArticle
     */
    'amount'?: number;
    /**
     * Amount of VAT. Has to be lower than total amount. Returned in  currency unit.
     * @type {number}
     * @memberof BaseArticle
     */
    'tax_amount'?: number;
    /**
     * The factor the vat has. Unit is percent (%) this an factor of 100
     * @type {number}
     * @memberof BaseArticle
     */
    'tax_percentage'?: number;
    /**
     * currency code in ISO 4217
     * @type {string}
     * @memberof BaseArticle
     */
    'currency'?: string;
    /**
     * Start of the Article usage/consumption
     * @type {string}
     * @memberof BaseArticle
     */
    'start_time'?: string;
    /**
     * End of the Article usage/consumption
     * @type {string}
     * @memberof BaseArticle
     */
    'end_time'?: string;
}


/**
 * 
 * @export
 * @interface BaseAssignment
 */
export interface BaseAssignment {
    /**
     * The unique identifier of the template assigned the site
     * @type {string}
     * @memberof BaseAssignment
     */
    'template_id': string;
    /**
     * The status of the assigned template
     * @type {boolean}
     * @memberof BaseAssignment
     */
    'status': boolean;
}
/**
 * 
 * @export
 * @interface BaseEmailTemplate
 */
export interface BaseEmailTemplate {
    /**
     * The unique identifier of the email template
     * @type {string}
     * @memberof BaseEmailTemplate
     */
    'id'?: string;
    /**
     * The subject for the email template
     * @type {string}
     * @memberof BaseEmailTemplate
     */
    'subject': string;
    /**
     * The message from email template
     * @type {string}
     * @memberof BaseEmailTemplate
     */
    'body': string;
    /**
     * The signature from email template that will appear in the footer
     * @type {string}
     * @memberof BaseEmailTemplate
     */
    'signature'?: string;
}
/**
 * 
 * @export
 * @interface Card
 */
export interface Card {
    /**
     * medium used for authentication e.g. Card
     * @type {string}
     * @memberof Card
     */
    'medium': CardMediumEnum;
    /**
     * card type used for authentication e.g. FleetCard
     * @type {string}
     * @memberof Card
     */
    'type'?: CardTypeEnum;
    /**
     * schema used for authentication
     * @type {string}
     * @memberof Card
     */
    'schema'?: CardSchemaEnum;
    /**
     * card type used for authentication e.g. A-VehicleCard
     * @type {string}
     * @memberof Card
     */
    'function'?: CardFunctionEnum;
    /**
     * Masked PAN
     * @type {string}
     * @memberof Card
     */
    'pan'?: string;
    /**
     * Track1 data of the card
     * @type {string}
     * @memberof Card
     */
    'track1'?: string;
    /**
     * Track2 data of the card
     * @type {string}
     * @memberof Card
     */
    'track2'?: string;
    /**
     * Track3 data of the card
     * @type {string}
     * @memberof Card
     */
    'track3'?: string;
}

export const CardMediumEnum = {
    Card: 'Card',
    Notes: 'Notes',
    Coin: 'Coin',
    Coupon: 'Coupon',
    Icc: 'ICC',
    Rfid: 'RFID',
    App: 'App',
    ManualInput: 'ManualInput',
    Avr: 'AVR',
    Remote: 'Remote',
    None: 'None'
} as const;

export type CardMediumEnum = typeof CardMediumEnum[keyof typeof CardMediumEnum];
export const CardTypeEnum = {
    PspCard: 'PSPCard',
    ChargingCard: 'ChargingCard',
    FleetCard: 'FleetCard',
    StationCard: 'StationCard',
    BankNotes: 'BankNotes',
    Coins: 'Coins',
    NoCard: 'NoCard',
    CashCard: 'CashCard',
    CityCard: 'CityCard',
    GiftCard: 'GiftCard'
} as const;

export type CardTypeEnum = typeof CardTypeEnum[keyof typeof CardTypeEnum];
export const CardSchemaEnum = {
    Hks: 'HKS',
    Visa: 'VISA',
    Maestro: 'Maestro',
    MasterCard: 'MasterCard',
    PostFinance: 'PostFinance',
    Vpay: 'VPAY',
    Dkv: 'DKV',
    Cnp: 'CNP',
    Other: 'Other',
    Hfc: 'HFC',
    Uta: 'UTA',
    Iso2: 'ISO-2',
    BayWa: 'BayWa'
} as const;

export type CardSchemaEnum = typeof CardSchemaEnum[keyof typeof CardSchemaEnum];
export const CardFunctionEnum = {
    SingleCard: 'SingleCard',
    AVehicleCard: 'A-VehicleCard',
    BDriverCard: 'B-DriverCard',
    CVehicleCard: 'C-VehicleCard',
    CDriverCard: 'C-DriverCard',
    ManagementCard: 'ManagementCard',
    PetroPoint: 'PetroPoint',
    ManualInput: 'ManualInput',
    Technician: 'Technician',
    Loyalty: 'Loyalty',
    SingleWashCard: 'SingleWashCard'
} as const;

export type CardFunctionEnum = typeof CardFunctionEnum[keyof typeof CardFunctionEnum];

/**
 * 
 * @export
 * @interface CardBase
 */
export interface CardBase {
    /**
     * 
     * @type {string}
     * @memberof CardBase
     */
    'number': string;
    /**
     * 
     * @type {string}
     * @memberof CardBase
     */
    'payment_card_id': string;
    /**
     * 
     * @type {boolean}
     * @memberof CardBase
     */
    'status'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof CardBase
     */
    'is_pin_required'?: boolean;
    /**
     * if is_pin_required is true, then pin value is required
     * @type {string}
     * @memberof CardBase
     */
    'pin'?: string;
    /**
     * 
     * @type {string}
     * @memberof CardBase
     */
    'additional_info'?: string;
    /**
     * 
     * @type {string}
     * @memberof CardBase
     */
    'release_date'?: string;
    /**
     * 
     * @type {string}
     * @memberof CardBase
     */
    'expiry_date'?: string;
    /**
     * 
     * @type {string}
     * @memberof CardBase
     */
    'card_owner'?: string;
    /**
     * 
     * @type {string}
     * @memberof CardBase
     */
    'vehicle'?: string;
}
/**
 * 
 * @export
 * @interface CardList
 */
export interface CardList {
    /**
     * 
     * @type {string}
     * @memberof CardList
     */
    'id'?: string;
    /**
     * 
     * @type {string}
     * @memberof CardList
     */
    'number'?: string;
    /**
     * 
     * @type {string}
     * @memberof CardList
     */
    'payment_card_id'?: string;
    /**
     * 
     * @type {boolean}
     * @memberof CardList
     */
    'status'?: boolean;
}
/**
 * 
 * @export
 * @interface ChangeMandator
 */
export interface ChangeMandator {
    /**
     * The id of the mandator which will be the current mandator for the logged in user
     * @type {string}
     * @memberof ChangeMandator
     */
    'mandator_id': string;
}
/**
 * 
 * @export
 * @interface ChargeDetailRecord
 */
export interface ChargeDetailRecord {
    /**
     * 
     * @type {string}
     * @memberof ChargeDetailRecord
     */
    'encoding_method'?: string;
    /**
     * 
     * @type {number}
     * @memberof ChargeDetailRecord
     */
    'encoding_method_version'?: number;
    /**
     * 
     * @type {string}
     * @memberof ChargeDetailRecord
     */
    'public_key'?: string;
    /**
     * 
     * @type {Array<ChargeDetailRecordSignedValuesInner>}
     * @memberof ChargeDetailRecord
     */
    'signed_values'?: Array<ChargeDetailRecordSignedValuesInner>;
}
/**
 * 
 * @export
 * @interface ChargeDetailRecordSignedValuesInner
 */
export interface ChargeDetailRecordSignedValuesInner {
    /**
     * 
     * @type {string}
     * @memberof ChargeDetailRecordSignedValuesInner
     */
    'nature'?: string;
    /**
     * 
     * @type {string}
     * @memberof ChargeDetailRecordSignedValuesInner
     */
    'plain_data'?: string;
    /**
     * 
     * @type {string}
     * @memberof ChargeDetailRecordSignedValuesInner
     */
    'signed_data'?: string;
}
/**
 * 
 * @export
 * @interface ChargingArticle
 */
export interface ChargingArticle {
    /**
     * 
     * @type {ArticleCategory}
     * @memberof ChargingArticle
     */
    'category': ArticleCategory;
    /**
     * This is a unique ID referencing to this sold article
     * @type {string}
     * @memberof ChargingArticle
     */
    'id'?: string;
    /**
     * 
     * @type {ArticleState}
     * @memberof ChargingArticle
     */
    'state'?: ArticleState;
    /**
     * This log is used to trace various events and status within this object.
     * @type {Array<ItemlogEntry>}
     * @memberof ChargingArticle
     */
    'log'?: Array<ItemlogEntry>;
    /**
     * This is ID is referencing the sold product, which is setup on this site.
     * @type {string}
     * @memberof ChargingArticle
     */
    'product_id'?: string;
    /**
     * Amount the user has to pay including VAT.in currency unit.
     * @type {number}
     * @memberof ChargingArticle
     */
    'amount'?: number;
    /**
     * Amount of VAT. Has to be lower than total amount. Returned in  currency unit.
     * @type {number}
     * @memberof ChargingArticle
     */
    'tax_amount'?: number;
    /**
     * The factor the vat has. Unit is percent (%) this an factor of 100
     * @type {number}
     * @memberof ChargingArticle
     */
    'tax_percentage'?: number;
    /**
     * currency code in ISO 4217
     * @type {string}
     * @memberof ChargingArticle
     */
    'currency'?: string;
    /**
     * Start of the Article usage/consumption
     * @type {string}
     * @memberof ChargingArticle
     */
    'start_time'?: string;
    /**
     * End of the Article usage/consumption
     * @type {string}
     * @memberof ChargingArticle
     */
    'end_time'?: string;
    /**
     * 
     * @type {string}
     * @memberof ChargingArticle
     */
    'code'?: string;
    /**
     * 
     * @type {number}
     * @memberof ChargingArticle
     */
    'unit_price'?: number;
    /**
     * 
     * @type {string}
     * @memberof ChargingArticle
     */
    'unit_measure'?: string;
    /**
     * how much kWh was consumed
     * @type {number}
     * @memberof ChargingArticle
     */
    'quantity'?: number;
    /**
     * The Maximum amount the user wants to spend Lower or equals reserved_amount
     * @type {number}
     * @memberof ChargingArticle
     */
    'max_amount'?: number;
    /**
     * The Maximum amount the user wants to spend Lower or equals reserved_amount
     * @type {number}
     * @memberof ChargingArticle
     */
    'max_quantity'?: number;
    /**
     * SOC = State Of Charge. This field is optionally sent by the charging point and gives the state of the battery level of the vehicle
     * @type {string}
     * @memberof ChargingArticle
     */
    'soc'?: string;
    /**
     * 
     * @type {string}
     * @memberof ChargingArticle
     */
    'name'?: string;
    /**
     * 
     * @type {Array<string>}
     * @memberof ChargingArticle
     */
    'description'?: Array<string>;
    /**
     * Client has to validate, that the unit price, it displays on the W&M approved display is OK. Generally an accepted solution is to do this based on checksums.
     * @type {string}
     * @memberof ChargingArticle
     */
    'signature'?: string;
}


/**
 * 
 * @export
 * @interface ChargingProductOffer
 */
export interface ChargingProductOffer {
    /**
     * The id of the product
     * @type {string}
     * @memberof ChargingProductOffer
     */
    'id'?: string;
    /**
     * 
     * @type {string}
     * @memberof ChargingProductOffer
     */
    'category'?: ChargingProductOfferCategoryEnum;
    /**
     * 
     * @type {string}
     * @memberof ChargingProductOffer
     */
    'name'?: string;
    /**
     * 
     * @type {string}
     * @memberof ChargingProductOffer
     */
    'description'?: string;
    /**
     * The unique identifier of a device
     * @type {string}
     * @memberof ChargingProductOffer
     */
    'code'?: string;
    /**
     * The price defined for a product on each site
     * @type {number}
     * @memberof ChargingProductOffer
     */
    'unit_price'?: number;
    /**
     * Th currency set on site
     * @type {string}
     * @memberof ChargingProductOffer
     */
    'currency'?: string;
    /**
     * Tax percentage applied for product prices of the site
     * @type {number}
     * @memberof ChargingProductOffer
     */
    'tax_percentage'?: number;
    /**
     * The unique identifier of the product power
     * @type {string}
     * @memberof ChargingProductOffer
     */
    'power_id'?: string;
    /**
     * 
     * @type {number}
     * @memberof ChargingProductOffer
     */
    'max_power'?: number;
    /**
     * 
     * @type {number}
     * @memberof ChargingProductOffer
     */
    'max_amount'?: number;
    /**
     * 
     * @type {string}
     * @memberof ChargingProductOffer
     */
    'unit_measure'?: string;
    /**
     * 
     * @type {Array<ChargingProductOfferAllOfRelatedProducts>}
     * @memberof ChargingProductOffer
     */
    'related_products'?: Array<ChargingProductOfferAllOfRelatedProducts>;
}

export const ChargingProductOfferCategoryEnum = {
    Parking: 'parking',
    Charging: 'charging',
    Refuelling: 'refuelling',
    Wash: 'wash'
} as const;

export type ChargingProductOfferCategoryEnum = typeof ChargingProductOfferCategoryEnum[keyof typeof ChargingProductOfferCategoryEnum];

/**
 * 
 * @export
 * @interface ChargingProductOfferAllOfRelatedProducts
 */
export interface ChargingProductOfferAllOfRelatedProducts {
    /**
     * The identifier of the product
     * @type {string}
     * @memberof ChargingProductOfferAllOfRelatedProducts
     */
    'code': string;
    /**
     * Name of the product
     * @type {string}
     * @memberof ChargingProductOfferAllOfRelatedProducts
     */
    'name': string;
    /**
     * Short description of the product
     * @type {string}
     * @memberof ChargingProductOfferAllOfRelatedProducts
     */
    'description'?: string;
    /**
     * The unit measure used for the product
     * @type {string}
     * @memberof ChargingProductOfferAllOfRelatedProducts
     */
    'unit_measure': ChargingProductOfferAllOfRelatedProductsUnitMeasureEnum;
    /**
     * Category of the product
     * @type {string}
     * @memberof ChargingProductOfferAllOfRelatedProducts
     */
    'category': ChargingProductOfferAllOfRelatedProductsCategoryEnum;
    /**
     * 
     * @type {string}
     * @memberof ChargingProductOfferAllOfRelatedProducts
     */
    'product_id'?: string;
    /**
     * 
     * @type {string}
     * @memberof ChargingProductOfferAllOfRelatedProducts
     */
    'currency'?: string;
    /**
     * 
     * @type {number}
     * @memberof ChargingProductOfferAllOfRelatedProducts
     */
    'tax_percentage'?: number;
    /**
     * 
     * @type {number}
     * @memberof ChargingProductOfferAllOfRelatedProducts
     */
    'max_power'?: number;
    /**
     * 
     * @type {number}
     * @memberof ChargingProductOfferAllOfRelatedProducts
     */
    'max_amount'?: number;
    /**
     * 
     * @type {number}
     * @memberof ChargingProductOfferAllOfRelatedProducts
     */
    'unit_price'?: number;
}

export const ChargingProductOfferAllOfRelatedProductsUnitMeasureEnum = {
    KWh: 'kWh',
    Pc: 'pc',
    Kg: 'kg',
    L: 'l',
    Min: 'min',
    Km: 'km',
    M: 'm',
    Cm: 'cm',
    Mm: 'mm',
    In: 'in',
    Wh: 'Wh',
    A: 'a',
    D: 'd',
    H: 'h',
    S: 's',
    M2: 'm²',
    M3: 'm³',
    P: 'p',
    C: '°C',
    F: '°F'
} as const;

export type ChargingProductOfferAllOfRelatedProductsUnitMeasureEnum = typeof ChargingProductOfferAllOfRelatedProductsUnitMeasureEnum[keyof typeof ChargingProductOfferAllOfRelatedProductsUnitMeasureEnum];
export const ChargingProductOfferAllOfRelatedProductsCategoryEnum = {
    Charging: 'charging',
    Refuelling: 'refuelling',
    Parking: 'parking',
    Wash: 'wash'
} as const;

export type ChargingProductOfferAllOfRelatedProductsCategoryEnum = typeof ChargingProductOfferAllOfRelatedProductsCategoryEnum[keyof typeof ChargingProductOfferAllOfRelatedProductsCategoryEnum];

/**
 * 
 * @export
 * @interface ChartsResponse
 */
export interface ChartsResponse {
    /**
     * The unique identifier of charts configurations
     * @type {string}
     * @memberof ChartsResponse
     */
    'id'?: string;
    /**
     * The configuration of all user charts
     * @type {string}
     * @memberof ChartsResponse
     */
    'configuration'?: string;
}
/**
 * 
 * @export
 * @interface CompGetSitesResponse
 */
export interface CompGetSitesResponse {
    /**
     * 
     * @type {string}
     * @memberof CompGetSitesResponse
     */
    'id'?: string;
    /**
     * Name of this site
     * @type {string}
     * @memberof CompGetSitesResponse
     */
    'name'?: string;
    /**
     * the unique id within HecOne for that site
     * @type {string}
     * @memberof CompGetSitesResponse
     */
    'code'?: string;
    /**
     * Id of the Mandator
     * @type {string}
     * @memberof CompGetSitesResponse
     */
    'mandator_id'?: string;
    /**
     * 
     * @type {string}
     * @memberof CompGetSitesResponse
     */
    'street'?: string;
    /**
     * 
     * @type {string}
     * @memberof CompGetSitesResponse
     */
    'city'?: string;
    /**
     * 
     * @type {string}
     * @memberof CompGetSitesResponse
     */
    'country'?: string;
    /**
     * 
     * @type {string}
     * @memberof CompGetSitesResponse
     */
    'currency'?: string;
    /**
     * 
     * @type {number}
     * @memberof CompGetSitesResponse
     */
    'tax_percentage'?: number;
    /**
     * 
     * @type {string}
     * @memberof CompGetSitesResponse
     */
    'site_state'?: CompGetSitesResponseSiteStateEnum;
    /**
     * 
     * @type {Gps}
     * @memberof CompGetSitesResponse
     */
    'gps'?: Gps;
    /**
     * All charging, parking or refuelling points of the site
     * @type {number}
     * @memberof CompGetSitesResponse
     */
    'capacity'?: number;
    /**
     * Timezone for a site
     * @type {string}
     * @memberof CompGetSitesResponse
     */
    'timezone'?: string;
    /**
     * Default language for a site
     * @type {string}
     * @memberof CompGetSitesResponse
     */
    'default_language'?: string;
}

export const CompGetSitesResponseSiteStateEnum = {
    Active: 'active',
    Inactive: 'inactive',
    Construction: 'construction',
    Dismantled: 'dismantled'
} as const;

export type CompGetSitesResponseSiteStateEnum = typeof CompGetSitesResponseSiteStateEnum[keyof typeof CompGetSitesResponseSiteStateEnum];

/**
 * 
 * @export
 * @interface Components
 */
export interface Components {
    /**
     * The id of the component
     * @type {string}
     * @memberof Components
     */
    'id'?: string;
    /**
     * The name of the module
     * @type {string}
     * @memberof Components
     */
    'module'?: string;
    /**
     * The name of the component
     * @type {string}
     * @memberof Components
     */
    'component'?: string;
    /**
     * The name of the sub-component
     * @type {string}
     * @memberof Components
     */
    'sub_component'?: string;
    /**
     * Determines if it is a payable service
     * @type {boolean}
     * @memberof Components
     */
    'is_service'?: boolean;
}
/**
 * 
 * @export
 * @interface CompositeFilterDescriptor
 */
export interface CompositeFilterDescriptor {
    /**
     * 
     * @type {string}
     * @memberof CompositeFilterDescriptor
     */
    'logic': CompositeFilterDescriptorLogicEnum;
    /**
     * 
     * @type {Array<CompositeFilterDescriptorAllOfFilters>}
     * @memberof CompositeFilterDescriptor
     */
    'filters': Array<CompositeFilterDescriptorAllOfFilters>;
}

export const CompositeFilterDescriptorLogicEnum = {
    Or: 'or',
    And: 'and'
} as const;

export type CompositeFilterDescriptorLogicEnum = typeof CompositeFilterDescriptorLogicEnum[keyof typeof CompositeFilterDescriptorLogicEnum];

/**
 * @type CompositeFilterDescriptorAllOfFilters
 * @export
 */
export type CompositeFilterDescriptorAllOfFilters = FilterDescriptor | SimpleFilterDescriptor;

/**
 * 
 * @export
 * @interface ConfigurationFieldMandator
 */
export interface ConfigurationFieldMandator {
    /**
     * 
     * @type {string}
     * @memberof ConfigurationFieldMandator
     */
    'name'?: string;
    /**
     * 
     * @type {ConfigurationFieldMandatorValue}
     * @memberof ConfigurationFieldMandator
     */
    'value'?: ConfigurationFieldMandatorValue;
    /**
     * 
     * @type {string}
     * @memberof ConfigurationFieldMandator
     */
    'type'?: ConfigurationFieldMandatorTypeEnum;
    /**
     * 
     * @type {boolean}
     * @memberof ConfigurationFieldMandator
     */
    'is_required'?: boolean;
}

export const ConfigurationFieldMandatorTypeEnum = {
    String: 'string',
    Number: 'number',
    Boolean: 'boolean',
    File: 'file'
} as const;

export type ConfigurationFieldMandatorTypeEnum = typeof ConfigurationFieldMandatorTypeEnum[keyof typeof ConfigurationFieldMandatorTypeEnum];

/**
 * 
 * @export
 * @interface ConfigurationFieldMandatorValue
 */
export interface ConfigurationFieldMandatorValue {
}
/**
 * 
 * @export
 * @interface ConstraintsResponse
 */
export interface ConstraintsResponse {
    /**
     * 
     * @type {ConstraintsResponseAllOfLinks}
     * @memberof ConstraintsResponse
     */
    '_links'?: ConstraintsResponseAllOfLinks;
    /**
     * 
     * @type {ConstraintsResponseAllOfMeta}
     * @memberof ConstraintsResponse
     */
    'meta'?: ConstraintsResponseAllOfMeta;
    /**
     * Response for constraints
     * @type {Array<ConstraintsResponseAllOfData>}
     * @memberof ConstraintsResponse
     */
    'data'?: Array<ConstraintsResponseAllOfData>;
}
/**
 * Properties of an allowed product
 * @export
 * @interface ConstraintsResponseAllOfAllowedProducts
 */
export interface ConstraintsResponseAllOfAllowedProducts {
    /**
     * This is mandator_product_name
     * @type {string}
     * @memberof ConstraintsResponseAllOfAllowedProducts
     */
    'name'?: string;
    /**
     * This is product category
     * @type {string}
     * @memberof ConstraintsResponseAllOfAllowedProducts
     */
    'category'?: string;
    /**
     * This is product id
     * @type {string}
     * @memberof ConstraintsResponseAllOfAllowedProducts
     */
    'id'?: string;
    /**
     * This is mandator_product_code
     * @type {string}
     * @memberof ConstraintsResponseAllOfAllowedProducts
     */
    'code'?: string;
    /**
     * This is unit measure from site product
     * @type {string}
     * @memberof ConstraintsResponseAllOfAllowedProducts
     */
    'unit_measure'?: string;
}
/**
 * 
 * @export
 * @interface ConstraintsResponseAllOfData
 */
export interface ConstraintsResponseAllOfData {
    /**
     * List of allowed products
     * @type {Array<ConstraintsResponseAllOfAllowedProducts>}
     * @memberof ConstraintsResponseAllOfData
     */
    'allowed_products'?: Array<ConstraintsResponseAllOfAllowedProducts>;
    /**
     * Maximum amount allowed
     * @type {number}
     * @memberof ConstraintsResponseAllOfData
     */
    'max_amount'?: number;
    /**
     * Currency for the maximum amount
     * @type {string}
     * @memberof ConstraintsResponseAllOfData
     */
    'max_amount_currency'?: string;
}
/**
 * Links object for navigation
 * @export
 * @interface ConstraintsResponseAllOfLinks
 */
export interface ConstraintsResponseAllOfLinks {
    /**
     * 
     * @type {string}
     * @memberof ConstraintsResponseAllOfLinks
     */
    'self'?: string;
    /**
     * 
     * @type {string}
     * @memberof ConstraintsResponseAllOfLinks
     */
    'next'?: string;
    /**
     * 
     * @type {string}
     * @memberof ConstraintsResponseAllOfLinks
     */
    'prev'?: string;
    /**
     * 
     * @type {string}
     * @memberof ConstraintsResponseAllOfLinks
     */
    'first'?: string;
    /**
     * 
     * @type {string}
     * @memberof ConstraintsResponseAllOfLinks
     */
    'last'?: string;
}
/**
 * Metadata related to the result set
 * @export
 * @interface ConstraintsResponseAllOfMeta
 */
export interface ConstraintsResponseAllOfMeta {
    /**
     * The maximum number of items returned
     * @type {number}
     * @memberof ConstraintsResponseAllOfMeta
     */
    'limit'?: number;
    /**
     * The index of the first item in the current result set
     * @type {number}
     * @memberof ConstraintsResponseAllOfMeta
     */
    'start'?: number;
    /**
     * The number of items returned in the current result set
     * @type {number}
     * @memberof ConstraintsResponseAllOfMeta
     */
    'size'?: number;
    /**
     * The total number of items available across all pages
     * @type {number}
     * @memberof ConstraintsResponseAllOfMeta
     */
    'total'?: number;
}
/**
 * 
 * @export
 * @interface CreateCard201Response
 */
export interface CreateCard201Response {
    /**
     * 
     * @type {ResponseBaseLinks}
     * @memberof CreateCard201Response
     */
    '_links'?: ResponseBaseLinks;
    /**
     * 
     * @type {ResponseBaseMeta}
     * @memberof CreateCard201Response
     */
    'meta'?: ResponseBaseMeta;
    /**
     * 
     * @type {Array<IdentifiableCard>}
     * @memberof CreateCard201Response
     */
    'data'?: Array<IdentifiableCard>;
}
/**
 * 
 * @export
 * @interface CreateCardRequest
 */
export interface CreateCardRequest {
    /**
     * 
     * @type {string}
     * @memberof CreateCardRequest
     */
    'number': string;
    /**
     * 
     * @type {string}
     * @memberof CreateCardRequest
     */
    'payment_card_id': string;
    /**
     * 
     * @type {boolean}
     * @memberof CreateCardRequest
     */
    'status'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof CreateCardRequest
     */
    'is_pin_required'?: boolean;
    /**
     * if is_pin_required is true, then pin value is required
     * @type {string}
     * @memberof CreateCardRequest
     */
    'pin'?: string;
    /**
     * 
     * @type {string}
     * @memberof CreateCardRequest
     */
    'additional_info'?: string;
    /**
     * 
     * @type {string}
     * @memberof CreateCardRequest
     */
    'release_date'?: string;
    /**
     * 
     * @type {string}
     * @memberof CreateCardRequest
     */
    'expiry_date'?: string;
    /**
     * 
     * @type {string}
     * @memberof CreateCardRequest
     */
    'card_owner'?: string;
    /**
     * 
     * @type {string}
     * @memberof CreateCardRequest
     */
    'vehicle'?: string;
    /**
     * The unique identifier of the mandator to which the card belongs
     * @type {string}
     * @memberof CreateCardRequest
     */
    'mandator_id': string;
}
/**
 * 
 * @export
 * @interface CreateDevice201Response
 */
export interface CreateDevice201Response {
    /**
     * 
     * @type {ResponseBaseLinks}
     * @memberof CreateDevice201Response
     */
    '_links'?: ResponseBaseLinks;
    /**
     * 
     * @type {ResponseBaseMeta}
     * @memberof CreateDevice201Response
     */
    'meta'?: ResponseBaseMeta;
    /**
     * 
     * @type {Array<CreateDevice201ResponseAllOfData>}
     * @memberof CreateDevice201Response
     */
    'data'?: Array<CreateDevice201ResponseAllOfData>;
}
/**
 * 
 * @export
 * @interface CreateDevice201ResponseAllOfData
 */
export interface CreateDevice201ResponseAllOfData {
    /**
     * 
     * @type {number}
     * @memberof CreateDevice201ResponseAllOfData
     */
    'latitude'?: number;
    /**
     * 
     * @type {number}
     * @memberof CreateDevice201ResponseAllOfData
     */
    'longitude'?: number;
    /**
     * 
     * @type {string}
     * @memberof CreateDevice201ResponseAllOfData
     */
    'board'?: string;
    /**
     * 
     * @type {string}
     * @memberof CreateDevice201ResponseAllOfData
     */
    'touch_display'?: CreateDevice201ResponseAllOfDataTouchDisplayEnum;
    /**
     * Represents property that determines if price information is sent to ocpp devices
     * @type {boolean}
     * @memberof CreateDevice201ResponseAllOfData
     */
    'has_cdr'?: boolean;
    /**
     * The unique identifier of the device
     * @type {string}
     * @memberof CreateDevice201ResponseAllOfData
     */
    'id'?: string;
    /**
     * The unique identifier of the site to which the device belongs
     * @type {string}
     * @memberof CreateDevice201ResponseAllOfData
     */
    'site_id'?: string;
    /**
     * 
     * @type {string}
     * @memberof CreateDevice201ResponseAllOfData
     */
    'name'?: string;
    /**
     * 
     * @type {string}
     * @memberof CreateDevice201ResponseAllOfData
     */
    'code'?: string;
    /**
     * The unique identifier of the protocol assigned to the device
     * @type {string}
     * @memberof CreateDevice201ResponseAllOfData
     */
    'protocol_id'?: string;
    /**
     * 
     * @type {string}
     * @memberof CreateDevice201ResponseAllOfData
     */
    'version'?: string;
    /**
     * 
     * @type {string}
     * @memberof CreateDevice201ResponseAllOfData
     */
    'installation_date'?: string;
    /**
     * 
     * @type {boolean}
     * @memberof CreateDevice201ResponseAllOfData
     */
    'device_state'?: boolean;
    /**
     * 
     * @type {string}
     * @memberof CreateDevice201ResponseAllOfData
     */
    'device_model_id'?: string;
    /**
     * This field can be edited until the provisioning is done
     * @type {string}
     * @memberof CreateDevice201ResponseAllOfData
     */
    'mac_address'?: string;
    /**
     * This is the date when the marriage between the hardware device and aws IoT took place.
     * @type {string}
     * @memberof CreateDevice201ResponseAllOfData
     */
    'provisioning_date'?: string;
}

export const CreateDevice201ResponseAllOfDataTouchDisplayEnum = {
    Yes: 'YES',
    No: 'NO'
} as const;

export type CreateDevice201ResponseAllOfDataTouchDisplayEnum = typeof CreateDevice201ResponseAllOfDataTouchDisplayEnum[keyof typeof CreateDevice201ResponseAllOfDataTouchDisplayEnum];

/**
 * 
 * @export
 * @interface CreateDigitalReceiptTemplate201Response
 */
export interface CreateDigitalReceiptTemplate201Response {
    /**
     * 
     * @type {ResponseBaseLinks}
     * @memberof CreateDigitalReceiptTemplate201Response
     */
    '_links'?: ResponseBaseLinks;
    /**
     * 
     * @type {ResponseBaseMeta}
     * @memberof CreateDigitalReceiptTemplate201Response
     */
    'meta'?: ResponseBaseMeta;
    /**
     * 
     * @type {Array<CreateTemplateResponseComponent>}
     * @memberof CreateDigitalReceiptTemplate201Response
     */
    'data'?: Array<CreateTemplateResponseComponent>;
}
/**
 * 
 * @export
 * @interface CreateField201Response
 */
export interface CreateField201Response {
    /**
     * 
     * @type {ResponseBaseLinks}
     * @memberof CreateField201Response
     */
    '_links'?: ResponseBaseLinks;
    /**
     * 
     * @type {ResponseBaseMeta}
     * @memberof CreateField201Response
     */
    'meta'?: ResponseBaseMeta;
    /**
     * 
     * @type {Array<ProviderFieldsResponse>}
     * @memberof CreateField201Response
     */
    'data'?: Array<ProviderFieldsResponse>;
}
/**
 * 
 * @export
 * @interface CreateMandator201Response
 */
export interface CreateMandator201Response {
    /**
     * 
     * @type {ResponseBaseLinks}
     * @memberof CreateMandator201Response
     */
    '_links'?: ResponseBaseLinks;
    /**
     * 
     * @type {ResponseBaseMeta}
     * @memberof CreateMandator201Response
     */
    'meta'?: ResponseBaseMeta;
    /**
     * 
     * @type {Array<CreateMandator201ResponseAllOfData>}
     * @memberof CreateMandator201Response
     */
    'data'?: Array<CreateMandator201ResponseAllOfData>;
}
/**
 * 
 * @export
 * @interface CreateMandator201ResponseAllOfData
 */
export interface CreateMandator201ResponseAllOfData {
    /**
     * 
     * @type {string}
     * @memberof CreateMandator201ResponseAllOfData
     */
    'id'?: string;
    /**
     * The mandator registration name used for identifying a mandator
     * @type {string}
     * @memberof CreateMandator201ResponseAllOfData
     */
    'name'?: string;
    /**
     * The street address of the mandator
     * @type {string}
     * @memberof CreateMandator201ResponseAllOfData
     */
    'street'?: string;
    /**
     * The city address of the mandator
     * @type {string}
     * @memberof CreateMandator201ResponseAllOfData
     */
    'city'?: string;
    /**
     * The zip code of the mandator\'s address
     * @type {string}
     * @memberof CreateMandator201ResponseAllOfData
     */
    'code'?: string;
    /**
     * The country address of the mandator
     * @type {string}
     * @memberof CreateMandator201ResponseAllOfData
     */
    'country'?: string;
    /**
     * The status of the mandator which can be active or inactive
     * @type {boolean}
     * @memberof CreateMandator201ResponseAllOfData
     */
    'mandator_state'?: boolean;
    /**
     * The email of the registered mandator
     * @type {string}
     * @memberof CreateMandator201ResponseAllOfData
     */
    'email'?: string;
    /**
     * A description that represents the partner of the mandator or even the main mandator to which the current mandator it is a subsidiary
     * @type {string}
     * @memberof CreateMandator201ResponseAllOfData
     */
    'sales_partner'?: string;
    /**
     * Describes the mandator in a short text
     * @type {string}
     * @memberof CreateMandator201ResponseAllOfData
     */
    'description'?: string;
    /**
     * Extra features like manage cards, view the list of charging points from integrators, etc on which the mandators has access to if they pay
     * @type {Array<string>}
     * @memberof CreateMandator201ResponseAllOfData
     */
    'services'?: Array<string>;
    /**
     * The url where the logo is stored. Represents the image displayed in the header of the web application
     * @type {string}
     * @memberof CreateMandator201ResponseAllOfData
     */
    'logo'?: string;
    /**
     * A json string that represents the customized theme that will be used by the web application
     * @type {string}
     * @memberof CreateMandator201ResponseAllOfData
     */
    'theme'?: string;
}
/**
 * 
 * @export
 * @interface CreateManufacturer
 */
export interface CreateManufacturer {
    /**
     * Name of the manufacturer
     * @type {string}
     * @memberof CreateManufacturer
     */
    'name': string;
    /**
     * 
     * @type {Array<string>}
     * @memberof CreateManufacturer
     */
    'protocol_ids': Array<string>;
    /**
     * 
     * @type {Array<DeviceModelBase>}
     * @memberof CreateManufacturer
     */
    'models'?: Array<DeviceModelBase>;
}
/**
 * 
 * @export
 * @interface CreateManufacturer200Response
 */
export interface CreateManufacturer200Response {
    /**
     * 
     * @type {ResponseBaseLinks}
     * @memberof CreateManufacturer200Response
     */
    '_links'?: ResponseBaseLinks;
    /**
     * 
     * @type {ResponseBaseMeta}
     * @memberof CreateManufacturer200Response
     */
    'meta'?: ResponseBaseMeta;
    /**
     * 
     * @type {Array<CreatedManufacturer>}
     * @memberof CreateManufacturer200Response
     */
    'data'?: Array<CreatedManufacturer>;
}
/**
 * 
 * @export
 * @interface CreatePermissionGroup
 */
export interface CreatePermissionGroup {
    /**
     * The level number of the permission group
     * @type {number}
     * @memberof CreatePermissionGroup
     */
    'level': number;
    /**
     * 
     * @type {string}
     * @memberof CreatePermissionGroup
     */
    'name': string;
    /**
     * 
     * @type {Array<PermissionGroupRight>}
     * @memberof CreatePermissionGroup
     */
    'rights': Array<PermissionGroupRight>;
}
/**
 * 
 * @export
 * @interface CreatePermissionGroup201Response
 */
export interface CreatePermissionGroup201Response {
    /**
     * 
     * @type {ResponseBaseLinks}
     * @memberof CreatePermissionGroup201Response
     */
    '_links'?: ResponseBaseLinks;
    /**
     * 
     * @type {ResponseBaseMeta}
     * @memberof CreatePermissionGroup201Response
     */
    'meta'?: ResponseBaseMeta;
    /**
     * 
     * @type {Array<PermissionGroupRightResponse>}
     * @memberof CreatePermissionGroup201Response
     */
    'data'?: Array<PermissionGroupRightResponse>;
}
/**
 * 
 * @export
 * @interface CreatePoint201Response
 */
export interface CreatePoint201Response {
    /**
     * 
     * @type {ResponseBaseLinks}
     * @memberof CreatePoint201Response
     */
    '_links'?: ResponseBaseLinks;
    /**
     * 
     * @type {ResponseBaseMeta}
     * @memberof CreatePoint201Response
     */
    'meta'?: ResponseBaseMeta;
    /**
     * 
     * @type {Array<PointResponse>}
     * @memberof CreatePoint201Response
     */
    'data'?: Array<PointResponse>;
}
/**
 * 
 * @export
 * @interface CreateProduct201Response
 */
export interface CreateProduct201Response {
    /**
     * 
     * @type {ResponseBaseLinks}
     * @memberof CreateProduct201Response
     */
    '_links'?: ResponseBaseLinks;
    /**
     * 
     * @type {ResponseBaseMeta}
     * @memberof CreateProduct201Response
     */
    'meta'?: ResponseBaseMeta;
    /**
     * 
     * @type {Array<Product>}
     * @memberof CreateProduct201Response
     */
    'data'?: Array<Product>;
}
/**
 * 
 * @export
 * @interface CreateSite
 */
export interface CreateSite {
    /**
     * Name of the site
     * @type {string}
     * @memberof CreateSite
     */
    'name': string;
    /**
     * Id of the Mandator where the station is assigned
     * @type {string}
     * @memberof CreateSite
     */
    'mandator_id': string;
    /**
     * Unique HecOne station number
     * @type {string}
     * @memberof CreateSite
     */
    'code': string;
    /**
     * Street address of the site
     * @type {string}
     * @memberof CreateSite
     */
    'street': string;
    /**
     * City address of the site
     * @type {string}
     * @memberof CreateSite
     */
    'city': string;
    /**
     * Country address of the site
     * @type {string}
     * @memberof CreateSite
     */
    'country': string;
    /**
     * Status of the site
     * @type {string}
     * @memberof CreateSite
     */
    'site_state': CreateSiteSiteStateEnum;
    /**
     * Currency used for product prices of the site
     * @type {string}
     * @memberof CreateSite
     */
    'currency'?: string;
    /**
     * Tax percentage applied for product prices of the site. Each product may have a different tax percentage, based on reglementations
     * @type {number}
     * @memberof CreateSite
     */
    'tax_percentage': number;
    /**
     * All charging, parking or refuelling points of the site
     * @type {number}
     * @memberof CreateSite
     */
    'capacity'?: number;
    /**
     * 
     * @type {Array<OpeningHour>}
     * @memberof CreateSite
     */
    'opening_hours'?: Array<OpeningHour>;
    /**
     * 
     * @type {Gps}
     * @memberof CreateSite
     */
    'gps': Gps;
    /**
     * Default language of the site
     * @type {string}
     * @memberof CreateSite
     */
    'default_language': string;
    /**
     * Unique identifier for a site. If it is not provided, the id will be programmatically generated
     * @type {string}
     * @memberof CreateSite
     */
    'id'?: string;
}

export const CreateSiteSiteStateEnum = {
    Active: 'active',
    Inactive: 'inactive',
    Construction: 'construction',
    Dismantled: 'dismantled'
} as const;

export type CreateSiteSiteStateEnum = typeof CreateSiteSiteStateEnum[keyof typeof CreateSiteSiteStateEnum];

/**
 * 
 * @export
 * @interface CreateSite201Response
 */
export interface CreateSite201Response {
    /**
     * 
     * @type {ResponseBaseLinks}
     * @memberof CreateSite201Response
     */
    '_links'?: ResponseBaseLinks;
    /**
     * 
     * @type {ResponseBaseMeta}
     * @memberof CreateSite201Response
     */
    'meta'?: ResponseBaseMeta;
    /**
     * 
     * @type {Array<Site>}
     * @memberof CreateSite201Response
     */
    'data'?: Array<Site>;
}
/**
 * 
 * @export
 * @interface CreateTemplate201Response
 */
export interface CreateTemplate201Response {
    /**
     * 
     * @type {ResponseBaseLinks}
     * @memberof CreateTemplate201Response
     */
    '_links'?: ResponseBaseLinks;
    /**
     * 
     * @type {ResponseBaseMeta}
     * @memberof CreateTemplate201Response
     */
    'meta'?: ResponseBaseMeta;
    /**
     * 
     * @type {Array<ProviderTemplatesResponse>}
     * @memberof CreateTemplate201Response
     */
    'data'?: Array<ProviderTemplatesResponse>;
}
/**
 * 
 * @export
 * @interface CreateTemplateRequestComponent
 */
export interface CreateTemplateRequestComponent {
    /**
     * Unique identifier of a mandator
     * @type {string}
     * @memberof CreateTemplateRequestComponent
     */
    'mandator_id': string;
    /**
     * The name of the receipt template
     * @type {string}
     * @memberof CreateTemplateRequestComponent
     */
    'name': string;
    /**
     * The type of the receipt template
     * @type {string}
     * @memberof CreateTemplateRequestComponent
     */
    'type': CreateTemplateRequestComponentTypeEnum;
    /**
     * 
     * @type {HeaderBase}
     * @memberof CreateTemplateRequestComponent
     */
    'header': HeaderBase;
    /**
     * 
     * @type {FooterBase}
     * @memberof CreateTemplateRequestComponent
     */
    'footer': FooterBase;
    /**
     * 
     * @type {Array<TemplateFieldBase>}
     * @memberof CreateTemplateRequestComponent
     */
    'template_fields': Array<TemplateFieldBase>;
}

export const CreateTemplateRequestComponentTypeEnum = {
    Invoice: 'invoice',
    Note: 'note'
} as const;

export type CreateTemplateRequestComponentTypeEnum = typeof CreateTemplateRequestComponentTypeEnum[keyof typeof CreateTemplateRequestComponentTypeEnum];

/**
 * 
 * @export
 * @interface CreateTemplateResponseComponent
 */
export interface CreateTemplateResponseComponent {
    /**
     * Unique identifier of a mandator
     * @type {string}
     * @memberof CreateTemplateResponseComponent
     */
    'mandator_id': string;
    /**
     * The name of the receipt template
     * @type {string}
     * @memberof CreateTemplateResponseComponent
     */
    'name': string;
    /**
     * The type of the receipt template
     * @type {string}
     * @memberof CreateTemplateResponseComponent
     */
    'type': CreateTemplateResponseComponentTypeEnum;
    /**
     * Unique identifier of a template
     * @type {string}
     * @memberof CreateTemplateResponseComponent
     */
    'id': string;
    /**
     * The date when the template was created in UTC
     * @type {string}
     * @memberof CreateTemplateResponseComponent
     */
    'created_at': string;
    /**
     * 
     * @type {ExtendedHeader}
     * @memberof CreateTemplateResponseComponent
     */
    'header': ExtendedHeader;
    /**
     * 
     * @type {ExtendedFooter}
     * @memberof CreateTemplateResponseComponent
     */
    'footer': ExtendedFooter;
    /**
     * 
     * @type {Array<ExtendedTemplateField>}
     * @memberof CreateTemplateResponseComponent
     */
    'template_fields': Array<ExtendedTemplateField>;
}

export const CreateTemplateResponseComponentTypeEnum = {
    Invoice: 'invoice',
    Note: 'note'
} as const;

export type CreateTemplateResponseComponentTypeEnum = typeof CreateTemplateResponseComponentTypeEnum[keyof typeof CreateTemplateResponseComponentTypeEnum];

/**
 * 
 * @export
 * @interface CreateTransactionV3201Response
 */
export interface CreateTransactionV3201Response {
    /**
     * 
     * @type {ResponseBaseLinks}
     * @memberof CreateTransactionV3201Response
     */
    '_links'?: ResponseBaseLinks;
    /**
     * 
     * @type {ResponseBaseMeta}
     * @memberof CreateTransactionV3201Response
     */
    'meta'?: ResponseBaseMeta;
    /**
     * 
     * @type {Array<TransactionsResponse>}
     * @memberof CreateTransactionV3201Response
     */
    'data'?: Array<TransactionsResponse>;
}
/**
 * 
 * @export
 * @interface CreateUser201Response
 */
export interface CreateUser201Response {
    /**
     * 
     * @type {ResponseBaseLinks}
     * @memberof CreateUser201Response
     */
    '_links'?: ResponseBaseLinks;
    /**
     * 
     * @type {ResponseBaseMeta}
     * @memberof CreateUser201Response
     */
    'meta'?: ResponseBaseMeta;
    /**
     * 
     * @type {Array<CreatedUser>}
     * @memberof CreateUser201Response
     */
    'data'?: Array<CreatedUser>;
}
/**
 * 
 * @export
 * @interface CreatedManufacturer
 */
export interface CreatedManufacturer {
    /**
     * Name of the manufacturer
     * @type {string}
     * @memberof CreatedManufacturer
     */
    'name': string;
    /**
     * 
     * @type {string}
     * @memberof CreatedManufacturer
     */
    'id'?: string;
    /**
     * The date when manufacturer was created
     * @type {string}
     * @memberof CreatedManufacturer
     */
    'created_at'?: string;
    /**
     * 
     * @type {Array<CreatedManufacturerAllOfModels>}
     * @memberof CreatedManufacturer
     */
    'models'?: Array<CreatedManufacturerAllOfModels>;
}
/**
 * 
 * @export
 * @interface CreatedManufacturerAllOfModels
 */
export interface CreatedManufacturerAllOfModels {
    /**
     * Device type name
     * @type {string}
     * @memberof CreatedManufacturerAllOfModels
     */
    'system_name': string;
    /**
     * How many seconds should we wait for Start after we send a Remote Start
     * @type {number}
     * @memberof CreatedManufacturerAllOfModels
     */
    'timeout': number;
    /**
     * Number of missed heartbeats -> after this number of missed heartbeats we close the websocket connection
     * @type {number}
     * @memberof CreatedManufacturerAllOfModels
     */
    'heartbeat_retries': number;
    /**
     * Device model hearbeat interval
     * @type {number}
     * @memberof CreatedManufacturerAllOfModels
     */
    'heartbeat_interval': number;
    /**
     * How many times to try calling the Remote Start and Stop
     * @type {number}
     * @memberof CreatedManufacturerAllOfModels
     */
    'remote_call_retries': number;
    /**
     * Device model meter value interval
     * @type {number}
     * @memberof CreatedManufacturerAllOfModels
     */
    'meter_value_interval'?: number;
    /**
     * This field represents whether a charging device was tested and can be offered to end customers
     * @type {string}
     * @memberof CreatedManufacturerAllOfModels
     */
    'test'?: CreatedManufacturerAllOfModelsTestEnum;
    /**
     * The version of the model that has been tested
     * @type {string}
     * @memberof CreatedManufacturerAllOfModels
     */
    'version'?: string;
    /**
     * 
     * @type {string}
     * @memberof CreatedManufacturerAllOfModels
     */
    'id'?: string;
    /**
     * 
     * @type {string}
     * @memberof CreatedManufacturerAllOfModels
     */
    'manufacturer_id'?: string;
}

export const CreatedManufacturerAllOfModelsTestEnum = {
    InTest: 'in_test',
    Open: 'open',
    Tested: 'tested'
} as const;

export type CreatedManufacturerAllOfModelsTestEnum = typeof CreatedManufacturerAllOfModelsTestEnum[keyof typeof CreatedManufacturerAllOfModelsTestEnum];

/**
 * 
 * @export
 * @interface CreatedUser
 */
export interface CreatedUser {
    /**
     * The first name of the user that registers
     * @type {string}
     * @memberof CreatedUser
     */
    'first_name'?: string;
    /**
     * The last name of the user that registers
     * @type {string}
     * @memberof CreatedUser
     */
    'last_name'?: string;
    /**
     * The gender of the user that registers; m - masculine, f - feminine, u - diverse
     * @type {string}
     * @memberof CreatedUser
     */
    'gender'?: CreatedUserGenderEnum;
    /**
     * The phone number of the user that registers
     * @type {string}
     * @memberof CreatedUser
     */
    'phone'?: string;
    /**
     * The id of the user\'s chosen default mandator. When the user logs in the web application, it has the preferred mandator set as default
     * @type {string}
     * @memberof CreatedUser
     */
    'preferred_mandator'?: string;
    /**
     * The default page number chosen by the user. When the user logs in the web application, the landing page is the page number set
     * @type {string}
     * @memberof CreatedUser
     */
    'page'?: string;
    /**
     * A comment note about the user
     * @type {string}
     * @memberof CreatedUser
     */
    'comment'?: string;
    /**
     * If it is set to true, the landing page and preferred mandator are taken into consideration
     * @type {boolean}
     * @memberof CreatedUser
     */
    'user_action'?: boolean;
    /**
     * The email of the user used as username in cognito
     * @type {string}
     * @memberof CreatedUser
     */
    'id'?: string;
    /**
     * The unique identifier of the user following a UUID v4 format received from cognito
     * @type {string}
     * @memberof CreatedUser
     */
    'sub'?: string;
    /**
     * The email address used for registration by the user
     * @type {string}
     * @memberof CreatedUser
     */
    'email'?: string;
    /**
     * This field is set if the user accepted the eula
     * @type {boolean}
     * @memberof CreatedUser
     */
    'accepted_eula'?: boolean;
}

export const CreatedUserGenderEnum = {
    F: 'f',
    M: 'm',
    U: 'u'
} as const;

export type CreatedUserGenderEnum = typeof CreatedUserGenderEnum[keyof typeof CreatedUserGenderEnum];

/**
 * 
 * @export
 * @interface CustomerData
 */
export interface CustomerData {
    /**
     * 
     * @type {number}
     * @memberof CustomerData
     */
    'odometer'?: number;
    /**
     * 
     * @type {string}
     * @memberof CustomerData
     */
    'driver_number'?: string;
    /**
     * 
     * @type {string}
     * @memberof CustomerData
     */
    'vehicle_number'?: string;
    /**
     * 
     * @type {string}
     * @memberof CustomerData
     */
    'additional_info'?: string;
    /**
     * 
     * @type {string}
     * @memberof CustomerData
     */
    'license_plate'?: string;
    /**
     * 
     * @type {string}
     * @memberof CustomerData
     */
    'cost_center'?: string;
}
/**
 * 
 * @export
 * @interface CustomerDataObject
 */
export interface CustomerDataObject {
    /**
     * 
     * @type {number}
     * @memberof CustomerDataObject
     */
    'odometer'?: number;
    /**
     * 
     * @type {string}
     * @memberof CustomerDataObject
     */
    'driver_number'?: string;
    /**
     * 
     * @type {string}
     * @memberof CustomerDataObject
     */
    'vehicle_number'?: string;
    /**
     * 
     * @type {string}
     * @memberof CustomerDataObject
     */
    'additional_info'?: string;
    /**
     * 
     * @type {string}
     * @memberof CustomerDataObject
     */
    'license_plate'?: string;
    /**
     * 
     * @type {string}
     * @memberof CustomerDataObject
     */
    'cost_center'?: string;
}
/**
 * 
 * @export
 * @interface DeleteDevices200Response
 */
export interface DeleteDevices200Response {
    /**
     * 
     * @type {ResponseBaseLinks}
     * @memberof DeleteDevices200Response
     */
    '_links'?: ResponseBaseLinks;
    /**
     * 
     * @type {ResponseBaseMeta}
     * @memberof DeleteDevices200Response
     */
    'meta'?: ResponseBaseMeta;
    /**
     * 
     * @type {Array<number>}
     * @memberof DeleteDevices200Response
     */
    'data'?: Array<number>;
}
/**
 * 
 * @export
 * @interface DeleteDevicesRequest
 */
export interface DeleteDevicesRequest {
    /**
     * 
     * @type {Array<string>}
     * @memberof DeleteDevicesRequest
     */
    'device_ids': Array<string>;
    /**
     * Allows to delete also the points that are assigned to selected devices
     * @type {boolean}
     * @memberof DeleteDevicesRequest
     */
    'delete_points': boolean;
}
/**
 * 
 * @export
 * @interface DeleteUser200Response
 */
export interface DeleteUser200Response {
    /**
     * 
     * @type {ResponseBaseLinks}
     * @memberof DeleteUser200Response
     */
    '_links'?: ResponseBaseLinks;
    /**
     * 
     * @type {ResponseBaseMeta}
     * @memberof DeleteUser200Response
     */
    'meta'?: ResponseBaseMeta;
    /**
     * 
     * @type {Array<DeleteUser200ResponseAllOfDataInner>}
     * @memberof DeleteUser200Response
     */
    'data'?: Array<DeleteUser200ResponseAllOfDataInner>;
}
/**
 * 
 * @export
 * @interface DeleteUser200ResponseAllOfDataInner
 */
export interface DeleteUser200ResponseAllOfDataInner {
    /**
     * 
     * @type {string}
     * @memberof DeleteUser200ResponseAllOfDataInner
     */
    'responseMessage'?: string;
}
/**
 * 
 * @export
 * @interface Device
 */
export interface Device {
    /**
     * The unique identifier of the device
     * @type {string}
     * @memberof Device
     */
    'id'?: string;
    /**
     * The unique identifier of the site to which the device belongs
     * @type {string}
     * @memberof Device
     */
    'site_id'?: string;
    /**
     * 
     * @type {string}
     * @memberof Device
     */
    'name'?: string;
    /**
     * 
     * @type {string}
     * @memberof Device
     */
    'code'?: string;
    /**
     * The unique identifier of the protocol assigned to the device
     * @type {string}
     * @memberof Device
     */
    'protocol_id'?: string;
    /**
     * 
     * @type {string}
     * @memberof Device
     */
    'version'?: string;
    /**
     * 
     * @type {string}
     * @memberof Device
     */
    'installation_date'?: string;
    /**
     * 
     * @type {boolean}
     * @memberof Device
     */
    'device_state'?: boolean;
    /**
     * 
     * @type {string}
     * @memberof Device
     */
    'device_model_id'?: string;
    /**
     * This field can be edited until the provisioning is done
     * @type {string}
     * @memberof Device
     */
    'mac_address'?: string;
    /**
     * This is the date when the marriage between the hardware device and aws IoT took place.
     * @type {string}
     * @memberof Device
     */
    'provisioning_date'?: string;
    /**
     * The name of the site to which the device belongs
     * @type {string}
     * @memberof Device
     */
    'site_name'?: string;
    /**
     * 
     * @type {string}
     * @memberof Device
     */
    'device_model_name'?: DeviceDeviceModelNameEnum;
    /**
     * 
     * @type {Gps}
     * @memberof Device
     */
    'gps'?: Gps;
    /**
     * The id of the manufacturer based on the protocol
     * @type {string}
     * @memberof Device
     */
    'manufacturer_id'?: string;
    /**
     * The name of the manufacturer
     * @type {string}
     * @memberof Device
     */
    'manufacturer_name'?: DeviceManufacturerNameEnum;
    /**
     * 
     * @type {string}
     * @memberof Device
     */
    'protocol_name'?: string;
}

export const DeviceDeviceModelNameEnum = {
    HecStar: 'HecStar',
    HecPay: 'HecPay',
    Pico: 'PICO',
    HecPosT: 'HecPOS-T'
} as const;

export type DeviceDeviceModelNameEnum = typeof DeviceDeviceModelNameEnum[keyof typeof DeviceDeviceModelNameEnum];
export const DeviceManufacturerNameEnum = {
    Hectronic: 'Hectronic',
    Compleo: 'Compleo',
    Abb: 'ABB'
} as const;

export type DeviceManufacturerNameEnum = typeof DeviceManufacturerNameEnum[keyof typeof DeviceManufacturerNameEnum];

/**
 * Is a configurable piece of hardware. Citea, Pico, HecStar, HecVision, ABB, Compleo, ...
 * @export
 * @interface DeviceBase
 */
export interface DeviceBase {
    /**
     * The unique identifier of the device
     * @type {string}
     * @memberof DeviceBase
     */
    'id'?: string;
    /**
     * The unique identifier of the site to which the device belongs
     * @type {string}
     * @memberof DeviceBase
     */
    'site_id'?: string;
    /**
     * 
     * @type {string}
     * @memberof DeviceBase
     */
    'name'?: string;
    /**
     * 
     * @type {string}
     * @memberof DeviceBase
     */
    'code'?: string;
    /**
     * The unique identifier of the protocol assigned to the device
     * @type {string}
     * @memberof DeviceBase
     */
    'protocol_id'?: string;
    /**
     * 
     * @type {string}
     * @memberof DeviceBase
     */
    'version'?: string;
    /**
     * 
     * @type {string}
     * @memberof DeviceBase
     */
    'installation_date'?: string;
    /**
     * 
     * @type {boolean}
     * @memberof DeviceBase
     */
    'device_state'?: boolean;
    /**
     * 
     * @type {string}
     * @memberof DeviceBase
     */
    'device_model_id'?: string;
    /**
     * This field can be edited until the provisioning is done
     * @type {string}
     * @memberof DeviceBase
     */
    'mac_address'?: string;
    /**
     * This is the date when the marriage between the hardware device and aws IoT took place.
     * @type {string}
     * @memberof DeviceBase
     */
    'provisioning_date'?: string;
}
/**
 * 
 * @export
 * @interface DeviceExtended
 */
export interface DeviceExtended {
    /**
     * The name of the site to which the device belongs
     * @type {string}
     * @memberof DeviceExtended
     */
    'site_name'?: string;
    /**
     * 
     * @type {string}
     * @memberof DeviceExtended
     */
    'device_model_name'?: DeviceExtendedDeviceModelNameEnum;
    /**
     * 
     * @type {Gps}
     * @memberof DeviceExtended
     */
    'gps'?: Gps;
    /**
     * The id of the manufacturer based on the protocol
     * @type {string}
     * @memberof DeviceExtended
     */
    'manufacturer_id'?: string;
    /**
     * The name of the manufacturer
     * @type {string}
     * @memberof DeviceExtended
     */
    'manufacturer_name'?: DeviceExtendedManufacturerNameEnum;
    /**
     * 
     * @type {string}
     * @memberof DeviceExtended
     */
    'protocol_name'?: string;
    /**
     * The unique identifier of the device
     * @type {string}
     * @memberof DeviceExtended
     */
    'id'?: string;
    /**
     * The unique identifier of the site to which the device belongs
     * @type {string}
     * @memberof DeviceExtended
     */
    'site_id'?: string;
    /**
     * 
     * @type {string}
     * @memberof DeviceExtended
     */
    'name'?: string;
    /**
     * 
     * @type {string}
     * @memberof DeviceExtended
     */
    'code'?: string;
    /**
     * The unique identifier of the protocol assigned to the device
     * @type {string}
     * @memberof DeviceExtended
     */
    'protocol_id'?: string;
    /**
     * 
     * @type {string}
     * @memberof DeviceExtended
     */
    'version'?: string;
    /**
     * 
     * @type {string}
     * @memberof DeviceExtended
     */
    'installation_date'?: string;
    /**
     * 
     * @type {boolean}
     * @memberof DeviceExtended
     */
    'device_state'?: boolean;
    /**
     * 
     * @type {string}
     * @memberof DeviceExtended
     */
    'device_model_id'?: string;
    /**
     * 
     * @type {string}
     * @memberof DeviceExtended
     */
    'mac_address'?: string;
    /**
     * 
     * @type {string}
     * @memberof DeviceExtended
     */
    'provisioning_date'?: string;
    /**
     * 
     * @type {string}
     * @memberof DeviceExtended
     */
    'board'?: string;
    /**
     * 
     * @type {string}
     * @memberof DeviceExtended
     */
    'touch_display'?: DeviceExtendedTouchDisplayEnum;
    /**
     * Represents property that determines if price information is sent to ocpp devices
     * @type {boolean}
     * @memberof DeviceExtended
     */
    'has_cdr'?: boolean;
}

export const DeviceExtendedDeviceModelNameEnum = {
    HecStar: 'HecStar',
    HecPay: 'HecPay',
    Pico: 'PICO',
    HecPosT: 'HecPOS-T'
} as const;

export type DeviceExtendedDeviceModelNameEnum = typeof DeviceExtendedDeviceModelNameEnum[keyof typeof DeviceExtendedDeviceModelNameEnum];
export const DeviceExtendedManufacturerNameEnum = {
    Hectronic: 'Hectronic',
    Compleo: 'Compleo',
    Abb: 'ABB'
} as const;

export type DeviceExtendedManufacturerNameEnum = typeof DeviceExtendedManufacturerNameEnum[keyof typeof DeviceExtendedManufacturerNameEnum];
export const DeviceExtendedTouchDisplayEnum = {
    Yes: 'YES',
    No: 'NO'
} as const;

export type DeviceExtendedTouchDisplayEnum = typeof DeviceExtendedTouchDisplayEnum[keyof typeof DeviceExtendedTouchDisplayEnum];

/**
 * 
 * @export
 * @interface DeviceLogs
 */
export interface DeviceLogs {
    /**
     * 
     * @type {string}
     * @memberof DeviceLogs
     */
    'message_id'?: string;
    /**
     * 
     * @type {string}
     * @memberof DeviceLogs
     */
    'message_type'?: string;
    /**
     * Stringified data
     * @type {string}
     * @memberof DeviceLogs
     */
    'data'?: string;
    /**
     * 
     * @type {string}
     * @memberof DeviceLogs
     */
    'level': DeviceLogsLevelEnum;
    /**
     * 
     * @type {number}
     * @memberof DeviceLogs
     */
    'point_number'?: number;
    /**
     * 
     * @type {string}
     * @memberof DeviceLogs
     */
    'transaction_id'?: string;
    /**
     * 
     * @type {string}
     * @memberof DeviceLogs
     */
    'timestamp'?: string;
}

export const DeviceLogsLevelEnum = {
    Info: 'info',
    Warning: 'warning',
    Error: 'error'
} as const;

export type DeviceLogsLevelEnum = typeof DeviceLogsLevelEnum[keyof typeof DeviceLogsLevelEnum];

/**
 * 
 * @export
 * @interface DeviceModel
 */
export interface DeviceModel {
    /**
     * Device type name
     * @type {string}
     * @memberof DeviceModel
     */
    'system_name': string;
    /**
     * How many seconds should we wait for Start after we send a Remote Start
     * @type {number}
     * @memberof DeviceModel
     */
    'timeout': number;
    /**
     * Number of missed heartbeats -> after this number of missed heartbeats we close the websocket connection
     * @type {number}
     * @memberof DeviceModel
     */
    'heartbeat_retries': number;
    /**
     * Device model hearbeat interval
     * @type {number}
     * @memberof DeviceModel
     */
    'heartbeat_interval': number;
    /**
     * How many times to try calling the Remote Start and Stop
     * @type {number}
     * @memberof DeviceModel
     */
    'remote_call_retries': number;
    /**
     * Device model meter value interval
     * @type {number}
     * @memberof DeviceModel
     */
    'meter_value_interval'?: number;
    /**
     * This field represents whether a charging device was tested and can be offered to end customers
     * @type {string}
     * @memberof DeviceModel
     */
    'test'?: DeviceModelTestEnum;
    /**
     * The version of the model that has been tested
     * @type {string}
     * @memberof DeviceModel
     */
    'version'?: string;
    /**
     * Device type unique identifier
     * @type {string}
     * @memberof DeviceModel
     */
    'id'?: string;
    /**
     * 
     * @type {string}
     * @memberof DeviceModel
     */
    'manufacturer_id'?: string;
}

export const DeviceModelTestEnum = {
    InTest: 'in_test',
    Open: 'open',
    Tested: 'tested'
} as const;

export type DeviceModelTestEnum = typeof DeviceModelTestEnum[keyof typeof DeviceModelTestEnum];

/**
 * 
 * @export
 * @interface DeviceModelBase
 */
export interface DeviceModelBase {
    /**
     * Device type name
     * @type {string}
     * @memberof DeviceModelBase
     */
    'system_name': string;
    /**
     * How many seconds should we wait for Start after we send a Remote Start
     * @type {number}
     * @memberof DeviceModelBase
     */
    'timeout': number;
    /**
     * Number of missed heartbeats -> after this number of missed heartbeats we close the websocket connection
     * @type {number}
     * @memberof DeviceModelBase
     */
    'heartbeat_retries': number;
    /**
     * Device model hearbeat interval
     * @type {number}
     * @memberof DeviceModelBase
     */
    'heartbeat_interval': number;
    /**
     * How many times to try calling the Remote Start and Stop
     * @type {number}
     * @memberof DeviceModelBase
     */
    'remote_call_retries': number;
    /**
     * Device model meter value interval
     * @type {number}
     * @memberof DeviceModelBase
     */
    'meter_value_interval'?: number;
    /**
     * This field represents whether a charging device was tested and can be offered to end customers
     * @type {string}
     * @memberof DeviceModelBase
     */
    'test'?: DeviceModelBaseTestEnum;
    /**
     * The version of the model that has been tested
     * @type {string}
     * @memberof DeviceModelBase
     */
    'version'?: string;
}

export const DeviceModelBaseTestEnum = {
    InTest: 'in_test',
    Open: 'open',
    Tested: 'tested'
} as const;

export type DeviceModelBaseTestEnum = typeof DeviceModelBaseTestEnum[keyof typeof DeviceModelBaseTestEnum];

/**
 * 
 * @export
 * @interface DeviceRequest
 */
export interface DeviceRequest {
    /**
     * The id of the protocol used for communicating with the device
     * @type {string}
     * @memberof DeviceRequest
     */
    'protocol_id': string;
    /**
     * Represents the type of the device based on the protocol used for communicating with the device. For charging points it represents the chargePointModel
     * @type {string}
     * @memberof DeviceRequest
     */
    'device_model_id'?: string;
    /**
     * Id of the site where the device is assigned
     * @type {string}
     * @memberof DeviceRequest
     */
    'site_id': string;
    /**
     * Name of this device
     * @type {string}
     * @memberof DeviceRequest
     */
    'name': string;
    /**
     * Unique HecOne device number
     * @type {string}
     * @memberof DeviceRequest
     */
    'code': string;
    /**
     * Represents the firmware version for charging points
     * @type {string}
     * @memberof DeviceRequest
     */
    'version'?: string;
    /**
     * Status of the device: true for active, false for inactive
     * @type {boolean}
     * @memberof DeviceRequest
     */
    'device_state'?: boolean;
    /**
     * The default date would be the date when the device is added.
     * @type {string}
     * @memberof DeviceRequest
     */
    'installation_date'?: string;
    /**
     * 
     * @type {string}
     * @memberof DeviceRequest
     */
    'board'?: string;
    /**
     * 
     * @type {string}
     * @memberof DeviceRequest
     */
    'touch_display'?: DeviceRequestTouchDisplayEnum;
    /**
     * Represents property that determines if price information is sent to ocpp devices
     * @type {boolean}
     * @memberof DeviceRequest
     */
    'has_cdr'?: boolean;
    /**
     * 
     * @type {Gps}
     * @memberof DeviceRequest
     */
    'gps'?: Gps;
    /**
     * Password used when adding a device in cognito as an user
     * @type {string}
     * @memberof DeviceRequest
     */
    'cognito_password'?: string;
    /**
     * This field can be edited until the provisioning is done
     * @type {string}
     * @memberof DeviceRequest
     */
    'mac_address'?: string;
}

export const DeviceRequestTouchDisplayEnum = {
    Yes: 'YES',
    No: 'NO'
} as const;

export type DeviceRequestTouchDisplayEnum = typeof DeviceRequestTouchDisplayEnum[keyof typeof DeviceRequestTouchDisplayEnum];

/**
 * 
 * @export
 * @interface DeviceRequestExtended
 */
export interface DeviceRequestExtended {
    /**
     * The id of the protocol used for communicating with the device
     * @type {string}
     * @memberof DeviceRequestExtended
     */
    'protocol_id': string;
    /**
     * Represents the type of the device based on the protocol used for communicating with the device. For charging points it represents the chargePointModel
     * @type {string}
     * @memberof DeviceRequestExtended
     */
    'device_model_id'?: string;
    /**
     * Id of the site where the device is assigned
     * @type {string}
     * @memberof DeviceRequestExtended
     */
    'site_id': string;
    /**
     * Name of this device
     * @type {string}
     * @memberof DeviceRequestExtended
     */
    'name': string;
    /**
     * Unique HecOne device number
     * @type {string}
     * @memberof DeviceRequestExtended
     */
    'code': string;
    /**
     * Represents the firmware version for charging points
     * @type {string}
     * @memberof DeviceRequestExtended
     */
    'version'?: string;
    /**
     * Status of the device: true for active, false for inactive
     * @type {boolean}
     * @memberof DeviceRequestExtended
     */
    'device_state'?: boolean;
    /**
     * The default date would be the date when the device is added.
     * @type {string}
     * @memberof DeviceRequestExtended
     */
    'installation_date'?: string;
    /**
     * 
     * @type {string}
     * @memberof DeviceRequestExtended
     */
    'board'?: string;
    /**
     * 
     * @type {string}
     * @memberof DeviceRequestExtended
     */
    'touch_display'?: DeviceRequestExtendedTouchDisplayEnum;
    /**
     * Represents property that determines if price information is sent to ocpp devices
     * @type {boolean}
     * @memberof DeviceRequestExtended
     */
    'has_cdr'?: boolean;
    /**
     * 
     * @type {Gps}
     * @memberof DeviceRequestExtended
     */
    'gps'?: Gps;
    /**
     * Password used when adding a device in cognito as an user
     * @type {string}
     * @memberof DeviceRequestExtended
     */
    'cognito_password'?: string;
    /**
     * This field can be edited until the provisioning is done
     * @type {string}
     * @memberof DeviceRequestExtended
     */
    'mac_address'?: string;
    /**
     * Unique identifier for a device. If it is not provided, the id will be programmatically generated
     * @type {string}
     * @memberof DeviceRequestExtended
     */
    'id'?: string;
}

export const DeviceRequestExtendedTouchDisplayEnum = {
    Yes: 'YES',
    No: 'NO'
} as const;

export type DeviceRequestExtendedTouchDisplayEnum = typeof DeviceRequestExtendedTouchDisplayEnum[keyof typeof DeviceRequestExtendedTouchDisplayEnum];

/**
 * 
 * @export
 * @interface DeviceResponseExtended
 */
export interface DeviceResponseExtended {
    /**
     * The unique identifier of the device
     * @type {string}
     * @memberof DeviceResponseExtended
     */
    'id'?: string;
    /**
     * The unique identifier of the site to which the device belongs
     * @type {string}
     * @memberof DeviceResponseExtended
     */
    'site_id'?: string;
    /**
     * 
     * @type {string}
     * @memberof DeviceResponseExtended
     */
    'name'?: string;
    /**
     * 
     * @type {string}
     * @memberof DeviceResponseExtended
     */
    'code'?: string;
    /**
     * The unique identifier of the protocol assigned to the device
     * @type {string}
     * @memberof DeviceResponseExtended
     */
    'protocol_id'?: string;
    /**
     * 
     * @type {string}
     * @memberof DeviceResponseExtended
     */
    'version'?: string;
    /**
     * 
     * @type {string}
     * @memberof DeviceResponseExtended
     */
    'installation_date'?: string;
    /**
     * 
     * @type {boolean}
     * @memberof DeviceResponseExtended
     */
    'device_state'?: boolean;
    /**
     * 
     * @type {string}
     * @memberof DeviceResponseExtended
     */
    'device_model_id'?: string;
    /**
     * This field can be edited until the provisioning is done
     * @type {string}
     * @memberof DeviceResponseExtended
     */
    'mac_address'?: string;
    /**
     * This is the date when the marriage between the hardware device and aws IoT took place.
     * @type {string}
     * @memberof DeviceResponseExtended
     */
    'provisioning_date'?: string;
    /**
     * 
     * @type {number}
     * @memberof DeviceResponseExtended
     */
    'latitude'?: number;
    /**
     * 
     * @type {number}
     * @memberof DeviceResponseExtended
     */
    'longitude'?: number;
    /**
     * 
     * @type {string}
     * @memberof DeviceResponseExtended
     */
    'board'?: string;
    /**
     * 
     * @type {string}
     * @memberof DeviceResponseExtended
     */
    'touch_display'?: DeviceResponseExtendedTouchDisplayEnum;
    /**
     * Represents property that determines if price information is sent to ocpp devices
     * @type {boolean}
     * @memberof DeviceResponseExtended
     */
    'has_cdr'?: boolean;
}

export const DeviceResponseExtendedTouchDisplayEnum = {
    Yes: 'YES',
    No: 'NO'
} as const;

export type DeviceResponseExtendedTouchDisplayEnum = typeof DeviceResponseExtendedTouchDisplayEnum[keyof typeof DeviceResponseExtendedTouchDisplayEnum];

/**
 * 
 * @export
 * @interface ErrorData
 */
export interface ErrorData {
    /**
     * 
     * @type {string}
     * @memberof ErrorData
     */
    'user_message'?: string;
    /**
     * 
     * @type {string}
     * @memberof ErrorData
     */
    'internal_message'?: string;
    /**
     * 
     * @type {number}
     * @memberof ErrorData
     */
    'code'?: number;
    /**
     * 
     * @type {string}
     * @memberof ErrorData
     */
    'more_info'?: string;
}
/**
 * 
 * @export
 * @interface Export200Response
 */
export interface Export200Response {
    /**
     * 
     * @type {string}
     * @memberof Export200Response
     */
    'access_file_path'?: string;
}
/**
 * 
 * @export
 * @interface ExtendedEntityWithId
 */
export interface ExtendedEntityWithId {
    /**
     * 
     * @type {string}
     * @memberof ExtendedEntityWithId
     */
    'id': string;
}
/**
 * 
 * @export
 * @interface ExtendedEntityWithTemplateId
 */
export interface ExtendedEntityWithTemplateId {
    /**
     * 
     * @type {string}
     * @memberof ExtendedEntityWithTemplateId
     */
    'template_id': string;
}
/**
 * 
 * @export
 * @interface ExtendedFooter
 */
export interface ExtendedFooter {
    /**
     * 
     * @type {string}
     * @memberof ExtendedFooter
     */
    'template_id': string;
    /**
     * 
     * @type {string}
     * @memberof ExtendedFooter
     */
    'id': string;
    /**
     * The url where the advertising logo is stored. Represents the image displayed in footer
     * @type {string}
     * @memberof ExtendedFooter
     */
    'advertising_logo'?: string;
    /**
     * The position of the advertising logo
     * @type {string}
     * @memberof ExtendedFooter
     */
    'advertising_alignment'?: ExtendedFooterAdvertisingAlignmentEnum;
    /**
     * Not implemented
     * @type {boolean}
     * @memberof ExtendedFooter
     */
    'show_QRCode'?: boolean;
    /**
     * 
     * @type {string}
     * @memberof ExtendedFooter
     */
    'description': string;
}

export const ExtendedFooterAdvertisingAlignmentEnum = {
    Right: 'right',
    Center: 'center',
    Left: 'left'
} as const;

export type ExtendedFooterAdvertisingAlignmentEnum = typeof ExtendedFooterAdvertisingAlignmentEnum[keyof typeof ExtendedFooterAdvertisingAlignmentEnum];

/**
 * 
 * @export
 * @interface ExtendedHeader
 */
export interface ExtendedHeader {
    /**
     * 
     * @type {string}
     * @memberof ExtendedHeader
     */
    'template_id': string;
    /**
     * 
     * @type {string}
     * @memberof ExtendedHeader
     */
    'id': string;
    /**
     * The url where the logo is stored. Represents the image displayed on receipt header
     * @type {string}
     * @memberof ExtendedHeader
     */
    'logo': string;
    /**
     * Title of the receipt
     * @type {string}
     * @memberof ExtendedHeader
     */
    'title'?: string;
    /**
     * The position of the logo image
     * @type {string}
     * @memberof ExtendedHeader
     */
    'logo_alignment': ExtendedHeaderLogoAlignmentEnum;
    /**
     * 
     * @type {string}
     * @memberof ExtendedHeader
     */
    'contact_details': string;
}

export const ExtendedHeaderLogoAlignmentEnum = {
    Right: 'right',
    Left: 'left'
} as const;

export type ExtendedHeaderLogoAlignmentEnum = typeof ExtendedHeaderLogoAlignmentEnum[keyof typeof ExtendedHeaderLogoAlignmentEnum];

/**
 * 
 * @export
 * @interface ExtendedPermissionGroup
 */
export interface ExtendedPermissionGroup {
    /**
     * The unique identifier of the permission group following a UUID v4 format
     * @type {string}
     * @memberof ExtendedPermissionGroup
     */
    'id'?: string;
    /**
     * The level number of the permission group
     * @type {number}
     * @memberof ExtendedPermissionGroup
     */
    'level'?: number;
    /**
     * The name of the permission group
     * @type {string}
     * @memberof ExtendedPermissionGroup
     */
    'name'?: string;
    /**
     * The date & time when the permission group was created
     * @type {string}
     * @memberof ExtendedPermissionGroup
     */
    'created_at'?: string;
    /**
     * 
     * @type {Array<PermissionGroupRight>}
     * @memberof ExtendedPermissionGroup
     */
    'rights'?: Array<PermissionGroupRight>;
    /**
     * The number of users which share the same permission group rights
     * @type {number}
     * @memberof ExtendedPermissionGroup
     */
    'count_of_users'?: number;
}
/**
 * 
 * @export
 * @interface ExtendedRight
 */
export interface ExtendedRight {
    /**
     * The unique identifier of the sub-component following a UUID v4 format
     * @type {string}
     * @memberof ExtendedRight
     */
    'sub_component_id'?: string;
    /**
     * The name of the sub-component
     * @type {string}
     * @memberof ExtendedRight
     */
    'sub_component'?: string;
    /**
     * The name of the component
     * @type {string}
     * @memberof ExtendedRight
     */
    'component'?: string;
    /**
     * The name of the module
     * @type {string}
     * @memberof ExtendedRight
     */
    'module'?: string;
    /**
     * The user has the right to add a resource
     * @type {boolean}
     * @memberof ExtendedRight
     */
    'add'?: boolean;
    /**
     * The user has the right to view a resource
     * @type {boolean}
     * @memberof ExtendedRight
     */
    'view'?: boolean;
    /**
     * The user has the right to edit a resource
     * @type {boolean}
     * @memberof ExtendedRight
     */
    'edit'?: boolean;
    /**
     * The user has the right to delete a resource
     * @type {boolean}
     * @memberof ExtendedRight
     */
    'delete'?: boolean;
    /**
     * Determines if it is a payable service
     * @type {boolean}
     * @memberof ExtendedRight
     */
    'is_service'?: boolean;
}
/**
 * 
 * @export
 * @interface ExtendedSitePrices
 */
export interface ExtendedSitePrices {
    /**
     * The unique identifier of the site
     * @type {string}
     * @memberof ExtendedSitePrices
     */
    'id'?: string;
    /**
     * Name of the site
     * @type {string}
     * @memberof ExtendedSitePrices
     */
    'name'?: string;
    /**
     * When was the last time when a price was updated for the site
     * @type {string}
     * @memberof ExtendedSitePrices
     */
    'last_update_of_prices'?: string;
    /**
     * The list of prices for each product of the site
     * @type {Array<ExtendedSitePricesPricesInner>}
     * @memberof ExtendedSitePrices
     */
    'prices'?: Array<ExtendedSitePricesPricesInner>;
}
/**
 * 
 * @export
 * @interface ExtendedSitePricesPricesInner
 */
export interface ExtendedSitePricesPricesInner {
    /**
     * The unique identifier of the product
     * @type {string}
     * @memberof ExtendedSitePricesPricesInner
     */
    'product_id': string;
    /**
     * The price used for product
     * @type {number}
     * @memberof ExtendedSitePricesPricesInner
     */
    'unit_price'?: number;
    /**
     * The price which will be used starting with activation_timestamp
     * @type {number}
     * @memberof ExtendedSitePricesPricesInner
     */
    'new_unit_price'?: number;
    /**
     * The date and time when the new_unit_price will be active
     * @type {string}
     * @memberof ExtendedSitePricesPricesInner
     */
    'activation_timestamp'?: string;
    /**
     * The name of the product
     * @type {string}
     * @memberof ExtendedSitePricesPricesInner
     */
    'product_name'?: string;
}
/**
 * 
 * @export
 * @interface ExtendedTemplateField
 */
export interface ExtendedTemplateField {
    /**
     * 
     * @type {string}
     * @memberof ExtendedTemplateField
     */
    'template_id': string;
    /**
     * 
     * @type {string}
     * @memberof ExtendedTemplateField
     */
    'id': string;
    /**
     * The unique identifier of the field from receipt
     * @type {string}
     * @memberof ExtendedTemplateField
     */
    'field_id': string;
    /**
     * The order of how the fields are displayed
     * @type {number}
     * @memberof ExtendedTemplateField
     */
    'field_order': number;
}
/**
 * 
 * @export
 * @interface ExtendedTemplateResponse
 */
export interface ExtendedTemplateResponse {
    /**
     * Unique identifier of a mandator
     * @type {string}
     * @memberof ExtendedTemplateResponse
     */
    'mandator_id': string;
    /**
     * The name of the receipt template
     * @type {string}
     * @memberof ExtendedTemplateResponse
     */
    'name': string;
    /**
     * The type of the receipt template
     * @type {string}
     * @memberof ExtendedTemplateResponse
     */
    'type': ExtendedTemplateResponseTypeEnum;
    /**
     * Unique identifier of a template
     * @type {string}
     * @memberof ExtendedTemplateResponse
     */
    'id': string;
    /**
     * The date when the template was created in UTC
     * @type {string}
     * @memberof ExtendedTemplateResponse
     */
    'created_at': string;
}

export const ExtendedTemplateResponseTypeEnum = {
    Invoice: 'invoice',
    Note: 'note'
} as const;

export type ExtendedTemplateResponseTypeEnum = typeof ExtendedTemplateResponseTypeEnum[keyof typeof ExtendedTemplateResponseTypeEnum];

/**
 * 
 * @export
 * @interface Field
 */
export interface Field {
    /**
     * 
     * @type {string}
     * @memberof Field
     */
    'id': string;
    /**
     * The name of the receipt field that is the same as transaction attribute
     * @type {string}
     * @memberof Field
     */
    'name': string;
    /**
     * Category to which the field belongs to
     * @type {string}
     * @memberof Field
     */
    'category': FieldCategoryEnum;
    /**
     * The position of the field at display
     * @type {string}
     * @memberof Field
     */
    'position': FieldPositionEnum;
    /**
     * Translations of the field for each system language
     * @type {string}
     * @memberof Field
     */
    'custom_translation'?: string;
}

export const FieldCategoryEnum = {
    Payment: 'payment',
    Article: 'article',
    General: 'general',
    CustomerData: 'customer_data',
    Receipt: 'receipt'
} as const;

export type FieldCategoryEnum = typeof FieldCategoryEnum[keyof typeof FieldCategoryEnum];
export const FieldPositionEnum = {
    Row: 'row',
    Column: 'column'
} as const;

export type FieldPositionEnum = typeof FieldPositionEnum[keyof typeof FieldPositionEnum];

/**
 * 
 * @export
 * @interface FieldsConfig
 */
export interface FieldsConfig {
    /**
     * A unique identifier received from TWINT
     * @type {string}
     * @memberof FieldsConfig
     */
    'service_agent_id': string;
    /**
     * The default amount used for transactions
     * @type {number}
     * @memberof FieldsConfig
     */
    'authorization_amount': number;
    /**
     * The url where the logo is stored
     * @type {string}
     * @memberof FieldsConfig
     */
    'merchant_logo': string;
}
/**
 * 
 * @export
 * @interface FieldsResponse
 */
export interface FieldsResponse {
    /**
     * 
     * @type {string}
     * @memberof FieldsResponse
     */
    'id'?: string;
    /**
     * 
     * @type {string}
     * @memberof FieldsResponse
     */
    'created_at'?: string;
    /**
     * The identifier of the field
     * @type {string}
     * @memberof FieldsResponse
     */
    'key': string;
    /**
     * The field name
     * @type {string}
     * @memberof FieldsResponse
     */
    'name': string;
    /**
     * The type of the field
     * @type {string}
     * @memberof FieldsResponse
     */
    'type': string;
    /**
     * The description for the field
     * @type {string}
     * @memberof FieldsResponse
     */
    'description'?: string;
    /**
     * Number of templates that are assigned to this field
     * @type {number}
     * @memberof FieldsResponse
     */
    'template_count'?: number;
}
/**
 * 
 * @export
 * @interface FilterDescriptor
 */
export interface FilterDescriptor {
    /**
     * 
     * @type {string}
     * @memberof FilterDescriptor
     */
    'field': string;
    /**
     * The filter operator (comparison). The supported operators are `\"eq\"` (equal to) = `\"gt\"` (greater than) > `\"lt\"` (greater than) < The following operators are supported for string fields only `\"contains\"`
     * @type {string}
     * @memberof FilterDescriptor
     */
    'operator': FilterDescriptorOperatorEnum;
    /**
     * 
     * @type {any}
     * @memberof FilterDescriptor
     */
    'value': any;
}

export const FilterDescriptorOperatorEnum = {
    Eq: 'eq',
    Gt: 'gt',
    Lt: 'lt',
    Contains: 'contains'
} as const;

export type FilterDescriptorOperatorEnum = typeof FilterDescriptorOperatorEnum[keyof typeof FilterDescriptorOperatorEnum];

/**
 * 
 * @export
 * @interface FleetNet
 */
export interface FleetNet {
    /**
     * The name of the object
     * @type {string}
     * @memberof FleetNet
     */
    'name': string;
    /**
     * The summary of the object
     * @type {string}
     * @memberof FleetNet
     */
    'summary': string;
    /**
     * The description of the object
     * @type {string}
     * @memberof FleetNet
     */
    'description': string;
    /**
     * 
     * @type {FleetNetLinks}
     * @memberof FleetNet
     */
    '_links': FleetNetLinks;
}
/**
 * 
 * @export
 * @interface FleetNetLinks
 */
export interface FleetNetLinks {
    /**
     * 
     * @type {FleetNetLinksBanner}
     * @memberof FleetNetLinks
     */
    'banner'?: FleetNetLinksBanner;
}
/**
 * 
 * @export
 * @interface FleetNetLinksBanner
 */
export interface FleetNetLinksBanner {
    /**
     * The URL of the banner
     * @type {string}
     * @memberof FleetNetLinksBanner
     */
    'href'?: string;
}
/**
 * 
 * @export
 * @interface FooterBase
 */
export interface FooterBase {
    /**
     * The url where the advertising logo is stored. Represents the image displayed in footer
     * @type {string}
     * @memberof FooterBase
     */
    'advertising_logo'?: string;
    /**
     * The position of the advertising logo
     * @type {string}
     * @memberof FooterBase
     */
    'advertising_alignment'?: FooterBaseAdvertisingAlignmentEnum;
    /**
     * Not implemented
     * @type {boolean}
     * @memberof FooterBase
     */
    'show_QRCode'?: boolean;
    /**
     * 
     * @type {string}
     * @memberof FooterBase
     */
    'description': string;
}

export const FooterBaseAdvertisingAlignmentEnum = {
    Right: 'right',
    Center: 'center',
    Left: 'left'
} as const;

export type FooterBaseAdvertisingAlignmentEnum = typeof FooterBaseAdvertisingAlignmentEnum[keyof typeof FooterBaseAdvertisingAlignmentEnum];

/**
 * 
 * @export
 * @interface FreeparkingConfigurationSite
 */
export interface FreeparkingConfigurationSite {
    /**
     * 
     * @type {string}
     * @memberof FreeparkingConfigurationSite
     */
    'id': string;
    /**
     * 
     * @type {string}
     * @memberof FreeparkingConfigurationSite
     */
    'name'?: string;
    /**
     * Name of the city
     * @type {string}
     * @memberof FreeparkingConfigurationSite
     */
    'city'?: string;
    /**
     * 
     * @type {FreeparkingNamedConfigurationPlain}
     * @memberof FreeparkingConfigurationSite
     */
    'configurations'?: FreeparkingNamedConfigurationPlain;
}
/**
 * 
 * @export
 * @interface FreeparkingConfigurationSiteResponse
 */
export interface FreeparkingConfigurationSiteResponse {
    /**
     * 
     * @type {ResponseBaseLinks}
     * @memberof FreeparkingConfigurationSiteResponse
     */
    '_links'?: ResponseBaseLinks;
    /**
     * 
     * @type {ResponseBaseMeta}
     * @memberof FreeparkingConfigurationSiteResponse
     */
    'meta'?: ResponseBaseMeta;
    /**
     * 
     * @type {Array<FreeparkingConfigurationSite>}
     * @memberof FreeparkingConfigurationSiteResponse
     */
    'data'?: Array<FreeparkingConfigurationSite>;
}
/**
 * 
 * @export
 * @interface FreeparkingNamedConfiguration
 */
export interface FreeparkingNamedConfiguration {
    /**
     * 
     * @type {string}
     * @memberof FreeparkingNamedConfiguration
     */
    'id'?: string;
    /**
     * must be unique per mandator.
     * @type {string}
     * @memberof FreeparkingNamedConfiguration
     */
    'name': string;
    /**
     * Type of entries. One configuration can ony have one type of entries. 
     * @type {string}
     * @memberof FreeparkingNamedConfiguration
     */
    'type': FreeparkingNamedConfigurationTypeEnum;
    /**
     * 
     * @type {string}
     * @memberof FreeparkingNamedConfiguration
     */
    'created': string;
    /**
     * 
     * @type {FreeparkingNamedConfigurationAllOfConfigurations}
     * @memberof FreeparkingNamedConfiguration
     */
    'configurations'?: FreeparkingNamedConfigurationAllOfConfigurations;
    /**
     * undefined means not requested. empty means there is no configuration. 
     * @type {Array<FreeparkingSite>}
     * @memberof FreeparkingNamedConfiguration
     */
    'sites'?: Array<FreeparkingSite>;
}

export const FreeparkingNamedConfigurationTypeEnum = {
    Interval: 'interval',
    Grace: 'grace'
} as const;

export type FreeparkingNamedConfigurationTypeEnum = typeof FreeparkingNamedConfigurationTypeEnum[keyof typeof FreeparkingNamedConfigurationTypeEnum];

/**
 * undefined means not requested. empty means there is no configuration. 
 * @export
 * @interface FreeparkingNamedConfigurationAllOfConfigurations
 */
export interface FreeparkingNamedConfigurationAllOfConfigurations {
}
/**
 * 
 * @export
 * @interface FreeparkingNamedConfigurationPlain
 */
export interface FreeparkingNamedConfigurationPlain {
    /**
     * 
     * @type {string}
     * @memberof FreeparkingNamedConfigurationPlain
     */
    'id'?: string;
    /**
     * must be unique per mandator.
     * @type {string}
     * @memberof FreeparkingNamedConfigurationPlain
     */
    'name': string;
    /**
     * Type of entries. One configuration can ony have one type of entries. 
     * @type {string}
     * @memberof FreeparkingNamedConfigurationPlain
     */
    'type': FreeparkingNamedConfigurationPlainTypeEnum;
}

export const FreeparkingNamedConfigurationPlainTypeEnum = {
    Interval: 'interval',
    Grace: 'grace'
} as const;

export type FreeparkingNamedConfigurationPlainTypeEnum = typeof FreeparkingNamedConfigurationPlainTypeEnum[keyof typeof FreeparkingNamedConfigurationPlainTypeEnum];

/**
 * 
 * @export
 * @interface FreeparkingNamedConfigurationResponse
 */
export interface FreeparkingNamedConfigurationResponse {
    /**
     * 
     * @type {ResponseBaseLinks}
     * @memberof FreeparkingNamedConfigurationResponse
     */
    '_links'?: ResponseBaseLinks;
    /**
     * 
     * @type {ResponseBaseMeta}
     * @memberof FreeparkingNamedConfigurationResponse
     */
    'meta'?: ResponseBaseMeta;
    /**
     * 
     * @type {Array<FreeparkingNamedConfiguration>}
     * @memberof FreeparkingNamedConfigurationResponse
     */
    'data'?: Array<FreeparkingNamedConfiguration>;
}
/**
 * 
 * @export
 * @interface FreeparkingPutNamedConfiguration
 */
export interface FreeparkingPutNamedConfiguration {
    /**
     * 
     * @type {string}
     * @memberof FreeparkingPutNamedConfiguration
     */
    'id'?: string;
    /**
     * must be unique per mandator.
     * @type {string}
     * @memberof FreeparkingPutNamedConfiguration
     */
    'name': string;
    /**
     * Type of entries. One configuration can ony have one type of entries. 
     * @type {string}
     * @memberof FreeparkingPutNamedConfiguration
     */
    'type': FreeparkingPutNamedConfigurationTypeEnum;
    /**
     * 
     * @type {FreeparkingPutNamedConfigurationAllOfConfigurations}
     * @memberof FreeparkingPutNamedConfiguration
     */
    'configurations': FreeparkingPutNamedConfigurationAllOfConfigurations;
}

export const FreeparkingPutNamedConfigurationTypeEnum = {
    Interval: 'interval',
    Grace: 'grace'
} as const;

export type FreeparkingPutNamedConfigurationTypeEnum = typeof FreeparkingPutNamedConfigurationTypeEnum[keyof typeof FreeparkingPutNamedConfigurationTypeEnum];

/**
 * undefined means not requested. empty means there is no configuration. 
 * @export
 * @interface FreeparkingPutNamedConfigurationAllOfConfigurations
 */
export interface FreeparkingPutNamedConfigurationAllOfConfigurations {
}
/**
 * 
 * @export
 * @interface FreeparkingSite
 */
export interface FreeparkingSite {
    /**
     * 
     * @type {string}
     * @memberof FreeparkingSite
     */
    'id': string;
    /**
     * 
     * @type {string}
     * @memberof FreeparkingSite
     */
    'name'?: string;
    /**
     * Name of the city
     * @type {string}
     * @memberof FreeparkingSite
     */
    'city'?: string;
}
/**
 * 
 * @export
 * @interface GeManufacturers200Response
 */
export interface GeManufacturers200Response {
    /**
     * 
     * @type {ResponseBaseLinks}
     * @memberof GeManufacturers200Response
     */
    '_links'?: ResponseBaseLinks;
    /**
     * 
     * @type {ResponseBaseMeta}
     * @memberof GeManufacturers200Response
     */
    'meta'?: ResponseBaseMeta;
    /**
     * 
     * @type {Array<Manufacturer>}
     * @memberof GeManufacturers200Response
     */
    'data'?: Array<Manufacturer>;
}
/**
 * 
 * @export
 * @interface GetAllPermissionGroups200Response
 */
export interface GetAllPermissionGroups200Response {
    /**
     * 
     * @type {ResponseBaseLinks}
     * @memberof GetAllPermissionGroups200Response
     */
    '_links'?: ResponseBaseLinks;
    /**
     * 
     * @type {ResponseBaseMeta}
     * @memberof GetAllPermissionGroups200Response
     */
    'meta'?: ResponseBaseMeta;
    /**
     * 
     * @type {Array<PermissionGroupResponse>}
     * @memberof GetAllPermissionGroups200Response
     */
    'data'?: Array<PermissionGroupResponse>;
}
/**
 * 
 * @export
 * @interface GetAllProducts200Response
 */
export interface GetAllProducts200Response {
    /**
     * 
     * @type {ResponseBaseLinks}
     * @memberof GetAllProducts200Response
     */
    '_links'?: ResponseBaseLinks;
    /**
     * 
     * @type {ResponseBaseMeta}
     * @memberof GetAllProducts200Response
     */
    'meta'?: ResponseBaseMeta;
    /**
     * 
     * @type {Array<GetProducts>}
     * @memberof GetAllProducts200Response
     */
    'data'?: Array<GetProducts>;
}
/**
 * 
 * @export
 * @interface GetCards200Response
 */
export interface GetCards200Response {
    /**
     * 
     * @type {ResponseBaseLinks}
     * @memberof GetCards200Response
     */
    '_links'?: ResponseBaseLinks;
    /**
     * 
     * @type {ResponseBaseMeta}
     * @memberof GetCards200Response
     */
    'meta'?: ResponseBaseMeta;
    /**
     * 
     * @type {Array<CardList>}
     * @memberof GetCards200Response
     */
    'data'?: Array<CardList>;
}
/**
 * 
 * @export
 * @interface GetComponents200Response
 */
export interface GetComponents200Response {
    /**
     * 
     * @type {ResponseBaseLinks}
     * @memberof GetComponents200Response
     */
    '_links'?: ResponseBaseLinks;
    /**
     * 
     * @type {ResponseBaseMeta}
     * @memberof GetComponents200Response
     */
    'meta'?: ResponseBaseMeta;
    /**
     * 
     * @type {Array<Components>}
     * @memberof GetComponents200Response
     */
    'data'?: Array<Components>;
}
/**
 * 
 * @export
 * @interface GetDeviceById200Response
 */
export interface GetDeviceById200Response {
    /**
     * 
     * @type {ResponseBaseLinks}
     * @memberof GetDeviceById200Response
     */
    '_links'?: ResponseBaseLinks;
    /**
     * 
     * @type {ResponseBaseMeta}
     * @memberof GetDeviceById200Response
     */
    'meta'?: ResponseBaseMeta;
    /**
     * 
     * @type {Array<GetDeviceById200ResponseAllOfData>}
     * @memberof GetDeviceById200Response
     */
    'data'?: Array<GetDeviceById200ResponseAllOfData>;
}
/**
 * 
 * @export
 * @interface GetDeviceById200ResponseAllOfData
 */
export interface GetDeviceById200ResponseAllOfData {
    /**
     * The name of the site to which the device belongs
     * @type {string}
     * @memberof GetDeviceById200ResponseAllOfData
     */
    'site_name'?: string;
    /**
     * 
     * @type {string}
     * @memberof GetDeviceById200ResponseAllOfData
     */
    'device_model_name'?: GetDeviceById200ResponseAllOfDataDeviceModelNameEnum;
    /**
     * 
     * @type {Gps}
     * @memberof GetDeviceById200ResponseAllOfData
     */
    'gps'?: Gps;
    /**
     * The id of the manufacturer based on the protocol
     * @type {string}
     * @memberof GetDeviceById200ResponseAllOfData
     */
    'manufacturer_id'?: string;
    /**
     * The name of the manufacturer
     * @type {string}
     * @memberof GetDeviceById200ResponseAllOfData
     */
    'manufacturer_name'?: GetDeviceById200ResponseAllOfDataManufacturerNameEnum;
    /**
     * 
     * @type {string}
     * @memberof GetDeviceById200ResponseAllOfData
     */
    'protocol_name'?: string;
    /**
     * The unique identifier of the device
     * @type {string}
     * @memberof GetDeviceById200ResponseAllOfData
     */
    'id'?: string;
    /**
     * The unique identifier of the site to which the device belongs
     * @type {string}
     * @memberof GetDeviceById200ResponseAllOfData
     */
    'site_id'?: string;
    /**
     * 
     * @type {string}
     * @memberof GetDeviceById200ResponseAllOfData
     */
    'name'?: string;
    /**
     * 
     * @type {string}
     * @memberof GetDeviceById200ResponseAllOfData
     */
    'code'?: string;
    /**
     * The unique identifier of the protocol assigned to the device
     * @type {string}
     * @memberof GetDeviceById200ResponseAllOfData
     */
    'protocol_id'?: string;
    /**
     * 
     * @type {string}
     * @memberof GetDeviceById200ResponseAllOfData
     */
    'version'?: string;
    /**
     * 
     * @type {string}
     * @memberof GetDeviceById200ResponseAllOfData
     */
    'installation_date'?: string;
    /**
     * 
     * @type {boolean}
     * @memberof GetDeviceById200ResponseAllOfData
     */
    'device_state'?: boolean;
    /**
     * 
     * @type {string}
     * @memberof GetDeviceById200ResponseAllOfData
     */
    'device_model_id'?: string;
    /**
     * 
     * @type {string}
     * @memberof GetDeviceById200ResponseAllOfData
     */
    'mac_address'?: string;
    /**
     * 
     * @type {string}
     * @memberof GetDeviceById200ResponseAllOfData
     */
    'provisioning_date'?: string;
    /**
     * 
     * @type {string}
     * @memberof GetDeviceById200ResponseAllOfData
     */
    'board'?: string;
    /**
     * 
     * @type {string}
     * @memberof GetDeviceById200ResponseAllOfData
     */
    'touch_display'?: GetDeviceById200ResponseAllOfDataTouchDisplayEnum;
    /**
     * Represents property that determines if price information is sent to ocpp devices
     * @type {boolean}
     * @memberof GetDeviceById200ResponseAllOfData
     */
    'has_cdr'?: boolean;
}

export const GetDeviceById200ResponseAllOfDataDeviceModelNameEnum = {
    HecStar: 'HecStar',
    HecPay: 'HecPay',
    Pico: 'PICO',
    HecPosT: 'HecPOS-T'
} as const;

export type GetDeviceById200ResponseAllOfDataDeviceModelNameEnum = typeof GetDeviceById200ResponseAllOfDataDeviceModelNameEnum[keyof typeof GetDeviceById200ResponseAllOfDataDeviceModelNameEnum];
export const GetDeviceById200ResponseAllOfDataManufacturerNameEnum = {
    Hectronic: 'Hectronic',
    Compleo: 'Compleo',
    Abb: 'ABB'
} as const;

export type GetDeviceById200ResponseAllOfDataManufacturerNameEnum = typeof GetDeviceById200ResponseAllOfDataManufacturerNameEnum[keyof typeof GetDeviceById200ResponseAllOfDataManufacturerNameEnum];
export const GetDeviceById200ResponseAllOfDataTouchDisplayEnum = {
    Yes: 'YES',
    No: 'NO'
} as const;

export type GetDeviceById200ResponseAllOfDataTouchDisplayEnum = typeof GetDeviceById200ResponseAllOfDataTouchDisplayEnum[keyof typeof GetDeviceById200ResponseAllOfDataTouchDisplayEnum];

/**
 * 
 * @export
 * @interface GetDeviceLogs200Response
 */
export interface GetDeviceLogs200Response {
    /**
     * 
     * @type {ResponseBaseLinks}
     * @memberof GetDeviceLogs200Response
     */
    '_links'?: ResponseBaseLinks;
    /**
     * 
     * @type {ResponseBaseMeta}
     * @memberof GetDeviceLogs200Response
     */
    'meta'?: ResponseBaseMeta;
    /**
     * 
     * @type {Array<DeviceLogs>}
     * @memberof GetDeviceLogs200Response
     */
    'data'?: Array<DeviceLogs>;
}
/**
 * 
 * @export
 * @interface GetDeviceModels200Response
 */
export interface GetDeviceModels200Response {
    /**
     * 
     * @type {ResponseBaseLinks}
     * @memberof GetDeviceModels200Response
     */
    '_links'?: ResponseBaseLinks;
    /**
     * 
     * @type {ResponseBaseMeta}
     * @memberof GetDeviceModels200Response
     */
    'meta'?: ResponseBaseMeta;
    /**
     * 
     * @type {Array<DeviceModel>}
     * @memberof GetDeviceModels200Response
     */
    'data'?: Array<DeviceModel>;
}
/**
 * 
 * @export
 * @interface GetDevicesOfSite200Response
 */
export interface GetDevicesOfSite200Response {
    /**
     * 
     * @type {ResponseBaseLinks}
     * @memberof GetDevicesOfSite200Response
     */
    '_links'?: ResponseBaseLinks;
    /**
     * 
     * @type {ResponseBaseMeta}
     * @memberof GetDevicesOfSite200Response
     */
    'meta'?: ResponseBaseMeta;
    /**
     * 
     * @type {Array<GetDevicesResponse>}
     * @memberof GetDevicesOfSite200Response
     */
    'data'?: Array<GetDevicesResponse>;
}
/**
 * 
 * @export
 * @interface GetDevicesResponse
 */
export interface GetDevicesResponse {
    /**
     * The unique identifier of the device
     * @type {string}
     * @memberof GetDevicesResponse
     */
    'id'?: string;
    /**
     * The unique identifier of the site to which the device belongs
     * @type {string}
     * @memberof GetDevicesResponse
     */
    'site_id'?: string;
    /**
     * 
     * @type {string}
     * @memberof GetDevicesResponse
     */
    'name'?: string;
    /**
     * 
     * @type {string}
     * @memberof GetDevicesResponse
     */
    'code'?: string;
    /**
     * The unique identifier of the protocol assigned to the device
     * @type {string}
     * @memberof GetDevicesResponse
     */
    'protocol_id'?: string;
    /**
     * 
     * @type {string}
     * @memberof GetDevicesResponse
     */
    'version'?: string;
    /**
     * 
     * @type {string}
     * @memberof GetDevicesResponse
     */
    'installation_date'?: string;
    /**
     * 
     * @type {boolean}
     * @memberof GetDevicesResponse
     */
    'device_state'?: boolean;
    /**
     * 
     * @type {string}
     * @memberof GetDevicesResponse
     */
    'device_model_id'?: string;
    /**
     * This field can be edited until the provisioning is done
     * @type {string}
     * @memberof GetDevicesResponse
     */
    'mac_address'?: string;
    /**
     * This is the date when the marriage between the hardware device and aws IoT took place.
     * @type {string}
     * @memberof GetDevicesResponse
     */
    'provisioning_date'?: string;
    /**
     * The name of the site
     * @type {string}
     * @memberof GetDevicesResponse
     */
    'site_name'?: string;
    /**
     * The name of the model assigned to the device
     * @type {string}
     * @memberof GetDevicesResponse
     */
    'device_model_name'?: string;
    /**
     * 
     * @type {Gps}
     * @memberof GetDevicesResponse
     */
    'gps'?: Gps;
    /**
     * The name of the protocol assigned to the device. Possible values are: HecOne360API, OCPP1.6, chargecloud
     * @type {string}
     * @memberof GetDevicesResponse
     */
    'protocol_name'?: string;
    /**
     * Represents property that determines if price information is sent to ocpp devices
     * @type {boolean}
     * @memberof GetDevicesResponse
     */
    'has_cdr'?: boolean;
}
/**
 * 
 * @export
 * @interface GetDigitalReceiptHistory200Response
 */
export interface GetDigitalReceiptHistory200Response {
    /**
     * 
     * @type {ResponseBaseLinks}
     * @memberof GetDigitalReceiptHistory200Response
     */
    '_links'?: ResponseBaseLinks;
    /**
     * 
     * @type {ResponseBaseMeta}
     * @memberof GetDigitalReceiptHistory200Response
     */
    'meta'?: ResponseBaseMeta;
    /**
     * 
     * @type {Array<TemplateHistoryResponse>}
     * @memberof GetDigitalReceiptHistory200Response
     */
    'data'?: Array<TemplateHistoryResponse>;
}
/**
 * 
 * @export
 * @interface GetDigitalReceiptTemplateDetails200Response
 */
export interface GetDigitalReceiptTemplateDetails200Response {
    /**
     * 
     * @type {ResponseBaseLinks}
     * @memberof GetDigitalReceiptTemplateDetails200Response
     */
    '_links'?: ResponseBaseLinks;
    /**
     * 
     * @type {ResponseBaseMeta}
     * @memberof GetDigitalReceiptTemplateDetails200Response
     */
    'meta'?: ResponseBaseMeta;
    /**
     * 
     * @type {Array<GetTemplateResponseComponent>}
     * @memberof GetDigitalReceiptTemplateDetails200Response
     */
    'data'?: Array<GetTemplateResponseComponent>;
}
/**
 * 
 * @export
 * @interface GetEmailTemplate
 */
export interface GetEmailTemplate {
    /**
     * The unique identifier of the email template
     * @type {string}
     * @memberof GetEmailTemplate
     */
    'id'?: string;
    /**
     * The subject for the email template
     * @type {string}
     * @memberof GetEmailTemplate
     */
    'subject': string;
    /**
     * The message from email template
     * @type {string}
     * @memberof GetEmailTemplate
     */
    'body': string;
    /**
     * The signature from email template that will appear in the footer
     * @type {string}
     * @memberof GetEmailTemplate
     */
    'signature'?: string;
    /**
     * Unique identifier of the mandator
     * @type {string}
     * @memberof GetEmailTemplate
     */
    'mandator_id'?: string;
}
/**
 * 
 * @export
 * @interface GetEmailTemplate200Response
 */
export interface GetEmailTemplate200Response {
    /**
     * 
     * @type {ResponseBaseLinks}
     * @memberof GetEmailTemplate200Response
     */
    '_links'?: ResponseBaseLinks;
    /**
     * 
     * @type {ResponseBaseMeta}
     * @memberof GetEmailTemplate200Response
     */
    'meta'?: ResponseBaseMeta;
    /**
     * 
     * @type {Array<GetEmailTemplate>}
     * @memberof GetEmailTemplate200Response
     */
    'data'?: Array<GetEmailTemplate>;
}
/**
 * 
 * @export
 * @interface GetFields200Response
 */
export interface GetFields200Response {
    /**
     * 
     * @type {ResponseBaseLinks}
     * @memberof GetFields200Response
     */
    '_links'?: ResponseBaseLinks;
    /**
     * 
     * @type {ResponseBaseMeta}
     * @memberof GetFields200Response
     */
    'meta'?: ResponseBaseMeta;
    /**
     * 
     * @type {Array<FieldsResponse>}
     * @memberof GetFields200Response
     */
    'data'?: Array<FieldsResponse>;
}
/**
 * 
 * @export
 * @interface GetHistory200Response
 */
export interface GetHistory200Response {
    /**
     * 
     * @type {ResponseBaseLinks}
     * @memberof GetHistory200Response
     */
    '_links'?: ResponseBaseLinks;
    /**
     * 
     * @type {ResponseBaseMeta}
     * @memberof GetHistory200Response
     */
    'meta'?: ResponseBaseMeta;
    /**
     * 
     * @type {Array<HistoryResponse>}
     * @memberof GetHistory200Response
     */
    'data'?: Array<HistoryResponse>;
}
/**
 * 
 * @export
 * @interface GetMandator200Response
 */
export interface GetMandator200Response {
    /**
     * 
     * @type {ResponseBaseLinks}
     * @memberof GetMandator200Response
     */
    '_links'?: ResponseBaseLinks;
    /**
     * 
     * @type {ResponseBaseMeta}
     * @memberof GetMandator200Response
     */
    'meta'?: ResponseBaseMeta;
    /**
     * 
     * @type {Array<MandatorExtended>}
     * @memberof GetMandator200Response
     */
    'data'?: Array<MandatorExtended>;
}
/**
 * 
 * @export
 * @interface GetMandatorProducts200Response
 */
export interface GetMandatorProducts200Response {
    /**
     * 
     * @type {ResponseBaseLinks}
     * @memberof GetMandatorProducts200Response
     */
    '_links'?: ResponseBaseLinks;
    /**
     * 
     * @type {ResponseBaseMeta}
     * @memberof GetMandatorProducts200Response
     */
    'meta'?: ResponseBaseMeta;
    /**
     * 
     * @type {Array<MandatorProductsResponseGet>}
     * @memberof GetMandatorProducts200Response
     */
    'data'?: Array<MandatorProductsResponseGet>;
}
/**
 * 
 * @export
 * @interface GetMandatorTemplates200Response
 */
export interface GetMandatorTemplates200Response {
    /**
     * 
     * @type {ResponseBaseLinks}
     * @memberof GetMandatorTemplates200Response
     */
    '_links'?: ResponseBaseLinks;
    /**
     * 
     * @type {ResponseBaseMeta}
     * @memberof GetMandatorTemplates200Response
     */
    'meta'?: ResponseBaseMeta;
    /**
     * 
     * @type {Array<MandatorTemplatesResponse>}
     * @memberof GetMandatorTemplates200Response
     */
    'data'?: Array<MandatorTemplatesResponse>;
}
/**
 * 
 * @export
 * @interface GetMandatorTemplatesForReceipts200Response
 */
export interface GetMandatorTemplatesForReceipts200Response {
    /**
     * 
     * @type {ResponseBaseLinks}
     * @memberof GetMandatorTemplatesForReceipts200Response
     */
    '_links'?: ResponseBaseLinks;
    /**
     * 
     * @type {ResponseBaseMeta}
     * @memberof GetMandatorTemplatesForReceipts200Response
     */
    'meta'?: ResponseBaseMeta;
    /**
     * 
     * @type {Array<GetTemplatesResponseComponent>}
     * @memberof GetMandatorTemplatesForReceipts200Response
     */
    'data'?: Array<GetTemplatesResponseComponent>;
}
/**
 * 
 * @export
 * @interface GetMandators200Response
 */
export interface GetMandators200Response {
    /**
     * 
     * @type {ResponseBaseLinks}
     * @memberof GetMandators200Response
     */
    '_links'?: ResponseBaseLinks;
    /**
     * 
     * @type {ResponseBaseMeta}
     * @memberof GetMandators200Response
     */
    'meta'?: ResponseBaseMeta;
    /**
     * 
     * @type {Array<MandatorBase>}
     * @memberof GetMandators200Response
     */
    'data'?: Array<MandatorBase>;
}
/**
 * 
 * @export
 * @interface GetManufacturer200Response
 */
export interface GetManufacturer200Response {
    /**
     * 
     * @type {ResponseBaseLinks}
     * @memberof GetManufacturer200Response
     */
    '_links'?: ResponseBaseLinks;
    /**
     * 
     * @type {ResponseBaseMeta}
     * @memberof GetManufacturer200Response
     */
    'meta'?: ResponseBaseMeta;
    /**
     * 
     * @type {Array<ManufacturerExtended>}
     * @memberof GetManufacturer200Response
     */
    'data'?: Array<ManufacturerExtended>;
}
/**
 * 
 * @export
 * @interface GetPermissionGroup200Response
 */
export interface GetPermissionGroup200Response {
    /**
     * 
     * @type {ResponseBaseLinks}
     * @memberof GetPermissionGroup200Response
     */
    '_links'?: ResponseBaseLinks;
    /**
     * 
     * @type {ResponseBaseMeta}
     * @memberof GetPermissionGroup200Response
     */
    'meta'?: ResponseBaseMeta;
    /**
     * 
     * @type {Array<ExtendedRight>}
     * @memberof GetPermissionGroup200Response
     */
    'data'?: Array<ExtendedRight>;
}
/**
 * 
 * @export
 * @interface GetPermissionGroupById200Response
 */
export interface GetPermissionGroupById200Response {
    /**
     * 
     * @type {ResponseBaseLinks}
     * @memberof GetPermissionGroupById200Response
     */
    '_links'?: ResponseBaseLinks;
    /**
     * 
     * @type {ResponseBaseMeta}
     * @memberof GetPermissionGroupById200Response
     */
    'meta'?: ResponseBaseMeta;
    /**
     * 
     * @type {Array<ExtendedPermissionGroup>}
     * @memberof GetPermissionGroupById200Response
     */
    'data'?: Array<ExtendedPermissionGroup>;
}
/**
 * A Point describes a Resource which is reserved for a customer over a certain duration. Parkinglot, Charging-Station or Refuelling-Station. Points can be restricted to specific devices, authorizations or identities
 * @export
 * @interface GetPoint
 */
export interface GetPoint {
    /**
     * The unique identifier of the point
     * @type {string}
     * @memberof GetPoint
     */
    'id'?: string;
    /**
     * The unique identifier of the device to which the point belongs
     * @type {string}
     * @memberof GetPoint
     */
    'device_id'?: string;
    /**
     * The unique identifier  of the device
     * @type {string}
     * @memberof GetPoint
     */
    'device_code'?: string;
    /**
     * Name of the device
     * @type {string}
     * @memberof GetPoint
     */
    'device_name'?: string;
    /**
     * The unique identifier of the site to which the point belongs
     * @type {string}
     * @memberof GetPoint
     */
    'site_id'?: string;
    /**
     * The number of a point is unique on a site and device
     * @type {number}
     * @memberof GetPoint
     */
    'point_number'?: number;
    /**
     * The name of the point
     * @type {string}
     * @memberof GetPoint
     */
    'name'?: string;
    /**
     * model name of the hardware point
     * @type {PointType}
     * @memberof GetPoint
     */
    'type'?: PointType;
    /**
     * The protocol assigned to the device
     * @type {string}
     * @memberof GetPoint
     */
    'protocol'?: string;
    /**
     * 
     * @type {PointStatus}
     * @memberof GetPoint
     */
    'status'?: PointStatus;
    /**
     * Represents property that determines if price information is sent to ocpp devices
     * @type {boolean}
     * @memberof GetPoint
     */
    'device_has_cdr'?: boolean;
}


/**
 * 
 * @export
 * @interface GetPointById200Response
 */
export interface GetPointById200Response {
    /**
     * 
     * @type {ResponseBaseLinks}
     * @memberof GetPointById200Response
     */
    '_links'?: ResponseBaseLinks;
    /**
     * 
     * @type {ResponseBaseMeta}
     * @memberof GetPointById200Response
     */
    'meta'?: ResponseBaseMeta;
    /**
     * 
     * @type {Array<GetPoint>}
     * @memberof GetPointById200Response
     */
    'data'?: Array<GetPoint>;
}
/**
 * 
 * @export
 * @interface GetPointsForAMandator200Response
 */
export interface GetPointsForAMandator200Response {
    /**
     * 
     * @type {ResponseBaseLinks}
     * @memberof GetPointsForAMandator200Response
     */
    '_links'?: ResponseBaseLinks;
    /**
     * 
     * @type {ResponseBaseMeta}
     * @memberof GetPointsForAMandator200Response
     */
    'meta'?: ResponseBaseMeta;
    /**
     * 
     * @type {Array<MandatorPoints>}
     * @memberof GetPointsForAMandator200Response
     */
    'data'?: Array<MandatorPoints>;
}
/**
 * 
 * @export
 * @interface GetPointsOfSite200Response
 */
export interface GetPointsOfSite200Response {
    /**
     * 
     * @type {ResponseBaseLinks}
     * @memberof GetPointsOfSite200Response
     */
    '_links'?: ResponseBaseLinks;
    /**
     * 
     * @type {ResponseBaseMeta}
     * @memberof GetPointsOfSite200Response
     */
    'meta'?: ResponseBaseMeta;
    /**
     * 
     * @type {Array<SitePoints>}
     * @memberof GetPointsOfSite200Response
     */
    'data'?: Array<SitePoints>;
}
/**
 * 
 * @export
 * @interface GetPointsQRCodes200Response
 */
export interface GetPointsQRCodes200Response {
    /**
     * 
     * @type {ResponseBaseLinks}
     * @memberof GetPointsQRCodes200Response
     */
    '_links'?: ResponseBaseLinks;
    /**
     * 
     * @type {ResponseBaseMeta}
     * @memberof GetPointsQRCodes200Response
     */
    'meta'?: ResponseBaseMeta;
    /**
     * 
     * @type {Array<PointsQR>}
     * @memberof GetPointsQRCodes200Response
     */
    'data'?: Array<PointsQR>;
}
/**
 * 
 * @export
 * @interface GetPricesForASite200Response
 */
export interface GetPricesForASite200Response {
    /**
     * 
     * @type {ResponseBaseLinks}
     * @memberof GetPricesForASite200Response
     */
    '_links'?: ResponseBaseLinks;
    /**
     * 
     * @type {ResponseBaseMeta}
     * @memberof GetPricesForASite200Response
     */
    'meta'?: ResponseBaseMeta;
    /**
     * 
     * @type {Array<ExtendedSitePrices>}
     * @memberof GetPricesForASite200Response
     */
    'data'?: Array<ExtendedSitePrices>;
}
/**
 * 
 * @export
 * @interface GetPricesForActiveSites200Response
 */
export interface GetPricesForActiveSites200Response {
    /**
     * 
     * @type {ResponseBaseLinks}
     * @memberof GetPricesForActiveSites200Response
     */
    '_links'?: ResponseBaseLinks;
    /**
     * 
     * @type {ResponseBaseMeta}
     * @memberof GetPricesForActiveSites200Response
     */
    'meta'?: ResponseBaseMeta;
    /**
     * 
     * @type {Array<ActiveSitePrices>}
     * @memberof GetPricesForActiveSites200Response
     */
    'data'?: Array<ActiveSitePrices>;
}
/**
 * 
 * @export
 * @interface GetProducts
 */
export interface GetProducts {
    /**
     * The unique identifier of the product
     * @type {string}
     * @memberof GetProducts
     */
    'id'?: string;
    /**
     * The identifier of the product
     * @type {string}
     * @memberof GetProducts
     */
    'code': string;
    /**
     * Name of the product
     * @type {string}
     * @memberof GetProducts
     */
    'name': string;
    /**
     * Short description of the product
     * @type {string}
     * @memberof GetProducts
     */
    'description'?: string;
    /**
     * The unit measure used for the product
     * @type {string}
     * @memberof GetProducts
     */
    'unit_measure': GetProductsUnitMeasureEnum;
    /**
     * Category of the product
     * @type {string}
     * @memberof GetProducts
     */
    'category': GetProductsCategoryEnum;
    /**
     * Concatenates the code and name of the product
     * @type {string}
     * @memberof GetProducts
     */
    'product'?: string;
    /**
     * Represents how often a product is linked to a point
     * @type {number}
     * @memberof GetProducts
     */
    'number_of_points'?: number;
}

export const GetProductsUnitMeasureEnum = {
    KWh: 'kWh',
    Pc: 'pc',
    Kg: 'kg',
    L: 'l',
    Min: 'min',
    Km: 'km',
    M: 'm',
    Cm: 'cm',
    Mm: 'mm',
    In: 'in',
    Wh: 'Wh',
    A: 'a',
    D: 'd',
    H: 'h',
    S: 's',
    M2: 'm²',
    M3: 'm³',
    P: 'p',
    C: '°C',
    F: '°F'
} as const;

export type GetProductsUnitMeasureEnum = typeof GetProductsUnitMeasureEnum[keyof typeof GetProductsUnitMeasureEnum];
export const GetProductsCategoryEnum = {
    Charging: 'charging',
    Refuelling: 'refuelling',
    Parking: 'parking',
    Wash: 'wash'
} as const;

export type GetProductsCategoryEnum = typeof GetProductsCategoryEnum[keyof typeof GetProductsCategoryEnum];

/**
 * 
 * @export
 * @interface GetProductsOfSite200Response
 */
export interface GetProductsOfSite200Response {
    /**
     * 
     * @type {ResponseBaseLinks}
     * @memberof GetProductsOfSite200Response
     */
    '_links'?: ResponseBaseLinks;
    /**
     * 
     * @type {ResponseBaseMeta}
     * @memberof GetProductsOfSite200Response
     */
    'meta'?: ResponseBaseMeta;
    /**
     * 
     * @type {Array<ProductOfferResponse>}
     * @memberof GetProductsOfSite200Response
     */
    'data'?: Array<ProductOfferResponse>;
}
/**
 * 
 * @export
 * @interface GetProductsRelations
 */
export interface GetProductsRelations {
    /**
     * The id of the product mapping
     * @type {string}
     * @memberof GetProductsRelations
     */
    'id'?: string;
    /**
     * The id of the product
     * @type {string}
     * @memberof GetProductsRelations
     */
    'product_id'?: string;
    /**
     * The name of the product
     * @type {string}
     * @memberof GetProductsRelations
     */
    'product_name'?: string;
    /**
     * 
     * @type {Array<GetProductsRelationsRelatedProductsInner>}
     * @memberof GetProductsRelations
     */
    'related_products'?: Array<GetProductsRelationsRelatedProductsInner>;
}
/**
 * 
 * @export
 * @interface GetProductsRelationsRelatedProductsInner
 */
export interface GetProductsRelationsRelatedProductsInner {
    /**
     * The id of the related product
     * @type {string}
     * @memberof GetProductsRelationsRelatedProductsInner
     */
    'product_id'?: string;
    /**
     * The name of the related product
     * @type {string}
     * @memberof GetProductsRelationsRelatedProductsInner
     */
    'product_name'?: string;
}
/**
 * 
 * @export
 * @interface GetProtocols200Response
 */
export interface GetProtocols200Response {
    /**
     * 
     * @type {ResponseBaseLinks}
     * @memberof GetProtocols200Response
     */
    '_links'?: ResponseBaseLinks;
    /**
     * 
     * @type {ResponseBaseMeta}
     * @memberof GetProtocols200Response
     */
    'meta'?: ResponseBaseMeta;
    /**
     * 
     * @type {Array<Protocol>}
     * @memberof GetProtocols200Response
     */
    'data'?: Array<Protocol>;
}
/**
 * 
 * @export
 * @interface GetProviders200Response
 */
export interface GetProviders200Response {
    /**
     * 
     * @type {ResponseBaseLinks}
     * @memberof GetProviders200Response
     */
    '_links'?: ResponseBaseLinks;
    /**
     * 
     * @type {ResponseBaseMeta}
     * @memberof GetProviders200Response
     */
    'meta'?: ResponseBaseMeta;
    /**
     * 
     * @type {Array<Provider>}
     * @memberof GetProviders200Response
     */
    'data'?: Array<Provider>;
}
/**
 * 
 * @export
 * @interface GetReceiptFields200Response
 */
export interface GetReceiptFields200Response {
    /**
     * 
     * @type {ResponseBaseLinks}
     * @memberof GetReceiptFields200Response
     */
    '_links'?: ResponseBaseLinks;
    /**
     * 
     * @type {ResponseBaseMeta}
     * @memberof GetReceiptFields200Response
     */
    'meta'?: ResponseBaseMeta;
    /**
     * 
     * @type {Array<Field>}
     * @memberof GetReceiptFields200Response
     */
    'data'?: Array<Field>;
}
/**
 * 
 * @export
 * @interface GetRelatedProducts200Response
 */
export interface GetRelatedProducts200Response {
    /**
     * 
     * @type {ResponseBaseLinks}
     * @memberof GetRelatedProducts200Response
     */
    '_links'?: ResponseBaseLinks;
    /**
     * 
     * @type {ResponseBaseMeta}
     * @memberof GetRelatedProducts200Response
     */
    'meta'?: ResponseBaseMeta;
    /**
     * 
     * @type {Array<GetProductsRelations>}
     * @memberof GetRelatedProducts200Response
     */
    'data'?: Array<GetProductsRelations>;
}
/**
 * 
 * @export
 * @interface GetRemoteControlActionsHistory200Response
 */
export interface GetRemoteControlActionsHistory200Response {
    /**
     * 
     * @type {ResponseBaseLinks}
     * @memberof GetRemoteControlActionsHistory200Response
     */
    '_links'?: ResponseBaseLinks;
    /**
     * 
     * @type {ResponseBaseMeta}
     * @memberof GetRemoteControlActionsHistory200Response
     */
    'meta'?: ResponseBaseMeta;
    /**
     * 
     * @type {Array<RemoteControlHistoryGet>}
     * @memberof GetRemoteControlActionsHistory200Response
     */
    'data'?: Array<RemoteControlHistoryGet>;
}
/**
 * 
 * @export
 * @interface GetServices200Response
 */
export interface GetServices200Response {
    /**
     * 
     * @type {ResponseBaseLinks}
     * @memberof GetServices200Response
     */
    '_links'?: ResponseBaseLinks;
    /**
     * 
     * @type {ResponseBaseMeta}
     * @memberof GetServices200Response
     */
    'meta'?: ResponseBaseMeta;
    /**
     * 
     * @type {Array<Services>}
     * @memberof GetServices200Response
     */
    'data'?: Array<Services>;
}
/**
 * 
 * @export
 * @interface GetSiteAndPointsByQR
 */
export interface GetSiteAndPointsByQR {
    /**
     * The unique identifier of the site
     * @type {string}
     * @memberof GetSiteAndPointsByQR
     */
    'id'?: string;
    /**
     * The name of the site
     * @type {string}
     * @memberof GetSiteAndPointsByQR
     */
    'name'?: string;
    /**
     * Unique HecOne station number
     * @type {string}
     * @memberof GetSiteAndPointsByQR
     */
    'code'?: string;
    /**
     * Street address of the site
     * @type {string}
     * @memberof GetSiteAndPointsByQR
     */
    'street'?: string;
    /**
     * City address of the site
     * @type {string}
     * @memberof GetSiteAndPointsByQR
     */
    'city'?: string;
    /**
     * Currency used for product prices of the site
     * @type {string}
     * @memberof GetSiteAndPointsByQR
     */
    'currency'?: string;
    /**
     * Which QR code was scanned
     * @type {string}
     * @memberof GetSiteAndPointsByQR
     */
    'QR_code_type'?: GetSiteAndPointsByQRQRCodeTypeEnum;
    /**
     * The url where the logo is stored
     * @type {string}
     * @memberof GetSiteAndPointsByQR
     */
    'merchant_logo'?: string;
    /**
     * The default amount used for transactions
     * @type {number}
     * @memberof GetSiteAndPointsByQR
     */
    'authorization_amount'?: number;
    /**
     * 
     * @type {Array<PointTwint>}
     * @memberof GetSiteAndPointsByQR
     */
    'points'?: Array<PointTwint>;
}

export const GetSiteAndPointsByQRQRCodeTypeEnum = {
    Site: 'site',
    Point: 'point'
} as const;

export type GetSiteAndPointsByQRQRCodeTypeEnum = typeof GetSiteAndPointsByQRQRCodeTypeEnum[keyof typeof GetSiteAndPointsByQRQRCodeTypeEnum];

/**
 * 
 * @export
 * @interface GetSiteAssignments200Response
 */
export interface GetSiteAssignments200Response {
    /**
     * 
     * @type {ResponseBaseLinks}
     * @memberof GetSiteAssignments200Response
     */
    '_links'?: ResponseBaseLinks;
    /**
     * 
     * @type {ResponseBaseMeta}
     * @memberof GetSiteAssignments200Response
     */
    'meta'?: ResponseBaseMeta;
    /**
     * This list contains the templates assigned to a site. It can have maximum 2 elements
     * @type {Array<SiteAssignment>}
     * @memberof GetSiteAssignments200Response
     */
    'data'?: Array<SiteAssignment>;
}
/**
 * 
 * @export
 * @interface GetSiteById200Response
 */
export interface GetSiteById200Response {
    /**
     * 
     * @type {ResponseBaseLinks}
     * @memberof GetSiteById200Response
     */
    '_links'?: ResponseBaseLinks;
    /**
     * 
     * @type {ResponseBaseMeta}
     * @memberof GetSiteById200Response
     */
    'meta'?: ResponseBaseMeta;
    /**
     * 
     * @type {Array<GetSiteResponse>}
     * @memberof GetSiteById200Response
     */
    'data'?: Array<GetSiteResponse>;
}
/**
 * 
 * @export
 * @interface GetSitePointsByQRCode200Response
 */
export interface GetSitePointsByQRCode200Response {
    /**
     * 
     * @type {ResponseBaseLinks}
     * @memberof GetSitePointsByQRCode200Response
     */
    '_links'?: ResponseBaseLinks;
    /**
     * 
     * @type {ResponseBaseMeta}
     * @memberof GetSitePointsByQRCode200Response
     */
    'meta'?: ResponseBaseMeta;
    /**
     * 
     * @type {Array<GetSiteAndPointsByQR>}
     * @memberof GetSitePointsByQRCode200Response
     */
    'data'?: Array<GetSiteAndPointsByQR>;
}
/**
 * 
 * @export
 * @interface GetSiteResponse
 */
export interface GetSiteResponse {
    /**
     * 
     * @type {string}
     * @memberof GetSiteResponse
     */
    'id'?: string;
    /**
     * Name of this site
     * @type {string}
     * @memberof GetSiteResponse
     */
    'name'?: string;
    /**
     * the unique id within HecOne for that site
     * @type {string}
     * @memberof GetSiteResponse
     */
    'code'?: string;
    /**
     * Name of the Mandator to which the site belongs
     * @type {string}
     * @memberof GetSiteResponse
     */
    'mandator_name'?: string;
    /**
     * Id of the Mandator to which the site belongs
     * @type {string}
     * @memberof GetSiteResponse
     */
    'mandator_id'?: string;
    /**
     * 
     * @type {string}
     * @memberof GetSiteResponse
     */
    'street'?: string;
    /**
     * 
     * @type {string}
     * @memberof GetSiteResponse
     */
    'city'?: string;
    /**
     * 
     * @type {string}
     * @memberof GetSiteResponse
     */
    'country'?: string;
    /**
     * 
     * @type {string}
     * @memberof GetSiteResponse
     */
    'currency'?: string;
    /**
     * 
     * @type {number}
     * @memberof GetSiteResponse
     */
    'tax_percentage'?: number;
    /**
     * 
     * @type {string}
     * @memberof GetSiteResponse
     */
    'site_state'?: GetSiteResponseSiteStateEnum;
    /**
     * 
     * @type {Array<SiteAllOfOpeningHours>}
     * @memberof GetSiteResponse
     */
    'opening_hours'?: Array<SiteAllOfOpeningHours>;
    /**
     * 
     * @type {Gps}
     * @memberof GetSiteResponse
     */
    'gps'?: Gps;
    /**
     * 
     * @type {Array<PointCategory>}
     * @memberof GetSiteResponse
     */
    'point_category'?: Array<PointCategory>;
    /**
     * All charging, parking or refuelling points of the site
     * @type {number}
     * @memberof GetSiteResponse
     */
    'capacity'?: number;
    /**
     * Timezone for a site
     * @type {string}
     * @memberof GetSiteResponse
     */
    'timezone'?: string;
    /**
     * Default language for a site
     * @type {string}
     * @memberof GetSiteResponse
     */
    'default_language'?: string;
}

export const GetSiteResponseSiteStateEnum = {
    Active: 'active',
    Inactive: 'inactive',
    Construction: 'construction',
    Dismantled: 'dismantled'
} as const;

export type GetSiteResponseSiteStateEnum = typeof GetSiteResponseSiteStateEnum[keyof typeof GetSiteResponseSiteStateEnum];

/**
 * 
 * @export
 * @interface GetSiteTwint200Response
 */
export interface GetSiteTwint200Response {
    /**
     * 
     * @type {ResponseBaseLinks}
     * @memberof GetSiteTwint200Response
     */
    '_links'?: ResponseBaseLinks;
    /**
     * 
     * @type {ResponseBaseMeta}
     * @memberof GetSiteTwint200Response
     */
    'meta'?: ResponseBaseMeta;
    /**
     * 
     * @type {Array<SiteDetails>}
     * @memberof GetSiteTwint200Response
     */
    'data'?: Array<SiteDetails>;
}
/**
 * 
 * @export
 * @interface GetSites200Response
 */
export interface GetSites200Response {
    /**
     * 
     * @type {ResponseBaseLinks}
     * @memberof GetSites200Response
     */
    '_links'?: ResponseBaseLinks;
    /**
     * 
     * @type {ResponseBaseMeta}
     * @memberof GetSites200Response
     */
    'meta'?: ResponseBaseMeta;
    /**
     * 
     * @type {Array<CompGetSitesResponse>}
     * @memberof GetSites200Response
     */
    'data'?: Array<CompGetSitesResponse>;
}
/**
 * @type GetSitesFilterParameter
 * @export
 */
export type GetSitesFilterParameter = CompositeFilterDescriptor | FilterDescriptor;

/**
 * 
 * @export
 * @interface GetSitesLocation200Response
 */
export interface GetSitesLocation200Response {
    /**
     * 
     * @type {ResponseBaseLinks}
     * @memberof GetSitesLocation200Response
     */
    '_links'?: ResponseBaseLinks;
    /**
     * 
     * @type {ResponseBaseMeta}
     * @memberof GetSitesLocation200Response
     */
    'meta'?: ResponseBaseMeta;
    /**
     * 
     * @type {Array<SiteLocation>}
     * @memberof GetSitesLocation200Response
     */
    'data'?: Array<SiteLocation>;
}
/**
 * 
 * @export
 * @interface GetSitesQRCodes200Response
 */
export interface GetSitesQRCodes200Response {
    /**
     * 
     * @type {ResponseBaseLinks}
     * @memberof GetSitesQRCodes200Response
     */
    '_links'?: ResponseBaseLinks;
    /**
     * 
     * @type {ResponseBaseMeta}
     * @memberof GetSitesQRCodes200Response
     */
    'meta'?: ResponseBaseMeta;
    /**
     * 
     * @type {Array<SitesQR>}
     * @memberof GetSitesQRCodes200Response
     */
    'data'?: Array<SitesQR>;
}
/**
 * 
 * @export
 * @interface GetSitesTemplates200Response
 */
export interface GetSitesTemplates200Response {
    /**
     * 
     * @type {ResponseBaseLinks}
     * @memberof GetSitesTemplates200Response
     */
    '_links'?: ResponseBaseLinks;
    /**
     * 
     * @type {ResponseBaseMeta}
     * @memberof GetSitesTemplates200Response
     */
    'meta'?: ResponseBaseMeta;
    /**
     * 
     * @type {Array<SitesAssignments>}
     * @memberof GetSitesTemplates200Response
     */
    'data'?: Array<SitesAssignments>;
}
/**
 * 
 * @export
 * @interface GetStatisticsV3200Response
 */
export interface GetStatisticsV3200Response {
    /**
     * 
     * @type {ResponseBaseLinks}
     * @memberof GetStatisticsV3200Response
     */
    '_links'?: ResponseBaseLinks;
    /**
     * 
     * @type {ResponseBaseMeta}
     * @memberof GetStatisticsV3200Response
     */
    'meta'?: ResponseBaseMeta;
    /**
     * 
     * @type {Array<StatisticsResponse>}
     * @memberof GetStatisticsV3200Response
     */
    'data'?: Array<StatisticsResponse>;
}
/**
 * 
 * @export
 * @interface GetTemplateResponseComponent
 */
export interface GetTemplateResponseComponent {
    /**
     * Unique identifier of a mandator
     * @type {string}
     * @memberof GetTemplateResponseComponent
     */
    'mandator_id': string;
    /**
     * The name of the receipt template
     * @type {string}
     * @memberof GetTemplateResponseComponent
     */
    'name': string;
    /**
     * The type of the receipt template
     * @type {string}
     * @memberof GetTemplateResponseComponent
     */
    'type': GetTemplateResponseComponentTypeEnum;
    /**
     * Unique identifier of a template
     * @type {string}
     * @memberof GetTemplateResponseComponent
     */
    'id': string;
    /**
     * The date when the template was created in UTC
     * @type {string}
     * @memberof GetTemplateResponseComponent
     */
    'created_at': string;
    /**
     * 
     * @type {UpdateHeader}
     * @memberof GetTemplateResponseComponent
     */
    'header': UpdateHeader;
    /**
     * 
     * @type {UpdateFooter}
     * @memberof GetTemplateResponseComponent
     */
    'footer': UpdateFooter;
    /**
     * 
     * @type {Array<GetTemplateResponseComponentAllOfTemplateFields>}
     * @memberof GetTemplateResponseComponent
     */
    'template_fields': Array<GetTemplateResponseComponentAllOfTemplateFields>;
}

export const GetTemplateResponseComponentTypeEnum = {
    Invoice: 'invoice',
    Note: 'note'
} as const;

export type GetTemplateResponseComponentTypeEnum = typeof GetTemplateResponseComponentTypeEnum[keyof typeof GetTemplateResponseComponentTypeEnum];

/**
 * 
 * @export
 * @interface GetTemplateResponseComponentAllOfTemplateFields
 */
export interface GetTemplateResponseComponentAllOfTemplateFields {
    /**
     * 
     * @type {string}
     * @memberof GetTemplateResponseComponentAllOfTemplateFields
     */
    'id': string;
    /**
     * The name of the receipt field that is the same as transaction attribute
     * @type {string}
     * @memberof GetTemplateResponseComponentAllOfTemplateFields
     */
    'name': string;
    /**
     * Category to which the field belongs to
     * @type {string}
     * @memberof GetTemplateResponseComponentAllOfTemplateFields
     */
    'category': GetTemplateResponseComponentAllOfTemplateFieldsCategoryEnum;
    /**
     * The position of the field at display
     * @type {string}
     * @memberof GetTemplateResponseComponentAllOfTemplateFields
     */
    'position': GetTemplateResponseComponentAllOfTemplateFieldsPositionEnum;
    /**
     * Translations of the field for each system language
     * @type {string}
     * @memberof GetTemplateResponseComponentAllOfTemplateFields
     */
    'custom_translation'?: string;
    /**
     * The unique identifier of the field from receipt
     * @type {string}
     * @memberof GetTemplateResponseComponentAllOfTemplateFields
     */
    'field_id': string;
    /**
     * The order of how the fields are displayed
     * @type {number}
     * @memberof GetTemplateResponseComponentAllOfTemplateFields
     */
    'field_order': number;
}

export const GetTemplateResponseComponentAllOfTemplateFieldsCategoryEnum = {
    Payment: 'payment',
    Article: 'article',
    General: 'general',
    CustomerData: 'customer_data',
    Receipt: 'receipt'
} as const;

export type GetTemplateResponseComponentAllOfTemplateFieldsCategoryEnum = typeof GetTemplateResponseComponentAllOfTemplateFieldsCategoryEnum[keyof typeof GetTemplateResponseComponentAllOfTemplateFieldsCategoryEnum];
export const GetTemplateResponseComponentAllOfTemplateFieldsPositionEnum = {
    Row: 'row',
    Column: 'column'
} as const;

export type GetTemplateResponseComponentAllOfTemplateFieldsPositionEnum = typeof GetTemplateResponseComponentAllOfTemplateFieldsPositionEnum[keyof typeof GetTemplateResponseComponentAllOfTemplateFieldsPositionEnum];

/**
 * 
 * @export
 * @interface GetTemplates200Response
 */
export interface GetTemplates200Response {
    /**
     * 
     * @type {ResponseBaseLinks}
     * @memberof GetTemplates200Response
     */
    '_links'?: ResponseBaseLinks;
    /**
     * 
     * @type {ResponseBaseMeta}
     * @memberof GetTemplates200Response
     */
    'meta'?: ResponseBaseMeta;
    /**
     * 
     * @type {Array<ProviderTemplates>}
     * @memberof GetTemplates200Response
     */
    'data'?: Array<ProviderTemplates>;
}
/**
 * 
 * @export
 * @interface GetTemplatesResponseComponent
 */
export interface GetTemplatesResponseComponent {
    /**
     * Unique identifier of a mandator
     * @type {string}
     * @memberof GetTemplatesResponseComponent
     */
    'mandator_id': string;
    /**
     * The name of the receipt template
     * @type {string}
     * @memberof GetTemplatesResponseComponent
     */
    'name': string;
    /**
     * The type of the receipt template
     * @type {string}
     * @memberof GetTemplatesResponseComponent
     */
    'type': GetTemplatesResponseComponentTypeEnum;
    /**
     * Unique identifier of a template
     * @type {string}
     * @memberof GetTemplatesResponseComponent
     */
    'id': string;
    /**
     * The date when the template was created in UTC
     * @type {string}
     * @memberof GetTemplatesResponseComponent
     */
    'created_at': string;
    /**
     * Represents the number of sites available for a template
     * @type {number}
     * @memberof GetTemplatesResponseComponent
     */
    'number_of_sites': number;
}

export const GetTemplatesResponseComponentTypeEnum = {
    Invoice: 'invoice',
    Note: 'note'
} as const;

export type GetTemplatesResponseComponentTypeEnum = typeof GetTemplatesResponseComponentTypeEnum[keyof typeof GetTemplatesResponseComponentTypeEnum];

/**
 * 
 * @export
 * @interface GetTransaction
 */
export interface GetTransaction {
    /**
     * The id of the transaction from HecOne
     * @type {string}
     * @memberof GetTransaction
     */
    'id'?: string;
    /**
     * The date and time when an article has started to be used
     * @type {string}
     * @memberof GetTransaction
     */
    'start_time'?: string;
    /**
     * The date and time when an article ended up being used
     * @type {string}
     * @memberof GetTransaction
     */
    'end_time'?: string;
    /**
     * The final amount that the user has to pay
     * @type {number}
     * @memberof GetTransaction
     */
    'amount'?: number;
    /**
     * How much of the article was consumed
     * @type {number}
     * @memberof GetTransaction
     */
    'quantity'?: number;
    /**
     * The unit measure used for the article
     * @type {string}
     * @memberof GetTransaction
     */
    'unit_measure'?: GetTransactionUnitMeasureEnum;
    /**
     * The unit price for the article
     * @type {number}
     * @memberof GetTransaction
     */
    'unit_price'?: number;
    /**
     * currency code in ISO 4217
     * @type {string}
     * @memberof GetTransaction
     */
    'currency'?: string;
    /**
     * The state of the transaction
     * @type {string}
     * @memberof GetTransaction
     */
    'state'?: GetTransactionStateEnum;
    /**
     * 
     * @type {GetTransactionSite}
     * @memberof GetTransaction
     */
    'site'?: GetTransactionSite;
    /**
     * 
     * @type {GetTransactionPoint}
     * @memberof GetTransaction
     */
    'point'?: GetTransactionPoint;
    /**
     * 
     * @type {LogInner}
     * @memberof GetTransaction
     */
    'log'?: LogInner;
}

export const GetTransactionUnitMeasureEnum = {
    KWh: 'kWh',
    Pc: 'pc',
    Kg: 'kg',
    L: 'l',
    Min: 'min',
    Km: 'km',
    M: 'm',
    Cm: 'cm',
    Mm: 'mm',
    In: 'in',
    Wh: 'Wh',
    A: 'a',
    D: 'd',
    H: 'h',
    S: 's',
    M2: 'm²',
    M3: 'm³',
    P: 'p',
    C: '°C',
    F: '°F'
} as const;

export type GetTransactionUnitMeasureEnum = typeof GetTransactionUnitMeasureEnum[keyof typeof GetTransactionUnitMeasureEnum];
export const GetTransactionStateEnum = {
    Created: 'created',
    Pending: 'pending',
    Consumed: 'consumed',
    Completed: 'completed',
    Declined: 'declined',
    Error: 'error'
} as const;

export type GetTransactionStateEnum = typeof GetTransactionStateEnum[keyof typeof GetTransactionStateEnum];

/**
 * 
 * @export
 * @interface GetTransactionPoint
 */
export interface GetTransactionPoint {
    /**
     * The name of the point
     * @type {string}
     * @memberof GetTransactionPoint
     */
    'name'?: string;
}
/**
 * 
 * @export
 * @interface GetTransactionSite
 */
export interface GetTransactionSite {
    /**
     * The name of the site
     * @type {string}
     * @memberof GetTransactionSite
     */
    'name'?: string;
    /**
     * Street address of the site
     * @type {string}
     * @memberof GetTransactionSite
     */
    'street'?: string;
    /**
     * City address of the site
     * @type {string}
     * @memberof GetTransactionSite
     */
    'city'?: string;
}
/**
 * 
 * @export
 * @interface GetTransactionTwint200Response
 */
export interface GetTransactionTwint200Response {
    /**
     * 
     * @type {ResponseBaseLinks}
     * @memberof GetTransactionTwint200Response
     */
    '_links'?: ResponseBaseLinks;
    /**
     * 
     * @type {ResponseBaseMeta}
     * @memberof GetTransactionTwint200Response
     */
    'meta'?: ResponseBaseMeta;
    /**
     * 
     * @type {Array<GetTransaction>}
     * @memberof GetTransactionTwint200Response
     */
    'data'?: Array<GetTransaction>;
}
/**
 * 
 * @export
 * @interface GetTransactionsV3200Response
 */
export interface GetTransactionsV3200Response {
    /**
     * 
     * @type {ResponseBaseLinks}
     * @memberof GetTransactionsV3200Response
     */
    '_links'?: ResponseBaseLinks;
    /**
     * 
     * @type {ResponseBaseMeta}
     * @memberof GetTransactionsV3200Response
     */
    'meta'?: ResponseBaseMeta;
    /**
     * 
     * @type {Array<TransactionsResponseExtended>}
     * @memberof GetTransactionsV3200Response
     */
    'data'?: Array<TransactionsResponseExtended>;
}
/**
 * 
 * @export
 * @interface GetUserById200Response
 */
export interface GetUserById200Response {
    /**
     * 
     * @type {ResponseBaseLinks}
     * @memberof GetUserById200Response
     */
    '_links'?: ResponseBaseLinks;
    /**
     * 
     * @type {ResponseBaseMeta}
     * @memberof GetUserById200Response
     */
    'meta'?: ResponseBaseMeta;
    /**
     * 
     * @type {Array<User>}
     * @memberof GetUserById200Response
     */
    'data'?: Array<User>;
}
/**
 * 
 * @export
 * @interface GetUserCharts200Response
 */
export interface GetUserCharts200Response {
    /**
     * 
     * @type {ResponseBaseLinks}
     * @memberof GetUserCharts200Response
     */
    '_links'?: ResponseBaseLinks;
    /**
     * 
     * @type {ResponseBaseMeta}
     * @memberof GetUserCharts200Response
     */
    'meta'?: ResponseBaseMeta;
    /**
     * 
     * @type {Array<ChartsResponse>}
     * @memberof GetUserCharts200Response
     */
    'data'?: Array<ChartsResponse>;
}
/**
 * 
 * @export
 * @interface GetUserLayouts200Response
 */
export interface GetUserLayouts200Response {
    /**
     * 
     * @type {ResponseBaseLinks}
     * @memberof GetUserLayouts200Response
     */
    '_links'?: ResponseBaseLinks;
    /**
     * 
     * @type {ResponseBaseMeta}
     * @memberof GetUserLayouts200Response
     */
    'meta'?: ResponseBaseMeta;
    /**
     * 
     * @type {Array<UserLayouts>}
     * @memberof GetUserLayouts200Response
     */
    'data'?: Array<UserLayouts>;
}
/**
 * 
 * @export
 * @interface GetUserPermissionGroup200Response
 */
export interface GetUserPermissionGroup200Response {
    /**
     * 
     * @type {ResponseBaseLinks}
     * @memberof GetUserPermissionGroup200Response
     */
    '_links'?: ResponseBaseLinks;
    /**
     * 
     * @type {ResponseBaseMeta}
     * @memberof GetUserPermissionGroup200Response
     */
    'meta'?: ResponseBaseMeta;
    /**
     * 
     * @type {Array<PermissionGroup>}
     * @memberof GetUserPermissionGroup200Response
     */
    'data'?: Array<PermissionGroup>;
}
/**
 * 
 * @export
 * @interface GetUsers200Response
 */
export interface GetUsers200Response {
    /**
     * 
     * @type {ResponseBaseLinks}
     * @memberof GetUsers200Response
     */
    '_links'?: ResponseBaseLinks;
    /**
     * 
     * @type {ResponseBaseMeta}
     * @memberof GetUsers200Response
     */
    'meta'?: ResponseBaseMeta;
    /**
     * 
     * @type {Array<UserResponse>}
     * @memberof GetUsers200Response
     */
    'data'?: Array<UserResponse>;
}
/**
 * 
 * @export
 * @interface Gps
 */
export interface Gps {
    /**
     * 
     * @type {number}
     * @memberof Gps
     */
    'latitude': number;
    /**
     * 
     * @type {number}
     * @memberof Gps
     */
    'longitude': number;
}
/**
 * 
 * @export
 * @interface GraceConfig
 */
export interface GraceConfig {
    /**
     * 
     * @type {number}
     * @memberof GraceConfig
     */
    'id'?: number;
    /**
     * time in minute. Duration a driver must wait after a granted period. 
     * @type {number}
     * @memberof GraceConfig
     */
    'grace_period': number;
    /**
     * time in minute. Duration free parking is granted.
     * @type {number}
     * @memberof GraceConfig
     */
    'duration': number;
    /**
     * indicate if free parking is available on monday
     * @type {boolean}
     * @memberof GraceConfig
     */
    'monday': boolean;
    /**
     * 
     * @type {boolean}
     * @memberof GraceConfig
     */
    'tuesday': boolean;
    /**
     * 
     * @type {boolean}
     * @memberof GraceConfig
     */
    'wednesday': boolean;
    /**
     * 
     * @type {boolean}
     * @memberof GraceConfig
     */
    'thursday': boolean;
    /**
     * 
     * @type {boolean}
     * @memberof GraceConfig
     */
    'friday': boolean;
    /**
     * 
     * @type {boolean}
     * @memberof GraceConfig
     */
    'saturday': boolean;
    /**
     * 
     * @type {boolean}
     * @memberof GraceConfig
     */
    'sunday': boolean;
}
/**
 * 
 * @export
 * @interface HeaderBase
 */
export interface HeaderBase {
    /**
     * The url where the logo is stored. Represents the image displayed on receipt header
     * @type {string}
     * @memberof HeaderBase
     */
    'logo': string;
    /**
     * Title of the receipt
     * @type {string}
     * @memberof HeaderBase
     */
    'title'?: string;
    /**
     * The position of the logo image
     * @type {string}
     * @memberof HeaderBase
     */
    'logo_alignment': HeaderBaseLogoAlignmentEnum;
    /**
     * 
     * @type {string}
     * @memberof HeaderBase
     */
    'contact_details': string;
}

export const HeaderBaseLogoAlignmentEnum = {
    Right: 'right',
    Left: 'left'
} as const;

export type HeaderBaseLogoAlignmentEnum = typeof HeaderBaseLogoAlignmentEnum[keyof typeof HeaderBaseLogoAlignmentEnum];

/**
 * 
 * @export
 * @interface HistoryResponse
 */
export interface HistoryResponse {
    /**
     * The user that did the change
     * @type {string}
     * @memberof HistoryResponse
     */
    'user'?: string;
    /**
     * The date and time when the change was made
     * @type {string}
     * @memberof HistoryResponse
     */
    'change_time'?: string;
    /**
     * The action that was made on the resource
     * @type {string}
     * @memberof HistoryResponse
     */
    'action'?: HistoryResponseActionEnum;
    /**
     * 
     * @type {HistoryResponseBeforeValue}
     * @memberof HistoryResponse
     */
    'before_value'?: HistoryResponseBeforeValue;
    /**
     * 
     * @type {HistoryResponseAfterValue}
     * @memberof HistoryResponse
     */
    'after_value'?: HistoryResponseAfterValue;
}

export const HistoryResponseActionEnum = {
    Create: 'create',
    Delete: 'delete',
    Update: 'update'
} as const;

export type HistoryResponseActionEnum = typeof HistoryResponseActionEnum[keyof typeof HistoryResponseActionEnum];

/**
 * @type HistoryResponseAfterValue
 * @export
 */
export type HistoryResponseAfterValue = Array<object> | object;

/**
 * @type HistoryResponseBeforeValue
 * @export
 */
export type HistoryResponseBeforeValue = Array<object> | object;

/**
 * 
 * @export
 * @interface IdentifiableCard
 */
export interface IdentifiableCard {
    /**
     * The unique identifier of the mandator to which the card belongs
     * @type {string}
     * @memberof IdentifiableCard
     */
    'mandator_id': string;
    /**
     * 
     * @type {string}
     * @memberof IdentifiableCard
     */
    'number': string;
    /**
     * 
     * @type {string}
     * @memberof IdentifiableCard
     */
    'payment_card_id': string;
    /**
     * 
     * @type {boolean}
     * @memberof IdentifiableCard
     */
    'status'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof IdentifiableCard
     */
    'is_pin_required'?: boolean;
    /**
     * if is_pin_required is true, then pin value is required
     * @type {string}
     * @memberof IdentifiableCard
     */
    'pin'?: string;
    /**
     * 
     * @type {string}
     * @memberof IdentifiableCard
     */
    'additional_info'?: string;
    /**
     * 
     * @type {string}
     * @memberof IdentifiableCard
     */
    'release_date'?: string;
    /**
     * 
     * @type {string}
     * @memberof IdentifiableCard
     */
    'expiry_date'?: string;
    /**
     * 
     * @type {string}
     * @memberof IdentifiableCard
     */
    'card_owner'?: string;
    /**
     * 
     * @type {string}
     * @memberof IdentifiableCard
     */
    'vehicle'?: string;
    /**
     * The unique identifier of the Card
     * @type {string}
     * @memberof IdentifiableCard
     */
    'id'?: string;
}
/**
 * Response for identification
 * @export
 * @interface Identification
 */
export interface Identification {
    /**
     * unique id generated for request
     * @type {string}
     * @memberof Identification
     */
    'id'?: string;
    /**
     * 
     * @type {Card}
     * @memberof Identification
     */
    'card'?: Card;
    /**
     * 
     * @type {AdditionalCard}
     * @memberof Identification
     */
    'additional_card'?: AdditionalCard;
    /**
     * 
     * @type {Array<string>}
     * @memberof Identification
     */
    'required_fields'?: Array<string>;
    /**
     * 
     * @type {IdentificationRestrictions}
     * @memberof Identification
     */
    'restrictions'?: IdentificationRestrictions;
    /**
     * 
     * @type {IdentificationPrivileges}
     * @memberof Identification
     */
    'privileges'?: IdentificationPrivileges;
    /**
     * state of auth request
     * @type {string}
     * @memberof Identification
     */
    'state'?: IdentificationStateEnum;
}

export const IdentificationStateEnum = {
    Pending: 'pending',
    Rejected: 'rejected',
    Identified: 'identified',
    Error: 'error'
} as const;

export type IdentificationStateEnum = typeof IdentificationStateEnum[keyof typeof IdentificationStateEnum];

/**
 * Error Response for identification
 * @export
 * @interface IdentificationErrorResponse
 */
export interface IdentificationErrorResponse {
    /**
     * 
     * @type {Array<IdentificationErrorResponseDataInner>}
     * @memberof IdentificationErrorResponse
     */
    'data'?: Array<IdentificationErrorResponseDataInner>;
    /**
     * 
     * @type {Array<SchemasResponseBaseErrorsInner>}
     * @memberof IdentificationErrorResponse
     */
    'errors'?: Array<SchemasResponseBaseErrorsInner>;
}
/**
 * 
 * @export
 * @interface IdentificationErrorResponseDataInner
 */
export interface IdentificationErrorResponseDataInner {
    /**
     * unique id generated for request
     * @type {string}
     * @memberof IdentificationErrorResponseDataInner
     */
    'id': string;
    /**
     * 
     * @type {string}
     * @memberof IdentificationErrorResponseDataInner
     */
    'state': IdentificationErrorResponseDataInnerStateEnum;
    /**
     * 
     * @type {Array<string>}
     * @memberof IdentificationErrorResponseDataInner
     */
    'required_fields'?: Array<IdentificationErrorResponseDataInnerRequiredFieldsEnum>;
}

export const IdentificationErrorResponseDataInnerStateEnum = {
    Error: 'error',
    Rejected: 'rejected',
    Pending: 'pending'
} as const;

export type IdentificationErrorResponseDataInnerStateEnum = typeof IdentificationErrorResponseDataInnerStateEnum[keyof typeof IdentificationErrorResponseDataInnerStateEnum];
export const IdentificationErrorResponseDataInnerRequiredFieldsEnum = {
    Pin: 'PIN',
    Odometer: 'odometer',
    Driver: 'driver',
    Cnp: 'CNP',
    BDriverCard: 'B-DriverCard',
    AVehicleCard: 'A-VehicleCard'
} as const;

export type IdentificationErrorResponseDataInnerRequiredFieldsEnum = typeof IdentificationErrorResponseDataInnerRequiredFieldsEnum[keyof typeof IdentificationErrorResponseDataInnerRequiredFieldsEnum];

/**
 * privileges like discounts - still not finalized!
 * @export
 * @interface IdentificationPrivileges
 */
export interface IdentificationPrivileges {
    /**
     * amount in Currency like EUR which is deducated from total later (e.g. 20EURO discount because of Voucher)
     * @type {number}
     * @memberof IdentificationPrivileges
     */
    'amount'?: number;
    /**
     * percentage in Currency like EUR which is deducated from total later (e.g. 10% discount because of Voucher)
     * @type {number}
     * @memberof IdentificationPrivileges
     */
    'percentage'?: number;
    /**
     * 
     * @type {Array<IdentificationProduct>}
     * @memberof IdentificationPrivileges
     */
    'products'?: Array<IdentificationProduct>;
}
/**
 * 
 * @export
 * @interface IdentificationProduct
 */
export interface IdentificationProduct {
    /**
     * The unique identifier of the product
     * @type {string}
     * @memberof IdentificationProduct
     */
    'id'?: string;
    /**
     * 
     * @type {string}
     * @memberof IdentificationProduct
     */
    'category'?: IdentificationProductCategoryEnum;
    /**
     * The identifier of the product
     * @type {string}
     * @memberof IdentificationProduct
     */
    'code'?: string;
    /**
     * Name of the product
     * @type {string}
     * @memberof IdentificationProduct
     */
    'name'?: string;
    /**
     * Short description of the product
     * @type {string}
     * @memberof IdentificationProduct
     */
    'description'?: string;
    /**
     * The unit measure used for the product
     * @type {string}
     * @memberof IdentificationProduct
     */
    'unit_measure'?: IdentificationProductUnitMeasureEnum;
}

export const IdentificationProductCategoryEnum = {
    Parking: 'parking',
    Charging: 'charging',
    Refuelling: 'refuelling'
} as const;

export type IdentificationProductCategoryEnum = typeof IdentificationProductCategoryEnum[keyof typeof IdentificationProductCategoryEnum];
export const IdentificationProductUnitMeasureEnum = {
    KWh: 'kWh',
    Pc: 'pc',
    Kg: 'kg',
    L: 'l',
    Min: 'min',
    Km: 'km',
    M: 'm',
    Cm: 'cm',
    Mm: 'mm',
    In: 'in',
    Wh: 'Wh',
    A: 'a',
    D: 'd',
    H: 'h',
    S: 's',
    M2: 'm²',
    M3: 'm³',
    P: 'p',
    C: '°C',
    F: '°F'
} as const;

export type IdentificationProductUnitMeasureEnum = typeof IdentificationProductUnitMeasureEnum[keyof typeof IdentificationProductUnitMeasureEnum];

/**
 * 
 * @export
 * @interface IdentificationRequest
 */
export interface IdentificationRequest {
    /**
     * 
     * @type {Card}
     * @memberof IdentificationRequest
     */
    'card'?: Card;
    /**
     * 
     * @type {AdditionalCard}
     * @memberof IdentificationRequest
     */
    'additional_card'?: AdditionalCard;
}
/**
 * 
 * @export
 * @interface IdentificationResponse
 */
export interface IdentificationResponse {
    /**
     * 
     * @type {ResponseBaseLinks}
     * @memberof IdentificationResponse
     */
    'links'?: ResponseBaseLinks;
    /**
     * 
     * @type {ResponseBaseMeta}
     * @memberof IdentificationResponse
     */
    'meta'?: ResponseBaseMeta;
    /**
     * 
     * @type {Array<SchemasResponseBaseErrorsInner>}
     * @memberof IdentificationResponse
     */
    'errors'?: Array<SchemasResponseBaseErrorsInner>;
    /**
     * 
     * @type {Array<Identification>}
     * @memberof IdentificationResponse
     */
    'data'?: Array<Identification>;
}
/**
 * restrictions
 * @export
 * @interface IdentificationRestrictions
 */
export interface IdentificationRestrictions {
    /**
     * max amount authorised by this request in a currency like EURO
     * @type {number}
     * @memberof IdentificationRestrictions
     */
    'amount'?: number;
    /**
     * max quantity authorised by this request in a quanitity like l or kg
     * @type {number}
     * @memberof IdentificationRestrictions
     */
    'quanitity'?: number;
    /**
     * 
     * @type {Array<IdentificationProduct>}
     * @memberof IdentificationRestrictions
     */
    'products'?: Array<IdentificationProduct>;
}
/**
 * 
 * @export
 * @interface IntervalConfig
 */
export interface IntervalConfig {
    /**
     * 
     * @type {number}
     * @memberof IntervalConfig
     */
    'id'?: number;
    /**
     * 
     * @type {string}
     * @memberof IntervalConfig
     * @deprecated
     */
    'mandator_id'?: string;
    /**
     * 
     * @type {string}
     * @memberof IntervalConfig
     * @deprecated
     */
    'site_id'?: string;
    /**
     * expect an string in hh:mm. Earliest time this config is applied.
     * @type {string}
     * @memberof IntervalConfig
     */
    'interval_start': string;
    /**
     * expect an string in hh:mm. Latest time until this config is applied excluding this time.
     * @type {string}
     * @memberof IntervalConfig
     */
    'interval_end': string;
    /**
     * time in minute. Duration free parking is granted.
     * @type {number}
     * @memberof IntervalConfig
     */
    'duration': number;
    /**
     * indicate if free parking is available on monday
     * @type {boolean}
     * @memberof IntervalConfig
     */
    'monday': boolean;
    /**
     * 
     * @type {boolean}
     * @memberof IntervalConfig
     */
    'tuesday': boolean;
    /**
     * 
     * @type {boolean}
     * @memberof IntervalConfig
     */
    'wednesday': boolean;
    /**
     * 
     * @type {boolean}
     * @memberof IntervalConfig
     */
    'thursday': boolean;
    /**
     * 
     * @type {boolean}
     * @memberof IntervalConfig
     */
    'friday': boolean;
    /**
     * 
     * @type {boolean}
     * @memberof IntervalConfig
     */
    'saturday': boolean;
    /**
     * 
     * @type {boolean}
     * @memberof IntervalConfig
     */
    'sunday': boolean;
}
/**
 * 
 * @export
 * @interface Invoice
 */
export interface Invoice {
    /**
     * 
     * @type {string}
     * @memberof Invoice
     */
    'id'?: string;
    /**
     * 
     * @type {string}
     * @memberof Invoice
     */
    'created_at'?: string;
    /**
     * Amount the user has to pay including VAT.in currency unit.
     * @type {number}
     * @memberof Invoice
     */
    'amount'?: number;
    /**
     * Amount of VAT. Has to be lower than total amount. Returned in  currency unit.
     * @type {number}
     * @memberof Invoice
     */
    'tax_amount'?: number;
    /**
     * currency code in ISO 4217
     * @type {string}
     * @memberof Invoice
     */
    'currency'?: string;
}
/**
 * 
 * @export
 * @interface InvoiceV3
 */
export interface InvoiceV3 {
    /**
     * The final amount of the transaction that needs to be payed for the articles
     * @type {number}
     * @memberof InvoiceV3
     */
    'amount'?: number;
    /**
     * The total amount of all collected payments
     * @type {number}
     * @memberof InvoiceV3
     */
    'paid_amount'?: number;
    /**
     * 
     * @type {string}
     * @memberof InvoiceV3
     */
    'currency'?: string;
    /**
     * The date and time when the invoice was created
     * @type {string}
     * @memberof InvoiceV3
     */
    'created_at'?: string;
    /**
     * 
     * @type {number}
     * @memberof InvoiceV3
     */
    'tax_amount'?: number;
}
/**
 * 
 * @export
 * @interface ItemlogEntry
 */
export interface ItemlogEntry {
    /**
     * 
     * @type {string}
     * @memberof ItemlogEntry
     */
    'description'?: string;
    /**
     * 
     * @type {string}
     * @memberof ItemlogEntry
     * @deprecated
     */
    'old_value'?: string;
    /**
     * 
     * @type {string}
     * @memberof ItemlogEntry
     * @deprecated
     */
    'target_value'?: string;
    /**
     * Codes will be defined in https://gitlab.hectronic.com:8443/hecone/misc/hecone-error-codes
     * @type {number}
     * @memberof ItemlogEntry
     */
    'code'?: number;
    /**
     * 
     * @type {string}
     * @memberof ItemlogEntry
     */
    'timestamp'?: string;
    /**
     * 
     * @type {string}
     * @memberof ItemlogEntry
     */
    'level'?: ItemlogEntryLevelEnum;
}

export const ItemlogEntryLevelEnum = {
    Info: 'info',
    Warn: 'warn',
    Error: 'error'
} as const;

export type ItemlogEntryLevelEnum = typeof ItemlogEntryLevelEnum[keyof typeof ItemlogEntryLevelEnum];

/**
 * 
 * @export
 * @interface Link
 */
export interface Link {
    /**
     * 
     * @type {string}
     * @memberof Link
     */
    'href'?: string;
}
/**
 * 
 * @export
 * @interface LogInner
 */
export interface LogInner {
    /**
     * 
     * @type {number}
     * @memberof LogInner
     */
    'code'?: number;
    /**
     * 
     * @type {string}
     * @memberof LogInner
     */
    'description'?: string;
    /**
     * 
     * @type {string}
     * @memberof LogInner
     */
    'timestamp'?: string;
    /**
     * 
     * @type {string}
     * @memberof LogInner
     */
    'level'?: string;
}
/**
 * 
 * @export
 * @interface LogicFilterDescriptor
 */
export interface LogicFilterDescriptor {
    /**
     * 
     * @type {string}
     * @memberof LogicFilterDescriptor
     */
    'logic': LogicFilterDescriptorLogicEnum;
}

export const LogicFilterDescriptorLogicEnum = {
    Or: 'or',
    And: 'and'
} as const;

export type LogicFilterDescriptorLogicEnum = typeof LogicFilterDescriptorLogicEnum[keyof typeof LogicFilterDescriptorLogicEnum];

/**
 * 
 * @export
 * @interface LoginResponse
 */
export interface LoginResponse {
    /**
     * 
     * @type {string}
     * @memberof LoginResponse
     */
    'access_token'?: string;
    /**
     * The expiration period in seconds
     * @type {number}
     * @memberof LoginResponse
     */
    'expires_in'?: number;
    /**
     * 
     * @type {string}
     * @memberof LoginResponse
     */
    'token_type'?: string;
    /**
     * 
     * @type {string}
     * @memberof LoginResponse
     */
    'refresh_token'?: string;
    /**
     * 
     * @type {string}
     * @memberof LoginResponse
     */
    'id_token'?: string;
}
/**
 * 
 * @export
 * @interface LoginUser
 */
export interface LoginUser {
    /**
     * User email
     * @type {string}
     * @memberof LoginUser
     */
    'email'?: string;
    /**
     * User password
     * @type {string}
     * @memberof LoginUser
     */
    'password'?: string;
    /**
     * 
     * @type {string}
     * @memberof LoginUser
     */
    'client_id'?: string;
    /**
     * 
     * @type {string}
     * @memberof LoginUser
     */
    'client_secret'?: string;
}
/**
 * 
 * @export
 * @interface MandatorBase
 */
export interface MandatorBase {
    /**
     * 
     * @type {string}
     * @memberof MandatorBase
     */
    'id'?: string;
    /**
     * The mandator registration name used for identifying a mandator
     * @type {string}
     * @memberof MandatorBase
     */
    'name'?: string;
    /**
     * The street address of the mandator
     * @type {string}
     * @memberof MandatorBase
     */
    'street'?: string;
    /**
     * The city address of the mandator
     * @type {string}
     * @memberof MandatorBase
     */
    'city'?: string;
    /**
     * The zip code of the mandator\'s address
     * @type {string}
     * @memberof MandatorBase
     */
    'code'?: string;
    /**
     * The country address of the mandator
     * @type {string}
     * @memberof MandatorBase
     */
    'country'?: string;
    /**
     * The status of the mandator which can be active or inactive
     * @type {boolean}
     * @memberof MandatorBase
     */
    'mandator_state'?: boolean;
    /**
     * The email of the registered mandator
     * @type {string}
     * @memberof MandatorBase
     */
    'email'?: string;
    /**
     * A description that represents the partner of the mandator or even the main mandator to which the current mandator it is a subsidiary
     * @type {string}
     * @memberof MandatorBase
     */
    'sales_partner'?: string;
}
/**
 * 
 * @export
 * @interface MandatorCreate
 */
export interface MandatorCreate {
    /**
     * The mandator registration name used for identifying a mandator
     * @type {string}
     * @memberof MandatorCreate
     */
    'name': string;
    /**
     * The street address of the mandator
     * @type {string}
     * @memberof MandatorCreate
     */
    'street': string;
    /**
     * The city address of the mandator
     * @type {string}
     * @memberof MandatorCreate
     */
    'city': string;
    /**
     * The zip code of the mandator\'s address
     * @type {string}
     * @memberof MandatorCreate
     */
    'code'?: string;
    /**
     * The country address of the mandator
     * @type {string}
     * @memberof MandatorCreate
     */
    'country': string;
    /**
     * The status of the mandator which can be active or inactive
     * @type {boolean}
     * @memberof MandatorCreate
     */
    'mandator_state'?: boolean;
    /**
     * The email of the registered mandator
     * @type {string}
     * @memberof MandatorCreate
     */
    'email'?: string;
    /**
     * A description that represents the partner of the mandator or even the main mandator to which the current mandator it is a subsidiary
     * @type {string}
     * @memberof MandatorCreate
     */
    'sales_partner'?: string;
    /**
     * Describes the mandator in a short text
     * @type {string}
     * @memberof MandatorCreate
     */
    'description'?: string;
    /**
     * Extra features like manage cards, view the list of charging points from integrators, etc on which the mandators has access to if they pay
     * @type {Array<string>}
     * @memberof MandatorCreate
     */
    'services'?: Array<string>;
    /**
     * The url where the logo is stored. Represents the image displayed in the header of the web application
     * @type {string}
     * @memberof MandatorCreate
     */
    'logo'?: string;
    /**
     * A json string that represents the customized theme that will be used by the web application
     * @type {string}
     * @memberof MandatorCreate
     */
    'theme'?: string;
}
/**
 * 
 * @export
 * @interface MandatorCreateResponse
 */
export interface MandatorCreateResponse {
    /**
     * 
     * @type {string}
     * @memberof MandatorCreateResponse
     */
    'id'?: string;
    /**
     * The mandator registration name used for identifying a mandator
     * @type {string}
     * @memberof MandatorCreateResponse
     */
    'name'?: string;
    /**
     * The street address of the mandator
     * @type {string}
     * @memberof MandatorCreateResponse
     */
    'street'?: string;
    /**
     * The city address of the mandator
     * @type {string}
     * @memberof MandatorCreateResponse
     */
    'city'?: string;
    /**
     * The zip code of the mandator\'s address
     * @type {string}
     * @memberof MandatorCreateResponse
     */
    'code'?: string;
    /**
     * The country address of the mandator
     * @type {string}
     * @memberof MandatorCreateResponse
     */
    'country'?: string;
    /**
     * The status of the mandator which can be active or inactive
     * @type {boolean}
     * @memberof MandatorCreateResponse
     */
    'mandator_state'?: boolean;
    /**
     * The email of the registered mandator
     * @type {string}
     * @memberof MandatorCreateResponse
     */
    'email'?: string;
    /**
     * A description that represents the partner of the mandator or even the main mandator to which the current mandator it is a subsidiary
     * @type {string}
     * @memberof MandatorCreateResponse
     */
    'sales_partner'?: string;
    /**
     * Describes the mandator in a short text
     * @type {string}
     * @memberof MandatorCreateResponse
     */
    'description'?: string;
    /**
     * Extra features like manage cards, view the list of charging points from integrators, etc on which the mandators has access to if they pay
     * @type {Array<string>}
     * @memberof MandatorCreateResponse
     */
    'services'?: Array<string>;
    /**
     * The url where the logo is stored. Represents the image displayed in the header of the web application
     * @type {string}
     * @memberof MandatorCreateResponse
     */
    'logo'?: string;
    /**
     * A json string that represents the customized theme that will be used by the web application
     * @type {string}
     * @memberof MandatorCreateResponse
     */
    'theme'?: string;
}
/**
 * 
 * @export
 * @interface MandatorExtended
 */
export interface MandatorExtended {
    /**
     * 
     * @type {string}
     * @memberof MandatorExtended
     */
    'id'?: string;
    /**
     * The mandator registration name used for identifying a mandator
     * @type {string}
     * @memberof MandatorExtended
     */
    'name'?: string;
    /**
     * The street address of the mandator
     * @type {string}
     * @memberof MandatorExtended
     */
    'street'?: string;
    /**
     * The city address of the mandator
     * @type {string}
     * @memberof MandatorExtended
     */
    'city'?: string;
    /**
     * The zip code of the mandator\'s address
     * @type {string}
     * @memberof MandatorExtended
     */
    'code'?: string;
    /**
     * The country address of the mandator
     * @type {string}
     * @memberof MandatorExtended
     */
    'country'?: string;
    /**
     * The status of the mandator which can be active or inactive
     * @type {boolean}
     * @memberof MandatorExtended
     */
    'mandator_state'?: boolean;
    /**
     * The email of the registered mandator
     * @type {string}
     * @memberof MandatorExtended
     */
    'email'?: string;
    /**
     * A description that represents the partner of the mandator or even the main mandator to which the current mandator it is a subsidiary
     * @type {string}
     * @memberof MandatorExtended
     */
    'sales_partner'?: string;
    /**
     * Describes the mandator in a short text
     * @type {string}
     * @memberof MandatorExtended
     */
    'description'?: string;
    /**
     * Extra features like manage cards, view the list of charging points from integrators, etc on which the mandators has access to if they pay
     * @type {Array<string>}
     * @memberof MandatorExtended
     */
    'services'?: Array<string>;
    /**
     * The url where the logo is stored. Represents the image displayed in the header of the web application
     * @type {string}
     * @memberof MandatorExtended
     */
    'logo'?: string;
    /**
     * A json string that represents the customized theme that will be used by the web application
     * @type {string}
     * @memberof MandatorExtended
     */
    'theme'?: string;
}
/**
 * 
 * @export
 * @interface MandatorPoints
 */
export interface MandatorPoints {
    /**
     * The unique identifier of the point
     * @type {string}
     * @memberof MandatorPoints
     */
    'id'?: string;
    /**
     * Point number
     * @type {number}
     * @memberof MandatorPoints
     */
    'point_number'?: number;
    /**
     * The name of the point
     * @type {string}
     * @memberof MandatorPoints
     */
    'name'?: string;
    /**
     * 
     * @type {PointStatus}
     * @memberof MandatorPoints
     */
    'type'?: PointStatus;
    /**
     * 
     * @type {PointStatus}
     * @memberof MandatorPoints
     */
    'status'?: PointStatus;
    /**
     * Code of the device where the point is assigned
     * @type {string}
     * @memberof MandatorPoints
     */
    'device_code'?: string;
    /**
     * Id of the device where the point is assigned
     * @type {string}
     * @memberof MandatorPoints
     */
    'device_id'?: string;
    /**
     * Id of the site where the point is assigned
     * @type {string}
     * @memberof MandatorPoints
     */
    'site_id'?: string;
    /**
     * Name of the site where the point is assigned
     * @type {string}
     * @memberof MandatorPoints
     */
    'site_name'?: string;
    /**
     * the list of product categories of the point without duplicates
     * @type {Set<string>}
     * @memberof MandatorPoints
     */
    'category'?: Set<string>;
}


/**
 * 
 * @export
 * @interface MandatorProductBase
 */
export interface MandatorProductBase {
    /**
     * 
     * @type {string}
     * @memberof MandatorProductBase
     */
    'id'?: string;
    /**
     * The id of the product
     * @type {string}
     * @memberof MandatorProductBase
     */
    'product_id': string;
    /**
     * The name of the product configured for the mandator
     * @type {string}
     * @memberof MandatorProductBase
     */
    'mandator_product_name'?: string;
    /**
     * The code of the product configured for the mandator
     * @type {string}
     * @memberof MandatorProductBase
     */
    'mandator_product_code'?: string;
}
/**
 * 
 * @export
 * @interface MandatorProductsRequest
 */
export interface MandatorProductsRequest {
    /**
     * 
     * @type {Array<string>}
     * @memberof MandatorProductsRequest
     */
    'deleted_products'?: Array<string>;
    /**
     * 
     * @type {Array<MandatorProductBase>}
     * @memberof MandatorProductsRequest
     */
    'products': Array<MandatorProductBase>;
}
/**
 * 
 * @export
 * @interface MandatorProductsResponse
 */
export interface MandatorProductsResponse {
    /**
     * 
     * @type {string}
     * @memberof MandatorProductsResponse
     */
    'id'?: string;
    /**
     * The id of the product
     * @type {string}
     * @memberof MandatorProductsResponse
     */
    'product_id': string;
    /**
     * The name of the product configured for the mandator
     * @type {string}
     * @memberof MandatorProductsResponse
     */
    'mandator_product_name'?: string;
    /**
     * The code of the product configured for the mandator
     * @type {string}
     * @memberof MandatorProductsResponse
     */
    'mandator_product_code'?: string;
    /**
     * The id of the mandator where the product belongs to
     * @type {string}
     * @memberof MandatorProductsResponse
     */
    'mandator_id'?: string;
}
/**
 * 
 * @export
 * @interface MandatorProductsResponseGet
 */
export interface MandatorProductsResponseGet {
    /**
     * 
     * @type {string}
     * @memberof MandatorProductsResponseGet
     */
    'id'?: string;
    /**
     * The id of the product
     * @type {string}
     * @memberof MandatorProductsResponseGet
     */
    'product_id': string;
    /**
     * The name of the product configured for the mandator
     * @type {string}
     * @memberof MandatorProductsResponseGet
     */
    'mandator_product_name'?: string;
    /**
     * The code of the product configured for the mandator
     * @type {string}
     * @memberof MandatorProductsResponseGet
     */
    'mandator_product_code'?: string;
    /**
     * The id of the mandator where the product belongs to
     * @type {string}
     * @memberof MandatorProductsResponseGet
     */
    'mandator_id'?: string;
    /**
     * The code of the product
     * @type {string}
     * @memberof MandatorProductsResponseGet
     */
    'product_code'?: string;
    /**
     * The name of the product
     * @type {string}
     * @memberof MandatorProductsResponseGet
     */
    'product_name'?: string;
    /**
     * 
     * @type {string}
     * @memberof MandatorProductsResponseGet
     */
    'category'?: MandatorProductsResponseGetCategoryEnum;
    /**
     * The unit measure used for the product
     * @type {string}
     * @memberof MandatorProductsResponseGet
     */
    'unit_measure'?: MandatorProductsResponseGetUnitMeasureEnum;
}

export const MandatorProductsResponseGetCategoryEnum = {
    Charging: 'charging',
    Refuelling: 'refuelling',
    Parking: 'parking',
    Wash: 'wash'
} as const;

export type MandatorProductsResponseGetCategoryEnum = typeof MandatorProductsResponseGetCategoryEnum[keyof typeof MandatorProductsResponseGetCategoryEnum];
export const MandatorProductsResponseGetUnitMeasureEnum = {
    KWh: 'kWh',
    Pc: 'pc',
    Kg: 'kg',
    L: 'l',
    Min: 'min',
    Km: 'km',
    M: 'm',
    Cm: 'cm',
    Mm: 'mm',
    In: 'in',
    Wh: 'Wh',
    A: 'a',
    D: 'd',
    H: 'h',
    S: 's',
    M2: 'm²',
    M3: 'm³',
    P: 'p',
    C: '°C',
    F: '°F'
} as const;

export type MandatorProductsResponseGetUnitMeasureEnum = typeof MandatorProductsResponseGetUnitMeasureEnum[keyof typeof MandatorProductsResponseGetUnitMeasureEnum];

/**
 * The mapping between the role/roles assigned for each mandator of the user
 * @export
 * @interface MandatorRoleLink
 */
export interface MandatorRoleLink {
    /**
     * 
     * @type {Array<string>}
     * @memberof MandatorRoleLink
     */
    'mandators'?: Array<string>;
    /**
     * 
     * @type {Array<string>}
     * @memberof MandatorRoleLink
     */
    'roles'?: Array<string>;
}
/**
 * The mapping between the role/roles assigned for each mandator of the user
 * @export
 * @interface MandatorRoleLinkResponse
 */
export interface MandatorRoleLinkResponse {
    /**
     * 
     * @type {string}
     * @memberof MandatorRoleLinkResponse
     */
    'id'?: string;
    /**
     * 
     * @type {string}
     * @memberof MandatorRoleLinkResponse
     */
    'mandator_id'?: string;
    /**
     * 
     * @type {string}
     * @memberof MandatorRoleLinkResponse
     */
    'role_id'?: string;
    /**
     * 
     * @type {string}
     * @memberof MandatorRoleLinkResponse
     */
    'user_id'?: string;
}
/**
 * 
 * @export
 * @interface MandatorTemplatesResponse
 */
export interface MandatorTemplatesResponse {
    /**
     * The id of the template
     * @type {string}
     * @memberof MandatorTemplatesResponse
     */
    'id'?: string;
    /**
     * The name of the template
     * @type {string}
     * @memberof MandatorTemplatesResponse
     */
    'name'?: string;
    /**
     * 
     * @type {boolean}
     * @memberof MandatorTemplatesResponse
     */
    'is_global'?: boolean;
    /**
     * The name of the provider
     * @type {string}
     * @memberof MandatorTemplatesResponse
     */
    'provider_name'?: string;
    /**
     * The mandator id of the template
     * @type {string}
     * @memberof MandatorTemplatesResponse
     */
    'mandator_id'?: string;
    /**
     * 
     * @type {Array<MandatorTemplatesResponseFieldsInner>}
     * @memberof MandatorTemplatesResponse
     */
    'fields'?: Array<MandatorTemplatesResponseFieldsInner>;
}
/**
 * 
 * @export
 * @interface MandatorTemplatesResponseFieldsInner
 */
export interface MandatorTemplatesResponseFieldsInner {
    /**
     * 
     * @type {string}
     * @memberof MandatorTemplatesResponseFieldsInner
     */
    'id'?: string;
    /**
     * 
     * @type {string}
     * @memberof MandatorTemplatesResponseFieldsInner
     */
    'key'?: string;
    /**
     * 
     * @type {string}
     * @memberof MandatorTemplatesResponseFieldsInner
     */
    'name'?: string;
    /**
     * 
     * @type {string}
     * @memberof MandatorTemplatesResponseFieldsInner
     */
    'value'?: string;
    /**
     * 
     * @type {string}
     * @memberof MandatorTemplatesResponseFieldsInner
     */
    'type'?: string;
}
/**
 * 
 * @export
 * @interface MandatorUpdate
 */
export interface MandatorUpdate {
    /**
     * The mandator registration name used for identifying a mandator
     * @type {string}
     * @memberof MandatorUpdate
     */
    'name': string;
    /**
     * The street address of the mandator
     * @type {string}
     * @memberof MandatorUpdate
     */
    'street': string;
    /**
     * The city address of the mandator
     * @type {string}
     * @memberof MandatorUpdate
     */
    'city': string;
    /**
     * The zip code of the mandator\'s address
     * @type {string}
     * @memberof MandatorUpdate
     */
    'code'?: string;
    /**
     * The country address of the mandator
     * @type {string}
     * @memberof MandatorUpdate
     */
    'country': string;
    /**
     * The status of the mandator which can be active or inactive
     * @type {boolean}
     * @memberof MandatorUpdate
     */
    'mandator_state'?: boolean;
    /**
     * The email of the registered mandator
     * @type {string}
     * @memberof MandatorUpdate
     */
    'email'?: string;
    /**
     * A description that represents the partner of the mandator or even the main mandator to which the current mandator it is a subsidiary
     * @type {string}
     * @memberof MandatorUpdate
     */
    'sales_partner'?: string;
    /**
     * Describes the mandator in a short text
     * @type {string}
     * @memberof MandatorUpdate
     */
    'description'?: string;
    /**
     * Extra features like manage cards, view the list of charging points from integrators, etc on which the mandators has access to if they pay
     * @type {Array<string>}
     * @memberof MandatorUpdate
     */
    'services'?: Array<string>;
    /**
     * 
     * @type {Array<MandatorUpdateProvidersFieldsValuesInner>}
     * @memberof MandatorUpdate
     */
    'providers_fields_values'?: Array<MandatorUpdateProvidersFieldsValuesInner>;
    /**
     * The url where the logo is stored. Represents the image displayed in the header of the web application
     * @type {string}
     * @memberof MandatorUpdate
     */
    'logo'?: string;
    /**
     * A json string that represents the customized theme that will be used by the web application
     * @type {string}
     * @memberof MandatorUpdate
     */
    'theme'?: string;
}
/**
 * 
 * @export
 * @interface MandatorUpdateList
 */
export interface MandatorUpdateList {
    /**
     * 
     * @type {Array<string>}
     * @memberof MandatorUpdateList
     */
    'mandators': Array<string>;
    /**
     * The status of the mandator which can be active or inactive
     * @type {boolean}
     * @memberof MandatorUpdateList
     */
    'mandator_state': boolean;
}
/**
 * 
 * @export
 * @interface MandatorUpdateProvidersFieldsValuesInner
 */
export interface MandatorUpdateProvidersFieldsValuesInner {
    /**
     * 
     * @type {string}
     * @memberof MandatorUpdateProvidersFieldsValuesInner
     */
    'template_id'?: string;
    /**
     * 
     * @type {string}
     * @memberof MandatorUpdateProvidersFieldsValuesInner
     */
    'field_id'?: string;
    /**
     * required only if is_global field from template is false
     * @type {string}
     * @memberof MandatorUpdateProvidersFieldsValuesInner
     */
    'field_value'?: string;
}
/**
 * 
 * @export
 * @interface Manufacturer
 */
export interface Manufacturer {
    /**
     * Name of the manufacturer
     * @type {string}
     * @memberof Manufacturer
     */
    'name': string;
    /**
     * The unique identifier of the manufacturer
     * @type {string}
     * @memberof Manufacturer
     */
    'id'?: string;
    /**
     * The date when a manufacturer was created
     * @type {string}
     * @memberof Manufacturer
     */
    'created_at'?: string;
    /**
     * The number of devices that are linked to one of the manufacturer models
     * @type {number}
     * @memberof Manufacturer
     */
    'number_of_devices'?: number;
}
/**
 * 
 * @export
 * @interface ManufacturerBase
 */
export interface ManufacturerBase {
    /**
     * Name of the manufacturer
     * @type {string}
     * @memberof ManufacturerBase
     */
    'name': string;
}
/**
 * 
 * @export
 * @interface ManufacturerExtended
 */
export interface ManufacturerExtended {
    /**
     * Name of the manufacturer
     * @type {string}
     * @memberof ManufacturerExtended
     */
    'name': string;
    /**
     * The unique identifier of the manufacturer
     * @type {string}
     * @memberof ManufacturerExtended
     */
    'id'?: string;
    /**
     * The date when a manufacturer was created
     * @type {string}
     * @memberof ManufacturerExtended
     */
    'created_at'?: string;
    /**
     * The protocols assigned to a manufacturer
     * @type {Array<string>}
     * @memberof ManufacturerExtended
     */
    'protocol_ids'?: Array<string>;
}
/**
 * 
 * @export
 * @interface Mapping
 */
export interface Mapping {
    /**
     * 
     * @type {string}
     * @memberof Mapping
     */
    'id'?: string;
    /**
     * ID of the Mandator, to which this service belongs
     * @type {string}
     * @memberof Mapping
     */
    'mandator_id'?: string;
    /**
     * 
     * @type {TariffService}
     * @memberof Mapping
     */
    'service'?: TariffService;
}
/**
 * 
 * @export
 * @interface MappingResponse
 */
export interface MappingResponse {
    /**
     * 
     * @type {ResponseBaseLinks}
     * @memberof MappingResponse
     */
    '_links'?: ResponseBaseLinks;
    /**
     * 
     * @type {ResponseBaseMeta}
     * @memberof MappingResponse
     */
    'meta'?: ResponseBaseMeta;
    /**
     * 
     * @type {Array<Mapping>}
     * @memberof MappingResponse
     */
    'data'?: Array<Mapping>;
}
/**
 * 
 * @export
 * @interface Meta
 */
export interface Meta {
    /**
     * the language of the user. Format is Accept-Language of the http header.
     * @type {string}
     * @memberof Meta
     */
    'language'?: string;
}
/**
 * 
 * @export
 * @interface ModelError
 */
export interface ModelError {
    /**
     * 
     * @type {Array<ErrorData>}
     * @memberof ModelError
     */
    'errors'?: Array<ErrorData>;
}
/**
 * 
 * @export
 * @interface Module
 */
export interface Module {
    /**
     * Name of the Module
     * @type {string}
     * @memberof Module
     */
    'name'?: string;
    /**
     * A short text, describing the Module. <=300 char
     * @type {string}
     * @memberof Module
     */
    'summary'?: string;
    /**
     * A longer Description of the Module containing all Infos.
     * @type {string}
     * @memberof Module
     */
    'description'?: string;
    /**
     * 
     * @type {ModuleLinks}
     * @memberof Module
     */
    '_links'?: ModuleLinks;
}
/**
 * 
 * @export
 * @interface ModuleActivation
 */
export interface ModuleActivation {
    /**
     * 
     * @type {boolean}
     * @memberof ModuleActivation
     */
    'active': boolean;
}
/**
 * 
 * @export
 * @interface ModuleActivationResponse
 */
export interface ModuleActivationResponse {
    /**
     * 
     * @type {string}
     * @memberof ModuleActivationResponse
     */
    'id': string;
    /**
     * 
     * @type {string}
     * @memberof ModuleActivationResponse
     */
    'mandator_id': string;
    /**
     * 
     * @type {boolean}
     * @memberof ModuleActivationResponse
     */
    'active': boolean;
}
/**
 * 
 * @export
 * @interface ModuleLink
 */
export interface ModuleLink {
    /**
     * 
     * @type {string}
     * @memberof ModuleLink
     */
    'href'?: string;
}
/**
 * 
 * @export
 * @interface ModuleLinks
 */
export interface ModuleLinks {
    /**
     * Link to the Banner image.
     * @type {Link}
     * @memberof ModuleLinks
     */
    'banner'?: Link;
}
/**
 * 
 * @export
 * @interface Modules
 */
export interface Modules {
    /**
     * The unique identifier of the Module
     * @type {string}
     * @memberof Modules
     */
    'id'?: string;
    /**
     * Name of the Module
     * @type {string}
     * @memberof Modules
     */
    'name'?: string;
    /**
     * Whether the Module is active or not
     * @type {boolean}
     * @memberof Modules
     */
    'active'?: boolean;
    /**
     * A longer Description of the Module containing all Infos.
     * @type {string}
     * @memberof Modules
     */
    'description'?: string;
    /**
     * A short text, describing the Module. <=300 char
     * @type {string}
     * @memberof Modules
     */
    'summary'?: string;
    /**
     * 
     * @type {ModulesLinks}
     * @memberof Modules
     */
    '_links'?: ModulesLinks;
}
/**
 * 
 * @export
 * @interface ModulesLinks
 */
export interface ModulesLinks {
    /**
     * Link to the Banner image.
     * @type {ModuleLink}
     * @memberof ModulesLinks
     */
    'banner'?: ModuleLink;
    /**
     * The url of the Module.
     * @type {string}
     * @memberof ModulesLinks
     */
    'base_url'?: string;
}
/**
 * 
 * @export
 * @interface NotUsedIdentification
 */
export interface NotUsedIdentification {
    /**
     * Identification of a certain user
     * @type {string}
     * @memberof NotUsedIdentification
     */
    'id'?: string;
    /**
     * 
     * @type {string}
     * @memberof NotUsedIdentification
     */
    'phone'?: string;
    /**
     * 
     * @type {string}
     * @memberof NotUsedIdentification
     */
    'email'?: string;
    /**
     * 
     * @type {string}
     * @memberof NotUsedIdentification
     */
    'cnp'?: string;
    /**
     * 
     * @type {Array<NotUsedIdentificationOngoingTransactionsInner>}
     * @memberof NotUsedIdentification
     */
    'ongoing_transactions'?: Array<NotUsedIdentificationOngoingTransactionsInner>;
}
/**
 * 
 * @export
 * @interface NotUsedIdentificationOngoingTransactionsInner
 */
export interface NotUsedIdentificationOngoingTransactionsInner {
    /**
     * ID of the ongoing transaction
     * @type {string}
     * @memberof NotUsedIdentificationOngoingTransactionsInner
     */
    'id'?: string;
    /**
     * 
     * @type {NotUsedIdentificationOngoingTransactionsInnerLinks}
     * @memberof NotUsedIdentificationOngoingTransactionsInner
     */
    '_links'?: NotUsedIdentificationOngoingTransactionsInnerLinks;
}
/**
 * 
 * @export
 * @interface NotUsedIdentificationOngoingTransactionsInnerLinks
 */
export interface NotUsedIdentificationOngoingTransactionsInnerLinks {
    /**
     * url of this transaction
     * @type {string}
     * @memberof NotUsedIdentificationOngoingTransactionsInnerLinks
     */
    'self'?: string;
}
/**
 * 
 * @export
 * @interface OpeningHour
 */
export interface OpeningHour {
    /**
     * 
     * @type {string}
     * @memberof OpeningHour
     */
    'days_availability': OpeningHourDaysAvailabilityEnum;
    /**
     * 
     * @type {string}
     * @memberof OpeningHour
     */
    'start_time': string;
    /**
     * 
     * @type {string}
     * @memberof OpeningHour
     */
    'end_time': string;
}

export const OpeningHourDaysAvailabilityEnum = {
    Alldays: 'alldays',
    Weekdays: 'weekdays',
    Saturday: 'saturday',
    Sunday: 'sunday'
} as const;

export type OpeningHourDaysAvailabilityEnum = typeof OpeningHourDaysAvailabilityEnum[keyof typeof OpeningHourDaysAvailabilityEnum];

/**
 * 
 * @export
 * @interface ParkingAllProductOffer
 */
export interface ParkingAllProductOffer {
    /**
     * The id of the product
     * @type {string}
     * @memberof ParkingAllProductOffer
     */
    'id'?: string;
    /**
     * 
     * @type {string}
     * @memberof ParkingAllProductOffer
     */
    'category'?: ParkingAllProductOfferCategoryEnum;
    /**
     * 
     * @type {string}
     * @memberof ParkingAllProductOffer
     */
    'name'?: string;
    /**
     * 
     * @type {string}
     * @memberof ParkingAllProductOffer
     */
    'description'?: string;
    /**
     * The unique identifier of a device
     * @type {string}
     * @memberof ParkingAllProductOffer
     */
    'code'?: string;
    /**
     * The price defined for a product on each site
     * @type {number}
     * @memberof ParkingAllProductOffer
     */
    'unit_price'?: number;
    /**
     * The currency set on site
     * @type {string}
     * @memberof ParkingAllProductOffer
     */
    'currency'?: string;
    /**
     * 
     * @type {number}
     * @memberof ParkingAllProductOffer
     */
    'max_amount'?: number;
    /**
     * Tax percentage applied for product prices of the site
     * @type {number}
     * @memberof ParkingAllProductOffer
     */
    'tax_percentage'?: number;
    /**
     * 
     * @type {string}
     * @memberof ParkingAllProductOffer
     */
    'unit_measure'?: string;
    /**
     * The unique identifier of the product power
     * @type {string}
     * @memberof ParkingAllProductOffer
     */
    'power_id'?: string;
    /**
     * 
     * @type {number}
     * @memberof ParkingAllProductOffer
     */
    'max_power'?: number;
    /**
     * 
     * @type {Array<ChargingProductOfferAllOfRelatedProducts>}
     * @memberof ParkingAllProductOffer
     */
    'related_products'?: Array<ChargingProductOfferAllOfRelatedProducts>;
    /**
     * maximum time a driver allowed to park
     * @type {number}
     * @memberof ParkingAllProductOffer
     */
    'max_duration'?: number;
    /**
     * freeparking time in minutes
     * @type {number}
     * @memberof ParkingAllProductOffer
     */
    'duration'?: number;
}

export const ParkingAllProductOfferCategoryEnum = {
    Parking: 'parking',
    Charging: 'charging',
    Refuelling: 'refuelling',
    Wash: 'wash'
} as const;

export type ParkingAllProductOfferCategoryEnum = typeof ParkingAllProductOfferCategoryEnum[keyof typeof ParkingAllProductOfferCategoryEnum];

/**
 * 
 * @export
 * @interface ParkingAllProductOfferResponse
 */
export interface ParkingAllProductOfferResponse {
    /**
     * 
     * @type {ResponseBaseLinks}
     * @memberof ParkingAllProductOfferResponse
     */
    '_links'?: ResponseBaseLinks;
    /**
     * 
     * @type {ResponseBaseMeta}
     * @memberof ParkingAllProductOfferResponse
     */
    'meta'?: ResponseBaseMeta;
    /**
     * 
     * @type {Array<ParkingAllProductOffer>}
     * @memberof ParkingAllProductOfferResponse
     */
    'data'?: Array<ParkingAllProductOffer>;
}
/**
 * 
 * @export
 * @interface ParkingArticle
 */
export interface ParkingArticle {
    /**
     * 
     * @type {ArticleCategory}
     * @memberof ParkingArticle
     */
    'category': ArticleCategory;
    /**
     * This is a unique ID referencing to this sold article
     * @type {string}
     * @memberof ParkingArticle
     */
    'id'?: string;
    /**
     * 
     * @type {ArticleState}
     * @memberof ParkingArticle
     */
    'state'?: ArticleState;
    /**
     * This log is used to trace various events and status within this object.
     * @type {Array<ItemlogEntry>}
     * @memberof ParkingArticle
     */
    'log'?: Array<ItemlogEntry>;
    /**
     * This is ID is referencing the sold product, which is setup on this site.
     * @type {string}
     * @memberof ParkingArticle
     */
    'product_id'?: string;
    /**
     * Amount the user has to pay including VAT.in currency unit.
     * @type {number}
     * @memberof ParkingArticle
     */
    'amount'?: number;
    /**
     * Amount of VAT. Has to be lower than total amount. Returned in  currency unit.
     * @type {number}
     * @memberof ParkingArticle
     */
    'tax_amount'?: number;
    /**
     * The factor the vat has. Unit is percent (%) this an factor of 100
     * @type {number}
     * @memberof ParkingArticle
     */
    'tax_percentage'?: number;
    /**
     * currency code in ISO 4217
     * @type {string}
     * @memberof ParkingArticle
     */
    'currency'?: string;
    /**
     * Start of the Article usage/consumption
     * @type {string}
     * @memberof ParkingArticle
     */
    'start_time'?: string;
    /**
     * End of the Article usage/consumption
     * @type {string}
     * @memberof ParkingArticle
     */
    'end_time'?: string;
}


/**
 * 
 * @export
 * @interface ParkingProductOffer2
 */
export interface ParkingProductOffer2 {
    /**
     * The id of the product
     * @type {string}
     * @memberof ParkingProductOffer2
     */
    'id'?: string;
    /**
     * 
     * @type {string}
     * @memberof ParkingProductOffer2
     */
    'category'?: ParkingProductOffer2CategoryEnum;
    /**
     * 
     * @type {string}
     * @memberof ParkingProductOffer2
     */
    'name'?: string;
    /**
     * 
     * @type {string}
     * @memberof ParkingProductOffer2
     */
    'description'?: string;
    /**
     * The currency set on site
     * @type {string}
     * @memberof ParkingProductOffer2
     */
    'currency'?: string;
    /**
     * Tax percentage applied for product prices of the site
     * @type {number}
     * @memberof ParkingProductOffer2
     */
    'tax_percentage'?: number;
    /**
     * maximum time a driver allowed to park
     * @type {number}
     * @memberof ParkingProductOffer2
     */
    'max_duration'?: number;
    /**
     * freeparking time in minutes
     * @type {number}
     * @memberof ParkingProductOffer2
     */
    'duration'?: number;
}

export const ParkingProductOffer2CategoryEnum = {
    Parking: 'parking',
    Charging: 'charging',
    Refuelling: 'refuelling',
    Wash: 'wash'
} as const;

export type ParkingProductOffer2CategoryEnum = typeof ParkingProductOffer2CategoryEnum[keyof typeof ParkingProductOffer2CategoryEnum];

/**
 * 
 * @export
 * @interface ParkingProductOfferResponse
 */
export interface ParkingProductOfferResponse {
    /**
     * 
     * @type {ResponseBaseLinks}
     * @memberof ParkingProductOfferResponse
     */
    '_links'?: ResponseBaseLinks;
    /**
     * 
     * @type {ResponseBaseMeta}
     * @memberof ParkingProductOfferResponse
     */
    'meta'?: ResponseBaseMeta;
    /**
     * 
     * @type {Array<ParkingProductOffer2>}
     * @memberof ParkingProductOfferResponse
     */
    'data'?: Array<ParkingProductOffer2>;
}
/**
 * 
 * @export
 * @interface ParkingTariff
 */
export interface ParkingTariff {
    /**
     * 
     * @type {string}
     * @memberof ParkingTariff
     */
    'id'?: string;
    /**
     * 
     * @type {string}
     * @memberof ParkingTariff
     */
    'mandator_id'?: string;
    /**
     * 
     * @type {string}
     * @memberof ParkingTariff
     */
    'name'?: string;
    /**
     * expect an string in hh:mm:ss. Earliest time this tariff is applied.
     * @type {string}
     * @memberof ParkingTariff
     */
    'open_time'?: string;
    /**
     * expect an string in hh:mm:ss. Latest time until this tariff is applied.
     * @type {string}
     * @memberof ParkingTariff
     */
    'close_time'?: string;
    /**
     * time in minute. Parking duration will be round up to an multiple of this.
     * @type {number}
     * @memberof ParkingTariff
     */
    'duration_step'?: number;
    /**
     * price of the tariff per duration_step with tax
     * @type {number}
     * @memberof ParkingTariff
     */
    'gross_price'?: number;
    /**
     * currency code in ISO 4217
     * @type {string}
     * @memberof ParkingTariff
     */
    'currency'?: string;
    /**
     * how many percent the tax is
     * @type {number}
     * @memberof ParkingTariff
     */
    'tax_rate'?: number;
    /**
     * time in minute. How long it is allowed to park per ticket.
     * @type {number}
     * @memberof ParkingTariff
     */
    'max_duration'?: number;
    /**
     * if disabled, max duration is until end time.
     * @type {boolean}
     * @memberof ParkingTariff
     */
    'max_duration_enabled'?: boolean;
    /**
     * indicate if tariff is available on monday
     * @type {boolean}
     * @memberof ParkingTariff
     */
    'monday'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof ParkingTariff
     */
    'tuesday'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof ParkingTariff
     */
    'wednesday'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof ParkingTariff
     */
    'thursday'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof ParkingTariff
     */
    'friday'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof ParkingTariff
     */
    'saturday'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof ParkingTariff
     */
    'sunday'?: boolean;
}
/**
 * 
 * @export
 * @interface ParkingTariffAssignment
 */
export interface ParkingTariffAssignment {
    /**
     * 
     * @type {string}
     * @memberof ParkingTariffAssignment
     */
    'id'?: string;
    /**
     * 
     * @type {string}
     * @memberof ParkingTariffAssignment
     */
    'tariff_id': string;
    /**
     * 
     * @type {string}
     * @memberof ParkingTariffAssignment
     */
    'site_id': string;
    /**
     * 
     * @type {ParkingTariffAssignmentActivationPeriod}
     * @memberof ParkingTariffAssignment
     */
    'activation_period': ParkingTariffAssignmentActivationPeriod;
    /**
     * 
     * @type {number}
     * @memberof ParkingTariffAssignment
     */
    'tariff_version': number;
    /**
     * 
     * @type {string}
     * @memberof ParkingTariffAssignment
     */
    'editor_client_id'?: string;
    /**
     * 
     * @type {string}
     * @memberof ParkingTariffAssignment
     */
    'changed_time'?: string;
}
/**
 * 
 * @export
 * @interface ParkingTariffAssignmentActivationPeriod
 */
export interface ParkingTariffAssignmentActivationPeriod {
    /**
     * 
     * @type {string}
     * @memberof ParkingTariffAssignmentActivationPeriod
     */
    'start_time': string;
    /**
     * 
     * @type {string}
     * @memberof ParkingTariffAssignmentActivationPeriod
     */
    'end_time'?: string;
}
/**
 * 
 * @export
 * @interface ParkingTariffAssignmentResponse
 */
export interface ParkingTariffAssignmentResponse {
    /**
     * 
     * @type {ResponseBaseLinks}
     * @memberof ParkingTariffAssignmentResponse
     */
    '_links'?: ResponseBaseLinks;
    /**
     * 
     * @type {ResponseBaseMeta}
     * @memberof ParkingTariffAssignmentResponse
     */
    'meta'?: ResponseBaseMeta;
    /**
     * 
     * @type {Array<ParkingTariffAssignment>}
     * @memberof ParkingTariffAssignmentResponse
     */
    'data'?: Array<ParkingTariffAssignment>;
}
/**
 * 
 * @export
 * @interface ParkingTariffHistory
 */
export interface ParkingTariffHistory {
    /**
     * 
     * @type {string}
     * @memberof ParkingTariffHistory
     */
    'id'?: string;
    /**
     * 
     * @type {string}
     * @memberof ParkingTariffHistory
     */
    'mandator_id'?: string;
    /**
     * 
     * @type {string}
     * @memberof ParkingTariffHistory
     */
    'name'?: string;
    /**
     * expect an string in hh:mm:ss. Earliest time this tariff is applied.
     * @type {string}
     * @memberof ParkingTariffHistory
     */
    'open_time'?: string;
    /**
     * expect an string in hh:mm:ss. Latest time until this tariff is applied.
     * @type {string}
     * @memberof ParkingTariffHistory
     */
    'close_time'?: string;
    /**
     * time in minute. Parking duration will be round up to an multiple of this.
     * @type {number}
     * @memberof ParkingTariffHistory
     */
    'duration_step'?: number;
    /**
     * price of the tariff per duration_step with tax
     * @type {number}
     * @memberof ParkingTariffHistory
     */
    'gross_price'?: number;
    /**
     * currency code in ISO 4217
     * @type {string}
     * @memberof ParkingTariffHistory
     */
    'currency'?: string;
    /**
     * how many percent the tax is
     * @type {number}
     * @memberof ParkingTariffHistory
     */
    'tax_rate'?: number;
    /**
     * time in minute. How long it is allowed to park per ticket.
     * @type {number}
     * @memberof ParkingTariffHistory
     */
    'max_duration'?: number;
    /**
     * if disabled, max duration is until end time.
     * @type {boolean}
     * @memberof ParkingTariffHistory
     */
    'max_duration_enabled'?: boolean;
    /**
     * indicate if tariff is available on monday
     * @type {boolean}
     * @memberof ParkingTariffHistory
     */
    'monday'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof ParkingTariffHistory
     */
    'tuesday'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof ParkingTariffHistory
     */
    'wednesday'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof ParkingTariffHistory
     */
    'thursday'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof ParkingTariffHistory
     */
    'friday'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof ParkingTariffHistory
     */
    'saturday'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof ParkingTariffHistory
     */
    'sunday'?: boolean;
    /**
     * 
     * @type {string}
     * @memberof ParkingTariffHistory
     */
    'editor_client_id'?: string;
    /**
     * 
     * @type {string}
     * @memberof ParkingTariffHistory
     */
    'changed_time'?: string;
    /**
     * 
     * @type {number}
     * @memberof ParkingTariffHistory
     */
    'version'?: number;
}
/**
 * 
 * @export
 * @interface ParkingTariffHistoryResponse
 */
export interface ParkingTariffHistoryResponse {
    /**
     * 
     * @type {ResponseBaseLinks}
     * @memberof ParkingTariffHistoryResponse
     */
    '_links'?: ResponseBaseLinks;
    /**
     * 
     * @type {ResponseBaseMeta}
     * @memberof ParkingTariffHistoryResponse
     */
    'meta'?: ResponseBaseMeta;
    /**
     * 
     * @type {Array<ParkingTariffHistory>}
     * @memberof ParkingTariffHistoryResponse
     */
    'data'?: Array<ParkingTariffHistory>;
}
/**
 * 
 * @export
 * @interface ParkingTariffResponse
 */
export interface ParkingTariffResponse {
    /**
     * 
     * @type {ResponseBaseLinks}
     * @memberof ParkingTariffResponse
     */
    '_links'?: ResponseBaseLinks;
    /**
     * 
     * @type {ResponseBaseMeta}
     * @memberof ParkingTariffResponse
     */
    'meta'?: ResponseBaseMeta;
    /**
     * 
     * @type {Array<ParkingTariff>}
     * @memberof ParkingTariffResponse
     */
    'data'?: Array<ParkingTariff>;
}
/**
 * 
 * @export
 * @interface PatchFieldsInner
 */
export interface PatchFieldsInner {
    /**
     * The id of the field that has to be be updated
     * @type {string}
     * @memberof PatchFieldsInner
     */
    'id': string;
    /**
     * Translations of the field for each system language
     * @type {string}
     * @memberof PatchFieldsInner
     */
    'custom_translation'?: string;
}
/**
 * 
 * @export
 * @interface PatchSiteRequest
 */
export interface PatchSiteRequest {
    /**
     * A unique identifier received from TWINT
     * @type {string}
     * @memberof PatchSiteRequest
     */
    'merchant_id': string;
}
/**
 * 
 * @export
 * @interface PatchSiteResponse
 */
export interface PatchSiteResponse {
    /**
     * A unique identifier received from TWINT
     * @type {string}
     * @memberof PatchSiteResponse
     */
    'merchant_id': string;
    /**
     * QR code of the site used for TWINT
     * @type {string}
     * @memberof PatchSiteResponse
     */
    'QR_code'?: string;
    /**
     * 
     * @type {Array<PointsQR>}
     * @memberof PatchSiteResponse
     */
    'points'?: Array<PointsQR>;
}
/**
 * 
 * @export
 * @interface PatchSiteTwint200Response
 */
export interface PatchSiteTwint200Response {
    /**
     * 
     * @type {ResponseBaseLinks}
     * @memberof PatchSiteTwint200Response
     */
    '_links'?: ResponseBaseLinks;
    /**
     * 
     * @type {ResponseBaseMeta}
     * @memberof PatchSiteTwint200Response
     */
    'meta'?: ResponseBaseMeta;
    /**
     * 
     * @type {Array<PatchSiteResponse>}
     * @memberof PatchSiteTwint200Response
     */
    'data'?: Array<PatchSiteResponse>;
}
/**
 * @type PatchTemplateAssignments
 * @export
 */
export type PatchTemplateAssignments = Array<PatchTemplateAssignmentsOneOf1Inner> | PatchTemplateAssignmentsOneOf;

/**
 * 
 * @export
 * @interface PatchTemplateAssignmentsOneOf
 */
export interface PatchTemplateAssignmentsOneOf {
    /**
     * If this flag is true, the template will be assigned to all mandator\'s sites
     * @type {boolean}
     * @memberof PatchTemplateAssignmentsOneOf
     */
    'assign_all': boolean;
    /**
     * 
     * @type {string}
     * @memberof PatchTemplateAssignmentsOneOf
     */
    'template_id': string;
}
/**
 * 
 * @export
 * @interface PatchTemplateAssignmentsOneOf1Inner
 */
export interface PatchTemplateAssignmentsOneOf1Inner {
    /**
     * 
     * @type {string}
     * @memberof PatchTemplateAssignmentsOneOf1Inner
     */
    'site_id': string;
    /**
     * 
     * @type {string}
     * @memberof PatchTemplateAssignmentsOneOf1Inner
     */
    'template_id'?: string;
    /**
     * The type of the template that has to be unassigned
     * @type {string}
     * @memberof PatchTemplateAssignmentsOneOf1Inner
     */
    'template_type'?: PatchTemplateAssignmentsOneOf1InnerTemplateTypeEnum;
}

export const PatchTemplateAssignmentsOneOf1InnerTemplateTypeEnum = {
    Invoice: 'invoice',
    Note: 'note'
} as const;

export type PatchTemplateAssignmentsOneOf1InnerTemplateTypeEnum = typeof PatchTemplateAssignmentsOneOf1InnerTemplateTypeEnum[keyof typeof PatchTemplateAssignmentsOneOf1InnerTemplateTypeEnum];

/**
 * 
 * @export
 * @interface PatchTransactionRequest
 */
export interface PatchTransactionRequest {
    /**
     * The unique identifier of the site for which the transaction is taking place
     * @type {string}
     * @memberof PatchTransactionRequest
     */
    'site_id'?: string;
    /**
     * The unique identifier of the point for which the transaction is taking place
     * @type {string}
     * @memberof PatchTransactionRequest
     */
    'point_id'?: string;
    /**
     * 
     * @type {Array<Articles>}
     * @memberof PatchTransactionRequest
     */
    'articles'?: Array<Articles>;
    /**
     * 
     * @type {Array<Payments>}
     * @memberof PatchTransactionRequest
     */
    'payments'?: Array<Payments>;
    /**
     * 
     * @type {CustomerDataObject}
     * @memberof PatchTransactionRequest
     */
    'customer_data'?: CustomerDataObject;
    /**
     * 
     * @type {ReceiptV3}
     * @memberof PatchTransactionRequest
     */
    'receipt'?: ReceiptV3;
    /**
     * Has possible two parts. First is number of fiscal printer which can be empty. Second Is a number between 0 and 9999 or 0 and 99999 (Customer-dependent). This number helps with identification, but is not unique on its own (overflow).
     * @type {string}
     * @memberof PatchTransactionRequest
     */
    'terminal_transaction_number'?: string | null;
}
/**
 * 
 * @export
 * @interface Payment
 */
export interface Payment {
    /**
     * 
     * @type {string}
     * @memberof Payment
     */
    'id'?: string;
    /**
     * Its a reference to an identification-process
     * @type {string}
     * @memberof Payment
     */
    'identification'?: string;
    /**
     * 
     * @type {string}
     * @memberof Payment
     */
    'state'?: PaymentStateEnum;
    /**
     * This log is used to trace various events and status within this object.
     * @type {Array<ItemlogEntry>}
     * @memberof Payment
     */
    'log'?: Array<ItemlogEntry>;
    /**
     * Id of the previous completed auth-process
     * @type {string}
     * @memberof Payment
     */
    'authorization_id'?: string;
    /**
     * 
     * @type {string}
     * @memberof Payment
     */
    'created_at'?: string;
    /**
     * Amount reserved on creditCard
     * @type {number}
     * @memberof Payment
     */
    'reserved_amount'?: number;
    /**
     * 
     * @type {PaymentMedium}
     * @memberof Payment
     */
    'medium'?: PaymentMedium;
    /**
     * Amount the user has to pay including VAT.in currency unit.
     * @type {number}
     * @memberof Payment
     */
    'amount'?: number;
    /**
     * currency code in ISO 4217
     * @type {string}
     * @memberof Payment
     */
    'currency'?: string;
    /**
     * 
     * @type {string}
     * @memberof Payment
     */
    'card_entrymode'?: PaymentCardEntrymodeEnum;
    /**
     * 
     * @type {string}
     * @memberof Payment
     */
    'card_type'?: PaymentCardTypeEnum;
    /**
     * 
     * @type {string}
     * @memberof Payment
     */
    'card_id'?: string;
    /**
     * a track is used for fleet card authorizations. It will inform the system which informations are requested and how to authorize that fleet cards
     * @type {string}
     * @memberof Payment
     */
    'track'?: string;
    /**
     * 
     * @type {string}
     * @memberof Payment
     */
    'approval_code'?: string;
    /**
     * Coins inserted to pay this payment. In the smallest currency unit.
     * @type {Array<number>}
     * @memberof Payment
     */
    'denomination'?: Array<number>;
    /**
     * 
     * @type {Array<string>}
     * @memberof Payment
     */
    'terminal_receipt'?: Array<string>;
    /**
     * 
     * @type {Array<string>}
     * @memberof Payment
     */
    'fiscal_receipt'?: Array<string>;
}

export const PaymentStateEnum = {
    Open: 'open',
    Reserved: 'reserved',
    Collected: 'collected',
    Error: 'error'
} as const;

export type PaymentStateEnum = typeof PaymentStateEnum[keyof typeof PaymentStateEnum];
export const PaymentCardEntrymodeEnum = {
    Mobile: 'Mobile',
    Terminal: 'Terminal',
    Mppa: 'MPPA'
} as const;

export type PaymentCardEntrymodeEnum = typeof PaymentCardEntrymodeEnum[keyof typeof PaymentCardEntrymodeEnum];
export const PaymentCardTypeEnum = {
    PspCard: 'PSPCard',
    ChargingCard: 'ChargingCard',
    SingleCard: 'SingleCard',
    FleetCard: 'FleetCard',
    AVehicleCard: 'A-VehicleCard',
    BDriverCard: 'B-DriverCard',
    CVehicleCard: 'C-VehicleCard',
    CDriverCard: 'C-DriverCard',
    ManagementCard: 'ManagementCard',
    PetroPoint: 'PetroPoint',
    ManualInput: 'ManualInput',
    Technician: 'Technician',
    Loyalty: 'Loyalty',
    SingleWashCard: 'SingleWashCard',
    NoCard: 'NoCard',
    Hks: 'HKS'
} as const;

export type PaymentCardTypeEnum = typeof PaymentCardTypeEnum[keyof typeof PaymentCardTypeEnum];

/**
 * 
 * @export
 * @enum {string}
 */

export const PaymentMedium = {
    Card: 'Card',
    Cash: 'Cash',
    Coin: 'Coin',
    FreeGrantedFreeparkingOffline: 'Free_GrantedFreeparking_Offline'
} as const;

export type PaymentMedium = typeof PaymentMedium[keyof typeof PaymentMedium];


/**
 * 
 * @export
 * @interface PaymentRequest
 */
export interface PaymentRequest {
    /**
     * 
     * @type {Payment}
     * @memberof PaymentRequest
     */
    'data'?: Payment;
}
/**
 * 
 * @export
 * @interface Payments
 */
export interface Payments {
    /**
     * The unique identifier of the payment
     * @type {string}
     * @memberof Payments
     */
    'id'?: string;
    /**
     * The reference to an identification-process
     * @type {string}
     * @memberof Payments
     */
    'identification_id'?: string;
    /**
     * The state of the payment
     * @type {string}
     * @memberof Payments
     */
    'state'?: PaymentsStateEnum;
    /**
     * Represents when the payment was created
     * @type {string}
     * @memberof Payments
     */
    'created_at'?: string;
    /**
     * The amount that was reserved for the payment
     * @type {number}
     * @memberof Payments
     */
    'reserved_amount'?: number;
    /**
     * The amount of the transaction
     * @type {number}
     * @memberof Payments
     */
    'amount'?: number;
    /**
     * The currency code used for article
     * @type {string}
     * @memberof Payments
     */
    'currency'?: string;
    /**
     * From where the payment was made
     * @type {string}
     * @memberof Payments
     * @deprecated
     */
    'card_entrymode'?: PaymentsCardEntrymodeEnum;
    /**
     * From where the payment was made. Deprecated values :/ Terminal, Magstripe-Reader, Mobile-App, AVR, ICC-Reader, Contactless-Reader, Barcode-Reader, BNA, CA, Web-App
     * @type {string}
     * @memberof Payments
     */
    'entry_mode'?: PaymentsEntryModeEnum;
    /**
     * The “object” that contains the payment information. Deprecated values :/ ICC, RFID, App, ManuallInput, AVR, Remote, Notes, QR-Code
     * @type {string}
     * @memberof Payments
     */
    'medium'?: PaymentsMediumEnum;
    /**
     * The type of the card used for payment. Deprecated values:/ BankNotes, Coins, NoCard, CashCard, Free, Remote
     * @type {string}
     * @memberof Payments
     */
    'type'?: PaymentsTypeEnum;
    /**
     * Deprecated values:/ PetroPoint, ManuallInput, SingleWashCard, GrantedFreeparking, A-VehicleCard, B-DriverCard, C-VehicleCard, C-DriverCard, Technician, Loyalty
     * @type {string}
     * @memberof Payments
     */
    'function'?: PaymentsFunctionEnum;
    /**
     * Deprecated values:/ VISA, CNP, Offline, MBWay, PetroPoint
     * @type {string}
     * @memberof Payments
     */
    'scheme'?: PaymentsSchemeEnum;
    /**
     * The number from the card
     * @type {string}
     * @memberof Payments
     */
    'card_id'?: string;
    /**
     * The pin of the card
     * @type {string}
     * @memberof Payments
     */
    'pin'?: string;
    /**
     * 
     * @type {Array<string>}
     * @memberof Payments
     */
    'terminal_receipt'?: Array<string>;
    /**
     * 
     * @type {Array<string>}
     * @memberof Payments
     */
    'fiscal_receipt'?: Array<string>;
    /**
     * 
     * @type {Array<LogInner>}
     * @memberof Payments
     */
    'log'?: Array<LogInner>;
    /**
     * 
     * @type {PaymentsAdditionalCard}
     * @memberof Payments
     */
    'additional_card'?: PaymentsAdditionalCard;
}

export const PaymentsStateEnum = {
    Open: 'open',
    Reserved: 'reserved',
    Collected: 'collected',
    Error: 'error',
    Declined: 'declined'
} as const;

export type PaymentsStateEnum = typeof PaymentsStateEnum[keyof typeof PaymentsStateEnum];
export const PaymentsCardEntrymodeEnum = {
    MagstripeReader: 'MagstripeReader',
    MobileApp: 'MobileApp',
    Keyboard: 'Keyboard',
    AutomaticVehicleRecognition: 'AutomaticVehicleRecognition',
    Remote: 'Remote',
    IntegratedCircuitCardReader: 'IntegratedCircuitCardReader',
    ContactlessReader: 'ContactlessReader',
    BarcodeReader: 'BarcodeReader',
    BanknoteAcceptor: 'BanknoteAcceptor',
    CoinAcceptor: 'CoinAcceptor',
    WebApp: 'WebApp',
    Terminal: 'Terminal',
    MagstripeReader2: 'Magstripe-Reader',
    MobileApp2: 'Mobile-App',
    Avr: 'AVR',
    IccReader: 'ICC-Reader',
    ContactlessReader2: 'Contactless-Reader',
    BarcodeReader2: 'Barcode-Reader',
    Bna: 'BNA',
    Ca: 'CA',
    WebApp2: 'Web-App'
} as const;

export type PaymentsCardEntrymodeEnum = typeof PaymentsCardEntrymodeEnum[keyof typeof PaymentsCardEntrymodeEnum];
export const PaymentsEntryModeEnum = {
    MagstripeReader: 'MagstripeReader',
    MobileApp: 'MobileApp',
    Keyboard: 'Keyboard',
    AutomaticVehicleRecognition: 'AutomaticVehicleRecognition',
    Remote: 'Remote',
    IntegratedCircuitCardReader: 'IntegratedCircuitCardReader',
    ContactlessReader: 'ContactlessReader',
    BarcodeReader: 'BarcodeReader',
    BanknoteAcceptor: 'BanknoteAcceptor',
    CoinAcceptor: 'CoinAcceptor',
    WebApp: 'WebApp',
    Terminal: 'Terminal',
    MagstripeReader2: 'Magstripe-Reader',
    MobileApp2: 'Mobile-App',
    Avr: 'AVR',
    IccReader: 'ICC-Reader',
    ContactlessReader2: 'Contactless-Reader',
    BarcodeReader2: 'Barcode-Reader',
    Bna: 'BNA',
    Ca: 'CA',
    WebApp2: 'Web-App'
} as const;

export type PaymentsEntryModeEnum = typeof PaymentsEntryModeEnum[keyof typeof PaymentsEntryModeEnum];
export const PaymentsMediumEnum = {
    Card: 'Card',
    Note: 'Note',
    Coin: 'Coin',
    Coupon: 'Coupon',
    None: 'None',
    QrCode: 'QRCode',
    Barcode: 'Barcode',
    Code: 'Code',
    Icc: 'ICC',
    Rfid: 'RFID',
    App: 'App',
    ManuallInput: 'ManuallInput',
    Avr: 'AVR',
    Remote: 'Remote',
    Notes: 'Notes',
    QrCode2: 'QR-Code'
} as const;

export type PaymentsMediumEnum = typeof PaymentsMediumEnum[keyof typeof PaymentsMediumEnum];
export const PaymentsTypeEnum = {
    PspCard: 'PSPCard',
    FleetCard: 'FleetCard',
    ChargingCard: 'ChargingCard',
    StationCard: 'StationCard',
    GiftCard: 'GiftCard',
    CityCard: 'CityCard',
    PushPayment: 'PushPayment',
    BankNotes: 'BankNotes',
    Coins: 'Coins',
    NoCard: 'NoCard',
    CashCard: 'CashCard',
    Free: 'Free',
    Remote: 'Remote'
} as const;

export type PaymentsTypeEnum = typeof PaymentsTypeEnum[keyof typeof PaymentsTypeEnum];
export const PaymentsFunctionEnum = {
    SingleCard: 'SingleCard',
    Omitted: 'Omitted',
    VehicleCard: 'VehicleCard',
    DriverCard: 'DriverCard',
    SingleVehicleCard: 'SingleVehicleCard',
    SingleDriverCard: 'SingleDriverCard',
    ManagementCard: 'ManagementCard',
    TechnicianCard: 'TechnicianCard',
    LoyaltyCard: 'LoyaltyCard',
    WashCard: 'WashCard',
    PetroPoint: 'PetroPoint',
    ManuallInput: 'ManuallInput',
    SingleWashCard: 'SingleWashCard',
    GrantedFreeparking: 'GrantedFreeparking',
    AVehicleCard: 'A-VehicleCard',
    BDriverCard: 'B-DriverCard',
    CVehicleCard: 'C-VehicleCard',
    CDriverCard: 'C-DriverCard',
    Technician: 'Technician',
    Loyalty: 'Loyalty'
} as const;

export type PaymentsFunctionEnum = typeof PaymentsFunctionEnum[keyof typeof PaymentsFunctionEnum];
export const PaymentsSchemeEnum = {
    _7ByteUid: '7 Byte UID',
    AeroPs: 'aeroPS',
    AgrolaEnergyCard: 'AGROLA Energy Card',
    AmericanExpress: 'American Express',
    ApCard: 'AP-Card',
    ApplePay: 'Apple Pay',
    Austrocard: 'AUSTROCARD',
    Avia: 'AVIA',
    Aviap: 'AVIAP',
    CombuCard: 'CombuCard',
    DaimlerCard: 'Daimler Card',
    DieseltankstelleAt: 'Dieseltankstelle AT',
    DinersClub: 'Diners Club',
    Drivitty: 'Drivitty',
    ElectronicCash: 'Electronic-Cash',
    EuDriverCard: 'EU Driver Card',
    Fleet: 'Fleet+',
    FleetNet: 'FleetNet',
    FreiFlott: 'frei & flott',
    GasCom: 'GasCom',
    Gioia: 'GIOIA',
    Girocard: 'girocard',
    H2LiveCard: 'H2.Live Card',
    Kienzle2002: 'Kienzle 2002',
    Kienzle2003: 'Kienzle 2003',
    KoppensCard: 'Koppens Card',
    Legic: 'Legic',
    Mctc: 'MCTC',
    MemoFill: 'MemoFill',
    MintHydrogen: 'Mint Hydrogen',
    Moveri: 'Moveri',
    Mpd: 'MPD',
    Novo: 'NOVO',
    OnlineChipCards: 'Online Chip Cards',
    Pace: 'PACE',
    Proeda: 'Proeda',
    Promotion: 'Promotion',
    Proxy: 'PROXY',
    Q1Card: 'Q1 Card',
    PRsrlCard: 'PRsrl card',
    Retail: 'Retail',
    RessaCard: 'RESSA Card',
    Rotada: 'Rotada',
    RothTankkarte: 'ROTH Tankkarte',
    Routex: 'Routex',
    Ryd: 'ryd',
    Visa: 'VISA',
    SchwabenCard: 'SchwabenCard',
    ShellCard: 'Shell Card',
    Shop: 'SHOP',
    SocarCard: 'SOCAR Card',
    Worldline: 'Worldline',
    WlflTransCard: 'Wölfl Trans. Card',
    StarFleetCard: 'star fleet card',
    Tds: 'TDS',
    TeamGutscheinkarte: 'team Gutscheinkarte',
    TeamKarte: 'team Karte',
    Total: 'TOTAL',
    UnionPay: 'UnionPay',
    VirtualCard: 'Virtual Card',
    Weat: 'WEAT',
    WebRemote: 'Web Remote',
    WestfalenVerbundk: 'Westfalen Verbundk.',
    Cnp: 'CNP',
    Offline: 'Offline',
    MbWay: 'MBWay',
    Hks: 'HKS',
    Visa2: 'Visa',
    Maestro: 'Maestro',
    Mastercard: 'Mastercard',
    PostFinance: 'PostFinance',
    Vpay: 'VPAY',
    Dkv: 'DKV',
    Hfc: 'HFC',
    Uta: 'UTA',
    Iso2: 'ISO-2',
    BayWa: 'BayWa',
    As24: 'AS24',
    Blik: 'BLIK',
    Mbway: 'MBWAY',
    Twint: 'TWINT',
    PetroPoint: 'PetroPoint',
    Other: 'Other',
    Alternoil: 'Alternoil',
    Ap: 'AP',
    Aviag: 'AVIAG',
    Axegaz: 'Axegaz',
    Diesel24: 'Diesel24',
    E100: 'E100',
    Edc: 'EDC',
    Ept: 'EPT',
    Eurowag: 'Eurowag',
    Eurotrafic: 'Eurotrafic',
    GenolG: 'GenolG+',
    Greenline: 'Greenline',
    Hoyer: 'Hoyer',
    Ids: 'IDS',
    Iq: 'IQ',
    Lanfer: 'Lanfer',
    Leu: 'Leu',
    LogPay: 'LogPay',
    Molgas: 'Molgas',
    OnTurtle: 'OnTurtle',
    RaiffeisenRCard: 'RaiffeisenR-CARD',
    Raiffeisen: 'Raiffeisen',
    Verbundkarte: 'Verbundkarte',
    Rmc: 'RMC',
    Rtv: 'RTV',
    Roadrunner: 'Roadrunner',
    RomacFuels: 'RomacFuels',
    Tankpool24: 'Tankpool24',
    Tfc: 'TFC',
    Tnd: 'TND',
    Vulcangas: 'Vulcangas'
} as const;

export type PaymentsSchemeEnum = typeof PaymentsSchemeEnum[keyof typeof PaymentsSchemeEnum];

/**
 * 
 * @export
 * @interface PaymentsAdditionalCard
 */
export interface PaymentsAdditionalCard {
    /**
     * 
     * @type {string}
     * @memberof PaymentsAdditionalCard
     */
    'function'?: PaymentsAdditionalCardFunctionEnum;
    /**
     * The number from the card
     * @type {string}
     * @memberof PaymentsAdditionalCard
     */
    'card_id'?: string;
}

export const PaymentsAdditionalCardFunctionEnum = {
    PetroPoint: 'PetroPoint',
    ManuallInput: 'ManuallInput',
    SingleWashCard: 'SingleWashCard',
    GrantedFreeparking: 'GrantedFreeparking',
    SingleCard: 'SingleCard',
    Omitted: 'Omitted',
    AVehicleCard: 'A-VehicleCard',
    BDriverCard: 'B-DriverCard',
    CVehicleCard: 'C-VehicleCard',
    CDriverCard: 'C-DriverCard',
    ManagementCard: 'ManagementCard',
    Technician: 'Technician',
    Loyalty: 'Loyalty',
    WashCard: 'WashCard'
} as const;

export type PaymentsAdditionalCardFunctionEnum = typeof PaymentsAdditionalCardFunctionEnum[keyof typeof PaymentsAdditionalCardFunctionEnum];

/**
 * 
 * @export
 * @interface PermissionGroup
 */
export interface PermissionGroup {
    /**
     * The unique identifier of the permission group following a UUID v4 format
     * @type {string}
     * @memberof PermissionGroup
     */
    'id'?: string;
    /**
     * The level number of the permission group
     * @type {number}
     * @memberof PermissionGroup
     */
    'level'?: number;
    /**
     * The name of the permission group
     * @type {string}
     * @memberof PermissionGroup
     */
    'name'?: string;
    /**
     * The date & time when the permission group was created
     * @type {string}
     * @memberof PermissionGroup
     */
    'created_at'?: string;
    /**
     * 
     * @type {Array<PermissionGroupRight>}
     * @memberof PermissionGroup
     */
    'rights'?: Array<PermissionGroupRight>;
}
/**
 * 
 * @export
 * @interface PermissionGroupResponse
 */
export interface PermissionGroupResponse {
    /**
     * The unique identifier of the permission group following a UUID v4 format
     * @type {string}
     * @memberof PermissionGroupResponse
     */
    'id'?: string;
    /**
     * The level number of the permission group
     * @type {number}
     * @memberof PermissionGroupResponse
     */
    'level'?: number;
    /**
     * The name of the permission group
     * @type {string}
     * @memberof PermissionGroupResponse
     */
    'name'?: string;
    /**
     * The date & time when the permission group was created
     * @type {string}
     * @memberof PermissionGroupResponse
     */
    'created_at'?: string;
    /**
     * The date & time when the permission group was updated
     * @type {string}
     * @memberof PermissionGroupResponse
     */
    'updated_at'?: string;
    /**
     * The number of users which share the same permission group rights
     * @type {number}
     * @memberof PermissionGroupResponse
     */
    'count_of_users'?: number;
}
/**
 * 
 * @export
 * @interface PermissionGroupRight
 */
export interface PermissionGroupRight {
    /**
     * The sub-component id of the web application that the rights are applied to
     * @type {string}
     * @memberof PermissionGroupRight
     */
    'sub_component_id': string;
    /**
     * The user has the right to add a resource
     * @type {boolean}
     * @memberof PermissionGroupRight
     */
    'add': boolean;
    /**
     * The user has the right to view a resource
     * @type {boolean}
     * @memberof PermissionGroupRight
     */
    'view': boolean;
    /**
     * The user has the right to edit a resource
     * @type {boolean}
     * @memberof PermissionGroupRight
     */
    'edit': boolean;
    /**
     * The user has the right to delete a resource
     * @type {boolean}
     * @memberof PermissionGroupRight
     */
    'delete': boolean;
}
/**
 * 
 * @export
 * @interface PermissionGroupRightResponse
 */
export interface PermissionGroupRightResponse {
    /**
     * 
     * @type {string}
     * @memberof PermissionGroupRightResponse
     */
    'id'?: string;
    /**
     * The level number of the permission group
     * @type {number}
     * @memberof PermissionGroupRightResponse
     */
    'level'?: number;
    /**
     * The name of the permission group
     * @type {string}
     * @memberof PermissionGroupRightResponse
     */
    'name'?: string;
}
/**
 * 
 * @export
 * @interface Point
 */
export interface Point {
    /**
     * The unique identifier of the site
     * @type {string}
     * @memberof Point
     */
    'site_id'?: string;
    /**
     * Id of the device where the point is assigned
     * @type {string}
     * @memberof Point
     */
    'device_id'?: string;
    /**
     * The name of the point
     * @type {string}
     * @memberof Point
     */
    'name'?: string;
    /**
     * The point number is unique on device and site
     * @type {number}
     * @memberof Point
     */
    'point_number'?: number;
    /**
     * 
     * @type {PointType}
     * @memberof Point
     */
    'type'?: PointType;
    /**
     * The unique identifier of the point. This is used for 3rd party synchronization/integration
     * @type {string}
     * @memberof Point
     */
    'id'?: string;
    /**
     * 
     * @type {Array<ProductPower>}
     * @memberof Point
     */
    'added_products'?: Array<ProductPower>;
    /**
     * 
     * @type {PointStatus}
     * @memberof Point
     */
    'status'?: PointStatus;
}


/**
 * 
 * @export
 * @interface PointBase
 */
export interface PointBase {
    /**
     * The unique identifier of the site
     * @type {string}
     * @memberof PointBase
     */
    'site_id'?: string;
    /**
     * Id of the device where the point is assigned
     * @type {string}
     * @memberof PointBase
     */
    'device_id'?: string;
    /**
     * The name of the point
     * @type {string}
     * @memberof PointBase
     */
    'name'?: string;
    /**
     * The point number is unique on device and site
     * @type {number}
     * @memberof PointBase
     */
    'point_number'?: number;
    /**
     * 
     * @type {PointType}
     * @memberof PointBase
     */
    'type'?: PointType;
}


/**
 * 
 * @export
 * @enum {string}
 */

export const PointCategory = {
    Charging: 'charging',
    Refuelling: 'refuelling',
    Parking: 'parking',
    Wash: 'wash'
} as const;

export type PointCategory = typeof PointCategory[keyof typeof PointCategory];


/**
 * 
 * @export
 * @interface PointRef
 */
export interface PointRef {
    /**
     * 
     * @type {string}
     * @memberof PointRef
     */
    'id': string;
    /**
     * max 30 chars
     * @type {string}
     * @memberof PointRef
     */
    'name'?: string;
    /**
     * 
     * @type {DeviceExtended}
     * @memberof PointRef
     */
    'device'?: DeviceExtended;
    /**
     * 
     * @type {Site}
     * @memberof PointRef
     */
    'site'?: Site;
}
/**
 * 
 * @export
 * @interface PointResponse
 */
export interface PointResponse {
    /**
     * The unique identifier of the site
     * @type {string}
     * @memberof PointResponse
     */
    'site_id'?: string;
    /**
     * Id of the device where the point is assigned
     * @type {string}
     * @memberof PointResponse
     */
    'device_id'?: string;
    /**
     * The name of the point
     * @type {string}
     * @memberof PointResponse
     */
    'name'?: string;
    /**
     * The point number is unique on device and site
     * @type {number}
     * @memberof PointResponse
     */
    'point_number'?: number;
    /**
     * 
     * @type {PointType}
     * @memberof PointResponse
     */
    'type'?: PointType;
    /**
     * 
     * @type {string}
     * @memberof PointResponse
     */
    'id'?: string;
    /**
     * 
     * @type {Array<ProductPower>}
     * @memberof PointResponse
     */
    'added_products'?: Array<ProductPower>;
    /**
     * 
     * @type {PointStatus}
     * @memberof PointResponse
     */
    'status'?: PointStatus;
}


/**
 * 
 * @export
 * @enum {string}
 */

export const PointStatus = {
    Available: 'available',
    Occupied: 'occupied',
    Reserved: 'reserved',
    OutOfOrder: 'out_of_order',
    InUse: 'in_use',
    Unknown: 'unknown'
} as const;

export type PointStatus = typeof PointStatus[keyof typeof PointStatus];


/**
 * 
 * @export
 * @interface PointTwint
 */
export interface PointTwint {
    /**
     * The unique identifier of the point
     * @type {string}
     * @memberof PointTwint
     */
    'id'?: string;
    /**
     * The name of the point
     * @type {string}
     * @memberof PointTwint
     */
    'name'?: string;
    /**
     * The point number is unique on device and site
     * @type {number}
     * @memberof PointTwint
     */
    'point_number'?: number;
    /**
     * Model name of the hardware point
     * @type {string}
     * @memberof PointTwint
     */
    'type'?: PointTwintTypeEnum;
    /**
     * The status of the point
     * @type {string}
     * @memberof PointTwint
     */
    'status'?: PointTwintStatusEnum;
    /**
     * 
     * @type {Array<ProductTwint>}
     * @memberof PointTwint
     */
    'products'?: Array<ProductTwint>;
}

export const PointTwintTypeEnum = {
    Type1: 'Type1',
    Type2: 'Type2',
    Ccs: 'CCS',
    ChAdeMo: 'CHAdeMO',
    Tesla: 'Tesla',
    Schuko: 'Schuko',
    Domestic: 'Domestic',
    Cee: 'CEE',
    Type1Combo: 'Type1 Combo',
    Type2Combo: 'Type2 Combo',
    Type3A: 'Type3A',
    Type3C: 'Type3C'
} as const;

export type PointTwintTypeEnum = typeof PointTwintTypeEnum[keyof typeof PointTwintTypeEnum];
export const PointTwintStatusEnum = {
    Available: 'available',
    Occupied: 'occupied',
    Reserved: 'reserved',
    OutOfOrder: 'out_of_order',
    InUse: 'in_use',
    Unknown: 'unknown'
} as const;

export type PointTwintStatusEnum = typeof PointTwintStatusEnum[keyof typeof PointTwintStatusEnum];

/**
 * 
 * @export
 * @enum {string}
 */

export const PointType = {
    Type1: 'Type1',
    Type2: 'Type2',
    Ccs: 'CCS',
    ChAdeMo: 'CHAdeMO',
    Tesla: 'Tesla',
    Schuko: 'Schuko',
    Domestic: 'Domestic',
    Cee: 'CEE',
    Type1Combo: 'Type1 Combo',
    Type2Combo: 'Type2 Combo',
    Type3A: 'Type3A',
    Type3C: 'Type3C'
} as const;

export type PointType = typeof PointType[keyof typeof PointType];


/**
 * 
 * @export
 * @interface PointUpdate
 */
export interface PointUpdate {
    /**
     * The unique identifier of the site
     * @type {string}
     * @memberof PointUpdate
     */
    'site_id'?: string;
    /**
     * Id of the device where the point is assigned
     * @type {string}
     * @memberof PointUpdate
     */
    'device_id'?: string;
    /**
     * The name of the point
     * @type {string}
     * @memberof PointUpdate
     */
    'name'?: string;
    /**
     * The point number is unique on device and site
     * @type {number}
     * @memberof PointUpdate
     */
    'point_number'?: number;
    /**
     * 
     * @type {PointType}
     * @memberof PointUpdate
     */
    'type'?: PointType;
    /**
     * 
     * @type {PointStatus}
     * @memberof PointUpdate
     */
    'status': PointStatus;
    /**
     * 
     * @type {Array<ProductPower>}
     * @memberof PointUpdate
     */
    'added_products'?: Array<ProductPower>;
    /**
     * 
     * @type {Array<UpdatedPoductPower>}
     * @memberof PointUpdate
     */
    'updated_products'?: Array<UpdatedPoductPower>;
    /**
     * The id of the products power that had to be unassigned from point
     * @type {Array<string>}
     * @memberof PointUpdate
     */
    'deleted_products'?: Array<string>;
}


/**
 * 
 * @export
 * @interface PointsQR
 */
export interface PointsQR {
    /**
     * The unique identifier of the point
     * @type {string}
     * @memberof PointsQR
     */
    'id': string;
    /**
     * The name of the point
     * @type {string}
     * @memberof PointsQR
     */
    'name': string;
    /**
     * The point number is unique on device and site
     * @type {number}
     * @memberof PointsQR
     */
    'point_number': number;
    /**
     * QR code of the point used for TWINT
     * @type {string}
     * @memberof PointsQR
     */
    'QR_code'?: string;
}
/**
 * 
 * @export
 * @interface Price
 */
export interface Price {
    /**
     * The unique identifier of the product
     * @type {string}
     * @memberof Price
     */
    'product_id': string;
    /**
     * The price used for product
     * @type {number}
     * @memberof Price
     */
    'unit_price'?: number;
    /**
     * The price which will be used starting with activation_timestamp
     * @type {number}
     * @memberof Price
     */
    'new_unit_price'?: number;
    /**
     * The date and time when the new_unit_price will be active
     * @type {string}
     * @memberof Price
     */
    'activation_timestamp'?: string;
}
/**
 * 
 * @export
 * @interface Product
 */
export interface Product {
    /**
     * The identifier of the product
     * @type {string}
     * @memberof Product
     */
    'code': string;
    /**
     * Name of the product
     * @type {string}
     * @memberof Product
     */
    'name': string;
    /**
     * Short description of the product
     * @type {string}
     * @memberof Product
     */
    'description'?: string;
    /**
     * The unit measure used for the product
     * @type {string}
     * @memberof Product
     */
    'unit_measure': ProductUnitMeasureEnum;
    /**
     * Category of the product
     * @type {string}
     * @memberof Product
     */
    'category': ProductCategoryEnum;
    /**
     * The unique identifier of the product
     * @type {string}
     * @memberof Product
     */
    'id'?: string;
}

export const ProductUnitMeasureEnum = {
    KWh: 'kWh',
    Pc: 'pc',
    Kg: 'kg',
    L: 'l',
    Min: 'min',
    Km: 'km',
    M: 'm',
    Cm: 'cm',
    Mm: 'mm',
    In: 'in',
    Wh: 'Wh',
    A: 'a',
    D: 'd',
    H: 'h',
    S: 's',
    M2: 'm²',
    M3: 'm³',
    P: 'p',
    C: '°C',
    F: '°F'
} as const;

export type ProductUnitMeasureEnum = typeof ProductUnitMeasureEnum[keyof typeof ProductUnitMeasureEnum];
export const ProductCategoryEnum = {
    Charging: 'charging',
    Refuelling: 'refuelling',
    Parking: 'parking',
    Wash: 'wash'
} as const;

export type ProductCategoryEnum = typeof ProductCategoryEnum[keyof typeof ProductCategoryEnum];

/**
 * 
 * @export
 * @interface ProductBase
 */
export interface ProductBase {
    /**
     * The identifier of the product
     * @type {string}
     * @memberof ProductBase
     */
    'code': string;
    /**
     * Name of the product
     * @type {string}
     * @memberof ProductBase
     */
    'name': string;
    /**
     * Short description of the product
     * @type {string}
     * @memberof ProductBase
     */
    'description'?: string;
    /**
     * The unit measure used for the product
     * @type {string}
     * @memberof ProductBase
     */
    'unit_measure': ProductBaseUnitMeasureEnum;
    /**
     * Category of the product
     * @type {string}
     * @memberof ProductBase
     */
    'category': ProductBaseCategoryEnum;
}

export const ProductBaseUnitMeasureEnum = {
    KWh: 'kWh',
    Pc: 'pc',
    Kg: 'kg',
    L: 'l',
    Min: 'min',
    Km: 'km',
    M: 'm',
    Cm: 'cm',
    Mm: 'mm',
    In: 'in',
    Wh: 'Wh',
    A: 'a',
    D: 'd',
    H: 'h',
    S: 's',
    M2: 'm²',
    M3: 'm³',
    P: 'p',
    C: '°C',
    F: '°F'
} as const;

export type ProductBaseUnitMeasureEnum = typeof ProductBaseUnitMeasureEnum[keyof typeof ProductBaseUnitMeasureEnum];
export const ProductBaseCategoryEnum = {
    Charging: 'charging',
    Refuelling: 'refuelling',
    Parking: 'parking',
    Wash: 'wash'
} as const;

export type ProductBaseCategoryEnum = typeof ProductBaseCategoryEnum[keyof typeof ProductBaseCategoryEnum];

/**
 * 
 * @export
 * @interface ProductOfferBase
 */
export interface ProductOfferBase {
    /**
     * The id of the product
     * @type {string}
     * @memberof ProductOfferBase
     */
    'id'?: string;
    /**
     * 
     * @type {string}
     * @memberof ProductOfferBase
     */
    'category'?: ProductOfferBaseCategoryEnum;
    /**
     * 
     * @type {string}
     * @memberof ProductOfferBase
     */
    'name'?: string;
    /**
     * 
     * @type {string}
     * @memberof ProductOfferBase
     */
    'description'?: string;
}

export const ProductOfferBaseCategoryEnum = {
    Parking: 'parking',
    Charging: 'charging',
    Refuelling: 'refuelling',
    Wash: 'wash'
} as const;

export type ProductOfferBaseCategoryEnum = typeof ProductOfferBaseCategoryEnum[keyof typeof ProductOfferBaseCategoryEnum];

/**
 * 
 * @export
 * @interface ProductOfferResponse
 */
export interface ProductOfferResponse {
    /**
     * The id of the product
     * @type {string}
     * @memberof ProductOfferResponse
     */
    'id'?: string;
    /**
     * 
     * @type {string}
     * @memberof ProductOfferResponse
     */
    'category'?: ProductOfferResponseCategoryEnum;
    /**
     * 
     * @type {string}
     * @memberof ProductOfferResponse
     */
    'name'?: string;
    /**
     * 
     * @type {string}
     * @memberof ProductOfferResponse
     */
    'description'?: string;
    /**
     * The unique identifier of a device
     * @type {string}
     * @memberof ProductOfferResponse
     */
    'code'?: string;
    /**
     * The price defined for a product on each site
     * @type {number}
     * @memberof ProductOfferResponse
     */
    'unit_price'?: number;
    /**
     * The currency set on site
     * @type {string}
     * @memberof ProductOfferResponse
     */
    'currency'?: string;
    /**
     * 
     * @type {number}
     * @memberof ProductOfferResponse
     */
    'max_amount'?: number;
    /**
     * Tax percentage applied for product prices of the site
     * @type {number}
     * @memberof ProductOfferResponse
     */
    'tax_percentage'?: number;
    /**
     * 
     * @type {string}
     * @memberof ProductOfferResponse
     */
    'unit_measure'?: string;
    /**
     * The unique identifier of the product power
     * @type {string}
     * @memberof ProductOfferResponse
     */
    'power_id'?: string;
    /**
     * 
     * @type {number}
     * @memberof ProductOfferResponse
     */
    'max_power'?: number;
    /**
     * 
     * @type {Array<ChargingProductOfferAllOfRelatedProducts>}
     * @memberof ProductOfferResponse
     */
    'related_products'?: Array<ChargingProductOfferAllOfRelatedProducts>;
    /**
     * maximum time a driver allowed to park
     * @type {number}
     * @memberof ProductOfferResponse
     */
    'max_duration'?: number;
    /**
     * freeparking time in minutes
     * @type {number}
     * @memberof ProductOfferResponse
     */
    'duration'?: number;
}

export const ProductOfferResponseCategoryEnum = {
    Parking: 'parking',
    Charging: 'charging',
    Refuelling: 'refuelling',
    Wash: 'wash'
} as const;

export type ProductOfferResponseCategoryEnum = typeof ProductOfferResponseCategoryEnum[keyof typeof ProductOfferResponseCategoryEnum];

/**
 * 
 * @export
 * @interface ProductPower
 */
export interface ProductPower {
    /**
     * The unique identifier of the product
     * @type {string}
     * @memberof ProductPower
     */
    'id': string;
    /**
     * This field is used to set the maximum power used for charging station
     * @type {number}
     * @memberof ProductPower
     */
    'max_power'?: number;
}
/**
 * 
 * @export
 * @interface ProductRelationsInner
 */
export interface ProductRelationsInner {
    /**
     * The id of the product
     * @type {string}
     * @memberof ProductRelationsInner
     */
    'product_id'?: string;
    /**
     * 
     * @type {Array<string>}
     * @memberof ProductRelationsInner
     */
    'related_products'?: Array<string>;
}
/**
 * 
 * @export
 * @interface ProductTwint
 */
export interface ProductTwint {
    /**
     * The unique identifier of the product
     * @type {string}
     * @memberof ProductTwint
     */
    'id'?: string;
    /**
     * The category of the product
     * @type {string}
     * @memberof ProductTwint
     */
    'category'?: ProductTwintCategoryEnum;
    /**
     * The unit measure used for the product
     * @type {string}
     * @memberof ProductTwint
     */
    'unit_measure'?: ProductTwintUnitMeasureEnum;
    /**
     * The price used for product
     * @type {number}
     * @memberof ProductTwint
     */
    'unit_price'?: number;
}

export const ProductTwintCategoryEnum = {
    Charging: 'charging',
    Parking: 'parking',
    Refuelling: 'refuelling',
    Wash: 'wash'
} as const;

export type ProductTwintCategoryEnum = typeof ProductTwintCategoryEnum[keyof typeof ProductTwintCategoryEnum];
export const ProductTwintUnitMeasureEnum = {
    KWh: 'kWh',
    Pc: 'pc',
    Kg: 'kg',
    L: 'l',
    Min: 'min',
    Km: 'km',
    M: 'm',
    Cm: 'cm',
    Mm: 'mm',
    In: 'in',
    Wh: 'Wh',
    A: 'a',
    D: 'd',
    H: 'h',
    S: 's',
    M2: 'm²',
    M3: 'm³',
    P: 'p',
    C: '°C',
    F: '°F'
} as const;

export type ProductTwintUnitMeasureEnum = typeof ProductTwintUnitMeasureEnum[keyof typeof ProductTwintUnitMeasureEnum];

/**
 * 
 * @export
 * @interface Protocol
 */
export interface Protocol {
    /**
     * Protocol unique identifier
     * @type {string}
     * @memberof Protocol
     */
    'id'?: string;
    /**
     * Name of the protocol
     * @type {string}
     * @memberof Protocol
     */
    'name'?: string;
}
/**
 * 
 * @export
 * @interface Provider
 */
export interface Provider {
    /**
     * The unique identifier of the provider
     * @type {string}
     * @memberof Provider
     */
    'id'?: string;
    /**
     * The provider name
     * @type {string}
     * @memberof Provider
     */
    'name'?: string;
}
/**
 * 
 * @export
 * @interface ProviderData
 */
export interface ProviderData {
    /**
     * 
     * @type {Array<string>}
     * @memberof ProviderData
     */
    'ids': Array<string>;
    /**
     * 
     * @type {string}
     * @memberof ProviderData
     */
    'action': string;
}
/**
 * 
 * @export
 * @interface ProviderFieldsRequest
 */
export interface ProviderFieldsRequest {
    /**
     * The identifier of the field
     * @type {string}
     * @memberof ProviderFieldsRequest
     */
    'key': string;
    /**
     * The field name
     * @type {string}
     * @memberof ProviderFieldsRequest
     */
    'name': string;
    /**
     * The type of the field
     * @type {string}
     * @memberof ProviderFieldsRequest
     */
    'type': string;
    /**
     * The description for the field
     * @type {string}
     * @memberof ProviderFieldsRequest
     */
    'description'?: string;
}
/**
 * 
 * @export
 * @interface ProviderFieldsResponse
 */
export interface ProviderFieldsResponse {
    /**
     * The identifier of the field
     * @type {string}
     * @memberof ProviderFieldsResponse
     */
    'key': string;
    /**
     * The field name
     * @type {string}
     * @memberof ProviderFieldsResponse
     */
    'name': string;
    /**
     * The type of the field
     * @type {string}
     * @memberof ProviderFieldsResponse
     */
    'type': string;
    /**
     * The description for the field
     * @type {string}
     * @memberof ProviderFieldsResponse
     */
    'description'?: string;
    /**
     * 
     * @type {string}
     * @memberof ProviderFieldsResponse
     */
    'id'?: string;
    /**
     * 
     * @type {string}
     * @memberof ProviderFieldsResponse
     */
    'created_at'?: string;
}
/**
 * 
 * @export
 * @interface ProviderTemplates
 */
export interface ProviderTemplates {
    /**
     * The unique identifier of the template
     * @type {string}
     * @memberof ProviderTemplates
     */
    'id'?: string;
    /**
     * The template name
     * @type {string}
     * @memberof ProviderTemplates
     */
    'name'?: string;
    /**
     * 
     * @type {string}
     * @memberof ProviderTemplates
     */
    'created_at'?: string;
    /**
     * How many mandators have assigned this template
     * @type {number}
     * @memberof ProviderTemplates
     */
    'number_of_mandators'?: number;
}
/**
 * 
 * @export
 * @interface ProviderTemplatesRequest
 */
export interface ProviderTemplatesRequest {
    /**
     * The template name
     * @type {string}
     * @memberof ProviderTemplatesRequest
     */
    'name': string;
    /**
     * The unique identifier of the provider
     * @type {string}
     * @memberof ProviderTemplatesRequest
     */
    'provider_id': string;
    /**
     * 
     * @type {boolean}
     * @memberof ProviderTemplatesRequest
     */
    'is_global': boolean;
    /**
     * The ids of the fields
     * @type {Array<string>}
     * @memberof ProviderTemplatesRequest
     */
    'fields'?: Array<string>;
    /**
     * Contains values for each selected field. This field is required if is_global is true and fields contains values
     * @type {Array<ProviderTemplatesRequestFieldsValuesInner>}
     * @memberof ProviderTemplatesRequest
     */
    'fields_values'?: Array<ProviderTemplatesRequestFieldsValuesInner>;
    /**
     * The id of the mandators
     * @type {Array<string>}
     * @memberof ProviderTemplatesRequest
     */
    'mandators'?: Array<string>;
    /**
     * The description for the template
     * @type {string}
     * @memberof ProviderTemplatesRequest
     */
    'description'?: string;
}
/**
 * 
 * @export
 * @interface ProviderTemplatesRequestFieldsValuesInner
 */
export interface ProviderTemplatesRequestFieldsValuesInner {
    /**
     * 
     * @type {string}
     * @memberof ProviderTemplatesRequestFieldsValuesInner
     */
    'field_id'?: string;
    /**
     * 
     * @type {string}
     * @memberof ProviderTemplatesRequestFieldsValuesInner
     */
    'value'?: string;
}
/**
 * 
 * @export
 * @interface ProviderTemplatesResponse
 */
export interface ProviderTemplatesResponse {
    /**
     * The template name
     * @type {string}
     * @memberof ProviderTemplatesResponse
     */
    'name': string;
    /**
     * The unique identifier of the provider
     * @type {string}
     * @memberof ProviderTemplatesResponse
     */
    'provider_id': string;
    /**
     * 
     * @type {boolean}
     * @memberof ProviderTemplatesResponse
     */
    'is_global': boolean;
    /**
     * The ids of the fields
     * @type {Array<string>}
     * @memberof ProviderTemplatesResponse
     */
    'fields'?: Array<string>;
    /**
     * Contains values for each selected field. This field is required if is_global is true and fields contains values
     * @type {Array<ProviderTemplatesRequestFieldsValuesInner>}
     * @memberof ProviderTemplatesResponse
     */
    'fields_values'?: Array<ProviderTemplatesRequestFieldsValuesInner>;
    /**
     * The id of the mandators
     * @type {Array<string>}
     * @memberof ProviderTemplatesResponse
     */
    'mandators'?: Array<string>;
    /**
     * The description for the template
     * @type {string}
     * @memberof ProviderTemplatesResponse
     */
    'description'?: string;
    /**
     * 
     * @type {string}
     * @memberof ProviderTemplatesResponse
     */
    'id'?: string;
    /**
     * 
     * @type {string}
     * @memberof ProviderTemplatesResponse
     */
    'created_at'?: string;
}
/**
 * 
 * @export
 * @interface Receipt
 */
export interface Receipt {
    /**
     * 
     * @type {string}
     * @memberof Receipt
     */
    'state'?: ReceiptStateEnum;
    /**
     * This log is used to trace various events and status within this object.
     * @type {Array<ItemlogEntry>}
     * @memberof Receipt
     */
    'log'?: Array<ItemlogEntry>;
    /**
     * the e-mail the receipt will be send to if not empty
     * @type {string}
     * @memberof Receipt
     */
    'email'?: string;
    /**
     * The encrypted OCMF meter reading value received when starting a charging session (if charging station is configured to send it)
     * @type {string}
     * @memberof Receipt
     */
    'ocmf_start'?: string;
    /**
     * The encrypted OCMF meter reading value received when charging is finished (if charging station is configured to send it)
     * @type {string}
     * @memberof Receipt
     */
    'ocmf_stop'?: string;
    /**
     * 
     * @type {ReceiptLink}
     * @memberof Receipt
     */
    '_links'?: ReceiptLink;
}

export const ReceiptStateEnum = {
    Created: 'created',
    Scanned: 'scanned',
    Downloaded: 'downloaded',
    Sent: 'sent'
} as const;

export type ReceiptStateEnum = typeof ReceiptStateEnum[keyof typeof ReceiptStateEnum];

/**
 * 
 * @export
 * @interface ReceiptLink
 */
export interface ReceiptLink {
    /**
     * 
     * @type {string}
     * @memberof ReceiptLink
     */
    'customer_url'?: string;
}
/**
 * 
 * @export
 * @interface ReceiptV3
 */
export interface ReceiptV3 {
    /**
     * 
     * @type {string}
     * @memberof ReceiptV3
     * @deprecated
     */
    'ocmf_start'?: string;
    /**
     * 
     * @type {string}
     * @memberof ReceiptV3
     * @deprecated
     */
    'ocmf_stop'?: string;
    /**
     * The state of the receipt
     * @type {string}
     * @memberof ReceiptV3
     */
    'state'?: string;
    /**
     * The email where the receipt will be send when is generated
     * @type {string}
     * @memberof ReceiptV3
     */
    'email'?: string;
    /**
     * 
     * @type {ReceiptV3Links}
     * @memberof ReceiptV3
     */
    'links'?: ReceiptV3Links;
    /**
     * 
     * @type {Array<LogInner>}
     * @memberof ReceiptV3
     */
    'log'?: Array<LogInner>;
}
/**
 * 
 * @export
 * @interface ReceiptV3Links
 */
export interface ReceiptV3Links {
    /**
     * The url where the receipt pdf is available
     * @type {string}
     * @memberof ReceiptV3Links
     */
    'customer_url'?: string;
}
/**
 * 
 * @export
 * @interface RefreshToken
 */
export interface RefreshToken {
    /**
     * Current refresh token
     * @type {string}
     * @memberof RefreshToken
     */
    'refresh_token': string;
}
/**
 * 
 * @export
 * @interface RefreshTokenAnswer
 */
export interface RefreshTokenAnswer {
    /**
     * 
     * @type {string}
     * @memberof RefreshTokenAnswer
     */
    'access_token'?: string;
    /**
     * 
     * @type {number}
     * @memberof RefreshTokenAnswer
     */
    'expires_in'?: number;
    /**
     * 
     * @type {string}
     * @memberof RefreshTokenAnswer
     */
    'token_type'?: string;
    /**
     * 
     * @type {string}
     * @memberof RefreshTokenAnswer
     */
    'id_token'?: string;
}
/**
 * 
 * @export
 * @interface RefuellingArticle
 */
export interface RefuellingArticle {
    /**
     * 
     * @type {ArticleCategory}
     * @memberof RefuellingArticle
     */
    'category': ArticleCategory;
    /**
     * This is a unique ID referencing to this sold article
     * @type {string}
     * @memberof RefuellingArticle
     */
    'id'?: string;
    /**
     * 
     * @type {ArticleState}
     * @memberof RefuellingArticle
     */
    'state'?: ArticleState;
    /**
     * This log is used to trace various events and status within this object.
     * @type {Array<ItemlogEntry>}
     * @memberof RefuellingArticle
     */
    'log'?: Array<ItemlogEntry>;
    /**
     * This is ID is referencing the sold product, which is setup on this site.
     * @type {string}
     * @memberof RefuellingArticle
     */
    'product_id'?: string;
    /**
     * Amount the user has to pay including VAT.in currency unit.
     * @type {number}
     * @memberof RefuellingArticle
     */
    'amount'?: number;
    /**
     * Amount of VAT. Has to be lower than total amount. Returned in  currency unit.
     * @type {number}
     * @memberof RefuellingArticle
     */
    'tax_amount'?: number;
    /**
     * The factor the vat has. Unit is percent (%) this an factor of 100
     * @type {number}
     * @memberof RefuellingArticle
     */
    'tax_percentage'?: number;
    /**
     * currency code in ISO 4217
     * @type {string}
     * @memberof RefuellingArticle
     */
    'currency'?: string;
    /**
     * Start of the Article usage/consumption
     * @type {string}
     * @memberof RefuellingArticle
     */
    'start_time'?: string;
    /**
     * End of the Article usage/consumption
     * @type {string}
     * @memberof RefuellingArticle
     */
    'end_time'?: string;
    /**
     * 
     * @type {string}
     * @memberof RefuellingArticle
     */
    'code'?: string;
    /**
     * 
     * @type {number}
     * @memberof RefuellingArticle
     */
    'unit_price'?: number;
    /**
     * 
     * @type {string}
     * @memberof RefuellingArticle
     */
    'unit_measure'?: string;
    /**
     * how much l was consumed
     * @type {number}
     * @memberof RefuellingArticle
     */
    'quantity'?: number;
    /**
     * The Maximum amount the user wants to spend Lower or equals reserved_amount
     * @type {number}
     * @memberof RefuellingArticle
     */
    'max_amount'?: number;
    /**
     * The Maximum amount the user wants to spend Lower or equals reserved_amount
     * @type {number}
     * @memberof RefuellingArticle
     */
    'max_quantity'?: number;
    /**
     * 
     * @type {string}
     * @memberof RefuellingArticle
     */
    'name'?: string;
    /**
     * this field can be used for additional information like for a washing product
     * @type {Array<string>}
     * @memberof RefuellingArticle
     */
    'description'?: Array<string>;
}


/**
 * 
 * @export
 * @interface RefuellingProductOffer
 */
export interface RefuellingProductOffer {
    /**
     * The id of the product
     * @type {string}
     * @memberof RefuellingProductOffer
     */
    'id'?: string;
    /**
     * 
     * @type {string}
     * @memberof RefuellingProductOffer
     */
    'category'?: RefuellingProductOfferCategoryEnum;
    /**
     * 
     * @type {string}
     * @memberof RefuellingProductOffer
     */
    'name'?: string;
    /**
     * 
     * @type {string}
     * @memberof RefuellingProductOffer
     */
    'description'?: string;
    /**
     * The unique identifier of a device
     * @type {string}
     * @memberof RefuellingProductOffer
     */
    'code'?: string;
    /**
     * The price defined for a product on each site
     * @type {number}
     * @memberof RefuellingProductOffer
     */
    'unit_price'?: number;
    /**
     * Th currency set on site
     * @type {string}
     * @memberof RefuellingProductOffer
     */
    'currency'?: string;
    /**
     * 
     * @type {number}
     * @memberof RefuellingProductOffer
     */
    'max_amount'?: number;
    /**
     * Tax percentage applied for product prices of the site
     * @type {number}
     * @memberof RefuellingProductOffer
     */
    'tax_percentage'?: number;
    /**
     * 
     * @type {string}
     * @memberof RefuellingProductOffer
     */
    'unit_measure'?: string;
    /**
     * The unique identifier of the product power
     * @type {string}
     * @memberof RefuellingProductOffer
     */
    'power_id'?: string;
}

export const RefuellingProductOfferCategoryEnum = {
    Parking: 'parking',
    Charging: 'charging',
    Refuelling: 'refuelling',
    Wash: 'wash'
} as const;

export type RefuellingProductOfferCategoryEnum = typeof RefuellingProductOfferCategoryEnum[keyof typeof RefuellingProductOfferCategoryEnum];

/**
 * 
 * @export
 * @interface RemoteControlForDevice200Response
 */
export interface RemoteControlForDevice200Response {
    /**
     * 
     * @type {ResponseBaseLinks}
     * @memberof RemoteControlForDevice200Response
     */
    '_links'?: ResponseBaseLinks;
    /**
     * 
     * @type {ResponseBaseMeta}
     * @memberof RemoteControlForDevice200Response
     */
    'meta'?: ResponseBaseMeta;
    /**
     * 
     * @type {Array<RemoteControlForDevice200ResponseAllOfData>}
     * @memberof RemoteControlForDevice200Response
     */
    'data'?: Array<RemoteControlForDevice200ResponseAllOfData>;
}
/**
 * 
 * @export
 * @interface RemoteControlForDevice200ResponseAllOfData
 */
export interface RemoteControlForDevice200ResponseAllOfData {
    /**
     * Code of the device
     * @type {boolean}
     * @memberof RemoteControlForDevice200ResponseAllOfData
     */
    'message_sent'?: boolean;
}
/**
 * 
 * @export
 * @interface RemoteControlHistory
 */
export interface RemoteControlHistory {
    /**
     * 
     * @type {string}
     * @memberof RemoteControlHistory
     */
    'device_id': string;
    /**
     * 
     * @type {string}
     * @memberof RemoteControlHistory
     */
    'point_id'?: string;
    /**
     * 
     * @type {string}
     * @memberof RemoteControlHistory
     */
    'action_name': RemoteControlHistoryActionNameEnum;
    /**
     * 
     * @type {string}
     * @memberof RemoteControlHistory
     */
    'action_state'?: RemoteControlHistoryActionStateEnum;
    /**
     * The id of the action
     * @type {string}
     * @memberof RemoteControlHistory
     */
    'id'?: string;
    /**
     * The id of the user that initiated the action
     * @type {string}
     * @memberof RemoteControlHistory
     */
    'user_id'?: string;
    /**
     * The mandator id of the user that initiated the action
     * @type {string}
     * @memberof RemoteControlHistory
     */
    'mandator_id'?: string;
    /**
     * The date when the action was created
     * @type {string}
     * @memberof RemoteControlHistory
     */
    'created_at'?: string;
    /**
     * The date when the action was updated
     * @type {string}
     * @memberof RemoteControlHistory
     */
    'updated_at'?: string;
}

export const RemoteControlHistoryActionNameEnum = {
    Start: 'start',
    Stop: 'stop',
    Restart: 'restart'
} as const;

export type RemoteControlHistoryActionNameEnum = typeof RemoteControlHistoryActionNameEnum[keyof typeof RemoteControlHistoryActionNameEnum];
export const RemoteControlHistoryActionStateEnum = {
    Pending: 'pending',
    InProgress: 'in_progress',
    Error: 'error',
    Completed: 'completed'
} as const;

export type RemoteControlHistoryActionStateEnum = typeof RemoteControlHistoryActionStateEnum[keyof typeof RemoteControlHistoryActionStateEnum];

/**
 * 
 * @export
 * @interface RemoteControlHistoryBase
 */
export interface RemoteControlHistoryBase {
    /**
     * 
     * @type {string}
     * @memberof RemoteControlHistoryBase
     */
    'device_id': string;
    /**
     * 
     * @type {string}
     * @memberof RemoteControlHistoryBase
     */
    'point_id'?: string;
    /**
     * 
     * @type {string}
     * @memberof RemoteControlHistoryBase
     */
    'action_name': RemoteControlHistoryBaseActionNameEnum;
    /**
     * 
     * @type {string}
     * @memberof RemoteControlHistoryBase
     */
    'action_state'?: RemoteControlHistoryBaseActionStateEnum;
}

export const RemoteControlHistoryBaseActionNameEnum = {
    Start: 'start',
    Stop: 'stop',
    Restart: 'restart'
} as const;

export type RemoteControlHistoryBaseActionNameEnum = typeof RemoteControlHistoryBaseActionNameEnum[keyof typeof RemoteControlHistoryBaseActionNameEnum];
export const RemoteControlHistoryBaseActionStateEnum = {
    Pending: 'pending',
    InProgress: 'in_progress',
    Error: 'error',
    Completed: 'completed'
} as const;

export type RemoteControlHistoryBaseActionStateEnum = typeof RemoteControlHistoryBaseActionStateEnum[keyof typeof RemoteControlHistoryBaseActionStateEnum];

/**
 * 
 * @export
 * @interface RemoteControlHistoryGet
 */
export interface RemoteControlHistoryGet {
    /**
     * The id of the action
     * @type {string}
     * @memberof RemoteControlHistoryGet
     */
    'action_id'?: string;
    /**
     * The state of the action
     * @type {string}
     * @memberof RemoteControlHistoryGet
     */
    'action_state'?: RemoteControlHistoryGetActionStateEnum;
    /**
     * The name of the action
     * @type {string}
     * @memberof RemoteControlHistoryGet
     */
    'action_name'?: RemoteControlHistoryGetActionNameEnum;
    /**
     * 
     * @type {RemoteControlHistoryGetPoint}
     * @memberof RemoteControlHistoryGet
     */
    'point'?: RemoteControlHistoryGetPoint;
    /**
     * 
     * @type {RemoteControlHistoryGetDevice}
     * @memberof RemoteControlHistoryGet
     */
    'device'?: RemoteControlHistoryGetDevice;
}

export const RemoteControlHistoryGetActionStateEnum = {
    Pending: 'pending',
    InProgress: 'in_progress',
    Error: 'error',
    Completed: 'completed'
} as const;

export type RemoteControlHistoryGetActionStateEnum = typeof RemoteControlHistoryGetActionStateEnum[keyof typeof RemoteControlHistoryGetActionStateEnum];
export const RemoteControlHistoryGetActionNameEnum = {
    Start: 'start',
    Stop: 'stop',
    Restart: 'restart'
} as const;

export type RemoteControlHistoryGetActionNameEnum = typeof RemoteControlHistoryGetActionNameEnum[keyof typeof RemoteControlHistoryGetActionNameEnum];

/**
 * 
 * @export
 * @interface RemoteControlHistoryGetDevice
 */
export interface RemoteControlHistoryGetDevice {
    /**
     * 
     * @type {string}
     * @memberof RemoteControlHistoryGetDevice
     */
    'id'?: string;
    /**
     * 
     * @type {boolean}
     * @memberof RemoteControlHistoryGetDevice
     */
    'device_state'?: boolean;
    /**
     * 
     * @type {string}
     * @memberof RemoteControlHistoryGetDevice
     */
    'name'?: string;
}
/**
 * 
 * @export
 * @interface RemoteControlHistoryGetPoint
 */
export interface RemoteControlHistoryGetPoint {
    /**
     * 
     * @type {string}
     * @memberof RemoteControlHistoryGetPoint
     */
    'id'?: string;
    /**
     * 
     * @type {string}
     * @memberof RemoteControlHistoryGetPoint
     */
    'status'?: RemoteControlHistoryGetPointStatusEnum;
    /**
     * The name of the point
     * @type {string}
     * @memberof RemoteControlHistoryGetPoint
     */
    'name'?: string;
}

export const RemoteControlHistoryGetPointStatusEnum = {
    Available: 'available',
    Occupied: 'occupied',
    Reserved: ' reserved',
    OutOfOrder: 'out_of_order',
    InUse: 'in_use',
    Unknown: 'unknown'
} as const;

export type RemoteControlHistoryGetPointStatusEnum = typeof RemoteControlHistoryGetPointStatusEnum[keyof typeof RemoteControlHistoryGetPointStatusEnum];

/**
 * 
 * @export
 * @interface RemoteControlHistoryResponse
 */
export interface RemoteControlHistoryResponse {
    /**
     * 
     * @type {ResponseBaseLinks}
     * @memberof RemoteControlHistoryResponse
     */
    '_links'?: ResponseBaseLinks;
    /**
     * 
     * @type {ResponseBaseMeta}
     * @memberof RemoteControlHistoryResponse
     */
    'meta'?: ResponseBaseMeta;
    /**
     * 
     * @type {Array<RemoteControlHistory>}
     * @memberof RemoteControlHistoryResponse
     */
    'data'?: Array<RemoteControlHistory>;
}
/**
 * 
 * @export
 * @interface RemoteControlRequest
 */
export interface RemoteControlRequest {
    /**
     * Code of the device
     * @type {string}
     * @memberof RemoteControlRequest
     */
    'device_code': string;
    /**
     * The action that needs to be done for a device
     * @type {string}
     * @memberof RemoteControlRequest
     */
    'event_type': RemoteControlRequestEventTypeEnum;
}

export const RemoteControlRequestEventTypeEnum = {
    Restart: 'restart',
    Test: 'test'
} as const;

export type RemoteControlRequestEventTypeEnum = typeof RemoteControlRequestEventTypeEnum[keyof typeof RemoteControlRequestEventTypeEnum];

/**
 * 
 * @export
 * @interface RemoteControlResponse
 */
export interface RemoteControlResponse {
    /**
     * Code of the device
     * @type {boolean}
     * @memberof RemoteControlResponse
     */
    'message_sent'?: boolean;
}
/**
 * 
 * @export
 * @interface ResponseBase
 */
export interface ResponseBase {
    /**
     * 
     * @type {ResponseBaseLinks}
     * @memberof ResponseBase
     */
    '_links'?: ResponseBaseLinks;
    /**
     * 
     * @type {ResponseBaseMeta}
     * @memberof ResponseBase
     */
    'meta'?: ResponseBaseMeta;
}
/**
 * Various links related to pagination. NOT IMPLEMENTED
 * @export
 * @interface ResponseBaseLinks
 */
export interface ResponseBaseLinks {
    /**
     * Link to this page
     * @type {string}
     * @memberof ResponseBaseLinks
     */
    'self'?: string;
    /**
     * Link to the next page
     * @type {string}
     * @memberof ResponseBaseLinks
     */
    'next'?: string;
    /**
     * Link to the previous page
     * @type {string}
     * @memberof ResponseBaseLinks
     */
    'prev'?: string;
    /**
     * Link to the first page
     * @type {string}
     * @memberof ResponseBaseLinks
     */
    'first'?: string;
    /**
     * Link to the last page
     * @type {string}
     * @memberof ResponseBaseLinks
     */
    'last'?: string;
}
/**
 * 
 * @export
 * @interface ResponseBaseMeta
 */
export interface ResponseBaseMeta {
    /**
     * The max amount of items returned
     * @type {number}
     * @memberof ResponseBaseMeta
     */
    'limit'?: number;
    /**
     * Number of items skipped
     * @type {number}
     * @memberof ResponseBaseMeta
     */
    'start'?: number;
    /**
     * Number of items returned
     * @type {number}
     * @memberof ResponseBaseMeta
     */
    'size'?: number;
    /**
     * Total number of items
     * @type {number}
     * @memberof ResponseBaseMeta
     */
    'total'?: number;
}
/**
 * 
 * @export
 * @interface SaveUserCharts200Response
 */
export interface SaveUserCharts200Response {
    /**
     * 
     * @type {ResponseBaseLinks}
     * @memberof SaveUserCharts200Response
     */
    '_links'?: ResponseBaseLinks;
    /**
     * 
     * @type {ResponseBaseMeta}
     * @memberof SaveUserCharts200Response
     */
    'meta'?: ResponseBaseMeta;
    /**
     * 
     * @type {Array<UpdateCharts>}
     * @memberof SaveUserCharts200Response
     */
    'data'?: Array<UpdateCharts>;
}
/**
 * 
 * @export
 * @interface SchemasResponseBase
 */
export interface SchemasResponseBase {
    /**
     * 
     * @type {ResponseBaseLinks}
     * @memberof SchemasResponseBase
     */
    'links'?: ResponseBaseLinks;
    /**
     * 
     * @type {ResponseBaseMeta}
     * @memberof SchemasResponseBase
     */
    'meta'?: ResponseBaseMeta;
    /**
     * 
     * @type {Array<SchemasResponseBaseErrorsInner>}
     * @memberof SchemasResponseBase
     */
    'errors'?: Array<SchemasResponseBaseErrorsInner>;
}
/**
 * 
 * @export
 * @interface SchemasResponseBaseErrorsInner
 */
export interface SchemasResponseBaseErrorsInner {
    /**
     * 
     * @type {string}
     * @memberof SchemasResponseBaseErrorsInner
     */
    'user_message'?: string;
    /**
     * 
     * @type {string}
     * @memberof SchemasResponseBaseErrorsInner
     */
    'internal_message'?: string;
    /**
     * 
     * @type {number}
     * @memberof SchemasResponseBaseErrorsInner
     */
    'code'?: number;
    /**
     * 
     * @type {string}
     * @memberof SchemasResponseBaseErrorsInner
     */
    'more_info'?: string;
}
/**
 * 
 * @export
 * @interface ServiceItem
 */
export interface ServiceItem {
    /**
     * 
     * @type {string}
     * @memberof ServiceItem
     */
    'id'?: string;
    /**
     * The service name as a child of the service parent
     * @type {string}
     * @memberof ServiceItem
     */
    'text'?: string;
}
/**
 * 
 * @export
 * @interface ServiceResponse
 */
export interface ServiceResponse {
    /**
     * 
     * @type {ResponseBaseLinks}
     * @memberof ServiceResponse
     */
    '_links'?: ResponseBaseLinks;
    /**
     * 
     * @type {ResponseBaseMeta}
     * @memberof ServiceResponse
     */
    'meta'?: ResponseBaseMeta;
    /**
     * 
     * @type {Array<TariffService>}
     * @memberof ServiceResponse
     */
    'data'?: Array<TariffService>;
}
/**
 * 
 * @export
 * @interface Services
 */
export interface Services {
    /**
     * Parent service name
     * @type {string}
     * @memberof Services
     */
    'text'?: string;
    /**
     * 
     * @type {Array<SubService>}
     * @memberof Services
     */
    'items'?: Array<SubService>;
}
/**
 * 
 * @export
 * @interface SimpleFilterDescriptor
 */
export interface SimpleFilterDescriptor {
    /**
     * 
     * @type {string}
     * @memberof SimpleFilterDescriptor
     */
    'logic': SimpleFilterDescriptorLogicEnum;
    /**
     * 
     * @type {Array<SimpleFilterDescriptorAllOfFilters>}
     * @memberof SimpleFilterDescriptor
     */
    'filters': Array<SimpleFilterDescriptorAllOfFilters>;
}

export const SimpleFilterDescriptorLogicEnum = {
    Or: 'or',
    And: 'and'
} as const;

export type SimpleFilterDescriptorLogicEnum = typeof SimpleFilterDescriptorLogicEnum[keyof typeof SimpleFilterDescriptorLogicEnum];

/**
 * @type SimpleFilterDescriptorAllOfFilters
 * @export
 */
export type SimpleFilterDescriptorAllOfFilters = FilterDescriptor;

/**
 * 
 * @export
 * @interface Site
 */
export interface Site {
    /**
     * Name of the site
     * @type {string}
     * @memberof Site
     */
    'name': string;
    /**
     * Id of the Mandator where the station is assigned
     * @type {string}
     * @memberof Site
     */
    'mandator_id': string;
    /**
     * Unique HecOne station number
     * @type {string}
     * @memberof Site
     */
    'code': string;
    /**
     * Street address of the site
     * @type {string}
     * @memberof Site
     */
    'street': string;
    /**
     * City address of the site
     * @type {string}
     * @memberof Site
     */
    'city': string;
    /**
     * Country address of the site
     * @type {string}
     * @memberof Site
     */
    'country': string;
    /**
     * Status of the site
     * @type {string}
     * @memberof Site
     */
    'site_state': SiteSiteStateEnum;
    /**
     * Currency used for product prices of the site
     * @type {string}
     * @memberof Site
     */
    'currency'?: string;
    /**
     * Tax percentage applied for product prices of the site. Each product may have a different tax percentage, based on reglementations
     * @type {number}
     * @memberof Site
     */
    'tax_percentage': number;
    /**
     * All charging, parking or refuelling points of the site
     * @type {number}
     * @memberof Site
     */
    'capacity'?: number;
    /**
     * The unique identifier of the site
     * @type {string}
     * @memberof Site
     */
    'id'?: string;
    /**
     * 
     * @type {number}
     * @memberof Site
     */
    'latitude'?: number;
    /**
     * 
     * @type {number}
     * @memberof Site
     */
    'longitude'?: number;
    /**
     * 
     * @type {Array<SiteAllOfOpeningHours>}
     * @memberof Site
     */
    'opening_hours'?: Array<SiteAllOfOpeningHours>;
    /**
     * 
     * @type {string}
     * @memberof Site
     */
    'default_language'?: string;
    /**
     * Timezone of the site
     * @type {string}
     * @memberof Site
     */
    'timezone'?: string;
}

export const SiteSiteStateEnum = {
    Active: 'active',
    Inactive: 'inactive',
    Construction: 'construction',
    Dismantled: 'dismantled'
} as const;

export type SiteSiteStateEnum = typeof SiteSiteStateEnum[keyof typeof SiteSiteStateEnum];

/**
 * 
 * @export
 * @interface SiteAllOfOpeningHours
 */
export interface SiteAllOfOpeningHours {
    /**
     * 
     * @type {string}
     * @memberof SiteAllOfOpeningHours
     */
    'days_availability': SiteAllOfOpeningHoursDaysAvailabilityEnum;
    /**
     * 
     * @type {string}
     * @memberof SiteAllOfOpeningHours
     */
    'start_time': string;
    /**
     * 
     * @type {string}
     * @memberof SiteAllOfOpeningHours
     */
    'end_time': string;
    /**
     * 
     * @type {string}
     * @memberof SiteAllOfOpeningHours
     */
    'id'?: string;
}

export const SiteAllOfOpeningHoursDaysAvailabilityEnum = {
    Alldays: 'alldays',
    Weekdays: 'weekdays',
    Saturday: 'saturday',
    Sunday: 'sunday'
} as const;

export type SiteAllOfOpeningHoursDaysAvailabilityEnum = typeof SiteAllOfOpeningHoursDaysAvailabilityEnum[keyof typeof SiteAllOfOpeningHoursDaysAvailabilityEnum];

/**
 * 
 * @export
 * @interface SiteAssignment
 */
export interface SiteAssignment {
    /**
     * The unique identifier of the template assigned the site
     * @type {string}
     * @memberof SiteAssignment
     */
    'template_id': string;
    /**
     * The status of the assigned template
     * @type {boolean}
     * @memberof SiteAssignment
     */
    'status': boolean;
    /**
     * The type of the template assigned the site
     * @type {string}
     * @memberof SiteAssignment
     */
    'type': SiteAssignmentTypeEnum;
}

export const SiteAssignmentTypeEnum = {
    Invoice: 'invoice',
    Note: 'note'
} as const;

export type SiteAssignmentTypeEnum = typeof SiteAssignmentTypeEnum[keyof typeof SiteAssignmentTypeEnum];

/**
 * 
 * @export
 * @interface SiteBase
 */
export interface SiteBase {
    /**
     * Name of the site
     * @type {string}
     * @memberof SiteBase
     */
    'name': string;
    /**
     * Id of the Mandator where the station is assigned
     * @type {string}
     * @memberof SiteBase
     */
    'mandator_id': string;
    /**
     * Unique HecOne station number
     * @type {string}
     * @memberof SiteBase
     */
    'code': string;
    /**
     * Street address of the site
     * @type {string}
     * @memberof SiteBase
     */
    'street': string;
    /**
     * City address of the site
     * @type {string}
     * @memberof SiteBase
     */
    'city': string;
    /**
     * Country address of the site
     * @type {string}
     * @memberof SiteBase
     */
    'country': string;
    /**
     * Status of the site
     * @type {string}
     * @memberof SiteBase
     */
    'site_state': SiteBaseSiteStateEnum;
    /**
     * Currency used for product prices of the site
     * @type {string}
     * @memberof SiteBase
     */
    'currency'?: string;
    /**
     * Tax percentage applied for product prices of the site. Each product may have a different tax percentage, based on reglementations
     * @type {number}
     * @memberof SiteBase
     */
    'tax_percentage': number;
    /**
     * All charging, parking or refuelling points of the site
     * @type {number}
     * @memberof SiteBase
     */
    'capacity'?: number;
}

export const SiteBaseSiteStateEnum = {
    Active: 'active',
    Inactive: 'inactive',
    Construction: 'construction',
    Dismantled: 'dismantled'
} as const;

export type SiteBaseSiteStateEnum = typeof SiteBaseSiteStateEnum[keyof typeof SiteBaseSiteStateEnum];

/**
 * 
 * @export
 * @interface SiteDetails
 */
export interface SiteDetails {
    /**
     * The name of the site
     * @type {string}
     * @memberof SiteDetails
     */
    'name'?: string;
    /**
     * The merchant id received from TWINT
     * @type {string}
     * @memberof SiteDetails
     */
    'merchant_id': string;
    /**
     * QR code of the site used for TWINT
     * @type {string}
     * @memberof SiteDetails
     */
    'QR_code'?: string;
}
/**
 * 
 * @export
 * @interface SiteExtended
 */
export interface SiteExtended {
    /**
     * Name of the site
     * @type {string}
     * @memberof SiteExtended
     */
    'name': string;
    /**
     * Id of the Mandator where the station is assigned
     * @type {string}
     * @memberof SiteExtended
     */
    'mandator_id': string;
    /**
     * Unique HecOne station number
     * @type {string}
     * @memberof SiteExtended
     */
    'code': string;
    /**
     * Street address of the site
     * @type {string}
     * @memberof SiteExtended
     */
    'street': string;
    /**
     * City address of the site
     * @type {string}
     * @memberof SiteExtended
     */
    'city': string;
    /**
     * Country address of the site
     * @type {string}
     * @memberof SiteExtended
     */
    'country': string;
    /**
     * Status of the site
     * @type {string}
     * @memberof SiteExtended
     */
    'site_state': SiteExtendedSiteStateEnum;
    /**
     * Currency used for product prices of the site
     * @type {string}
     * @memberof SiteExtended
     */
    'currency'?: string;
    /**
     * Tax percentage applied for product prices of the site. Each product may have a different tax percentage, based on reglementations
     * @type {number}
     * @memberof SiteExtended
     */
    'tax_percentage': number;
    /**
     * All charging, parking or refuelling points of the site
     * @type {number}
     * @memberof SiteExtended
     */
    'capacity'?: number;
    /**
     * 
     * @type {Array<OpeningHour>}
     * @memberof SiteExtended
     */
    'opening_hours'?: Array<OpeningHour>;
    /**
     * 
     * @type {Gps}
     * @memberof SiteExtended
     */
    'gps': Gps;
    /**
     * Default language of the site
     * @type {string}
     * @memberof SiteExtended
     */
    'default_language': string;
}

export const SiteExtendedSiteStateEnum = {
    Active: 'active',
    Inactive: 'inactive',
    Construction: 'construction',
    Dismantled: 'dismantled'
} as const;

export type SiteExtendedSiteStateEnum = typeof SiteExtendedSiteStateEnum[keyof typeof SiteExtendedSiteStateEnum];

/**
 * 
 * @export
 * @interface SiteFreeparkingConfigAssignment
 */
export interface SiteFreeparkingConfigAssignment {
    /**
     * 
     * @type {string}
     * @memberof SiteFreeparkingConfigAssignment
     */
    'site_id': string;
    /**
     * 
     * @type {string}
     * @memberof SiteFreeparkingConfigAssignment
     */
    'config_id': string;
}
/**
 * 
 * @export
 * @interface SiteLocation
 */
export interface SiteLocation {
    /**
     * Unique HecOne station number
     * @type {string}
     * @memberof SiteLocation
     */
    'code'?: string;
    /**
     * Name of the site
     * @type {string}
     * @memberof SiteLocation
     */
    'name'?: string;
    /**
     * 
     * @type {Gps}
     * @memberof SiteLocation
     */
    'gps'?: Gps;
    /**
     * 
     * @type {Array<SiteLocationPoints>}
     * @memberof SiteLocation
     */
    'points'?: Array<SiteLocationPoints>;
    /**
     * 
     * @type {Array<SiteLocationDevices>}
     * @memberof SiteLocation
     */
    'devices'?: Array<SiteLocationDevices>;
}
/**
 * 
 * @export
 * @interface SiteLocationDevices
 */
export interface SiteLocationDevices {
    /**
     * The name of the device
     * @type {string}
     * @memberof SiteLocationDevices
     */
    'name'?: string;
    /**
     * 
     * @type {boolean}
     * @memberof SiteLocationDevices
     */
    'device_state'?: boolean;
}
/**
 * 
 * @export
 * @interface SiteLocationPoints
 */
export interface SiteLocationPoints {
    /**
     * The id of the point
     * @type {string}
     * @memberof SiteLocationPoints
     */
    'id'?: string;
    /**
     * The name of the point
     * @type {string}
     * @memberof SiteLocationPoints
     */
    'name'?: string;
    /**
     * 
     * @type {PointStatus}
     * @memberof SiteLocationPoints
     */
    'status'?: PointStatus;
}


/**
 * 
 * @export
 * @interface SitePoints
 */
export interface SitePoints {
    /**
     * The unique identifier of the point
     * @type {string}
     * @memberof SitePoints
     */
    'id'?: string;
    /**
     * Point number
     * @type {number}
     * @memberof SitePoints
     */
    'point_number'?: number;
    /**
     * The name of the point
     * @type {string}
     * @memberof SitePoints
     */
    'name'?: string;
    /**
     * 
     * @type {PointType}
     * @memberof SitePoints
     */
    'type'?: PointType;
    /**
     * 
     * @type {PointStatus}
     * @memberof SitePoints
     */
    'status'?: PointStatus;
    /**
     * Code of the device where the point is assigned
     * @type {string}
     * @memberof SitePoints
     */
    'device_code'?: string;
    /**
     * Id of the device where the point is assigned
     * @type {string}
     * @memberof SitePoints
     */
    'device_id'?: string;
    /**
     * Id of the site where the point is assigned
     * @type {string}
     * @memberof SitePoints
     */
    'site_id'?: string;
    /**
     * the list of product categories of the point without duplicates
     * @type {Set<string>}
     * @memberof SitePoints
     */
    'category'?: Set<string>;
    /**
     * 
     * @type {Gps}
     * @memberof SitePoints
     */
    'gps'?: Gps;
}


/**
 * 
 * @export
 * @interface SitesAssignments
 */
export interface SitesAssignments {
    /**
     * The unique identifier of the site
     * @type {string}
     * @memberof SitesAssignments
     */
    'id': string;
    /**
     * The name of the site
     * @type {string}
     * @memberof SitesAssignments
     */
    'name': string;
    /**
     * The city of the site
     * @type {string}
     * @memberof SitesAssignments
     */
    'city': string;
    /**
     * The name of the invoice template
     * @type {string}
     * @memberof SitesAssignments
     */
    'invoice_name'?: string;
    /**
     * The status of the invoice template
     * @type {boolean}
     * @memberof SitesAssignments
     */
    'invoice_status'?: boolean;
    /**
     * Unique identifier of the invoice template
     * @type {string}
     * @memberof SitesAssignments
     */
    'invoice_id'?: string;
    /**
     * The name of the delivery note template
     * @type {string}
     * @memberof SitesAssignments
     */
    'note_name'?: string;
    /**
     * The status of the delivery note  template
     * @type {boolean}
     * @memberof SitesAssignments
     */
    'note_status'?: boolean;
    /**
     * Unique identifier of the delivery note template
     * @type {string}
     * @memberof SitesAssignments
     */
    'note_id'?: string;
}
/**
 * 
 * @export
 * @interface SitesQR
 */
export interface SitesQR {
    /**
     * The unique identifier of the site
     * @type {string}
     * @memberof SitesQR
     */
    'id': string;
    /**
     * The name of the site
     * @type {string}
     * @memberof SitesQR
     */
    'name': string;
    /**
     * Unique HecOne station number
     * @type {string}
     * @memberof SitesQR
     */
    'code': string;
    /**
     * QR code of the site used for TWINT
     * @type {string}
     * @memberof SitesQR
     */
    'QR_code'?: string;
}
/**
 * 
 * @export
 * @interface StartTransaction
 */
export interface StartTransaction {
    /**
     * The unique identifier of the site
     * @type {string}
     * @memberof StartTransaction
     */
    'site_id': string;
    /**
     * The unique identifier of the point
     * @type {string}
     * @memberof StartTransaction
     */
    'point_id': string;
    /**
     * The reserved amount that was selected by the user
     * @type {number}
     * @memberof StartTransaction
     */
    'reserved_amount': number;
    /**
     * 
     * @type {Array<StartTransactionArticlesInner>}
     * @memberof StartTransaction
     */
    'articles': Array<StartTransactionArticlesInner>;
}
/**
 * 
 * @export
 * @interface StartTransactionArticlesInner
 */
export interface StartTransactionArticlesInner {
    /**
     * The unique identifier of the used product
     * @type {string}
     * @memberof StartTransactionArticlesInner
     */
    'product_id': string;
    /**
     * The category of the used product
     * @type {string}
     * @memberof StartTransactionArticlesInner
     */
    'category': StartTransactionArticlesInnerCategoryEnum;
}

export const StartTransactionArticlesInnerCategoryEnum = {
    Charging: 'charging',
    Refuelling: 'refuelling',
    Parking: 'parking',
    Wash: 'wash'
} as const;

export type StartTransactionArticlesInnerCategoryEnum = typeof StartTransactionArticlesInnerCategoryEnum[keyof typeof StartTransactionArticlesInnerCategoryEnum];

/**
 * 
 * @export
 * @interface StartTransactionResponse
 */
export interface StartTransactionResponse {
    /**
     * Pairing token received from twint
     * @type {string}
     * @memberof StartTransactionResponse
     */
    'token'?: string;
    /**
     * The id of the transaction from HecOne
     * @type {string}
     * @memberof StartTransactionResponse
     */
    'transaction_id'?: string;
    /**
     * The mandator id where the transaction took place
     * @type {string}
     * @memberof StartTransactionResponse
     */
    'mandator_id'?: string;
}
/**
 * 
 * @export
 * @interface StartTwintTransaction200Response
 */
export interface StartTwintTransaction200Response {
    /**
     * 
     * @type {ResponseBaseLinks}
     * @memberof StartTwintTransaction200Response
     */
    '_links'?: ResponseBaseLinks;
    /**
     * 
     * @type {ResponseBaseMeta}
     * @memberof StartTwintTransaction200Response
     */
    'meta'?: ResponseBaseMeta;
    /**
     * 
     * @type {Array<StartTransactionResponse>}
     * @memberof StartTwintTransaction200Response
     */
    'data'?: Array<StartTransactionResponse>;
}
/**
 * 
 * @export
 * @interface StatisticsResponse
 */
export interface StatisticsResponse {
    /**
     * 
     * @type {Array<StatisticsResponseHourlyTransactionsInner>}
     * @memberof StatisticsResponse
     */
    'hourly_transactions'?: Array<StatisticsResponseHourlyTransactionsInner>;
    /**
     * 
     * @type {Array<StatisticsResponsePaymentTypeRankingInner>}
     * @memberof StatisticsResponse
     */
    'payment_type_ranking'?: Array<StatisticsResponsePaymentTypeRankingInner>;
    /**
     * 
     * @type {Array<StatisticsResponsePaymentMediumRankingInner>}
     * @memberof StatisticsResponse
     */
    'payment_medium_ranking'?: Array<StatisticsResponsePaymentMediumRankingInner>;
    /**
     * 
     * @type {Array<StatisticsResponsePaymentSchemeRankingInner>}
     * @memberof StatisticsResponse
     */
    'payment_scheme_ranking'?: Array<StatisticsResponsePaymentSchemeRankingInner>;
    /**
     * 
     * @type {Array<StatisticsResponsePaymentEntryModeRankingInner>}
     * @memberof StatisticsResponse
     */
    'payment_entry_mode_ranking'?: Array<StatisticsResponsePaymentEntryModeRankingInner>;
    /**
     * 
     * @type {Array<StatisticsResponsePaymentFunctionRankingInner>}
     * @memberof StatisticsResponse
     */
    'payment_function_ranking'?: Array<StatisticsResponsePaymentFunctionRankingInner>;
    /**
     * 
     * @type {Array<StatisticsResponseArticleQuantitiesRankingInner>}
     * @memberof StatisticsResponse
     */
    'article_quantities_ranking'?: Array<StatisticsResponseArticleQuantitiesRankingInner>;
    /**
     * 
     * @type {Array<StatisticsResponseSitesAmountRankingInner>}
     * @memberof StatisticsResponse
     */
    'sites_amount_ranking'?: Array<StatisticsResponseSitesAmountRankingInner>;
    /**
     * 
     * @type {Array<StatisticsResponseSitesTransactionsRankingInner>}
     * @memberof StatisticsResponse
     */
    'sites_transactions_ranking'?: Array<StatisticsResponseSitesTransactionsRankingInner>;
    /**
     * 
     * @type {StatisticsResponseTransactionsCount}
     * @memberof StatisticsResponse
     */
    'transactions_count'?: StatisticsResponseTransactionsCount;
    /**
     * 
     * @type {StatisticsResponseTransactionsCount}
     * @memberof StatisticsResponse
     */
    'transactions_total_amount'?: StatisticsResponseTransactionsCount;
}
/**
 * 
 * @export
 * @interface StatisticsResponseArticleQuantitiesRankingInner
 */
export interface StatisticsResponseArticleQuantitiesRankingInner {
    /**
     * 
     * @type {string}
     * @memberof StatisticsResponseArticleQuantitiesRankingInner
     */
    'unit_measure'?: string;
    /**
     * 
     * @type {Array<StatisticsResponseArticleQuantitiesRankingInnerArticlesQuantitiesInner>}
     * @memberof StatisticsResponseArticleQuantitiesRankingInner
     */
    'articles_quantities'?: Array<StatisticsResponseArticleQuantitiesRankingInnerArticlesQuantitiesInner>;
}
/**
 * 
 * @export
 * @interface StatisticsResponseArticleQuantitiesRankingInnerArticlesQuantitiesInner
 */
export interface StatisticsResponseArticleQuantitiesRankingInnerArticlesQuantitiesInner {
    /**
     * 
     * @type {string}
     * @memberof StatisticsResponseArticleQuantitiesRankingInnerArticlesQuantitiesInner
     */
    'article_name'?: string;
    /**
     * 
     * @type {number}
     * @memberof StatisticsResponseArticleQuantitiesRankingInnerArticlesQuantitiesInner
     */
    'quantity'?: number;
}
/**
 * 
 * @export
 * @interface StatisticsResponseHourlyTransactionsInner
 */
export interface StatisticsResponseHourlyTransactionsInner {
    /**
     * 
     * @type {string}
     * @memberof StatisticsResponseHourlyTransactionsInner
     */
    'hour'?: string;
    /**
     * 
     * @type {number}
     * @memberof StatisticsResponseHourlyTransactionsInner
     */
    'transactions'?: number;
}
/**
 * 
 * @export
 * @interface StatisticsResponsePaymentEntryModeRankingInner
 */
export interface StatisticsResponsePaymentEntryModeRankingInner {
    /**
     * 
     * @type {number}
     * @memberof StatisticsResponsePaymentEntryModeRankingInner
     */
    'amount'?: number;
    /**
     * 
     * @type {string}
     * @memberof StatisticsResponsePaymentEntryModeRankingInner
     */
    'payment_entry_mode'?: string;
}
/**
 * 
 * @export
 * @interface StatisticsResponsePaymentFunctionRankingInner
 */
export interface StatisticsResponsePaymentFunctionRankingInner {
    /**
     * 
     * @type {number}
     * @memberof StatisticsResponsePaymentFunctionRankingInner
     */
    'amount'?: number;
    /**
     * 
     * @type {string}
     * @memberof StatisticsResponsePaymentFunctionRankingInner
     */
    'payment_function'?: string;
}
/**
 * 
 * @export
 * @interface StatisticsResponsePaymentMediumRankingInner
 */
export interface StatisticsResponsePaymentMediumRankingInner {
    /**
     * 
     * @type {number}
     * @memberof StatisticsResponsePaymentMediumRankingInner
     */
    'amount'?: number;
    /**
     * 
     * @type {string}
     * @memberof StatisticsResponsePaymentMediumRankingInner
     */
    'payment_medium'?: string;
}
/**
 * 
 * @export
 * @interface StatisticsResponsePaymentSchemeRankingInner
 */
export interface StatisticsResponsePaymentSchemeRankingInner {
    /**
     * 
     * @type {number}
     * @memberof StatisticsResponsePaymentSchemeRankingInner
     */
    'amount'?: number;
    /**
     * 
     * @type {string}
     * @memberof StatisticsResponsePaymentSchemeRankingInner
     */
    'payment_scheme'?: string;
}
/**
 * 
 * @export
 * @interface StatisticsResponsePaymentTypeRankingInner
 */
export interface StatisticsResponsePaymentTypeRankingInner {
    /**
     * 
     * @type {number}
     * @memberof StatisticsResponsePaymentTypeRankingInner
     */
    'amount'?: number;
    /**
     * 
     * @type {string}
     * @memberof StatisticsResponsePaymentTypeRankingInner
     */
    'payment_type'?: string;
}
/**
 * 
 * @export
 * @interface StatisticsResponseSitesAmountRankingInner
 */
export interface StatisticsResponseSitesAmountRankingInner {
    /**
     * 
     * @type {number}
     * @memberof StatisticsResponseSitesAmountRankingInner
     */
    'amount'?: number;
    /**
     * 
     * @type {string}
     * @memberof StatisticsResponseSitesAmountRankingInner
     */
    'site_name'?: string;
}
/**
 * 
 * @export
 * @interface StatisticsResponseSitesTransactionsRankingInner
 */
export interface StatisticsResponseSitesTransactionsRankingInner {
    /**
     * 
     * @type {string}
     * @memberof StatisticsResponseSitesTransactionsRankingInner
     */
    'site_name'?: string;
    /**
     * 
     * @type {number}
     * @memberof StatisticsResponseSitesTransactionsRankingInner
     */
    'transactions'?: number;
}
/**
 * 
 * @export
 * @interface StatisticsResponseTransactionsCount
 */
export interface StatisticsResponseTransactionsCount {
    /**
     * 
     * @type {number}
     * @memberof StatisticsResponseTransactionsCount
     */
    'growth'?: number;
    /**
     * 
     * @type {number}
     * @memberof StatisticsResponseTransactionsCount
     */
    'value'?: number;
}
/**
 * 
 * @export
 * @interface SubService
 */
export interface SubService {
    /**
     * The service name as a child of the service parent
     * @type {string}
     * @memberof SubService
     */
    'text'?: string;
    /**
     * 
     * @type {Array<ServiceItem>}
     * @memberof SubService
     */
    'items'?: Array<ServiceItem>;
}
/**
 * 
 * @export
 * @interface TariffService
 */
export interface TariffService {
    /**
     * 
     * @type {string}
     * @memberof TariffService
     */
    'id'?: string;
    /**
     * Name of the Service
     * @type {string}
     * @memberof TariffService
     */
    'name'?: string;
    /**
     * Identificator of the Queue of the Service. ARN or URL.
     * @type {string}
     * @memberof TariffService
     */
    'target_queue_id'?: string;
}
/**
 * 
 * @export
 * @interface TemplateBase
 */
export interface TemplateBase {
    /**
     * Unique identifier of a mandator
     * @type {string}
     * @memberof TemplateBase
     */
    'mandator_id': string;
    /**
     * The name of the receipt template
     * @type {string}
     * @memberof TemplateBase
     */
    'name': string;
    /**
     * The type of the receipt template
     * @type {string}
     * @memberof TemplateBase
     */
    'type': TemplateBaseTypeEnum;
}

export const TemplateBaseTypeEnum = {
    Invoice: 'invoice',
    Note: 'note'
} as const;

export type TemplateBaseTypeEnum = typeof TemplateBaseTypeEnum[keyof typeof TemplateBaseTypeEnum];

/**
 * 
 * @export
 * @interface TemplateFieldBase
 */
export interface TemplateFieldBase {
    /**
     * The unique identifier of the field from receipt
     * @type {string}
     * @memberof TemplateFieldBase
     */
    'field_id': string;
    /**
     * The order of how the fields are displayed
     * @type {number}
     * @memberof TemplateFieldBase
     */
    'field_order': number;
}
/**
 * 
 * @export
 * @interface TemplateHistoryResponse
 */
export interface TemplateHistoryResponse {
    /**
     * The user that did the change
     * @type {string}
     * @memberof TemplateHistoryResponse
     */
    'user'?: string;
    /**
     * The date and time when the change was made
     * @type {string}
     * @memberof TemplateHistoryResponse
     */
    'change_time'?: string;
    /**
     * The action that was made on the resource
     * @type {string}
     * @memberof TemplateHistoryResponse
     */
    'action'?: TemplateHistoryResponseActionEnum;
    /**
     * 
     * @type {TemplateHistoryResponseBeforeValue}
     * @memberof TemplateHistoryResponse
     */
    'before_value'?: TemplateHistoryResponseBeforeValue;
    /**
     * 
     * @type {TemplateHistoryResponseAfterValue}
     * @memberof TemplateHistoryResponse
     */
    'after_value'?: TemplateHistoryResponseAfterValue;
}

export const TemplateHistoryResponseActionEnum = {
    Create: 'create',
    Delete: 'delete',
    Update: 'update'
} as const;

export type TemplateHistoryResponseActionEnum = typeof TemplateHistoryResponseActionEnum[keyof typeof TemplateHistoryResponseActionEnum];

/**
 * @type TemplateHistoryResponseAfterValue
 * @export
 */
export type TemplateHistoryResponseAfterValue = Array<object> | object;

/**
 * @type TemplateHistoryResponseBeforeValue
 * @export
 */
export type TemplateHistoryResponseBeforeValue = Array<object> | object;

/**
 * 
 * @export
 * @interface Transaction
 */
export interface Transaction {
    /**
     * 
     * @type {string}
     * @memberof Transaction
     */
    'id'?: string;
    /**
     * Has possible two parts. First is number of fiscal printer which can be empty. Second Is a number between 0 and 9999 or 0 and 99999 (Customer-dependent). This number helps with identification, but is not unique on its own (overflow).
     * @type {string}
     * @memberof Transaction
     */
    'terminal_transaction_number'?: string;
    /**
     * Human readable Number to identify the transaction in a certain set of transaction.  This number is not unique in HecOne.
     * @type {number}
     * @memberof Transaction
     */
    'number'?: number;
    /**
     * 
     * @type {TransactionState}
     * @memberof Transaction
     */
    'state'?: TransactionState;
    /**
     * 
     * @type {Invoice}
     * @memberof Transaction
     */
    'invoice'?: Invoice;
    /**
     * 
     * @type {TransactionIdentification}
     * @memberof Transaction
     */
    'identification'?: TransactionIdentification;
    /**
     * 
     * @type {string}
     * @memberof Transaction
     */
    'site_id'?: string;
    /**
     * 
     * @type {string}
     * @memberof Transaction
     */
    'mandator_id'?: string;
    /**
     * 
     * @type {string}
     * @memberof Transaction
     */
    'point_id'?: string;
    /**
     * 
     * @type {Meta}
     * @memberof Transaction
     */
    'meta'?: Meta;
    /**
     * 
     * @type {CustomerData}
     * @memberof Transaction
     */
    'customer_data'?: CustomerData;
    /**
     * 
     * @type {Array<TransactionExtArticlesInner>}
     * @memberof Transaction
     */
    'articles'?: Array<TransactionExtArticlesInner>;
    /**
     * This log is used to trace various events and status within this object.
     * @type {Array<ItemlogEntry>}
     * @memberof Transaction
     */
    'log'?: Array<ItemlogEntry>;
    /**
     * 
     * @type {Array<Payment>}
     * @memberof Transaction
     */
    'payments'?: Array<Payment>;
    /**
     * Amount the user has paid including VAT.
     * @type {number}
     * @memberof Transaction
     */
    'payed_amount'?: number;
    /**
     * 
     * @type {Receipt}
     * @memberof Transaction
     */
    'receipt'?: Receipt;
}


/**
 * 
 * @export
 * @interface TransactionClient
 */
export interface TransactionClient {
    /**
     * The unique identifier of the user. For user type the email.
     * @type {string}
     * @memberof TransactionClient
     */
    'id'?: string;
    /**
     * 
     * @type {string}
     * @memberof TransactionClient
     */
    'type'?: TransactionClientTypeEnum;
}

export const TransactionClientTypeEnum = {
    User: 'user',
    Device: 'device'
} as const;

export type TransactionClientTypeEnum = typeof TransactionClientTypeEnum[keyof typeof TransactionClientTypeEnum];

/**
 * event to trigger state changes. Confirmed means user has want to buy the selected articles. abort means user want to stop the transaction.
 * @export
 * @enum {string}
 */

export const TransactionEvent = {
    Release: 'release',
    Abort: 'abort',
    Paid: 'paid'
} as const;

export type TransactionEvent = typeof TransactionEvent[keyof typeof TransactionEvent];


/**
 * 
 * @export
 * @interface TransactionExt
 */
export interface TransactionExt {
    /**
     * 
     * @type {string}
     * @memberof TransactionExt
     */
    'id': string;
    /**
     * Has possible two parts. First is number of fiscal printer which can be empty. Second Is a number between 0 and 9999 or 0 and 99999 (Customer-dependent). This number helps with identification, but is not unique on its own (overflow).
     * @type {string}
     * @memberof TransactionExt
     */
    'terminal_transaction_number'?: string;
    /**
     * 
     * @type {TransactionState}
     * @memberof TransactionExt
     */
    'state': TransactionState;
    /**
     * 
     * @type {Invoice}
     * @memberof TransactionExt
     */
    'invoice'?: Invoice;
    /**
     * 
     * @type {NotUsedIdentification}
     * @memberof TransactionExt
     */
    'identification'?: NotUsedIdentification;
    /**
     * 
     * @type {string}
     * @memberof TransactionExt
     */
    'created_at'?: string;
    /**
     * 
     * @type {PointRef}
     * @memberof TransactionExt
     */
    'point'?: PointRef;
    /**
     * 
     * @type {Array<TransactionExtArticlesInner>}
     * @memberof TransactionExt
     */
    'articles'?: Array<TransactionExtArticlesInner>;
    /**
     * 
     * @type {Array<Payment>}
     * @memberof TransactionExt
     */
    'payments'?: Array<Payment>;
    /**
     * Amount the user has paid including VAT.
     * @type {number}
     * @memberof TransactionExt
     */
    'payed_amount'?: number;
    /**
     * 
     * @type {Receipt}
     * @memberof TransactionExt
     */
    'receipt'?: Receipt;
}


/**
 * 
 * @export
 * @interface TransactionExtArticlesInner
 */
export interface TransactionExtArticlesInner {
    /**
     * 
     * @type {ArticleCategory}
     * @memberof TransactionExtArticlesInner
     */
    'category': ArticleCategory;
    /**
     * This is a unique ID referencing to this sold article
     * @type {string}
     * @memberof TransactionExtArticlesInner
     */
    'id'?: string;
    /**
     * 
     * @type {ArticleState}
     * @memberof TransactionExtArticlesInner
     */
    'state'?: ArticleState;
    /**
     * This log is used to trace various events and status within this object.
     * @type {Array<ItemlogEntry>}
     * @memberof TransactionExtArticlesInner
     */
    'log'?: Array<ItemlogEntry>;
    /**
     * This is ID is referencing the sold product, which is setup on this site.
     * @type {string}
     * @memberof TransactionExtArticlesInner
     */
    'product_id'?: string;
    /**
     * Amount the user has to pay including VAT.in currency unit.
     * @type {number}
     * @memberof TransactionExtArticlesInner
     */
    'amount'?: number;
    /**
     * Amount of VAT. Has to be lower than total amount. Returned in  currency unit.
     * @type {number}
     * @memberof TransactionExtArticlesInner
     */
    'tax_amount'?: number;
    /**
     * The factor the vat has. Unit is percent (%) this an factor of 100
     * @type {number}
     * @memberof TransactionExtArticlesInner
     */
    'tax_percentage'?: number;
    /**
     * currency code in ISO 4217
     * @type {string}
     * @memberof TransactionExtArticlesInner
     */
    'currency'?: string;
    /**
     * Start of the Article usage/consumption
     * @type {string}
     * @memberof TransactionExtArticlesInner
     */
    'start_time'?: string;
    /**
     * End of the Article usage/consumption
     * @type {string}
     * @memberof TransactionExtArticlesInner
     */
    'end_time'?: string;
    /**
     * 
     * @type {string}
     * @memberof TransactionExtArticlesInner
     */
    'code'?: string;
    /**
     * 
     * @type {number}
     * @memberof TransactionExtArticlesInner
     */
    'unit_price'?: number;
    /**
     * 
     * @type {string}
     * @memberof TransactionExtArticlesInner
     */
    'unit_measure'?: string;
    /**
     * how much l was consumed
     * @type {number}
     * @memberof TransactionExtArticlesInner
     */
    'quantity'?: number;
    /**
     * The Maximum amount the user wants to spend Lower or equals reserved_amount
     * @type {number}
     * @memberof TransactionExtArticlesInner
     */
    'max_amount'?: number;
    /**
     * The Maximum amount the user wants to spend Lower or equals reserved_amount
     * @type {number}
     * @memberof TransactionExtArticlesInner
     */
    'max_quantity'?: number;
    /**
     * SOC = State Of Charge. This field is optionally sent by the charging point and gives the state of the battery level of the vehicle
     * @type {string}
     * @memberof TransactionExtArticlesInner
     */
    'soc'?: string;
    /**
     * 
     * @type {string}
     * @memberof TransactionExtArticlesInner
     */
    'name'?: string;
    /**
     * this field can be used for additional information like for a washing product
     * @type {Array<string>}
     * @memberof TransactionExtArticlesInner
     */
    'description'?: Array<string>;
    /**
     * Client has to validate, that the unit price, it displays on the W&M approved display is OK. Generally an accepted solution is to do this based on checksums.
     * @type {string}
     * @memberof TransactionExtArticlesInner
     */
    'signature'?: string;
}


/**
 * Response for constraints error
 * @export
 * @interface TransactionIdErrorResponse
 */
export interface TransactionIdErrorResponse {
    /**
     * List of error details
     * @type {Array<TransactionIdErrorResponseDataInner>}
     * @memberof TransactionIdErrorResponse
     */
    'data'?: Array<TransactionIdErrorResponseDataInner>;
    /**
     * List of general errors
     * @type {Array<TransactionIdErrorResponseErrorsInner>}
     * @memberof TransactionIdErrorResponse
     */
    'errors'?: Array<TransactionIdErrorResponseErrorsInner>;
}
/**
 * 
 * @export
 * @interface TransactionIdErrorResponseDataInner
 */
export interface TransactionIdErrorResponseDataInner {
    /**
     * Unique identifier for the error
     * @type {string}
     * @memberof TransactionIdErrorResponseDataInner
     */
    'id'?: string;
    /**
     * State of the error
     * @type {string}
     * @memberof TransactionIdErrorResponseDataInner
     */
    'state'?: string;
    /**
     * List of required fields for the error
     * @type {string}
     * @memberof TransactionIdErrorResponseDataInner
     */
    'required_fields'?: string;
}
/**
 * 
 * @export
 * @interface TransactionIdErrorResponseErrorsInner
 */
export interface TransactionIdErrorResponseErrorsInner {
    /**
     * Error message
     * @type {string}
     * @memberof TransactionIdErrorResponseErrorsInner
     */
    'user_message'?: string;
    /**
     * Error code
     * @type {number}
     * @memberof TransactionIdErrorResponseErrorsInner
     */
    'code'?: number;
}
/**
 * 
 * @export
 * @interface TransactionIdentification
 */
export interface TransactionIdentification {
    /**
     * ID of the identified customer
     * @type {string}
     * @memberof TransactionIdentification
     */
    'id'?: string;
    /**
     * Car numberplate of the user
     * @type {string}
     * @memberof TransactionIdentification
     */
    'cnp'?: string;
    /**
     * add more as required
     * @type {string}
     * @memberof TransactionIdentification
     */
    'todo'?: string;
}
/**
 * 
 * @export
 * @interface TransactionListResponse
 */
export interface TransactionListResponse {
    /**
     * 
     * @type {ResponseBaseLinks}
     * @memberof TransactionListResponse
     */
    '_links'?: ResponseBaseLinks;
    /**
     * 
     * @type {ResponseBaseMeta}
     * @memberof TransactionListResponse
     */
    'meta'?: ResponseBaseMeta;
    /**
     * 
     * @type {Array<TransactionExt>}
     * @memberof TransactionListResponse
     */
    'data'?: Array<TransactionExt>;
}
/**
 * 
 * @export
 * @interface TransactionRequest
 */
export interface TransactionRequest {
    /**
     * 
     * @type {Transaction}
     * @memberof TransactionRequest
     */
    'data'?: Transaction;
    /**
     * 
     * @type {TransactionEvent}
     * @memberof TransactionRequest
     */
    'event'?: TransactionEvent;
}


/**
 * 
 * @export
 * @interface TransactionResponse
 */
export interface TransactionResponse {
    /**
     * 
     * @type {ResponseBaseLinks}
     * @memberof TransactionResponse
     */
    '_links'?: ResponseBaseLinks;
    /**
     * 
     * @type {ResponseBaseMeta}
     * @memberof TransactionResponse
     */
    'meta'?: ResponseBaseMeta;
    /**
     * 
     * @type {Transaction}
     * @memberof TransactionResponse
     */
    'data'?: Transaction;
}
/**
 * 
 * @export
 * @enum {string}
 */

export const TransactionState = {
    Created: 'created',
    Pending: 'pending',
    Consumed: 'consumed',
    Completed: 'completed',
    Declined: 'declined',
    Error: 'error'
} as const;

export type TransactionState = typeof TransactionState[keyof typeof TransactionState];


/**
 * 
 * @export
 * @interface TransactionStatistic
 */
export interface TransactionStatistic {
    /**
     * 
     * @type {TransactionStatisticTotalAmountResponse}
     * @memberof TransactionStatistic
     */
    'transactions_total_amount'?: TransactionStatisticTotalAmountResponse;
    /**
     * 
     * @type {TransactionStatisticCountResponse}
     * @memberof TransactionStatistic
     */
    'transactions_count'?: TransactionStatisticCountResponse;
    /**
     * 
     * @type {TransactionStatisticHourlyTransactionsResponse}
     * @memberof TransactionStatistic
     */
    'hourly_transactions'?: TransactionStatisticHourlyTransactionsResponse;
    /**
     * 
     * @type {TransactionStatisticSitesRankingResponse}
     * @memberof TransactionStatistic
     */
    'sites_ranking'?: TransactionStatisticSitesRankingResponse;
    /**
     * 
     * @type {TransactionStatisticPaymentMethodsRankingResponse}
     * @memberof TransactionStatistic
     */
    'payment_methods_ranking'?: TransactionStatisticPaymentMethodsRankingResponse;
}
/**
 * 
 * @export
 * @interface TransactionStatisticCount
 */
export interface TransactionStatisticCount {
    /**
     * 
     * @type {number}
     * @memberof TransactionStatisticCount
     */
    'value': number;
    /**
     * 
     * @type {number}
     * @memberof TransactionStatisticCount
     */
    'growth': number;
}
/**
 * 
 * @export
 * @interface TransactionStatisticCountResponse
 */
export interface TransactionStatisticCountResponse {
    /**
     * 
     * @type {ResponseBaseLinks}
     * @memberof TransactionStatisticCountResponse
     */
    '_links'?: ResponseBaseLinks;
    /**
     * 
     * @type {ResponseBaseMeta}
     * @memberof TransactionStatisticCountResponse
     */
    'meta'?: ResponseBaseMeta;
    /**
     * 
     * @type {TransactionStatisticCount}
     * @memberof TransactionStatisticCountResponse
     */
    'data'?: TransactionStatisticCount;
}
/**
 * 
 * @export
 * @interface TransactionStatisticHourlyTransactions
 */
export interface TransactionStatisticHourlyTransactions {
    /**
     * 
     * @type {number}
     * @memberof TransactionStatisticHourlyTransactions
     */
    'hour': number;
    /**
     * 
     * @type {number}
     * @memberof TransactionStatisticHourlyTransactions
     */
    'transactions': number;
}
/**
 * 
 * @export
 * @interface TransactionStatisticHourlyTransactionsResponse
 */
export interface TransactionStatisticHourlyTransactionsResponse {
    /**
     * 
     * @type {ResponseBaseLinks}
     * @memberof TransactionStatisticHourlyTransactionsResponse
     */
    '_links'?: ResponseBaseLinks;
    /**
     * 
     * @type {ResponseBaseMeta}
     * @memberof TransactionStatisticHourlyTransactionsResponse
     */
    'meta'?: ResponseBaseMeta;
    /**
     * 
     * @type {Array<TransactionStatisticHourlyTransactions>}
     * @memberof TransactionStatisticHourlyTransactionsResponse
     */
    'data'?: Array<TransactionStatisticHourlyTransactions>;
}
/**
 * 
 * @export
 * @interface TransactionStatisticPaymentMethodsRanking
 */
export interface TransactionStatisticPaymentMethodsRanking {
    /**
     * 
     * @type {string}
     * @memberof TransactionStatisticPaymentMethodsRanking
     */
    'payment_method': string;
    /**
     * 
     * @type {number}
     * @memberof TransactionStatisticPaymentMethodsRanking
     */
    'amount': number;
}
/**
 * 
 * @export
 * @interface TransactionStatisticPaymentMethodsRankingResponse
 */
export interface TransactionStatisticPaymentMethodsRankingResponse {
    /**
     * 
     * @type {ResponseBaseLinks}
     * @memberof TransactionStatisticPaymentMethodsRankingResponse
     */
    '_links'?: ResponseBaseLinks;
    /**
     * 
     * @type {ResponseBaseMeta}
     * @memberof TransactionStatisticPaymentMethodsRankingResponse
     */
    'meta'?: ResponseBaseMeta;
    /**
     * 
     * @type {Array<TransactionStatisticPaymentMethodsRanking>}
     * @memberof TransactionStatisticPaymentMethodsRankingResponse
     */
    'data'?: Array<TransactionStatisticPaymentMethodsRanking>;
}
/**
 * 
 * @export
 * @interface TransactionStatisticResponse
 */
export interface TransactionStatisticResponse {
    /**
     * 
     * @type {ResponseBaseLinks}
     * @memberof TransactionStatisticResponse
     */
    '_links'?: ResponseBaseLinks;
    /**
     * 
     * @type {ResponseBaseMeta}
     * @memberof TransactionStatisticResponse
     */
    'meta'?: ResponseBaseMeta;
    /**
     * 
     * @type {TransactionStatistic}
     * @memberof TransactionStatisticResponse
     */
    'data'?: TransactionStatistic;
}
/**
 * 
 * @export
 * @interface TransactionStatisticSitesRanking
 */
export interface TransactionStatisticSitesRanking {
    /**
     * 
     * @type {string}
     * @memberof TransactionStatisticSitesRanking
     */
    'site_name': string;
    /**
     * 
     * @type {number}
     * @memberof TransactionStatisticSitesRanking
     */
    'amount': number;
}
/**
 * 
 * @export
 * @interface TransactionStatisticSitesRankingResponse
 */
export interface TransactionStatisticSitesRankingResponse {
    /**
     * 
     * @type {ResponseBaseLinks}
     * @memberof TransactionStatisticSitesRankingResponse
     */
    '_links'?: ResponseBaseLinks;
    /**
     * 
     * @type {ResponseBaseMeta}
     * @memberof TransactionStatisticSitesRankingResponse
     */
    'meta'?: ResponseBaseMeta;
    /**
     * 
     * @type {Array<TransactionStatisticSitesRanking>}
     * @memberof TransactionStatisticSitesRankingResponse
     */
    'data'?: Array<TransactionStatisticSitesRanking>;
}
/**
 * 
 * @export
 * @interface TransactionStatisticTotalAmount
 */
export interface TransactionStatisticTotalAmount {
    /**
     * 
     * @type {number}
     * @memberof TransactionStatisticTotalAmount
     */
    'value': number;
    /**
     * 
     * @type {number}
     * @memberof TransactionStatisticTotalAmount
     */
    'growth': number;
}
/**
 * 
 * @export
 * @interface TransactionStatisticTotalAmountResponse
 */
export interface TransactionStatisticTotalAmountResponse {
    /**
     * 
     * @type {ResponseBaseLinks}
     * @memberof TransactionStatisticTotalAmountResponse
     */
    '_links'?: ResponseBaseLinks;
    /**
     * 
     * @type {ResponseBaseMeta}
     * @memberof TransactionStatisticTotalAmountResponse
     */
    'meta'?: ResponseBaseMeta;
    /**
     * 
     * @type {TransactionStatisticTotalAmount}
     * @memberof TransactionStatisticTotalAmountResponse
     */
    'data'?: TransactionStatisticTotalAmount;
}
/**
 * 
 * @export
 * @interface TransactionV3Request
 */
export interface TransactionV3Request {
    /**
     * The unique identifier of the site for which the transaction is taking place
     * @type {string}
     * @memberof TransactionV3Request
     */
    'site_id': string;
    /**
     * The unique identifier of the point for which the transaction is taking place
     * @type {string}
     * @memberof TransactionV3Request
     */
    'point_id'?: string;
    /**
     * 
     * @type {Array<Articles>}
     * @memberof TransactionV3Request
     */
    'articles'?: Array<Articles>;
    /**
     * 
     * @type {Array<Payments>}
     * @memberof TransactionV3Request
     */
    'payments'?: Array<Payments>;
    /**
     * 
     * @type {CustomerDataObject}
     * @memberof TransactionV3Request
     */
    'customer_data'?: CustomerDataObject;
    /**
     * 
     * @type {ReceiptV3}
     * @memberof TransactionV3Request
     */
    'receipt'?: ReceiptV3;
    /**
     * Has possible two parts. First is number of fiscal printer which can be empty. Second Is a number between 0 and 9999 or 0 and 99999 (Customer-dependent). This number helps with identification, but is not unique on its own (overflow).
     * @type {string}
     * @memberof TransactionV3Request
     */
    'terminal_transaction_number'?: string;
}
/**
 * 
 * @export
 * @interface TransactionsResponse
 */
export interface TransactionsResponse {
    /**
     * The unique identifier of the site for which the transaction is taking place
     * @type {string}
     * @memberof TransactionsResponse
     */
    'site_id': string;
    /**
     * The unique identifier of the point for which the transaction is taking place
     * @type {string}
     * @memberof TransactionsResponse
     */
    'point_id'?: string;
    /**
     * 
     * @type {Array<Articles>}
     * @memberof TransactionsResponse
     */
    'articles'?: Array<Articles>;
    /**
     * 
     * @type {Array<Payments>}
     * @memberof TransactionsResponse
     */
    'payments'?: Array<Payments>;
    /**
     * 
     * @type {CustomerDataObject}
     * @memberof TransactionsResponse
     */
    'customer_data'?: CustomerDataObject;
    /**
     * 
     * @type {ReceiptV3}
     * @memberof TransactionsResponse
     */
    'receipt'?: ReceiptV3;
    /**
     * Has possible two parts. First is number of fiscal printer which can be empty. Second Is a number between 0 and 9999 or 0 and 99999 (Customer-dependent). This number helps with identification, but is not unique on its own (overflow).
     * @type {string}
     * @memberof TransactionsResponse
     */
    'terminal_transaction_number'?: string;
    /**
     * The unique identifier of the transaction
     * @type {string}
     * @memberof TransactionsResponse
     */
    'id'?: string;
    /**
     * The unique identifier of the mandator
     * @type {string}
     * @memberof TransactionsResponse
     */
    'mandator_id'?: string;
    /**
     * The state of the transaction
     * @type {string}
     * @memberof TransactionsResponse
     */
    'state'?: TransactionsResponseStateEnum;
    /**
     * 
     * @type {InvoiceV3}
     * @memberof TransactionsResponse
     */
    'invoice'?: InvoiceV3;
    /**
     * 
     * @type {TransactionClient}
     * @memberof TransactionsResponse
     */
    'client'?: TransactionClient;
}

export const TransactionsResponseStateEnum = {
    Created: 'created',
    Pending: 'pending',
    Consumed: 'consumed',
    Completed: 'completed',
    Declined: 'declined',
    Error: 'error'
} as const;

export type TransactionsResponseStateEnum = typeof TransactionsResponseStateEnum[keyof typeof TransactionsResponseStateEnum];

/**
 * 
 * @export
 * @interface TransactionsResponseExtended
 */
export interface TransactionsResponseExtended {
    /**
     * The unique identifier of the transaction
     * @type {string}
     * @memberof TransactionsResponseExtended
     */
    'id'?: string;
    /**
     * The unique identifier of the mandator
     * @type {string}
     * @memberof TransactionsResponseExtended
     */
    'mandator_id'?: string;
    /**
     * The state of the transaction
     * @type {string}
     * @memberof TransactionsResponseExtended
     */
    'state'?: TransactionsResponseExtendedStateEnum;
    /**
     * 
     * @type {InvoiceV3}
     * @memberof TransactionsResponseExtended
     */
    'invoice'?: InvoiceV3;
    /**
     * 
     * @type {TransactionClient}
     * @memberof TransactionsResponseExtended
     */
    'client'?: TransactionClient;
    /**
     * The unique identifier of the site for which the transaction is taking place
     * @type {string}
     * @memberof TransactionsResponseExtended
     */
    'site_id': string;
    /**
     * The unique identifier of the point for which the transaction is taking place
     * @type {string}
     * @memberof TransactionsResponseExtended
     */
    'point_id'?: string;
    /**
     * 
     * @type {Array<Articles>}
     * @memberof TransactionsResponseExtended
     */
    'articles'?: Array<Articles>;
    /**
     * 
     * @type {Array<Payments>}
     * @memberof TransactionsResponseExtended
     */
    'payments'?: Array<Payments>;
    /**
     * 
     * @type {CustomerDataObject}
     * @memberof TransactionsResponseExtended
     */
    'customer_data'?: CustomerDataObject;
    /**
     * 
     * @type {ReceiptV3}
     * @memberof TransactionsResponseExtended
     */
    'receipt'?: ReceiptV3;
    /**
     * Has possible two parts. First is number of fiscal printer which can be empty. Second Is a number between 0 and 9999 or 0 and 99999 (Customer-dependent). This number helps with identification, but is not unique on its own (overflow).
     * @type {string}
     * @memberof TransactionsResponseExtended
     */
    'terminal_transaction_number'?: string;
    /**
     * 
     * @type {TransactionsResponseExtendedAllOfSite}
     * @memberof TransactionsResponseExtended
     */
    'site'?: TransactionsResponseExtendedAllOfSite;
    /**
     * 
     * @type {TransactionsResponseExtendedAllOfDevice}
     * @memberof TransactionsResponseExtended
     */
    'device'?: TransactionsResponseExtendedAllOfDevice;
    /**
     * 
     * @type {TransactionsResponseExtendedAllOfPoint}
     * @memberof TransactionsResponseExtended
     */
    'point'?: TransactionsResponseExtendedAllOfPoint;
    /**
     * 
     * @type {Array<TransactionsResponseExtendedAllOfProducts>}
     * @memberof TransactionsResponseExtended
     */
    'products'?: Array<TransactionsResponseExtendedAllOfProducts>;
    /**
     * 
     * @type {TransactionsResponseExtendedAllOfCardIssuer}
     * @memberof TransactionsResponseExtended
     */
    'card_issuer'?: TransactionsResponseExtendedAllOfCardIssuer;
    /**
     * 
     * @type {Array<LogInner>}
     * @memberof TransactionsResponseExtended
     */
    'log'?: Array<LogInner>;
}

export const TransactionsResponseExtendedStateEnum = {
    Created: 'created',
    Pending: 'pending',
    Consumed: 'consumed',
    Completed: 'completed',
    Declined: 'declined',
    Error: 'error'
} as const;

export type TransactionsResponseExtendedStateEnum = typeof TransactionsResponseExtendedStateEnum[keyof typeof TransactionsResponseExtendedStateEnum];

/**
 * 
 * @export
 * @interface TransactionsResponseExtendedAllOfCardIssuer
 */
export interface TransactionsResponseExtendedAllOfCardIssuer {
    /**
     * 
     * @type {string}
     * @memberof TransactionsResponseExtendedAllOfCardIssuer
     */
    'vehicle_number'?: string;
    /**
     * 
     * @type {string}
     * @memberof TransactionsResponseExtendedAllOfCardIssuer
     */
    'driver_name'?: string;
}
/**
 * 
 * @export
 * @interface TransactionsResponseExtendedAllOfDevice
 */
export interface TransactionsResponseExtendedAllOfDevice {
    /**
     * 
     * @type {string}
     * @memberof TransactionsResponseExtendedAllOfDevice
     */
    'name'?: string;
    /**
     * 
     * @type {string}
     * @memberof TransactionsResponseExtendedAllOfDevice
     */
    'code'?: string;
}
/**
 * 
 * @export
 * @interface TransactionsResponseExtendedAllOfPoint
 */
export interface TransactionsResponseExtendedAllOfPoint {
    /**
     * 
     * @type {string}
     * @memberof TransactionsResponseExtendedAllOfPoint
     */
    'name'?: string;
    /**
     * 
     * @type {number}
     * @memberof TransactionsResponseExtendedAllOfPoint
     */
    'number'?: number;
}
/**
 * 
 * @export
 * @interface TransactionsResponseExtendedAllOfProducts
 */
export interface TransactionsResponseExtendedAllOfProducts {
    /**
     * 
     * @type {string}
     * @memberof TransactionsResponseExtendedAllOfProducts
     */
    'id'?: string;
    /**
     * 
     * @type {string}
     * @memberof TransactionsResponseExtendedAllOfProducts
     */
    'code'?: string;
}
/**
 * 
 * @export
 * @interface TransactionsResponseExtendedAllOfSite
 */
export interface TransactionsResponseExtendedAllOfSite {
    /**
     * 
     * @type {string}
     * @memberof TransactionsResponseExtendedAllOfSite
     */
    'name'?: string;
    /**
     * 
     * @type {string}
     * @memberof TransactionsResponseExtendedAllOfSite
     */
    'code'?: string;
    /**
     * 
     * @type {string}
     * @memberof TransactionsResponseExtendedAllOfSite
     */
    'timezone'?: string;
    /**
     * 
     * @type {string}
     * @memberof TransactionsResponseExtendedAllOfSite
     */
    'street'?: string;
    /**
     * 
     * @type {string}
     * @memberof TransactionsResponseExtendedAllOfSite
     */
    'city'?: string;
}
/**
 * 
 * @export
 * @interface TransactionsResponseResponse
 */
export interface TransactionsResponseResponse {
    /**
     * 
     * @type {ResponseBaseLinks}
     * @memberof TransactionsResponseResponse
     */
    '_links'?: ResponseBaseLinks;
    /**
     * 
     * @type {ResponseBaseMeta}
     * @memberof TransactionsResponseResponse
     */
    'meta'?: ResponseBaseMeta;
    /**
     * 
     * @type {Array<TransactionsResponseResponseAllOfData>}
     * @memberof TransactionsResponseResponse
     */
    'data'?: Array<TransactionsResponseResponseAllOfData>;
}
/**
 * @type TransactionsResponseResponseAllOfData
 * @export
 */
export type TransactionsResponseResponseAllOfData = TransactionsResponse | TransactionsResponseExtended;

/**
 * 
 * @export
 * @interface UpdateCharts
 */
export interface UpdateCharts {
    /**
     * The unique identifier of the configuration
     * @type {string}
     * @memberof UpdateCharts
     */
    'id'?: string;
    /**
     * 
     * @type {Array<UpdateChartsConfigurationInner>}
     * @memberof UpdateCharts
     */
    'configuration': Array<UpdateChartsConfigurationInner>;
}
/**
 * 
 * @export
 * @interface UpdateChartsConfigurationInner
 */
export interface UpdateChartsConfigurationInner {
    /**
     * Term key in order to translate chart name
     * @type {string}
     * @memberof UpdateChartsConfigurationInner
     */
    'system_name': string;
    /**
     * Available chart types are pie, column, donut, bar
     * @type {string}
     * @memberof UpdateChartsConfigurationInner
     */
    'type': string;
    /**
     * 
     * @type {Array<string>}
     * @memberof UpdateChartsConfigurationInner
     */
    'series_colors'?: Array<string>;
}
/**
 * 
 * @export
 * @interface UpdateFooter
 */
export interface UpdateFooter {
    /**
     * 
     * @type {string}
     * @memberof UpdateFooter
     */
    'id': string;
    /**
     * The url where the advertising logo is stored. Represents the image displayed in footer
     * @type {string}
     * @memberof UpdateFooter
     */
    'advertising_logo'?: string;
    /**
     * The position of the advertising logo
     * @type {string}
     * @memberof UpdateFooter
     */
    'advertising_alignment'?: UpdateFooterAdvertisingAlignmentEnum;
    /**
     * Not implemented
     * @type {boolean}
     * @memberof UpdateFooter
     */
    'show_QRCode'?: boolean;
    /**
     * 
     * @type {string}
     * @memberof UpdateFooter
     */
    'description': string;
}

export const UpdateFooterAdvertisingAlignmentEnum = {
    Right: 'right',
    Center: 'center',
    Left: 'left'
} as const;

export type UpdateFooterAdvertisingAlignmentEnum = typeof UpdateFooterAdvertisingAlignmentEnum[keyof typeof UpdateFooterAdvertisingAlignmentEnum];

/**
 * 
 * @export
 * @interface UpdateHeader
 */
export interface UpdateHeader {
    /**
     * 
     * @type {string}
     * @memberof UpdateHeader
     */
    'id': string;
    /**
     * The url where the logo is stored. Represents the image displayed on receipt header
     * @type {string}
     * @memberof UpdateHeader
     */
    'logo': string;
    /**
     * Title of the receipt
     * @type {string}
     * @memberof UpdateHeader
     */
    'title'?: string;
    /**
     * The position of the logo image
     * @type {string}
     * @memberof UpdateHeader
     */
    'logo_alignment': UpdateHeaderLogoAlignmentEnum;
    /**
     * 
     * @type {string}
     * @memberof UpdateHeader
     */
    'contact_details': string;
}

export const UpdateHeaderLogoAlignmentEnum = {
    Right: 'right',
    Left: 'left'
} as const;

export type UpdateHeaderLogoAlignmentEnum = typeof UpdateHeaderLogoAlignmentEnum[keyof typeof UpdateHeaderLogoAlignmentEnum];

/**
 * 
 * @export
 * @interface UpdateManufacturer
 */
export interface UpdateManufacturer {
    /**
     * Name of the manufacturer
     * @type {string}
     * @memberof UpdateManufacturer
     */
    'name': string;
    /**
     * 
     * @type {Array<string>}
     * @memberof UpdateManufacturer
     */
    'protocol_ids': Array<string>;
    /**
     * 
     * @type {Array<string>}
     * @memberof UpdateManufacturer
     */
    'deleted_protocols'?: Array<string>;
    /**
     * 
     * @type {Array<string>}
     * @memberof UpdateManufacturer
     */
    'deleted_models'?: Array<string>;
    /**
     * 
     * @type {Array<UpdateManufacturerAllOfModels>}
     * @memberof UpdateManufacturer
     */
    'models'?: Array<UpdateManufacturerAllOfModels>;
}
/**
 * 
 * @export
 * @interface UpdateManufacturerAllOfModels
 */
export interface UpdateManufacturerAllOfModels {
    /**
     * Device type name
     * @type {string}
     * @memberof UpdateManufacturerAllOfModels
     */
    'system_name': string;
    /**
     * How many seconds should we wait for Start after we send a Remote Start
     * @type {number}
     * @memberof UpdateManufacturerAllOfModels
     */
    'timeout': number;
    /**
     * Number of missed heartbeats -> after this number of missed heartbeats we close the websocket connection
     * @type {number}
     * @memberof UpdateManufacturerAllOfModels
     */
    'heartbeat_retries': number;
    /**
     * Device model hearbeat interval
     * @type {number}
     * @memberof UpdateManufacturerAllOfModels
     */
    'heartbeat_interval': number;
    /**
     * How many times to try calling the Remote Start and Stop
     * @type {number}
     * @memberof UpdateManufacturerAllOfModels
     */
    'remote_call_retries': number;
    /**
     * Device model meter value interval
     * @type {number}
     * @memberof UpdateManufacturerAllOfModels
     */
    'meter_value_interval'?: number;
    /**
     * This field represents whether a charging device was tested and can be offered to end customers
     * @type {string}
     * @memberof UpdateManufacturerAllOfModels
     */
    'test'?: UpdateManufacturerAllOfModelsTestEnum;
    /**
     * The version of the model that has been tested
     * @type {string}
     * @memberof UpdateManufacturerAllOfModels
     */
    'version'?: string;
    /**
     * 
     * @type {string}
     * @memberof UpdateManufacturerAllOfModels
     */
    'id'?: string;
}

export const UpdateManufacturerAllOfModelsTestEnum = {
    InTest: 'in_test',
    Open: 'open',
    Tested: 'tested'
} as const;

export type UpdateManufacturerAllOfModelsTestEnum = typeof UpdateManufacturerAllOfModelsTestEnum[keyof typeof UpdateManufacturerAllOfModelsTestEnum];

/**
 * 
 * @export
 * @interface UpdatePrices
 */
export interface UpdatePrices {
    /**
     * The unique identifier of the product
     * @type {string}
     * @memberof UpdatePrices
     */
    'product_id': string;
    /**
     * The price used for product
     * @type {number}
     * @memberof UpdatePrices
     */
    'unit_price'?: number;
    /**
     * The price which will be used starting with activation_timestamp
     * @type {number}
     * @memberof UpdatePrices
     */
    'new_unit_price'?: number;
    /**
     * The date and time when the new_unit_price will be active
     * @type {string}
     * @memberof UpdatePrices
     */
    'activation_timestamp'?: string;
    /**
     * If this field is true, the new unit price will be set immediately
     * @type {boolean}
     * @memberof UpdatePrices
     */
    'now'?: boolean;
    /**
     * The name of the product
     * @type {string}
     * @memberof UpdatePrices
     */
    'product_name'?: string;
}
/**
 * 
 * @export
 * @interface UpdateRemoteControlActionHistoryRequest
 */
export interface UpdateRemoteControlActionHistoryRequest {
    /**
     * 
     * @type {string}
     * @memberof UpdateRemoteControlActionHistoryRequest
     */
    'action_state'?: string;
}
/**
 * 
 * @export
 * @interface UpdateRemoteControlActionResponse
 */
export interface UpdateRemoteControlActionResponse {
    /**
     * 
     * @type {ResponseBaseLinks}
     * @memberof UpdateRemoteControlActionResponse
     */
    '_links'?: ResponseBaseLinks;
    /**
     * 
     * @type {ResponseBaseMeta}
     * @memberof UpdateRemoteControlActionResponse
     */
    'meta'?: ResponseBaseMeta;
    /**
     * 
     * @type {Array<number>}
     * @memberof UpdateRemoteControlActionResponse
     */
    'data'?: Array<UpdateRemoteControlActionResponseDataEnum>;
}

export const UpdateRemoteControlActionResponseDataEnum = {
    NUMBER_1: 1,
    NUMBER_0: 0
} as const;

export type UpdateRemoteControlActionResponseDataEnum = typeof UpdateRemoteControlActionResponseDataEnum[keyof typeof UpdateRemoteControlActionResponseDataEnum];

/**
 * 
 * @export
 * @interface UpdateSite200Response
 */
export interface UpdateSite200Response {
    /**
     * 
     * @type {ResponseBaseLinks}
     * @memberof UpdateSite200Response
     */
    '_links'?: ResponseBaseLinks;
    /**
     * 
     * @type {ResponseBaseMeta}
     * @memberof UpdateSite200Response
     */
    'meta'?: ResponseBaseMeta;
    /**
     * 
     * @type {Array<number>}
     * @memberof UpdateSite200Response
     */
    'data'?: Array<number>;
}
/**
 * 
 * @export
 * @interface UpdateSitePricesRequest
 */
export interface UpdateSitePricesRequest {
    /**
     * 
     * @type {Array<UpdatePrices>}
     * @memberof UpdateSitePricesRequest
     */
    'prices'?: Array<UpdatePrices>;
}
/**
 * 
 * @export
 * @interface UpdateTemplateRequest
 */
export interface UpdateTemplateRequest {
    /**
     * Unique identifier of a mandator
     * @type {string}
     * @memberof UpdateTemplateRequest
     */
    'mandator_id': string;
    /**
     * The name of the receipt template
     * @type {string}
     * @memberof UpdateTemplateRequest
     */
    'name': string;
    /**
     * The type of the receipt template
     * @type {string}
     * @memberof UpdateTemplateRequest
     */
    'type': UpdateTemplateRequestTypeEnum;
    /**
     * 
     * @type {UpdateHeader}
     * @memberof UpdateTemplateRequest
     */
    'header': UpdateHeader;
    /**
     * 
     * @type {UpdateFooter}
     * @memberof UpdateTemplateRequest
     */
    'footer': UpdateFooter;
    /**
     * 
     * @type {Array<TemplateFieldBase>}
     * @memberof UpdateTemplateRequest
     */
    'template_fields': Array<TemplateFieldBase>;
}

export const UpdateTemplateRequestTypeEnum = {
    Invoice: 'invoice',
    Note: 'note'
} as const;

export type UpdateTemplateRequestTypeEnum = typeof UpdateTemplateRequestTypeEnum[keyof typeof UpdateTemplateRequestTypeEnum];

/**
 * 
 * @export
 * @interface UpdateUser
 */
export interface UpdateUser {
    /**
     * The first name of the user that registers
     * @type {string}
     * @memberof UpdateUser
     */
    'first_name'?: string;
    /**
     * The last name of the user that registers
     * @type {string}
     * @memberof UpdateUser
     */
    'last_name'?: string;
    /**
     * The gender of the user that registers; m - masculine, f - feminine, u - diverse
     * @type {string}
     * @memberof UpdateUser
     */
    'gender'?: UpdateUserGenderEnum;
    /**
     * The phone number of the user that registers
     * @type {string}
     * @memberof UpdateUser
     */
    'phone'?: string;
    /**
     * The id of the user\'s chosen default mandator. When the user logs in the web application, it has the preferred mandator set as default
     * @type {string}
     * @memberof UpdateUser
     */
    'preferred_mandator'?: string;
    /**
     * The default page number chosen by the user. When the user logs in the web application, the landing page is the page number set
     * @type {string}
     * @memberof UpdateUser
     */
    'page'?: string;
    /**
     * A comment note about the user
     * @type {string}
     * @memberof UpdateUser
     */
    'comment'?: string;
    /**
     * If it is set to true, the landing page and preferred mandator are taken into consideration
     * @type {boolean}
     * @memberof UpdateUser
     */
    'user_action'?: boolean;
    /**
     * The status of the user which can be enabled or disabled. If it is disabled, the user can\'t log in until it gets enabled back
     * @type {boolean}
     * @memberof UpdateUser
     */
    'enabled'?: boolean;
    /**
     * 
     * @type {MandatorRoleLink}
     * @memberof UpdateUser
     */
    'mandator_role_link': MandatorRoleLink;
    /**
     * This field is used to identify if the user wants to use dark mode in the web application
     * @type {boolean}
     * @memberof UpdateUser
     */
    'dark_mode'?: boolean;
}

export const UpdateUserGenderEnum = {
    F: 'f',
    M: 'm',
    U: 'u'
} as const;

export type UpdateUserGenderEnum = typeof UpdateUserGenderEnum[keyof typeof UpdateUserGenderEnum];

/**
 * 
 * @export
 * @interface UpdateUserList
 */
export interface UpdateUserList {
    /**
     * 
     * @type {Array<string>}
     * @memberof UpdateUserList
     */
    'users': Array<string>;
    /**
     * The status of the user which can be enabled or disabled. If it is disabled, the user can\'t log in until it gets enabled back
     * @type {boolean}
     * @memberof UpdateUserList
     */
    'enabled': boolean;
}
/**
 * 
 * @export
 * @interface UpdateUserList200Response
 */
export interface UpdateUserList200Response {
    /**
     * 
     * @type {ResponseBaseLinks}
     * @memberof UpdateUserList200Response
     */
    '_links'?: ResponseBaseLinks;
    /**
     * 
     * @type {ResponseBaseMeta}
     * @memberof UpdateUserList200Response
     */
    'meta'?: ResponseBaseMeta;
    /**
     * 
     * @type {Array<UpdateUserList>}
     * @memberof UpdateUserList200Response
     */
    'data'?: Array<UpdateUserList>;
}
/**
 * 
 * @export
 * @interface UpdatedPoductPower
 */
export interface UpdatedPoductPower {
    /**
     * The unique identifier of the products power
     * @type {string}
     * @memberof UpdatedPoductPower
     */
    'power_id': string;
    /**
     * This field is used to set the maximum power used for charging station
     * @type {number}
     * @memberof UpdatedPoductPower
     */
    'max_power'?: number;
}
/**
 * 
 * @export
 * @interface User
 */
export interface User {
    /**
     * The first name of the user that registers
     * @type {string}
     * @memberof User
     */
    'first_name'?: string;
    /**
     * The last name of the user that registers
     * @type {string}
     * @memberof User
     */
    'last_name'?: string;
    /**
     * The gender of the user that registers; m - masculine, f - feminine, u - diverse
     * @type {string}
     * @memberof User
     */
    'gender'?: UserGenderEnum;
    /**
     * The phone number of the user that registers
     * @type {string}
     * @memberof User
     */
    'phone'?: string;
    /**
     * The id of the user\'s chosen default mandator. When the user logs in the web application, it has the preferred mandator set as default
     * @type {string}
     * @memberof User
     */
    'preferred_mandator'?: string;
    /**
     * The default page number chosen by the user. When the user logs in the web application, the landing page is the page number set
     * @type {string}
     * @memberof User
     */
    'page'?: string;
    /**
     * A comment note about the user
     * @type {string}
     * @memberof User
     */
    'comment'?: string;
    /**
     * If it is set to true, the landing page and preferred mandator are taken into consideration
     * @type {boolean}
     * @memberof User
     */
    'user_action'?: boolean;
    /**
     * The unique identifier of the mandator following a UUID v4 format
     * @type {string}
     * @memberof User
     */
    'id'?: string;
    /**
     * The unique identifier of the user following a UUID v4 format received from cognito
     * @type {string}
     * @memberof User
     */
    'sub'?: string;
    /**
     * The email address used for registration by the user
     * @type {string}
     * @memberof User
     */
    'email': string;
    /**
     * Defines the state of the user account
     * @type {string}
     * @memberof User
     */
    'user_status'?: string;
    /**
     * The status of the user which can be enabled or disabled. If it is disabled, the user can\'t log in until it gets enabled back
     * @type {boolean}
     * @memberof User
     */
    'enabled'?: boolean;
    /**
     * 
     * @type {Array<MandatorRoleLinkResponse>}
     * @memberof User
     */
    'mandator_role_link': Array<MandatorRoleLinkResponse>;
    /**
     * This field is set if the user accepted the eula
     * @type {boolean}
     * @memberof User
     */
    'accepted_eula'?: boolean;
    /**
     * This field is used to identify if the user wants to use dark mode in the web application
     * @type {boolean}
     * @memberof User
     */
    'dark_mode'?: boolean;
}

export const UserGenderEnum = {
    F: 'f',
    M: 'm',
    U: 'u'
} as const;

export type UserGenderEnum = typeof UserGenderEnum[keyof typeof UserGenderEnum];

/**
 * 
 * @export
 * @interface UserBase
 */
export interface UserBase {
    /**
     * The first name of the user that registers
     * @type {string}
     * @memberof UserBase
     */
    'first_name'?: string;
    /**
     * The last name of the user that registers
     * @type {string}
     * @memberof UserBase
     */
    'last_name'?: string;
    /**
     * The gender of the user that registers; m - masculine, f - feminine, u - diverse
     * @type {string}
     * @memberof UserBase
     */
    'gender'?: UserBaseGenderEnum;
    /**
     * The phone number of the user that registers
     * @type {string}
     * @memberof UserBase
     */
    'phone'?: string;
    /**
     * The id of the user\'s chosen default mandator. When the user logs in the web application, it has the preferred mandator set as default
     * @type {string}
     * @memberof UserBase
     */
    'preferred_mandator'?: string;
    /**
     * The default page number chosen by the user. When the user logs in the web application, the landing page is the page number set
     * @type {string}
     * @memberof UserBase
     */
    'page'?: string;
    /**
     * A comment note about the user
     * @type {string}
     * @memberof UserBase
     */
    'comment'?: string;
    /**
     * If it is set to true, the landing page and preferred mandator are taken into consideration
     * @type {boolean}
     * @memberof UserBase
     */
    'user_action'?: boolean;
}

export const UserBaseGenderEnum = {
    F: 'f',
    M: 'm',
    U: 'u'
} as const;

export type UserBaseGenderEnum = typeof UserBaseGenderEnum[keyof typeof UserBaseGenderEnum];

/**
 * 
 * @export
 * @interface UserBasePost
 */
export interface UserBasePost {
    /**
     * The first name of the user that registers
     * @type {string}
     * @memberof UserBasePost
     */
    'first_name'?: string;
    /**
     * The last name of the user that registers
     * @type {string}
     * @memberof UserBasePost
     */
    'last_name'?: string;
    /**
     * The gender of the user that registers; m - masculine, f - feminine, u - diverse
     * @type {string}
     * @memberof UserBasePost
     */
    'gender'?: UserBasePostGenderEnum;
    /**
     * The phone number of the user that registers
     * @type {string}
     * @memberof UserBasePost
     */
    'phone'?: string;
    /**
     * The id of the user\'s chosen default mandator. When the user logs in the web application, it has the preferred mandator set as default
     * @type {string}
     * @memberof UserBasePost
     */
    'preferred_mandator'?: string;
    /**
     * The default page number chosen by the user. When the user logs in the web application, the landing page is the page number set
     * @type {string}
     * @memberof UserBasePost
     */
    'page'?: string;
    /**
     * A comment note about the user
     * @type {string}
     * @memberof UserBasePost
     */
    'comment'?: string;
    /**
     * If it is set to true, the landing page and preferred mandator are taken into consideration
     * @type {boolean}
     * @memberof UserBasePost
     */
    'user_action'?: boolean;
    /**
     * 
     * @type {string}
     * @memberof UserBasePost
     */
    'email': string;
    /**
     * The status of the user which can be enabled or disabled. If it is disabled, the user can\'t log in until it gets enabled back
     * @type {boolean}
     * @memberof UserBasePost
     */
    'enabled'?: boolean;
    /**
     * 
     * @type {Array<MandatorRoleLink>}
     * @memberof UserBasePost
     */
    'mandator_role_link': Array<MandatorRoleLink>;
}

export const UserBasePostGenderEnum = {
    F: 'f',
    M: 'm',
    U: 'u'
} as const;

export type UserBasePostGenderEnum = typeof UserBasePostGenderEnum[keyof typeof UserBasePostGenderEnum];

/**
 * 
 * @export
 * @interface UserLayouts
 */
export interface UserLayouts {
    /**
     * The unique id of the user layouts
     * @type {string}
     * @memberof UserLayouts
     */
    'id'?: string;
    /**
     * The dashboard\'s layouts for different breakpoints
     * @type {string}
     * @memberof UserLayouts
     */
    'layouts': string;
}
/**
 * 
 * @export
 * @interface UserResponse
 */
export interface UserResponse {
    /**
     * The first name of the user that registers
     * @type {string}
     * @memberof UserResponse
     */
    'first_name'?: string;
    /**
     * The last name of the user that registers
     * @type {string}
     * @memberof UserResponse
     */
    'last_name'?: string;
    /**
     * The gender of the user that registers; m - masculine, f - feminine, u - diverse
     * @type {string}
     * @memberof UserResponse
     */
    'gender'?: UserResponseGenderEnum;
    /**
     * The phone number of the user that registers
     * @type {string}
     * @memberof UserResponse
     */
    'phone'?: string;
    /**
     * The id of the user\'s chosen default mandator. When the user logs in the web application, it has the preferred mandator set as default
     * @type {string}
     * @memberof UserResponse
     */
    'preferred_mandator'?: string;
    /**
     * The default page number chosen by the user. When the user logs in the web application, the landing page is the page number set
     * @type {string}
     * @memberof UserResponse
     */
    'page'?: string;
    /**
     * A comment note about the user
     * @type {string}
     * @memberof UserResponse
     */
    'comment'?: string;
    /**
     * If it is set to true, the landing page and preferred mandator are taken into consideration
     * @type {boolean}
     * @memberof UserResponse
     */
    'user_action'?: boolean;
    /**
     * The email of the user used as username in cognito
     * @type {string}
     * @memberof UserResponse
     */
    'id'?: string;
    /**
     * The email address used for registration by the user
     * @type {string}
     * @memberof UserResponse
     */
    'email'?: string;
    /**
     * The following values are the available: UNCONFIRMED, CONFIRMED, EXTERNAL_PROVIDER, ARCHIVED, UNKNOWN, RESET_REQUIRED, FORCE_CHANGE_PASSWORD
     * @type {string}
     * @memberof UserResponse
     */
    'user_status'?: string;
    /**
     * The status of the user which can be enabled or disabled. If it is disabled, the user can\'t log in until it gets enabled back
     * @type {boolean}
     * @memberof UserResponse
     */
    'enabled'?: boolean;
}

export const UserResponseGenderEnum = {
    F: 'f',
    M: 'm',
    U: 'u'
} as const;

export type UserResponseGenderEnum = typeof UserResponseGenderEnum[keyof typeof UserResponseGenderEnum];


/**
 * AuthApi - axios parameter creator
 * @export
 */
export const AuthApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Login a user to the system
         * @param {LoginUser} loginUser User credentials that needs to login. The user can login with either the pair username/password or client_id/client_secret
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        loginUser: async (loginUser: LoginUser, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'loginUser' is not null or undefined
            assertParamExists('loginUser', 'loginUser', loginUser)
            const localVarPath = `/users/login`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Authorization required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(loginUser, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Returns the new token based on the refresh token
         * @param {RefreshToken} refreshToken Refresh token of the user
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        refreshToken: async (refreshToken: RefreshToken, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'refreshToken' is not null or undefined
            assertParamExists('refreshToken', 'refreshToken', refreshToken)
            const localVarPath = `/users/refresh_token`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Authorization required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(refreshToken, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * AuthApi - functional programming interface
 * @export
 */
export const AuthApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = AuthApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary Login a user to the system
         * @param {LoginUser} loginUser User credentials that needs to login. The user can login with either the pair username/password or client_id/client_secret
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async loginUser(loginUser: LoginUser, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<LoginResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.loginUser(loginUser, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['AuthApi.loginUser']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @summary Returns the new token based on the refresh token
         * @param {RefreshToken} refreshToken Refresh token of the user
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async refreshToken(refreshToken: RefreshToken, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<RefreshTokenAnswer>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.refreshToken(refreshToken, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['AuthApi.refreshToken']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
    }
};

/**
 * AuthApi - factory interface
 * @export
 */
export const AuthApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = AuthApiFp(configuration)
    return {
        /**
         * 
         * @summary Login a user to the system
         * @param {LoginUser} loginUser User credentials that needs to login. The user can login with either the pair username/password or client_id/client_secret
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        loginUser(loginUser: LoginUser, options?: RawAxiosRequestConfig): AxiosPromise<LoginResponse> {
            return localVarFp.loginUser(loginUser, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Returns the new token based on the refresh token
         * @param {RefreshToken} refreshToken Refresh token of the user
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        refreshToken(refreshToken: RefreshToken, options?: RawAxiosRequestConfig): AxiosPromise<RefreshTokenAnswer> {
            return localVarFp.refreshToken(refreshToken, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * AuthApi - object-oriented interface
 * @export
 * @class AuthApi
 * @extends {BaseAPI}
 */
export class AuthApi extends BaseAPI {
    /**
     * 
     * @summary Login a user to the system
     * @param {LoginUser} loginUser User credentials that needs to login. The user can login with either the pair username/password or client_id/client_secret
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AuthApi
     */
    public loginUser(loginUser: LoginUser, options?: RawAxiosRequestConfig) {
        return AuthApiFp(this.configuration).loginUser(loginUser, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Returns the new token based on the refresh token
     * @param {RefreshToken} refreshToken Refresh token of the user
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AuthApi
     */
    public refreshToken(refreshToken: RefreshToken, options?: RawAxiosRequestConfig) {
        return AuthApiFp(this.configuration).refreshToken(refreshToken, options).then((request) => request(this.axios, this.basePath));
    }
}



/**
 * CardsApi - axios parameter creator
 * @export
 */
export const CardsApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Adds a new card to the system
         * @param {CreateCardRequest} createCardRequest Card object that needs to be added to the system
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createCard: async (createCardRequest: CreateCardRequest, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'createCardRequest' is not null or undefined
            assertParamExists('createCard', 'createCardRequest', createCardRequest)
            const localVarPath = `/payments/cards`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Authorization required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(createCardRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Delete a card by id
         * @param {string} cardId Id of the card that has to be deleted
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteCard: async (cardId: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'cardId' is not null or undefined
            assertParamExists('deleteCard', 'cardId', cardId)
            const localVarPath = `/payments/cards/{card_id}`
                .replace(`{${"card_id"}}`, encodeURIComponent(String(cardId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Authorization required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Returns a single card details
         * @param {string} cardId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getCard: async (cardId: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'cardId' is not null or undefined
            assertParamExists('getCard', 'cardId', cardId)
            const localVarPath = `/payments/cards/{card_id}`
                .replace(`{${"card_id"}}`, encodeURIComponent(String(cardId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Authorization required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get all cards for current mandator
         * @param {string} mandatorId 
         * @param {string} [sort] order ascending or descending the list of cards by a property. For descending order the sort field should be prefixed with a minus(\&#39;-\&#39;)
         * @param {number} [pageOffset] number of items that should be skipped and not included in the response
         * @param {number} [pageLimit] number of items to be included in the response
         * @param {GetSitesFilterParameter} [filter] Filters should be sent as encoded URI. Filterable items: number, payment_card_id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getCards: async (mandatorId: string, sort?: string, pageOffset?: number, pageLimit?: number, filter?: GetSitesFilterParameter, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'mandatorId' is not null or undefined
            assertParamExists('getCards', 'mandatorId', mandatorId)
            const localVarPath = `/payments/cards`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Authorization required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (mandatorId !== undefined) {
                localVarQueryParameter['mandator_id'] = mandatorId;
            }

            if (sort !== undefined) {
                localVarQueryParameter['sort'] = sort;
            }

            if (pageOffset !== undefined) {
                localVarQueryParameter['page[offset]'] = pageOffset;
            }

            if (pageLimit !== undefined) {
                localVarQueryParameter['page[limit]'] = pageLimit;
            }

            if (filter !== undefined) {
                for (const [key, value] of Object.entries(filter)) {
                    localVarQueryParameter[key] = value;
                }
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Updates a card by id
         * @param {string} cardId Id of the card that has to be updated
         * @param {CardBase} cardBase 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateCard: async (cardId: string, cardBase: CardBase, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'cardId' is not null or undefined
            assertParamExists('updateCard', 'cardId', cardId)
            // verify required parameter 'cardBase' is not null or undefined
            assertParamExists('updateCard', 'cardBase', cardBase)
            const localVarPath = `/payments/cards/{card_id}`
                .replace(`{${"card_id"}}`, encodeURIComponent(String(cardId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Authorization required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(cardBase, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * CardsApi - functional programming interface
 * @export
 */
export const CardsApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = CardsApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary Adds a new card to the system
         * @param {CreateCardRequest} createCardRequest Card object that needs to be added to the system
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async createCard(createCardRequest: CreateCardRequest, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<CreateCard201Response>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.createCard(createCardRequest, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['CardsApi.createCard']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @summary Delete a card by id
         * @param {string} cardId Id of the card that has to be deleted
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async deleteCard(cardId: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<UpdateSite200Response>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.deleteCard(cardId, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['CardsApi.deleteCard']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @summary Returns a single card details
         * @param {string} cardId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getCard(cardId: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<CreateCard201Response>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getCard(cardId, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['CardsApi.getCard']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @summary Get all cards for current mandator
         * @param {string} mandatorId 
         * @param {string} [sort] order ascending or descending the list of cards by a property. For descending order the sort field should be prefixed with a minus(\&#39;-\&#39;)
         * @param {number} [pageOffset] number of items that should be skipped and not included in the response
         * @param {number} [pageLimit] number of items to be included in the response
         * @param {GetSitesFilterParameter} [filter] Filters should be sent as encoded URI. Filterable items: number, payment_card_id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getCards(mandatorId: string, sort?: string, pageOffset?: number, pageLimit?: number, filter?: GetSitesFilterParameter, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<GetCards200Response>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getCards(mandatorId, sort, pageOffset, pageLimit, filter, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['CardsApi.getCards']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @summary Updates a card by id
         * @param {string} cardId Id of the card that has to be updated
         * @param {CardBase} cardBase 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updateCard(cardId: string, cardBase: CardBase, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<UpdateSite200Response>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.updateCard(cardId, cardBase, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['CardsApi.updateCard']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
    }
};

/**
 * CardsApi - factory interface
 * @export
 */
export const CardsApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = CardsApiFp(configuration)
    return {
        /**
         * 
         * @summary Adds a new card to the system
         * @param {CreateCardRequest} createCardRequest Card object that needs to be added to the system
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createCard(createCardRequest: CreateCardRequest, options?: RawAxiosRequestConfig): AxiosPromise<CreateCard201Response> {
            return localVarFp.createCard(createCardRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Delete a card by id
         * @param {string} cardId Id of the card that has to be deleted
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteCard(cardId: string, options?: RawAxiosRequestConfig): AxiosPromise<UpdateSite200Response> {
            return localVarFp.deleteCard(cardId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Returns a single card details
         * @param {string} cardId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getCard(cardId: string, options?: RawAxiosRequestConfig): AxiosPromise<CreateCard201Response> {
            return localVarFp.getCard(cardId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get all cards for current mandator
         * @param {string} mandatorId 
         * @param {string} [sort] order ascending or descending the list of cards by a property. For descending order the sort field should be prefixed with a minus(\&#39;-\&#39;)
         * @param {number} [pageOffset] number of items that should be skipped and not included in the response
         * @param {number} [pageLimit] number of items to be included in the response
         * @param {GetSitesFilterParameter} [filter] Filters should be sent as encoded URI. Filterable items: number, payment_card_id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getCards(mandatorId: string, sort?: string, pageOffset?: number, pageLimit?: number, filter?: GetSitesFilterParameter, options?: RawAxiosRequestConfig): AxiosPromise<GetCards200Response> {
            return localVarFp.getCards(mandatorId, sort, pageOffset, pageLimit, filter, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Updates a card by id
         * @param {string} cardId Id of the card that has to be updated
         * @param {CardBase} cardBase 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateCard(cardId: string, cardBase: CardBase, options?: RawAxiosRequestConfig): AxiosPromise<UpdateSite200Response> {
            return localVarFp.updateCard(cardId, cardBase, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * CardsApi - object-oriented interface
 * @export
 * @class CardsApi
 * @extends {BaseAPI}
 */
export class CardsApi extends BaseAPI {
    /**
     * 
     * @summary Adds a new card to the system
     * @param {CreateCardRequest} createCardRequest Card object that needs to be added to the system
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CardsApi
     */
    public createCard(createCardRequest: CreateCardRequest, options?: RawAxiosRequestConfig) {
        return CardsApiFp(this.configuration).createCard(createCardRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Delete a card by id
     * @param {string} cardId Id of the card that has to be deleted
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CardsApi
     */
    public deleteCard(cardId: string, options?: RawAxiosRequestConfig) {
        return CardsApiFp(this.configuration).deleteCard(cardId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Returns a single card details
     * @param {string} cardId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CardsApi
     */
    public getCard(cardId: string, options?: RawAxiosRequestConfig) {
        return CardsApiFp(this.configuration).getCard(cardId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get all cards for current mandator
     * @param {string} mandatorId 
     * @param {string} [sort] order ascending or descending the list of cards by a property. For descending order the sort field should be prefixed with a minus(\&#39;-\&#39;)
     * @param {number} [pageOffset] number of items that should be skipped and not included in the response
     * @param {number} [pageLimit] number of items to be included in the response
     * @param {GetSitesFilterParameter} [filter] Filters should be sent as encoded URI. Filterable items: number, payment_card_id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CardsApi
     */
    public getCards(mandatorId: string, sort?: string, pageOffset?: number, pageLimit?: number, filter?: GetSitesFilterParameter, options?: RawAxiosRequestConfig) {
        return CardsApiFp(this.configuration).getCards(mandatorId, sort, pageOffset, pageLimit, filter, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Updates a card by id
     * @param {string} cardId Id of the card that has to be updated
     * @param {CardBase} cardBase 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CardsApi
     */
    public updateCard(cardId: string, cardBase: CardBase, options?: RawAxiosRequestConfig) {
        return CardsApiFp(this.configuration).updateCard(cardId, cardBase, options).then((request) => request(this.axios, this.basePath));
    }
}



/**
 * ConstraintsApi - axios parameter creator
 * @export
 */
export const ConstraintsApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * Get response of constraints based on transaction id
         * @summary get constraints response
         * @param {string} transactionId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getConstraints: async (transactionId: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'transactionId' is not null or undefined
            assertParamExists('getConstraints', 'transactionId', transactionId)
            const localVarPath = `/v3/constraints/{transaction_id}`
                .replace(`{${"transaction_id"}}`, encodeURIComponent(String(transactionId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Authorization required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * ConstraintsApi - functional programming interface
 * @export
 */
export const ConstraintsApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = ConstraintsApiAxiosParamCreator(configuration)
    return {
        /**
         * Get response of constraints based on transaction id
         * @summary get constraints response
         * @param {string} transactionId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getConstraints(transactionId: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ConstraintsResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getConstraints(transactionId, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['ConstraintsApi.getConstraints']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
    }
};

/**
 * ConstraintsApi - factory interface
 * @export
 */
export const ConstraintsApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = ConstraintsApiFp(configuration)
    return {
        /**
         * Get response of constraints based on transaction id
         * @summary get constraints response
         * @param {string} transactionId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getConstraints(transactionId: string, options?: RawAxiosRequestConfig): AxiosPromise<ConstraintsResponse> {
            return localVarFp.getConstraints(transactionId, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * ConstraintsApi - object-oriented interface
 * @export
 * @class ConstraintsApi
 * @extends {BaseAPI}
 */
export class ConstraintsApi extends BaseAPI {
    /**
     * Get response of constraints based on transaction id
     * @summary get constraints response
     * @param {string} transactionId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ConstraintsApi
     */
    public getConstraints(transactionId: string, options?: RawAxiosRequestConfig) {
        return ConstraintsApiFp(this.configuration).getConstraints(transactionId, options).then((request) => request(this.axios, this.basePath));
    }
}



/**
 * DeviceConfigurationApi - axios parameter creator
 * @export
 */
export const DeviceConfigurationApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Return a list of device logs. This is a paginated request.
         * @param {string} deviceCode unique code identifying a device, also known as evse id
         * @param {string} [sort] order the list of device logs ascending or descending by a property. For descending order the sort field should be prefixed with a minus(\&#39;-\&#39;)
         * @param {number} [pageOffset] number of items that should be skipped and not included in the response
         * @param {number} [pageLimit] number of items to be included in the response
         * @param {GetSitesFilterParameter} [filter] Filters should be sent as encoded URI. Filterable items: level, message_type, point_number, time range
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getDeviceLogs: async (deviceCode: string, sort?: string, pageOffset?: number, pageLimit?: number, filter?: GetSitesFilterParameter, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'deviceCode' is not null or undefined
            assertParamExists('getDeviceLogs', 'deviceCode', deviceCode)
            const localVarPath = `/deviceconfiguration/logs/{device_code}`
                .replace(`{${"device_code"}}`, encodeURIComponent(String(deviceCode)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Authorization required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (sort !== undefined) {
                localVarQueryParameter['sort'] = sort;
            }

            if (pageOffset !== undefined) {
                localVarQueryParameter['page[offset]'] = pageOffset;
            }

            if (pageLimit !== undefined) {
                localVarQueryParameter['page[limit]'] = pageLimit;
            }

            if (filter !== undefined) {
                for (const [key, value] of Object.entries(filter)) {
                    localVarQueryParameter[key] = value;
                }
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * DeviceConfigurationApi - functional programming interface
 * @export
 */
export const DeviceConfigurationApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = DeviceConfigurationApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary Return a list of device logs. This is a paginated request.
         * @param {string} deviceCode unique code identifying a device, also known as evse id
         * @param {string} [sort] order the list of device logs ascending or descending by a property. For descending order the sort field should be prefixed with a minus(\&#39;-\&#39;)
         * @param {number} [pageOffset] number of items that should be skipped and not included in the response
         * @param {number} [pageLimit] number of items to be included in the response
         * @param {GetSitesFilterParameter} [filter] Filters should be sent as encoded URI. Filterable items: level, message_type, point_number, time range
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getDeviceLogs(deviceCode: string, sort?: string, pageOffset?: number, pageLimit?: number, filter?: GetSitesFilterParameter, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<GetDeviceLogs200Response>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getDeviceLogs(deviceCode, sort, pageOffset, pageLimit, filter, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['DeviceConfigurationApi.getDeviceLogs']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
    }
};

/**
 * DeviceConfigurationApi - factory interface
 * @export
 */
export const DeviceConfigurationApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = DeviceConfigurationApiFp(configuration)
    return {
        /**
         * 
         * @summary Return a list of device logs. This is a paginated request.
         * @param {string} deviceCode unique code identifying a device, also known as evse id
         * @param {string} [sort] order the list of device logs ascending or descending by a property. For descending order the sort field should be prefixed with a minus(\&#39;-\&#39;)
         * @param {number} [pageOffset] number of items that should be skipped and not included in the response
         * @param {number} [pageLimit] number of items to be included in the response
         * @param {GetSitesFilterParameter} [filter] Filters should be sent as encoded URI. Filterable items: level, message_type, point_number, time range
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getDeviceLogs(deviceCode: string, sort?: string, pageOffset?: number, pageLimit?: number, filter?: GetSitesFilterParameter, options?: RawAxiosRequestConfig): AxiosPromise<GetDeviceLogs200Response> {
            return localVarFp.getDeviceLogs(deviceCode, sort, pageOffset, pageLimit, filter, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * DeviceConfigurationApi - object-oriented interface
 * @export
 * @class DeviceConfigurationApi
 * @extends {BaseAPI}
 */
export class DeviceConfigurationApi extends BaseAPI {
    /**
     * 
     * @summary Return a list of device logs. This is a paginated request.
     * @param {string} deviceCode unique code identifying a device, also known as evse id
     * @param {string} [sort] order the list of device logs ascending or descending by a property. For descending order the sort field should be prefixed with a minus(\&#39;-\&#39;)
     * @param {number} [pageOffset] number of items that should be skipped and not included in the response
     * @param {number} [pageLimit] number of items to be included in the response
     * @param {GetSitesFilterParameter} [filter] Filters should be sent as encoded URI. Filterable items: level, message_type, point_number, time range
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DeviceConfigurationApi
     */
    public getDeviceLogs(deviceCode: string, sort?: string, pageOffset?: number, pageLimit?: number, filter?: GetSitesFilterParameter, options?: RawAxiosRequestConfig) {
        return DeviceConfigurationApiFp(this.configuration).getDeviceLogs(deviceCode, sort, pageOffset, pageLimit, filter, options).then((request) => request(this.axios, this.basePath));
    }
}



/**
 * DevicesApi - axios parameter creator
 * @export
 */
export const DevicesApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Adds a new device to a site
         * @param {DeviceRequestExtended} deviceRequestExtended Device object that needs to be added to the system
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createDevice: async (deviceRequestExtended: DeviceRequestExtended, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'deviceRequestExtended' is not null or undefined
            assertParamExists('createDevice', 'deviceRequestExtended', deviceRequestExtended)
            const localVarPath = `/devices`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Authorization required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(deviceRequestExtended, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Deletes a device by id
         * @param {string} deviceId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteDevice: async (deviceId: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'deviceId' is not null or undefined
            assertParamExists('deleteDevice', 'deviceId', deviceId)
            const localVarPath = `/devices/{device_id}`
                .replace(`{${"device_id"}}`, encodeURIComponent(String(deviceId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Authorization required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Deletes multiple devices by their ids and optionally also the points
         * @param {DeleteDevicesRequest} deleteDevicesRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteDevices: async (deleteDevicesRequest: DeleteDevicesRequest, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'deleteDevicesRequest' is not null or undefined
            assertParamExists('deleteDevices', 'deleteDevicesRequest', deleteDevicesRequest)
            const localVarPath = `/devices`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Authorization required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(deleteDevicesRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get a single Device
         * @param {string} deviceId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getDeviceById: async (deviceId: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'deviceId' is not null or undefined
            assertParamExists('getDeviceById', 'deviceId', deviceId)
            const localVarPath = `/devices/{device_id}`
                .replace(`{${"device_id"}}`, encodeURIComponent(String(deviceId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Authorization required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Returns all device types for a manufacturer
         * @param {string} [manufacturerId] if not set all device types will be returned
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getDeviceModels: async (manufacturerId?: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/device_models`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Authorization required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (manufacturerId != null) {
                localVarHeaderParameter['manufacturer_id'] = String(manufacturerId);
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Getting List of Devices on a mandator. Paginated.
         * @param {string} [sort] order ascending or descending the list of devices by a property. For descending order the sort field should be prefixed with a minus(\&#39;-\&#39;)
         * @param {number} [pageOffset] number of items that should be skipped and not included in the response
         * @param {number} [pageLimit] number of items to be included in the response
         * @param {GetSitesFilterParameter} [filter] Filters should be sent as encoded URI. Filterable items: name, code, protocol_name, device_model_name, version, site_name.
         * @param {string} [mandatorId] If the mandator id is not send, an empty list will be fetched
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getDevicesOfSites: async (sort?: string, pageOffset?: number, pageLimit?: number, filter?: GetSitesFilterParameter, mandatorId?: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/devices`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Authorization required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (sort !== undefined) {
                localVarQueryParameter['sort'] = sort;
            }

            if (pageOffset !== undefined) {
                localVarQueryParameter['page[offset]'] = pageOffset;
            }

            if (pageLimit !== undefined) {
                localVarQueryParameter['page[limit]'] = pageLimit;
            }

            if (filter !== undefined) {
                for (const [key, value] of Object.entries(filter)) {
                    localVarQueryParameter[key] = value;
                }
            }

            if (mandatorId !== undefined) {
                localVarQueryParameter['mandator_id'] = mandatorId;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Returns all protocols from the system
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getProtocols: async (options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/protocols`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Authorization required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Provides support for remotely restart a device or to test its connectivity with HecOne
         * @param {string} deviceId 
         * @param {RemoteControlRequest} remoteControlRequest Device object that needs to be added to the system
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        remoteControlForDevice: async (deviceId: string, remoteControlRequest: RemoteControlRequest, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'deviceId' is not null or undefined
            assertParamExists('remoteControlForDevice', 'deviceId', deviceId)
            // verify required parameter 'remoteControlRequest' is not null or undefined
            assertParamExists('remoteControlForDevice', 'remoteControlRequest', remoteControlRequest)
            const localVarPath = `/devices/{device_id}/controls`
                .replace(`{${"device_id"}}`, encodeURIComponent(String(deviceId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Authorization required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(remoteControlRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Update a device by id
         * @param {string} deviceId 
         * @param {DeviceRequest} deviceRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateDevice: async (deviceId: string, deviceRequest: DeviceRequest, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'deviceId' is not null or undefined
            assertParamExists('updateDevice', 'deviceId', deviceId)
            // verify required parameter 'deviceRequest' is not null or undefined
            assertParamExists('updateDevice', 'deviceRequest', deviceRequest)
            const localVarPath = `/devices/{device_id}`
                .replace(`{${"device_id"}}`, encodeURIComponent(String(deviceId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Authorization required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(deviceRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * DevicesApi - functional programming interface
 * @export
 */
export const DevicesApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = DevicesApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary Adds a new device to a site
         * @param {DeviceRequestExtended} deviceRequestExtended Device object that needs to be added to the system
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async createDevice(deviceRequestExtended: DeviceRequestExtended, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<CreateDevice201Response>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.createDevice(deviceRequestExtended, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['DevicesApi.createDevice']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @summary Deletes a device by id
         * @param {string} deviceId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async deleteDevice(deviceId: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<UpdateSite200Response>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.deleteDevice(deviceId, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['DevicesApi.deleteDevice']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @summary Deletes multiple devices by their ids and optionally also the points
         * @param {DeleteDevicesRequest} deleteDevicesRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async deleteDevices(deleteDevicesRequest: DeleteDevicesRequest, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<DeleteDevices200Response>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.deleteDevices(deleteDevicesRequest, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['DevicesApi.deleteDevices']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @summary Get a single Device
         * @param {string} deviceId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getDeviceById(deviceId: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<GetDeviceById200Response>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getDeviceById(deviceId, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['DevicesApi.getDeviceById']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @summary Returns all device types for a manufacturer
         * @param {string} [manufacturerId] if not set all device types will be returned
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getDeviceModels(manufacturerId?: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<GetDeviceModels200Response>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getDeviceModels(manufacturerId, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['DevicesApi.getDeviceModels']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @summary Getting List of Devices on a mandator. Paginated.
         * @param {string} [sort] order ascending or descending the list of devices by a property. For descending order the sort field should be prefixed with a minus(\&#39;-\&#39;)
         * @param {number} [pageOffset] number of items that should be skipped and not included in the response
         * @param {number} [pageLimit] number of items to be included in the response
         * @param {GetSitesFilterParameter} [filter] Filters should be sent as encoded URI. Filterable items: name, code, protocol_name, device_model_name, version, site_name.
         * @param {string} [mandatorId] If the mandator id is not send, an empty list will be fetched
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getDevicesOfSites(sort?: string, pageOffset?: number, pageLimit?: number, filter?: GetSitesFilterParameter, mandatorId?: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<GetDevicesOfSite200Response>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getDevicesOfSites(sort, pageOffset, pageLimit, filter, mandatorId, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['DevicesApi.getDevicesOfSites']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @summary Returns all protocols from the system
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getProtocols(options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<GetProtocols200Response>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getProtocols(options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['DevicesApi.getProtocols']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @summary Provides support for remotely restart a device or to test its connectivity with HecOne
         * @param {string} deviceId 
         * @param {RemoteControlRequest} remoteControlRequest Device object that needs to be added to the system
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async remoteControlForDevice(deviceId: string, remoteControlRequest: RemoteControlRequest, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<RemoteControlForDevice200Response>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.remoteControlForDevice(deviceId, remoteControlRequest, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['DevicesApi.remoteControlForDevice']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @summary Update a device by id
         * @param {string} deviceId 
         * @param {DeviceRequest} deviceRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updateDevice(deviceId: string, deviceRequest: DeviceRequest, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<UpdateSite200Response>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.updateDevice(deviceId, deviceRequest, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['DevicesApi.updateDevice']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
    }
};

/**
 * DevicesApi - factory interface
 * @export
 */
export const DevicesApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = DevicesApiFp(configuration)
    return {
        /**
         * 
         * @summary Adds a new device to a site
         * @param {DeviceRequestExtended} deviceRequestExtended Device object that needs to be added to the system
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createDevice(deviceRequestExtended: DeviceRequestExtended, options?: RawAxiosRequestConfig): AxiosPromise<CreateDevice201Response> {
            return localVarFp.createDevice(deviceRequestExtended, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Deletes a device by id
         * @param {string} deviceId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteDevice(deviceId: string, options?: RawAxiosRequestConfig): AxiosPromise<UpdateSite200Response> {
            return localVarFp.deleteDevice(deviceId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Deletes multiple devices by their ids and optionally also the points
         * @param {DeleteDevicesRequest} deleteDevicesRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteDevices(deleteDevicesRequest: DeleteDevicesRequest, options?: RawAxiosRequestConfig): AxiosPromise<DeleteDevices200Response> {
            return localVarFp.deleteDevices(deleteDevicesRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get a single Device
         * @param {string} deviceId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getDeviceById(deviceId: string, options?: RawAxiosRequestConfig): AxiosPromise<GetDeviceById200Response> {
            return localVarFp.getDeviceById(deviceId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Returns all device types for a manufacturer
         * @param {string} [manufacturerId] if not set all device types will be returned
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getDeviceModels(manufacturerId?: string, options?: RawAxiosRequestConfig): AxiosPromise<GetDeviceModels200Response> {
            return localVarFp.getDeviceModels(manufacturerId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Getting List of Devices on a mandator. Paginated.
         * @param {string} [sort] order ascending or descending the list of devices by a property. For descending order the sort field should be prefixed with a minus(\&#39;-\&#39;)
         * @param {number} [pageOffset] number of items that should be skipped and not included in the response
         * @param {number} [pageLimit] number of items to be included in the response
         * @param {GetSitesFilterParameter} [filter] Filters should be sent as encoded URI. Filterable items: name, code, protocol_name, device_model_name, version, site_name.
         * @param {string} [mandatorId] If the mandator id is not send, an empty list will be fetched
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getDevicesOfSites(sort?: string, pageOffset?: number, pageLimit?: number, filter?: GetSitesFilterParameter, mandatorId?: string, options?: RawAxiosRequestConfig): AxiosPromise<GetDevicesOfSite200Response> {
            return localVarFp.getDevicesOfSites(sort, pageOffset, pageLimit, filter, mandatorId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Returns all protocols from the system
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getProtocols(options?: RawAxiosRequestConfig): AxiosPromise<GetProtocols200Response> {
            return localVarFp.getProtocols(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Provides support for remotely restart a device or to test its connectivity with HecOne
         * @param {string} deviceId 
         * @param {RemoteControlRequest} remoteControlRequest Device object that needs to be added to the system
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        remoteControlForDevice(deviceId: string, remoteControlRequest: RemoteControlRequest, options?: RawAxiosRequestConfig): AxiosPromise<RemoteControlForDevice200Response> {
            return localVarFp.remoteControlForDevice(deviceId, remoteControlRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Update a device by id
         * @param {string} deviceId 
         * @param {DeviceRequest} deviceRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateDevice(deviceId: string, deviceRequest: DeviceRequest, options?: RawAxiosRequestConfig): AxiosPromise<UpdateSite200Response> {
            return localVarFp.updateDevice(deviceId, deviceRequest, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * DevicesApi - object-oriented interface
 * @export
 * @class DevicesApi
 * @extends {BaseAPI}
 */
export class DevicesApi extends BaseAPI {
    /**
     * 
     * @summary Adds a new device to a site
     * @param {DeviceRequestExtended} deviceRequestExtended Device object that needs to be added to the system
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DevicesApi
     */
    public createDevice(deviceRequestExtended: DeviceRequestExtended, options?: RawAxiosRequestConfig) {
        return DevicesApiFp(this.configuration).createDevice(deviceRequestExtended, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Deletes a device by id
     * @param {string} deviceId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DevicesApi
     */
    public deleteDevice(deviceId: string, options?: RawAxiosRequestConfig) {
        return DevicesApiFp(this.configuration).deleteDevice(deviceId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Deletes multiple devices by their ids and optionally also the points
     * @param {DeleteDevicesRequest} deleteDevicesRequest 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DevicesApi
     */
    public deleteDevices(deleteDevicesRequest: DeleteDevicesRequest, options?: RawAxiosRequestConfig) {
        return DevicesApiFp(this.configuration).deleteDevices(deleteDevicesRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get a single Device
     * @param {string} deviceId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DevicesApi
     */
    public getDeviceById(deviceId: string, options?: RawAxiosRequestConfig) {
        return DevicesApiFp(this.configuration).getDeviceById(deviceId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Returns all device types for a manufacturer
     * @param {string} [manufacturerId] if not set all device types will be returned
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DevicesApi
     */
    public getDeviceModels(manufacturerId?: string, options?: RawAxiosRequestConfig) {
        return DevicesApiFp(this.configuration).getDeviceModels(manufacturerId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Getting List of Devices on a mandator. Paginated.
     * @param {string} [sort] order ascending or descending the list of devices by a property. For descending order the sort field should be prefixed with a minus(\&#39;-\&#39;)
     * @param {number} [pageOffset] number of items that should be skipped and not included in the response
     * @param {number} [pageLimit] number of items to be included in the response
     * @param {GetSitesFilterParameter} [filter] Filters should be sent as encoded URI. Filterable items: name, code, protocol_name, device_model_name, version, site_name.
     * @param {string} [mandatorId] If the mandator id is not send, an empty list will be fetched
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DevicesApi
     */
    public getDevicesOfSites(sort?: string, pageOffset?: number, pageLimit?: number, filter?: GetSitesFilterParameter, mandatorId?: string, options?: RawAxiosRequestConfig) {
        return DevicesApiFp(this.configuration).getDevicesOfSites(sort, pageOffset, pageLimit, filter, mandatorId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Returns all protocols from the system
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DevicesApi
     */
    public getProtocols(options?: RawAxiosRequestConfig) {
        return DevicesApiFp(this.configuration).getProtocols(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Provides support for remotely restart a device or to test its connectivity with HecOne
     * @param {string} deviceId 
     * @param {RemoteControlRequest} remoteControlRequest Device object that needs to be added to the system
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DevicesApi
     */
    public remoteControlForDevice(deviceId: string, remoteControlRequest: RemoteControlRequest, options?: RawAxiosRequestConfig) {
        return DevicesApiFp(this.configuration).remoteControlForDevice(deviceId, remoteControlRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Update a device by id
     * @param {string} deviceId 
     * @param {DeviceRequest} deviceRequest 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DevicesApi
     */
    public updateDevice(deviceId: string, deviceRequest: DeviceRequest, options?: RawAxiosRequestConfig) {
        return DevicesApiFp(this.configuration).updateDevice(deviceId, deviceRequest, options).then((request) => request(this.axios, this.basePath));
    }
}



/**
 * DigitalReceiptsApi - axios parameter creator
 * @export
 */
export const DigitalReceiptsApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Creates a link between module and mandator
         * @param {ActivationRequest} activationRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        activateModuleForAMandator: async (activationRequest: ActivationRequest, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'activationRequest' is not null or undefined
            assertParamExists('activateModuleForAMandator', 'activationRequest', activationRequest)
            const localVarPath = `/digitalreceipts/config/mandators/activations`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Authorization required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(activationRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Adds a new receipt template for a mandator
         * @param {CreateTemplateRequestComponent} createTemplateRequestComponent Receipt Template object that needs to be added to the system
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createDigitalReceiptTemplate: async (createTemplateRequestComponent: CreateTemplateRequestComponent, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'createTemplateRequestComponent' is not null or undefined
            assertParamExists('createDigitalReceiptTemplate', 'createTemplateRequestComponent', createTemplateRequestComponent)
            const localVarPath = `/digitalreceipts/templates`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Authorization required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(createTemplateRequestComponent, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Deletes the link between module and mandator
         * @param {string} mandatorId Mandator id to deactivate
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deactivateDigitalReceiptForAMandator: async (mandatorId: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'mandatorId' is not null or undefined
            assertParamExists('deactivateDigitalReceiptForAMandator', 'mandatorId', mandatorId)
            const localVarPath = `/digitalreceipts/config/mandators/activations/{mandator_id}`
                .replace(`{${"mandator_id"}}`, encodeURIComponent(String(mandatorId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Authorization required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Unassign and delete a template
         * @param {string} templateId Template to unassign from sites and delete
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteDigitalReceiptTemplate: async (templateId: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'templateId' is not null or undefined
            assertParamExists('deleteDigitalReceiptTemplate', 'templateId', templateId)
            const localVarPath = `/digitalreceipts/templates/{template_id}`
                .replace(`{${"template_id"}}`, encodeURIComponent(String(templateId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Authorization required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Deletes a list of templates
         * @param {Array<string>} requestBody A list that contains the ids of the templates
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteDigitalReceiptTemplates: async (requestBody: Array<string>, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'requestBody' is not null or undefined
            assertParamExists('deleteDigitalReceiptTemplates', 'requestBody', requestBody)
            const localVarPath = `/digitalreceipts/templates`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Authorization required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(requestBody, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Returns a list of history for different resources
         * @param {string} resourceId The id of the resource (the id of the site or tenant id in case of field translations)
         * @param {GetDigitalReceiptHistoryResourceNameEnum} resourceName The name of the resource
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getDigitalReceiptHistory: async (resourceId: string, resourceName: GetDigitalReceiptHistoryResourceNameEnum, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'resourceId' is not null or undefined
            assertParamExists('getDigitalReceiptHistory', 'resourceId', resourceId)
            // verify required parameter 'resourceName' is not null or undefined
            assertParamExists('getDigitalReceiptHistory', 'resourceName', resourceName)
            const localVarPath = `/digitalreceipts/history`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Authorization required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (resourceId !== undefined) {
                localVarQueryParameter['resource_id'] = resourceId;
            }

            if (resourceName !== undefined) {
                localVarQueryParameter['resource_name'] = resourceName;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Getting Module details.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getDigitalReceiptModule: async (options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/digitalreceipts/module`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Authorization required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Getting Digital Receipt status for a mandator.
         * @param {string} mandatorId Mandator unique identifier
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getDigitalReceiptStatusForAMandator: async (mandatorId: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'mandatorId' is not null or undefined
            assertParamExists('getDigitalReceiptStatusForAMandator', 'mandatorId', mandatorId)
            const localVarPath = `/digitalreceipts/config/mandators/activations/{mandator_id}`
                .replace(`{${"mandator_id"}}`, encodeURIComponent(String(mandatorId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Authorization required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Getting Template details.
         * @param {string} templateId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getDigitalReceiptTemplateDetails: async (templateId: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'templateId' is not null or undefined
            assertParamExists('getDigitalReceiptTemplateDetails', 'templateId', templateId)
            const localVarPath = `/digitalreceipts/templates/{template_id}`
                .replace(`{${"template_id"}}`, encodeURIComponent(String(templateId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Authorization required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Returns the email template
         * @summary Get the email template for a mandator
         * @param {string} mandatorId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getEmailTemplate: async (mandatorId: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'mandatorId' is not null or undefined
            assertParamExists('getEmailTemplate', 'mandatorId', mandatorId)
            const localVarPath = `/digitalreceipts/mandators/{mandator_id}/emailtemplates`
                .replace(`{${"mandator_id"}}`, encodeURIComponent(String(mandatorId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Authorization required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Returns all mandator receipt templates
         * @summary Get the receipt templates for a mandator
         * @param {string} mandatorId 
         * @param {string} [sort] order ascending or descending the list of templates by a property (name, type, created_at). For descending order the sort field should be prefixed with a minus(\&#39;-\&#39;)
         * @param {number} [pageOffset] number of items that should be skipped and not included in the response
         * @param {number} [pageLimit] number of items to be included in the response
         * @param {GetSitesFilterParameter} [filter] Filters should be sent as encoded URI. Filterable items: name, type
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getMandatorTemplatesForReceipts: async (mandatorId: string, sort?: string, pageOffset?: number, pageLimit?: number, filter?: GetSitesFilterParameter, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'mandatorId' is not null or undefined
            assertParamExists('getMandatorTemplatesForReceipts', 'mandatorId', mandatorId)
            const localVarPath = `/digitalreceipts/mandators/{mandator_id}/templates`
                .replace(`{${"mandator_id"}}`, encodeURIComponent(String(mandatorId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Authorization required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (sort !== undefined) {
                localVarQueryParameter['sort'] = sort;
            }

            if (pageOffset !== undefined) {
                localVarQueryParameter['page[offset]'] = pageOffset;
            }

            if (pageLimit !== undefined) {
                localVarQueryParameter['page[limit]'] = pageLimit;
            }

            if (filter !== undefined) {
                for (const [key, value] of Object.entries(filter)) {
                    localVarQueryParameter[key] = value;
                }
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Returns all fields from the system
         * @summary Get all fields that can be displayed on a receipt
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getReceiptFields: async (options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/digitalreceipts/fields`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Authorization required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Returns the templates assigned to a site
         * @summary Get the assigned templates to a site
         * @param {string} siteId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getSiteAssignments: async (siteId: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'siteId' is not null or undefined
            assertParamExists('getSiteAssignments', 'siteId', siteId)
            const localVarPath = `/digitalreceipts/sites/templates/assignments/{site_id}`
                .replace(`{${"site_id"}}`, encodeURIComponent(String(siteId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Authorization required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Returns all sites with their templates
         * @summary Get the list of all sites for current mandator with their assigned templates
         * @param {string} [sort] order ascending or descending the list of sites by a property (name, city). For descending order the sort field should be prefixed with a minus(\&#39;-\&#39;)
         * @param {number} [pageOffset] number of items that should be skipped and not included in the response
         * @param {number} [pageLimit] number of items to be included in the response
         * @param {GetSitesFilterParameter} [filter] Filters should be sent as encoded URI. Filterable items: name, city
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getSitesTemplates: async (sort?: string, pageOffset?: number, pageLimit?: number, filter?: GetSitesFilterParameter, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/digitalreceipts/sites/templates/assignments`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Authorization required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (sort !== undefined) {
                localVarQueryParameter['sort'] = sort;
            }

            if (pageOffset !== undefined) {
                localVarQueryParameter['page[offset]'] = pageOffset;
            }

            if (pageLimit !== undefined) {
                localVarQueryParameter['page[limit]'] = pageLimit;
            }

            if (filter !== undefined) {
                for (const [key, value] of Object.entries(filter)) {
                    localVarQueryParameter[key] = value;
                }
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Updates the translations for receipt fields
         * @param {Array<PatchFieldsInner>} patchFieldsInner 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        patchFields: async (patchFieldsInner: Array<PatchFieldsInner>, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'patchFieldsInner' is not null or undefined
            assertParamExists('patchFields', 'patchFieldsInner', patchFieldsInner)
            const localVarPath = `/digitalreceipts/fields`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Authorization required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(patchFieldsInner, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Adds, Deletes or updates templates for a site
         * @param {string} siteId 
         * @param {Array<BaseAssignment>} [baseAssignment] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        patchSiteAssignments: async (siteId: string, baseAssignment?: Array<BaseAssignment>, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'siteId' is not null or undefined
            assertParamExists('patchSiteAssignments', 'siteId', siteId)
            const localVarPath = `/digitalreceipts/sites/templates/assignments/{site_id}`
                .replace(`{${"site_id"}}`, encodeURIComponent(String(siteId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Authorization required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(baseAssignment, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Adds, Deletes or Updates site-template assignments based on template id
         * @param {PatchTemplateAssignments} [patchTemplateAssignments] Can contain either a list of site and template ids or assign_all flag and template id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        patchSiteTemplateAssignments: async (patchTemplateAssignments?: PatchTemplateAssignments, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/digitalreceipts/sites/templates/assignments`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Authorization required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(patchTemplateAssignments, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Updates a receipt template by id
         * @param {string} templateId 
         * @param {UpdateTemplateRequest} updateTemplateRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateDigitalReceiptTemplate: async (templateId: string, updateTemplateRequest: UpdateTemplateRequest, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'templateId' is not null or undefined
            assertParamExists('updateDigitalReceiptTemplate', 'templateId', templateId)
            // verify required parameter 'updateTemplateRequest' is not null or undefined
            assertParamExists('updateDigitalReceiptTemplate', 'updateTemplateRequest', updateTemplateRequest)
            const localVarPath = `/digitalreceipts/templates/{template_id}`
                .replace(`{${"template_id"}}`, encodeURIComponent(String(templateId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Authorization required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(updateTemplateRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Creates or Updates an email template
         * @param {string} mandatorId 
         * @param {BaseEmailTemplate} baseEmailTemplate 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateEmailTemplate: async (mandatorId: string, baseEmailTemplate: BaseEmailTemplate, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'mandatorId' is not null or undefined
            assertParamExists('updateEmailTemplate', 'mandatorId', mandatorId)
            // verify required parameter 'baseEmailTemplate' is not null or undefined
            assertParamExists('updateEmailTemplate', 'baseEmailTemplate', baseEmailTemplate)
            const localVarPath = `/digitalreceipts/mandators/{mandator_id}/emailtemplates`
                .replace(`{${"mandator_id"}}`, encodeURIComponent(String(mandatorId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Authorization required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(baseEmailTemplate, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * DigitalReceiptsApi - functional programming interface
 * @export
 */
export const DigitalReceiptsApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = DigitalReceiptsApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary Creates a link between module and mandator
         * @param {ActivationRequest} activationRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async activateModuleForAMandator(activationRequest: ActivationRequest, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ActivationResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.activateModuleForAMandator(activationRequest, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['DigitalReceiptsApi.activateModuleForAMandator']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @summary Adds a new receipt template for a mandator
         * @param {CreateTemplateRequestComponent} createTemplateRequestComponent Receipt Template object that needs to be added to the system
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async createDigitalReceiptTemplate(createTemplateRequestComponent: CreateTemplateRequestComponent, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<CreateDigitalReceiptTemplate201Response>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.createDigitalReceiptTemplate(createTemplateRequestComponent, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['DigitalReceiptsApi.createDigitalReceiptTemplate']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @summary Deletes the link between module and mandator
         * @param {string} mandatorId Mandator id to deactivate
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async deactivateDigitalReceiptForAMandator(mandatorId: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<number>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.deactivateDigitalReceiptForAMandator(mandatorId, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['DigitalReceiptsApi.deactivateDigitalReceiptForAMandator']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @summary Unassign and delete a template
         * @param {string} templateId Template to unassign from sites and delete
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async deleteDigitalReceiptTemplate(templateId: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<number>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.deleteDigitalReceiptTemplate(templateId, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['DigitalReceiptsApi.deleteDigitalReceiptTemplate']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @summary Deletes a list of templates
         * @param {Array<string>} requestBody A list that contains the ids of the templates
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async deleteDigitalReceiptTemplates(requestBody: Array<string>, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<number>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.deleteDigitalReceiptTemplates(requestBody, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['DigitalReceiptsApi.deleteDigitalReceiptTemplates']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @summary Returns a list of history for different resources
         * @param {string} resourceId The id of the resource (the id of the site or tenant id in case of field translations)
         * @param {GetDigitalReceiptHistoryResourceNameEnum} resourceName The name of the resource
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getDigitalReceiptHistory(resourceId: string, resourceName: GetDigitalReceiptHistoryResourceNameEnum, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<GetDigitalReceiptHistory200Response>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getDigitalReceiptHistory(resourceId, resourceName, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['DigitalReceiptsApi.getDigitalReceiptHistory']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @summary Getting Module details.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getDigitalReceiptModule(options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Module>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getDigitalReceiptModule(options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['DigitalReceiptsApi.getDigitalReceiptModule']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @summary Getting Digital Receipt status for a mandator.
         * @param {string} mandatorId Mandator unique identifier
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getDigitalReceiptStatusForAMandator(mandatorId: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Activation>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getDigitalReceiptStatusForAMandator(mandatorId, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['DigitalReceiptsApi.getDigitalReceiptStatusForAMandator']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @summary Getting Template details.
         * @param {string} templateId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getDigitalReceiptTemplateDetails(templateId: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<GetDigitalReceiptTemplateDetails200Response>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getDigitalReceiptTemplateDetails(templateId, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['DigitalReceiptsApi.getDigitalReceiptTemplateDetails']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * Returns the email template
         * @summary Get the email template for a mandator
         * @param {string} mandatorId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getEmailTemplate(mandatorId: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<GetEmailTemplate200Response>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getEmailTemplate(mandatorId, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['DigitalReceiptsApi.getEmailTemplate']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * Returns all mandator receipt templates
         * @summary Get the receipt templates for a mandator
         * @param {string} mandatorId 
         * @param {string} [sort] order ascending or descending the list of templates by a property (name, type, created_at). For descending order the sort field should be prefixed with a minus(\&#39;-\&#39;)
         * @param {number} [pageOffset] number of items that should be skipped and not included in the response
         * @param {number} [pageLimit] number of items to be included in the response
         * @param {GetSitesFilterParameter} [filter] Filters should be sent as encoded URI. Filterable items: name, type
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getMandatorTemplatesForReceipts(mandatorId: string, sort?: string, pageOffset?: number, pageLimit?: number, filter?: GetSitesFilterParameter, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<GetMandatorTemplatesForReceipts200Response>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getMandatorTemplatesForReceipts(mandatorId, sort, pageOffset, pageLimit, filter, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['DigitalReceiptsApi.getMandatorTemplatesForReceipts']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * Returns all fields from the system
         * @summary Get all fields that can be displayed on a receipt
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getReceiptFields(options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<GetReceiptFields200Response>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getReceiptFields(options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['DigitalReceiptsApi.getReceiptFields']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * Returns the templates assigned to a site
         * @summary Get the assigned templates to a site
         * @param {string} siteId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getSiteAssignments(siteId: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<GetSiteAssignments200Response>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getSiteAssignments(siteId, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['DigitalReceiptsApi.getSiteAssignments']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * Returns all sites with their templates
         * @summary Get the list of all sites for current mandator with their assigned templates
         * @param {string} [sort] order ascending or descending the list of sites by a property (name, city). For descending order the sort field should be prefixed with a minus(\&#39;-\&#39;)
         * @param {number} [pageOffset] number of items that should be skipped and not included in the response
         * @param {number} [pageLimit] number of items to be included in the response
         * @param {GetSitesFilterParameter} [filter] Filters should be sent as encoded URI. Filterable items: name, city
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getSitesTemplates(sort?: string, pageOffset?: number, pageLimit?: number, filter?: GetSitesFilterParameter, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<GetSitesTemplates200Response>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getSitesTemplates(sort, pageOffset, pageLimit, filter, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['DigitalReceiptsApi.getSitesTemplates']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @summary Updates the translations for receipt fields
         * @param {Array<PatchFieldsInner>} patchFieldsInner 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async patchFields(patchFieldsInner: Array<PatchFieldsInner>, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<UpdateSite200Response>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.patchFields(patchFieldsInner, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['DigitalReceiptsApi.patchFields']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @summary Adds, Deletes or updates templates for a site
         * @param {string} siteId 
         * @param {Array<BaseAssignment>} [baseAssignment] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async patchSiteAssignments(siteId: string, baseAssignment?: Array<BaseAssignment>, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<UpdateSite200Response>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.patchSiteAssignments(siteId, baseAssignment, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['DigitalReceiptsApi.patchSiteAssignments']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @summary Adds, Deletes or Updates site-template assignments based on template id
         * @param {PatchTemplateAssignments} [patchTemplateAssignments] Can contain either a list of site and template ids or assign_all flag and template id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async patchSiteTemplateAssignments(patchTemplateAssignments?: PatchTemplateAssignments, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<UpdateSite200Response>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.patchSiteTemplateAssignments(patchTemplateAssignments, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['DigitalReceiptsApi.patchSiteTemplateAssignments']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @summary Updates a receipt template by id
         * @param {string} templateId 
         * @param {UpdateTemplateRequest} updateTemplateRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updateDigitalReceiptTemplate(templateId: string, updateTemplateRequest: UpdateTemplateRequest, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<UpdateSite200Response>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.updateDigitalReceiptTemplate(templateId, updateTemplateRequest, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['DigitalReceiptsApi.updateDigitalReceiptTemplate']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @summary Creates or Updates an email template
         * @param {string} mandatorId 
         * @param {BaseEmailTemplate} baseEmailTemplate 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updateEmailTemplate(mandatorId: string, baseEmailTemplate: BaseEmailTemplate, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<UpdateSite200Response>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.updateEmailTemplate(mandatorId, baseEmailTemplate, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['DigitalReceiptsApi.updateEmailTemplate']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
    }
};

/**
 * DigitalReceiptsApi - factory interface
 * @export
 */
export const DigitalReceiptsApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = DigitalReceiptsApiFp(configuration)
    return {
        /**
         * 
         * @summary Creates a link between module and mandator
         * @param {ActivationRequest} activationRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        activateModuleForAMandator(activationRequest: ActivationRequest, options?: RawAxiosRequestConfig): AxiosPromise<ActivationResponse> {
            return localVarFp.activateModuleForAMandator(activationRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Adds a new receipt template for a mandator
         * @param {CreateTemplateRequestComponent} createTemplateRequestComponent Receipt Template object that needs to be added to the system
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createDigitalReceiptTemplate(createTemplateRequestComponent: CreateTemplateRequestComponent, options?: RawAxiosRequestConfig): AxiosPromise<CreateDigitalReceiptTemplate201Response> {
            return localVarFp.createDigitalReceiptTemplate(createTemplateRequestComponent, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Deletes the link between module and mandator
         * @param {string} mandatorId Mandator id to deactivate
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deactivateDigitalReceiptForAMandator(mandatorId: string, options?: RawAxiosRequestConfig): AxiosPromise<number> {
            return localVarFp.deactivateDigitalReceiptForAMandator(mandatorId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Unassign and delete a template
         * @param {string} templateId Template to unassign from sites and delete
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteDigitalReceiptTemplate(templateId: string, options?: RawAxiosRequestConfig): AxiosPromise<number> {
            return localVarFp.deleteDigitalReceiptTemplate(templateId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Deletes a list of templates
         * @param {Array<string>} requestBody A list that contains the ids of the templates
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteDigitalReceiptTemplates(requestBody: Array<string>, options?: RawAxiosRequestConfig): AxiosPromise<number> {
            return localVarFp.deleteDigitalReceiptTemplates(requestBody, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Returns a list of history for different resources
         * @param {string} resourceId The id of the resource (the id of the site or tenant id in case of field translations)
         * @param {GetDigitalReceiptHistoryResourceNameEnum} resourceName The name of the resource
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getDigitalReceiptHistory(resourceId: string, resourceName: GetDigitalReceiptHistoryResourceNameEnum, options?: RawAxiosRequestConfig): AxiosPromise<GetDigitalReceiptHistory200Response> {
            return localVarFp.getDigitalReceiptHistory(resourceId, resourceName, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Getting Module details.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getDigitalReceiptModule(options?: RawAxiosRequestConfig): AxiosPromise<Module> {
            return localVarFp.getDigitalReceiptModule(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Getting Digital Receipt status for a mandator.
         * @param {string} mandatorId Mandator unique identifier
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getDigitalReceiptStatusForAMandator(mandatorId: string, options?: RawAxiosRequestConfig): AxiosPromise<Activation> {
            return localVarFp.getDigitalReceiptStatusForAMandator(mandatorId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Getting Template details.
         * @param {string} templateId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getDigitalReceiptTemplateDetails(templateId: string, options?: RawAxiosRequestConfig): AxiosPromise<GetDigitalReceiptTemplateDetails200Response> {
            return localVarFp.getDigitalReceiptTemplateDetails(templateId, options).then((request) => request(axios, basePath));
        },
        /**
         * Returns the email template
         * @summary Get the email template for a mandator
         * @param {string} mandatorId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getEmailTemplate(mandatorId: string, options?: RawAxiosRequestConfig): AxiosPromise<GetEmailTemplate200Response> {
            return localVarFp.getEmailTemplate(mandatorId, options).then((request) => request(axios, basePath));
        },
        /**
         * Returns all mandator receipt templates
         * @summary Get the receipt templates for a mandator
         * @param {string} mandatorId 
         * @param {string} [sort] order ascending or descending the list of templates by a property (name, type, created_at). For descending order the sort field should be prefixed with a minus(\&#39;-\&#39;)
         * @param {number} [pageOffset] number of items that should be skipped and not included in the response
         * @param {number} [pageLimit] number of items to be included in the response
         * @param {GetSitesFilterParameter} [filter] Filters should be sent as encoded URI. Filterable items: name, type
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getMandatorTemplatesForReceipts(mandatorId: string, sort?: string, pageOffset?: number, pageLimit?: number, filter?: GetSitesFilterParameter, options?: RawAxiosRequestConfig): AxiosPromise<GetMandatorTemplatesForReceipts200Response> {
            return localVarFp.getMandatorTemplatesForReceipts(mandatorId, sort, pageOffset, pageLimit, filter, options).then((request) => request(axios, basePath));
        },
        /**
         * Returns all fields from the system
         * @summary Get all fields that can be displayed on a receipt
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getReceiptFields(options?: RawAxiosRequestConfig): AxiosPromise<GetReceiptFields200Response> {
            return localVarFp.getReceiptFields(options).then((request) => request(axios, basePath));
        },
        /**
         * Returns the templates assigned to a site
         * @summary Get the assigned templates to a site
         * @param {string} siteId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getSiteAssignments(siteId: string, options?: RawAxiosRequestConfig): AxiosPromise<GetSiteAssignments200Response> {
            return localVarFp.getSiteAssignments(siteId, options).then((request) => request(axios, basePath));
        },
        /**
         * Returns all sites with their templates
         * @summary Get the list of all sites for current mandator with their assigned templates
         * @param {string} [sort] order ascending or descending the list of sites by a property (name, city). For descending order the sort field should be prefixed with a minus(\&#39;-\&#39;)
         * @param {number} [pageOffset] number of items that should be skipped and not included in the response
         * @param {number} [pageLimit] number of items to be included in the response
         * @param {GetSitesFilterParameter} [filter] Filters should be sent as encoded URI. Filterable items: name, city
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getSitesTemplates(sort?: string, pageOffset?: number, pageLimit?: number, filter?: GetSitesFilterParameter, options?: RawAxiosRequestConfig): AxiosPromise<GetSitesTemplates200Response> {
            return localVarFp.getSitesTemplates(sort, pageOffset, pageLimit, filter, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Updates the translations for receipt fields
         * @param {Array<PatchFieldsInner>} patchFieldsInner 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        patchFields(patchFieldsInner: Array<PatchFieldsInner>, options?: RawAxiosRequestConfig): AxiosPromise<UpdateSite200Response> {
            return localVarFp.patchFields(patchFieldsInner, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Adds, Deletes or updates templates for a site
         * @param {string} siteId 
         * @param {Array<BaseAssignment>} [baseAssignment] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        patchSiteAssignments(siteId: string, baseAssignment?: Array<BaseAssignment>, options?: RawAxiosRequestConfig): AxiosPromise<UpdateSite200Response> {
            return localVarFp.patchSiteAssignments(siteId, baseAssignment, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Adds, Deletes or Updates site-template assignments based on template id
         * @param {PatchTemplateAssignments} [patchTemplateAssignments] Can contain either a list of site and template ids or assign_all flag and template id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        patchSiteTemplateAssignments(patchTemplateAssignments?: PatchTemplateAssignments, options?: RawAxiosRequestConfig): AxiosPromise<UpdateSite200Response> {
            return localVarFp.patchSiteTemplateAssignments(patchTemplateAssignments, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Updates a receipt template by id
         * @param {string} templateId 
         * @param {UpdateTemplateRequest} updateTemplateRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateDigitalReceiptTemplate(templateId: string, updateTemplateRequest: UpdateTemplateRequest, options?: RawAxiosRequestConfig): AxiosPromise<UpdateSite200Response> {
            return localVarFp.updateDigitalReceiptTemplate(templateId, updateTemplateRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Creates or Updates an email template
         * @param {string} mandatorId 
         * @param {BaseEmailTemplate} baseEmailTemplate 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateEmailTemplate(mandatorId: string, baseEmailTemplate: BaseEmailTemplate, options?: RawAxiosRequestConfig): AxiosPromise<UpdateSite200Response> {
            return localVarFp.updateEmailTemplate(mandatorId, baseEmailTemplate, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * DigitalReceiptsApi - object-oriented interface
 * @export
 * @class DigitalReceiptsApi
 * @extends {BaseAPI}
 */
export class DigitalReceiptsApi extends BaseAPI {
    /**
     * 
     * @summary Creates a link between module and mandator
     * @param {ActivationRequest} activationRequest 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DigitalReceiptsApi
     */
    public activateModuleForAMandator(activationRequest: ActivationRequest, options?: RawAxiosRequestConfig) {
        return DigitalReceiptsApiFp(this.configuration).activateModuleForAMandator(activationRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Adds a new receipt template for a mandator
     * @param {CreateTemplateRequestComponent} createTemplateRequestComponent Receipt Template object that needs to be added to the system
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DigitalReceiptsApi
     */
    public createDigitalReceiptTemplate(createTemplateRequestComponent: CreateTemplateRequestComponent, options?: RawAxiosRequestConfig) {
        return DigitalReceiptsApiFp(this.configuration).createDigitalReceiptTemplate(createTemplateRequestComponent, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Deletes the link between module and mandator
     * @param {string} mandatorId Mandator id to deactivate
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DigitalReceiptsApi
     */
    public deactivateDigitalReceiptForAMandator(mandatorId: string, options?: RawAxiosRequestConfig) {
        return DigitalReceiptsApiFp(this.configuration).deactivateDigitalReceiptForAMandator(mandatorId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Unassign and delete a template
     * @param {string} templateId Template to unassign from sites and delete
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DigitalReceiptsApi
     */
    public deleteDigitalReceiptTemplate(templateId: string, options?: RawAxiosRequestConfig) {
        return DigitalReceiptsApiFp(this.configuration).deleteDigitalReceiptTemplate(templateId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Deletes a list of templates
     * @param {Array<string>} requestBody A list that contains the ids of the templates
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DigitalReceiptsApi
     */
    public deleteDigitalReceiptTemplates(requestBody: Array<string>, options?: RawAxiosRequestConfig) {
        return DigitalReceiptsApiFp(this.configuration).deleteDigitalReceiptTemplates(requestBody, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Returns a list of history for different resources
     * @param {string} resourceId The id of the resource (the id of the site or tenant id in case of field translations)
     * @param {GetDigitalReceiptHistoryResourceNameEnum} resourceName The name of the resource
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DigitalReceiptsApi
     */
    public getDigitalReceiptHistory(resourceId: string, resourceName: GetDigitalReceiptHistoryResourceNameEnum, options?: RawAxiosRequestConfig) {
        return DigitalReceiptsApiFp(this.configuration).getDigitalReceiptHistory(resourceId, resourceName, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Getting Module details.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DigitalReceiptsApi
     */
    public getDigitalReceiptModule(options?: RawAxiosRequestConfig) {
        return DigitalReceiptsApiFp(this.configuration).getDigitalReceiptModule(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Getting Digital Receipt status for a mandator.
     * @param {string} mandatorId Mandator unique identifier
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DigitalReceiptsApi
     */
    public getDigitalReceiptStatusForAMandator(mandatorId: string, options?: RawAxiosRequestConfig) {
        return DigitalReceiptsApiFp(this.configuration).getDigitalReceiptStatusForAMandator(mandatorId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Getting Template details.
     * @param {string} templateId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DigitalReceiptsApi
     */
    public getDigitalReceiptTemplateDetails(templateId: string, options?: RawAxiosRequestConfig) {
        return DigitalReceiptsApiFp(this.configuration).getDigitalReceiptTemplateDetails(templateId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Returns the email template
     * @summary Get the email template for a mandator
     * @param {string} mandatorId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DigitalReceiptsApi
     */
    public getEmailTemplate(mandatorId: string, options?: RawAxiosRequestConfig) {
        return DigitalReceiptsApiFp(this.configuration).getEmailTemplate(mandatorId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Returns all mandator receipt templates
     * @summary Get the receipt templates for a mandator
     * @param {string} mandatorId 
     * @param {string} [sort] order ascending or descending the list of templates by a property (name, type, created_at). For descending order the sort field should be prefixed with a minus(\&#39;-\&#39;)
     * @param {number} [pageOffset] number of items that should be skipped and not included in the response
     * @param {number} [pageLimit] number of items to be included in the response
     * @param {GetSitesFilterParameter} [filter] Filters should be sent as encoded URI. Filterable items: name, type
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DigitalReceiptsApi
     */
    public getMandatorTemplatesForReceipts(mandatorId: string, sort?: string, pageOffset?: number, pageLimit?: number, filter?: GetSitesFilterParameter, options?: RawAxiosRequestConfig) {
        return DigitalReceiptsApiFp(this.configuration).getMandatorTemplatesForReceipts(mandatorId, sort, pageOffset, pageLimit, filter, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Returns all fields from the system
     * @summary Get all fields that can be displayed on a receipt
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DigitalReceiptsApi
     */
    public getReceiptFields(options?: RawAxiosRequestConfig) {
        return DigitalReceiptsApiFp(this.configuration).getReceiptFields(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Returns the templates assigned to a site
     * @summary Get the assigned templates to a site
     * @param {string} siteId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DigitalReceiptsApi
     */
    public getSiteAssignments(siteId: string, options?: RawAxiosRequestConfig) {
        return DigitalReceiptsApiFp(this.configuration).getSiteAssignments(siteId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Returns all sites with their templates
     * @summary Get the list of all sites for current mandator with their assigned templates
     * @param {string} [sort] order ascending or descending the list of sites by a property (name, city). For descending order the sort field should be prefixed with a minus(\&#39;-\&#39;)
     * @param {number} [pageOffset] number of items that should be skipped and not included in the response
     * @param {number} [pageLimit] number of items to be included in the response
     * @param {GetSitesFilterParameter} [filter] Filters should be sent as encoded URI. Filterable items: name, city
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DigitalReceiptsApi
     */
    public getSitesTemplates(sort?: string, pageOffset?: number, pageLimit?: number, filter?: GetSitesFilterParameter, options?: RawAxiosRequestConfig) {
        return DigitalReceiptsApiFp(this.configuration).getSitesTemplates(sort, pageOffset, pageLimit, filter, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Updates the translations for receipt fields
     * @param {Array<PatchFieldsInner>} patchFieldsInner 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DigitalReceiptsApi
     */
    public patchFields(patchFieldsInner: Array<PatchFieldsInner>, options?: RawAxiosRequestConfig) {
        return DigitalReceiptsApiFp(this.configuration).patchFields(patchFieldsInner, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Adds, Deletes or updates templates for a site
     * @param {string} siteId 
     * @param {Array<BaseAssignment>} [baseAssignment] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DigitalReceiptsApi
     */
    public patchSiteAssignments(siteId: string, baseAssignment?: Array<BaseAssignment>, options?: RawAxiosRequestConfig) {
        return DigitalReceiptsApiFp(this.configuration).patchSiteAssignments(siteId, baseAssignment, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Adds, Deletes or Updates site-template assignments based on template id
     * @param {PatchTemplateAssignments} [patchTemplateAssignments] Can contain either a list of site and template ids or assign_all flag and template id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DigitalReceiptsApi
     */
    public patchSiteTemplateAssignments(patchTemplateAssignments?: PatchTemplateAssignments, options?: RawAxiosRequestConfig) {
        return DigitalReceiptsApiFp(this.configuration).patchSiteTemplateAssignments(patchTemplateAssignments, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Updates a receipt template by id
     * @param {string} templateId 
     * @param {UpdateTemplateRequest} updateTemplateRequest 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DigitalReceiptsApi
     */
    public updateDigitalReceiptTemplate(templateId: string, updateTemplateRequest: UpdateTemplateRequest, options?: RawAxiosRequestConfig) {
        return DigitalReceiptsApiFp(this.configuration).updateDigitalReceiptTemplate(templateId, updateTemplateRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Creates or Updates an email template
     * @param {string} mandatorId 
     * @param {BaseEmailTemplate} baseEmailTemplate 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DigitalReceiptsApi
     */
    public updateEmailTemplate(mandatorId: string, baseEmailTemplate: BaseEmailTemplate, options?: RawAxiosRequestConfig) {
        return DigitalReceiptsApiFp(this.configuration).updateEmailTemplate(mandatorId, baseEmailTemplate, options).then((request) => request(this.axios, this.basePath));
    }
}

/**
 * @export
 */
export const GetDigitalReceiptHistoryResourceNameEnum = {
    Site: 'site',
    Template: 'template',
    Field: 'field',
    MandatorFieldTranslation: 'mandator_field_translation'
} as const;
export type GetDigitalReceiptHistoryResourceNameEnum = typeof GetDigitalReceiptHistoryResourceNameEnum[keyof typeof GetDigitalReceiptHistoryResourceNameEnum];


/**
 * FleetnetApi - axios parameter creator
 * @export
 */
export const FleetnetApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * Fleetnet allows to collect payments using fleetcard
         * @summary Get Module Information for Fleetnet
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getfleetnetModule: async (options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/v3/fleetnet/module`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Authorization required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * FleetnetApi - functional programming interface
 * @export
 */
export const FleetnetApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = FleetnetApiAxiosParamCreator(configuration)
    return {
        /**
         * Fleetnet allows to collect payments using fleetcard
         * @summary Get Module Information for Fleetnet
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getfleetnetModule(options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<FleetNet>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getfleetnetModule(options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['FleetnetApi.getfleetnetModule']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
    }
};

/**
 * FleetnetApi - factory interface
 * @export
 */
export const FleetnetApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = FleetnetApiFp(configuration)
    return {
        /**
         * Fleetnet allows to collect payments using fleetcard
         * @summary Get Module Information for Fleetnet
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getfleetnetModule(options?: RawAxiosRequestConfig): AxiosPromise<FleetNet> {
            return localVarFp.getfleetnetModule(options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * FleetnetApi - object-oriented interface
 * @export
 * @class FleetnetApi
 * @extends {BaseAPI}
 */
export class FleetnetApi extends BaseAPI {
    /**
     * Fleetnet allows to collect payments using fleetcard
     * @summary Get Module Information for Fleetnet
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof FleetnetApi
     */
    public getfleetnetModule(options?: RawAxiosRequestConfig) {
        return FleetnetApiFp(this.configuration).getfleetnetModule(options).then((request) => request(this.axios, this.basePath));
    }
}



/**
 * FreeparkingApi - axios parameter creator
 * @export
 */
export const FreeparkingApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary create an freeparking site Assignment
         * @param {SiteFreeparkingConfigAssignment} [siteFreeparkingConfigAssignment] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        addFreeParkingSiteAssignment: async (siteFreeparkingConfigAssignment?: SiteFreeparkingConfigAssignment, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/parking/freeparking/config/site-assignmnets`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Authorization required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(siteFreeparkingConfigAssignment, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary delete an freeparking site Assignment
         * @param {string} siteId 
         * @param {string} [configurationId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteFreeParkingSiteAssignment: async (siteId: string, configurationId?: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'siteId' is not null or undefined
            assertParamExists('deleteFreeParkingSiteAssignment', 'siteId', siteId)
            const localVarPath = `/parking/freeparking/config/site-assignmnets`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Authorization required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (configurationId !== undefined) {
                localVarQueryParameter['configuration_id'] = configurationId;
            }

            if (siteId !== undefined) {
                localVarQueryParameter['site_id'] = siteId;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Delete Free Parking Configuration By Id
         * @param {string} configurationId id of the configuration
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteNamedConfigById: async (configurationId: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'configurationId' is not null or undefined
            assertParamExists('deleteNamedConfigById', 'configurationId', configurationId)
            const localVarPath = `/parking/freeparking/config/named-configuration/{configuration_id}`
                .replace(`{${"configuration_id"}}`, encodeURIComponent(String(configurationId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Authorization required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Getting Products which are available on a dedicated point.
         * @param {string} siteId Id of the site
         * @param {string} pointId Id of the point
         * @param {string} [sort] order ascending or descending the list of products by a property. For descending order the sort field should be prefixed with a minus(\&#39;-\&#39;)
         * @param {number} [pageOffset] number of items that should be skipped and not included in the response 
         * @param {number} [pageLimit] number of items to be included in the response
         * @param {string} [cnp] license_plate of the car, without separators.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        freeparkingGetProductsOfPoint: async (siteId: string, pointId: string, sort?: string, pageOffset?: number, pageLimit?: number, cnp?: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'siteId' is not null or undefined
            assertParamExists('freeparkingGetProductsOfPoint', 'siteId', siteId)
            // verify required parameter 'pointId' is not null or undefined
            assertParamExists('freeparkingGetProductsOfPoint', 'pointId', pointId)
            const localVarPath = `/parking/freeparking/sites/{site_id}/points/{point_id}/products`
                .replace(`{${"site_id"}}`, encodeURIComponent(String(siteId)))
                .replace(`{${"point_id"}}`, encodeURIComponent(String(pointId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Authorization required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (sort !== undefined) {
                localVarQueryParameter['sort'] = sort;
            }

            if (pageOffset !== undefined) {
                localVarQueryParameter['page[offset]'] = pageOffset;
            }

            if (pageLimit !== undefined) {
                localVarQueryParameter['page[limit]'] = pageLimit;
            }

            if (cnp !== undefined) {
                localVarQueryParameter['cnp'] = cnp;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get Free Parking Site Configuration
         * @param {string} [sort] Comma separate list of sorting parameter. Parameters start with - are descending. default is name. Possible values id,name,city 
         * @param {string} [pageOffset] number of items that should be skipped and not included in the response 
         * @param {string} [pageLimit] number of items to be included in the response
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getFreeparkingSiteConfiguration: async (sort?: string, pageOffset?: string, pageLimit?: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/parking/freeparking/config/sites`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Authorization required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (sort !== undefined) {
                localVarQueryParameter['sort'] = sort;
            }

            if (pageOffset !== undefined) {
                localVarQueryParameter['page[offset]'] = pageOffset;
            }

            if (pageLimit !== undefined) {
                localVarQueryParameter['page[limit]'] = pageLimit;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get Free Parking Site Configuration By Id
         * @param {string} siteId id of the configuration
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getFreeparkingSiteConfigurationById: async (siteId: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'siteId' is not null or undefined
            assertParamExists('getFreeparkingSiteConfigurationById', 'siteId', siteId)
            const localVarPath = `/parking/freeparking/config/sites/{site_id}`
                .replace(`{${"site_id"}}`, encodeURIComponent(String(siteId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Authorization required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Returns information about the Module
         * @summary get Module Information for freeparking
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getModuleInformation: async (options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/parking/freeparking/module`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Authorization required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get Free Parking Configuration
         * @param {string} [include] comma separate value of include fields. Allowed values configurations, sites. 
         * @param {string} [sort] Comma separate list of sorting parameter. Parameters start with - are descending. default is name. Possible values id,name,type,created 
         * @param {string} [pageOffset] number of items that should be skipped and not included in the response 
         * @param {string} [pageLimit] number of items to be included in the response
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getNamedConfig: async (include?: string, sort?: string, pageOffset?: string, pageLimit?: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/parking/freeparking/config/named-configuration`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Authorization required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (include !== undefined) {
                localVarQueryParameter['include'] = include;
            }

            if (sort !== undefined) {
                localVarQueryParameter['sort'] = sort;
            }

            if (pageOffset !== undefined) {
                localVarQueryParameter['page[offset]'] = pageOffset;
            }

            if (pageLimit !== undefined) {
                localVarQueryParameter['page[limit]'] = pageLimit;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get Free Parking Configuration By Id
         * @param {string} configurationId id of the configuration
         * @param {string} [include] comma separate value of include fields. Allowed values configurations, sites. 
         * @param {string} [sort] Comma separate list of sorting parameter. Parameters start with - the are descending. default is name. Possible values id,name,type,created 
         * @param {string} [pageOffset] number of items that should be skipped and not included in the response 
         * @param {string} [pageLimit] number of items to be included in the response
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getNamedConfigById: async (configurationId: string, include?: string, sort?: string, pageOffset?: string, pageLimit?: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'configurationId' is not null or undefined
            assertParamExists('getNamedConfigById', 'configurationId', configurationId)
            const localVarPath = `/parking/freeparking/config/named-configuration/{configuration_id}`
                .replace(`{${"configuration_id"}}`, encodeURIComponent(String(configurationId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Authorization required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (include !== undefined) {
                localVarQueryParameter['include'] = include;
            }

            if (sort !== undefined) {
                localVarQueryParameter['sort'] = sort;
            }

            if (pageOffset !== undefined) {
                localVarQueryParameter['page[offset]'] = pageOffset;
            }

            if (pageLimit !== undefined) {
                localVarQueryParameter['page[limit]'] = pageLimit;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Is Freeparking Visible for the mandator
         * @param {string} mandatorId Mandator unique identifier
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getVisibleStatusForAMandator: async (mandatorId: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'mandatorId' is not null or undefined
            assertParamExists('getVisibleStatusForAMandator', 'mandatorId', mandatorId)
            const localVarPath = `/parking/freeparking/config/mandators/activations/{mandator_id}`
                .replace(`{${"mandator_id"}}`, encodeURIComponent(String(mandatorId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Authorization required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary make Module invisible for mandator
         * @param {string} mandatorId Mandator id to deactivate
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        invisibleModuleForAMandator: async (mandatorId: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'mandatorId' is not null or undefined
            assertParamExists('invisibleModuleForAMandator', 'mandatorId', mandatorId)
            const localVarPath = `/parking/freeparking/config/mandators/activations/{mandator_id}`
                .replace(`{${"mandator_id"}}`, encodeURIComponent(String(mandatorId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Authorization required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary update or create a freeparking Configuration
         * @param {FreeparkingPutNamedConfiguration} [freeparkingPutNamedConfiguration] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateNamedConfig: async (freeparkingPutNamedConfiguration?: FreeparkingPutNamedConfiguration, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/parking/freeparking/config/named-configuration`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Authorization required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(freeparkingPutNamedConfiguration, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Make this module visible for the mandator
         * @param {ActivationRequest} activationRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        visibleModuleForAMandator: async (activationRequest: ActivationRequest, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'activationRequest' is not null or undefined
            assertParamExists('visibleModuleForAMandator', 'activationRequest', activationRequest)
            const localVarPath = `/parking/freeparking/config/mandators/activations`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Authorization required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(activationRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * FreeparkingApi - functional programming interface
 * @export
 */
export const FreeparkingApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = FreeparkingApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary create an freeparking site Assignment
         * @param {SiteFreeparkingConfigAssignment} [siteFreeparkingConfigAssignment] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async addFreeParkingSiteAssignment(siteFreeparkingConfigAssignment?: SiteFreeparkingConfigAssignment, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.addFreeParkingSiteAssignment(siteFreeparkingConfigAssignment, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['FreeparkingApi.addFreeParkingSiteAssignment']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @summary delete an freeparking site Assignment
         * @param {string} siteId 
         * @param {string} [configurationId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async deleteFreeParkingSiteAssignment(siteId: string, configurationId?: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.deleteFreeParkingSiteAssignment(siteId, configurationId, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['FreeparkingApi.deleteFreeParkingSiteAssignment']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @summary Delete Free Parking Configuration By Id
         * @param {string} configurationId id of the configuration
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async deleteNamedConfigById(configurationId: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<FreeparkingNamedConfigurationResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.deleteNamedConfigById(configurationId, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['FreeparkingApi.deleteNamedConfigById']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @summary Getting Products which are available on a dedicated point.
         * @param {string} siteId Id of the site
         * @param {string} pointId Id of the point
         * @param {string} [sort] order ascending or descending the list of products by a property. For descending order the sort field should be prefixed with a minus(\&#39;-\&#39;)
         * @param {number} [pageOffset] number of items that should be skipped and not included in the response 
         * @param {number} [pageLimit] number of items to be included in the response
         * @param {string} [cnp] license_plate of the car, without separators.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async freeparkingGetProductsOfPoint(siteId: string, pointId: string, sort?: string, pageOffset?: number, pageLimit?: number, cnp?: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ParkingProductOfferResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.freeparkingGetProductsOfPoint(siteId, pointId, sort, pageOffset, pageLimit, cnp, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['FreeparkingApi.freeparkingGetProductsOfPoint']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @summary Get Free Parking Site Configuration
         * @param {string} [sort] Comma separate list of sorting parameter. Parameters start with - are descending. default is name. Possible values id,name,city 
         * @param {string} [pageOffset] number of items that should be skipped and not included in the response 
         * @param {string} [pageLimit] number of items to be included in the response
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getFreeparkingSiteConfiguration(sort?: string, pageOffset?: string, pageLimit?: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<FreeparkingConfigurationSiteResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getFreeparkingSiteConfiguration(sort, pageOffset, pageLimit, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['FreeparkingApi.getFreeparkingSiteConfiguration']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @summary Get Free Parking Site Configuration By Id
         * @param {string} siteId id of the configuration
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getFreeparkingSiteConfigurationById(siteId: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<FreeparkingConfigurationSiteResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getFreeparkingSiteConfigurationById(siteId, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['FreeparkingApi.getFreeparkingSiteConfigurationById']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * Returns information about the Module
         * @summary get Module Information for freeparking
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getModuleInformation(options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Module>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getModuleInformation(options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['FreeparkingApi.getModuleInformation']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @summary Get Free Parking Configuration
         * @param {string} [include] comma separate value of include fields. Allowed values configurations, sites. 
         * @param {string} [sort] Comma separate list of sorting parameter. Parameters start with - are descending. default is name. Possible values id,name,type,created 
         * @param {string} [pageOffset] number of items that should be skipped and not included in the response 
         * @param {string} [pageLimit] number of items to be included in the response
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getNamedConfig(include?: string, sort?: string, pageOffset?: string, pageLimit?: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<FreeparkingNamedConfigurationResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getNamedConfig(include, sort, pageOffset, pageLimit, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['FreeparkingApi.getNamedConfig']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @summary Get Free Parking Configuration By Id
         * @param {string} configurationId id of the configuration
         * @param {string} [include] comma separate value of include fields. Allowed values configurations, sites. 
         * @param {string} [sort] Comma separate list of sorting parameter. Parameters start with - the are descending. default is name. Possible values id,name,type,created 
         * @param {string} [pageOffset] number of items that should be skipped and not included in the response 
         * @param {string} [pageLimit] number of items to be included in the response
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getNamedConfigById(configurationId: string, include?: string, sort?: string, pageOffset?: string, pageLimit?: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<FreeparkingNamedConfigurationResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getNamedConfigById(configurationId, include, sort, pageOffset, pageLimit, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['FreeparkingApi.getNamedConfigById']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @summary Is Freeparking Visible for the mandator
         * @param {string} mandatorId Mandator unique identifier
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getVisibleStatusForAMandator(mandatorId: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Activation>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getVisibleStatusForAMandator(mandatorId, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['FreeparkingApi.getVisibleStatusForAMandator']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @summary make Module invisible for mandator
         * @param {string} mandatorId Mandator id to deactivate
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async invisibleModuleForAMandator(mandatorId: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<number>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.invisibleModuleForAMandator(mandatorId, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['FreeparkingApi.invisibleModuleForAMandator']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @summary update or create a freeparking Configuration
         * @param {FreeparkingPutNamedConfiguration} [freeparkingPutNamedConfiguration] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updateNamedConfig(freeparkingPutNamedConfiguration?: FreeparkingPutNamedConfiguration, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<FreeparkingNamedConfigurationResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.updateNamedConfig(freeparkingPutNamedConfiguration, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['FreeparkingApi.updateNamedConfig']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @summary Make this module visible for the mandator
         * @param {ActivationRequest} activationRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async visibleModuleForAMandator(activationRequest: ActivationRequest, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ActivationResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.visibleModuleForAMandator(activationRequest, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['FreeparkingApi.visibleModuleForAMandator']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
    }
};

/**
 * FreeparkingApi - factory interface
 * @export
 */
export const FreeparkingApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = FreeparkingApiFp(configuration)
    return {
        /**
         * 
         * @summary create an freeparking site Assignment
         * @param {SiteFreeparkingConfigAssignment} [siteFreeparkingConfigAssignment] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        addFreeParkingSiteAssignment(siteFreeparkingConfigAssignment?: SiteFreeparkingConfigAssignment, options?: RawAxiosRequestConfig): AxiosPromise<void> {
            return localVarFp.addFreeParkingSiteAssignment(siteFreeparkingConfigAssignment, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary delete an freeparking site Assignment
         * @param {string} siteId 
         * @param {string} [configurationId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteFreeParkingSiteAssignment(siteId: string, configurationId?: string, options?: RawAxiosRequestConfig): AxiosPromise<void> {
            return localVarFp.deleteFreeParkingSiteAssignment(siteId, configurationId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Delete Free Parking Configuration By Id
         * @param {string} configurationId id of the configuration
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteNamedConfigById(configurationId: string, options?: RawAxiosRequestConfig): AxiosPromise<FreeparkingNamedConfigurationResponse> {
            return localVarFp.deleteNamedConfigById(configurationId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Getting Products which are available on a dedicated point.
         * @param {string} siteId Id of the site
         * @param {string} pointId Id of the point
         * @param {string} [sort] order ascending or descending the list of products by a property. For descending order the sort field should be prefixed with a minus(\&#39;-\&#39;)
         * @param {number} [pageOffset] number of items that should be skipped and not included in the response 
         * @param {number} [pageLimit] number of items to be included in the response
         * @param {string} [cnp] license_plate of the car, without separators.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        freeparkingGetProductsOfPoint(siteId: string, pointId: string, sort?: string, pageOffset?: number, pageLimit?: number, cnp?: string, options?: RawAxiosRequestConfig): AxiosPromise<ParkingProductOfferResponse> {
            return localVarFp.freeparkingGetProductsOfPoint(siteId, pointId, sort, pageOffset, pageLimit, cnp, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get Free Parking Site Configuration
         * @param {string} [sort] Comma separate list of sorting parameter. Parameters start with - are descending. default is name. Possible values id,name,city 
         * @param {string} [pageOffset] number of items that should be skipped and not included in the response 
         * @param {string} [pageLimit] number of items to be included in the response
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getFreeparkingSiteConfiguration(sort?: string, pageOffset?: string, pageLimit?: string, options?: RawAxiosRequestConfig): AxiosPromise<FreeparkingConfigurationSiteResponse> {
            return localVarFp.getFreeparkingSiteConfiguration(sort, pageOffset, pageLimit, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get Free Parking Site Configuration By Id
         * @param {string} siteId id of the configuration
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getFreeparkingSiteConfigurationById(siteId: string, options?: RawAxiosRequestConfig): AxiosPromise<FreeparkingConfigurationSiteResponse> {
            return localVarFp.getFreeparkingSiteConfigurationById(siteId, options).then((request) => request(axios, basePath));
        },
        /**
         * Returns information about the Module
         * @summary get Module Information for freeparking
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getModuleInformation(options?: RawAxiosRequestConfig): AxiosPromise<Module> {
            return localVarFp.getModuleInformation(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get Free Parking Configuration
         * @param {string} [include] comma separate value of include fields. Allowed values configurations, sites. 
         * @param {string} [sort] Comma separate list of sorting parameter. Parameters start with - are descending. default is name. Possible values id,name,type,created 
         * @param {string} [pageOffset] number of items that should be skipped and not included in the response 
         * @param {string} [pageLimit] number of items to be included in the response
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getNamedConfig(include?: string, sort?: string, pageOffset?: string, pageLimit?: string, options?: RawAxiosRequestConfig): AxiosPromise<FreeparkingNamedConfigurationResponse> {
            return localVarFp.getNamedConfig(include, sort, pageOffset, pageLimit, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get Free Parking Configuration By Id
         * @param {string} configurationId id of the configuration
         * @param {string} [include] comma separate value of include fields. Allowed values configurations, sites. 
         * @param {string} [sort] Comma separate list of sorting parameter. Parameters start with - the are descending. default is name. Possible values id,name,type,created 
         * @param {string} [pageOffset] number of items that should be skipped and not included in the response 
         * @param {string} [pageLimit] number of items to be included in the response
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getNamedConfigById(configurationId: string, include?: string, sort?: string, pageOffset?: string, pageLimit?: string, options?: RawAxiosRequestConfig): AxiosPromise<FreeparkingNamedConfigurationResponse> {
            return localVarFp.getNamedConfigById(configurationId, include, sort, pageOffset, pageLimit, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Is Freeparking Visible for the mandator
         * @param {string} mandatorId Mandator unique identifier
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getVisibleStatusForAMandator(mandatorId: string, options?: RawAxiosRequestConfig): AxiosPromise<Activation> {
            return localVarFp.getVisibleStatusForAMandator(mandatorId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary make Module invisible for mandator
         * @param {string} mandatorId Mandator id to deactivate
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        invisibleModuleForAMandator(mandatorId: string, options?: RawAxiosRequestConfig): AxiosPromise<number> {
            return localVarFp.invisibleModuleForAMandator(mandatorId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary update or create a freeparking Configuration
         * @param {FreeparkingPutNamedConfiguration} [freeparkingPutNamedConfiguration] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateNamedConfig(freeparkingPutNamedConfiguration?: FreeparkingPutNamedConfiguration, options?: RawAxiosRequestConfig): AxiosPromise<FreeparkingNamedConfigurationResponse> {
            return localVarFp.updateNamedConfig(freeparkingPutNamedConfiguration, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Make this module visible for the mandator
         * @param {ActivationRequest} activationRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        visibleModuleForAMandator(activationRequest: ActivationRequest, options?: RawAxiosRequestConfig): AxiosPromise<ActivationResponse> {
            return localVarFp.visibleModuleForAMandator(activationRequest, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * FreeparkingApi - object-oriented interface
 * @export
 * @class FreeparkingApi
 * @extends {BaseAPI}
 */
export class FreeparkingApi extends BaseAPI {
    /**
     * 
     * @summary create an freeparking site Assignment
     * @param {SiteFreeparkingConfigAssignment} [siteFreeparkingConfigAssignment] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof FreeparkingApi
     */
    public addFreeParkingSiteAssignment(siteFreeparkingConfigAssignment?: SiteFreeparkingConfigAssignment, options?: RawAxiosRequestConfig) {
        return FreeparkingApiFp(this.configuration).addFreeParkingSiteAssignment(siteFreeparkingConfigAssignment, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary delete an freeparking site Assignment
     * @param {string} siteId 
     * @param {string} [configurationId] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof FreeparkingApi
     */
    public deleteFreeParkingSiteAssignment(siteId: string, configurationId?: string, options?: RawAxiosRequestConfig) {
        return FreeparkingApiFp(this.configuration).deleteFreeParkingSiteAssignment(siteId, configurationId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Delete Free Parking Configuration By Id
     * @param {string} configurationId id of the configuration
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof FreeparkingApi
     */
    public deleteNamedConfigById(configurationId: string, options?: RawAxiosRequestConfig) {
        return FreeparkingApiFp(this.configuration).deleteNamedConfigById(configurationId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Getting Products which are available on a dedicated point.
     * @param {string} siteId Id of the site
     * @param {string} pointId Id of the point
     * @param {string} [sort] order ascending or descending the list of products by a property. For descending order the sort field should be prefixed with a minus(\&#39;-\&#39;)
     * @param {number} [pageOffset] number of items that should be skipped and not included in the response 
     * @param {number} [pageLimit] number of items to be included in the response
     * @param {string} [cnp] license_plate of the car, without separators.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof FreeparkingApi
     */
    public freeparkingGetProductsOfPoint(siteId: string, pointId: string, sort?: string, pageOffset?: number, pageLimit?: number, cnp?: string, options?: RawAxiosRequestConfig) {
        return FreeparkingApiFp(this.configuration).freeparkingGetProductsOfPoint(siteId, pointId, sort, pageOffset, pageLimit, cnp, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get Free Parking Site Configuration
     * @param {string} [sort] Comma separate list of sorting parameter. Parameters start with - are descending. default is name. Possible values id,name,city 
     * @param {string} [pageOffset] number of items that should be skipped and not included in the response 
     * @param {string} [pageLimit] number of items to be included in the response
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof FreeparkingApi
     */
    public getFreeparkingSiteConfiguration(sort?: string, pageOffset?: string, pageLimit?: string, options?: RawAxiosRequestConfig) {
        return FreeparkingApiFp(this.configuration).getFreeparkingSiteConfiguration(sort, pageOffset, pageLimit, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get Free Parking Site Configuration By Id
     * @param {string} siteId id of the configuration
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof FreeparkingApi
     */
    public getFreeparkingSiteConfigurationById(siteId: string, options?: RawAxiosRequestConfig) {
        return FreeparkingApiFp(this.configuration).getFreeparkingSiteConfigurationById(siteId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Returns information about the Module
     * @summary get Module Information for freeparking
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof FreeparkingApi
     */
    public getModuleInformation(options?: RawAxiosRequestConfig) {
        return FreeparkingApiFp(this.configuration).getModuleInformation(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get Free Parking Configuration
     * @param {string} [include] comma separate value of include fields. Allowed values configurations, sites. 
     * @param {string} [sort] Comma separate list of sorting parameter. Parameters start with - are descending. default is name. Possible values id,name,type,created 
     * @param {string} [pageOffset] number of items that should be skipped and not included in the response 
     * @param {string} [pageLimit] number of items to be included in the response
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof FreeparkingApi
     */
    public getNamedConfig(include?: string, sort?: string, pageOffset?: string, pageLimit?: string, options?: RawAxiosRequestConfig) {
        return FreeparkingApiFp(this.configuration).getNamedConfig(include, sort, pageOffset, pageLimit, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get Free Parking Configuration By Id
     * @param {string} configurationId id of the configuration
     * @param {string} [include] comma separate value of include fields. Allowed values configurations, sites. 
     * @param {string} [sort] Comma separate list of sorting parameter. Parameters start with - the are descending. default is name. Possible values id,name,type,created 
     * @param {string} [pageOffset] number of items that should be skipped and not included in the response 
     * @param {string} [pageLimit] number of items to be included in the response
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof FreeparkingApi
     */
    public getNamedConfigById(configurationId: string, include?: string, sort?: string, pageOffset?: string, pageLimit?: string, options?: RawAxiosRequestConfig) {
        return FreeparkingApiFp(this.configuration).getNamedConfigById(configurationId, include, sort, pageOffset, pageLimit, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Is Freeparking Visible for the mandator
     * @param {string} mandatorId Mandator unique identifier
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof FreeparkingApi
     */
    public getVisibleStatusForAMandator(mandatorId: string, options?: RawAxiosRequestConfig) {
        return FreeparkingApiFp(this.configuration).getVisibleStatusForAMandator(mandatorId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary make Module invisible for mandator
     * @param {string} mandatorId Mandator id to deactivate
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof FreeparkingApi
     */
    public invisibleModuleForAMandator(mandatorId: string, options?: RawAxiosRequestConfig) {
        return FreeparkingApiFp(this.configuration).invisibleModuleForAMandator(mandatorId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary update or create a freeparking Configuration
     * @param {FreeparkingPutNamedConfiguration} [freeparkingPutNamedConfiguration] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof FreeparkingApi
     */
    public updateNamedConfig(freeparkingPutNamedConfiguration?: FreeparkingPutNamedConfiguration, options?: RawAxiosRequestConfig) {
        return FreeparkingApiFp(this.configuration).updateNamedConfig(freeparkingPutNamedConfiguration, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Make this module visible for the mandator
     * @param {ActivationRequest} activationRequest 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof FreeparkingApi
     */
    public visibleModuleForAMandator(activationRequest: ActivationRequest, options?: RawAxiosRequestConfig) {
        return FreeparkingApiFp(this.configuration).visibleModuleForAMandator(activationRequest, options).then((request) => request(this.axios, this.basePath));
    }
}



/**
 * IdentificationsApi - axios parameter creator
 * @export
 */
export const IdentificationsApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * update identification request with missing information required for auth
         * @summary update identification request
         * @param {string} identId UUID of identification request to update
         * @param {IdentificationRequest} [identificationRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v3IdentificationsIdentIdPatch: async (identId: string, identificationRequest?: IdentificationRequest, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'identId' is not null or undefined
            assertParamExists('v3IdentificationsIdentIdPatch', 'identId', identId)
            const localVarPath = `/v3/identifications/{ident_id}`
                .replace(`{${"ident_id"}}`, encodeURIComponent(String(identId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Authorization required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(identificationRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * New request for identification and authorisation of card
         * @summary new identification request
         * @param {IdentificationRequest} [identificationRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v3IdentificationsPost: async (identificationRequest?: IdentificationRequest, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/v3/identifications`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Authorization required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(identificationRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * IdentificationsApi - functional programming interface
 * @export
 */
export const IdentificationsApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = IdentificationsApiAxiosParamCreator(configuration)
    return {
        /**
         * update identification request with missing information required for auth
         * @summary update identification request
         * @param {string} identId UUID of identification request to update
         * @param {IdentificationRequest} [identificationRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async v3IdentificationsIdentIdPatch(identId: string, identificationRequest?: IdentificationRequest, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<IdentificationResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.v3IdentificationsIdentIdPatch(identId, identificationRequest, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['IdentificationsApi.v3IdentificationsIdentIdPatch']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * New request for identification and authorisation of card
         * @summary new identification request
         * @param {IdentificationRequest} [identificationRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async v3IdentificationsPost(identificationRequest?: IdentificationRequest, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<IdentificationResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.v3IdentificationsPost(identificationRequest, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['IdentificationsApi.v3IdentificationsPost']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
    }
};

/**
 * IdentificationsApi - factory interface
 * @export
 */
export const IdentificationsApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = IdentificationsApiFp(configuration)
    return {
        /**
         * update identification request with missing information required for auth
         * @summary update identification request
         * @param {string} identId UUID of identification request to update
         * @param {IdentificationRequest} [identificationRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v3IdentificationsIdentIdPatch(identId: string, identificationRequest?: IdentificationRequest, options?: RawAxiosRequestConfig): AxiosPromise<IdentificationResponse> {
            return localVarFp.v3IdentificationsIdentIdPatch(identId, identificationRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * New request for identification and authorisation of card
         * @summary new identification request
         * @param {IdentificationRequest} [identificationRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v3IdentificationsPost(identificationRequest?: IdentificationRequest, options?: RawAxiosRequestConfig): AxiosPromise<IdentificationResponse> {
            return localVarFp.v3IdentificationsPost(identificationRequest, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * IdentificationsApi - object-oriented interface
 * @export
 * @class IdentificationsApi
 * @extends {BaseAPI}
 */
export class IdentificationsApi extends BaseAPI {
    /**
     * update identification request with missing information required for auth
     * @summary update identification request
     * @param {string} identId UUID of identification request to update
     * @param {IdentificationRequest} [identificationRequest] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof IdentificationsApi
     */
    public v3IdentificationsIdentIdPatch(identId: string, identificationRequest?: IdentificationRequest, options?: RawAxiosRequestConfig) {
        return IdentificationsApiFp(this.configuration).v3IdentificationsIdentIdPatch(identId, identificationRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * New request for identification and authorisation of card
     * @summary new identification request
     * @param {IdentificationRequest} [identificationRequest] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof IdentificationsApi
     */
    public v3IdentificationsPost(identificationRequest?: IdentificationRequest, options?: RawAxiosRequestConfig) {
        return IdentificationsApiFp(this.configuration).v3IdentificationsPost(identificationRequest, options).then((request) => request(this.axios, this.basePath));
    }
}



/**
 * MandatorsApi - axios parameter creator
 * @export
 */
export const MandatorsApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * Adds a new mandator in the system
         * @summary Add a Mandator
         * @param {MandatorCreate} mandatorCreate mandator object to be created
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createMandator: async (mandatorCreate: MandatorCreate, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'mandatorCreate' is not null or undefined
            assertParamExists('createMandator', 'mandatorCreate', mandatorCreate)
            const localVarPath = `/mandators`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Authorization required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(mandatorCreate, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Deletes a mandator by id
         * @param {string} mandatorId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteMandator: async (mandatorId: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'mandatorId' is not null or undefined
            assertParamExists('deleteMandator', 'mandatorId', mandatorId)
            const localVarPath = `/mandators/{mandator_id}`
                .replace(`{${"mandator_id"}}`, encodeURIComponent(String(mandatorId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Authorization required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get Mandator by Id
         * @param {string} mandatorId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getMandator: async (mandatorId: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'mandatorId' is not null or undefined
            assertParamExists('getMandator', 'mandatorId', mandatorId)
            const localVarPath = `/mandators/{mandator_id}`
                .replace(`{${"mandator_id"}}`, encodeURIComponent(String(mandatorId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Authorization required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Returns a list of mandators based on given filters, pagination and sorting options.
         * @param {string} [sort] order ascending or descending the list of mandators by a property. For descending order the sort field should be prefixed with a minus(\&#39;-\&#39;)
         * @param {number} [pageOffset] number of items that should be skipped and not included in the response
         * @param {number} [pageLimit] number of items to be included in the response
         * @param {GetSitesFilterParameter} [filter] Filters should be sent as encoded URI.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getMandators: async (sort?: string, pageOffset?: number, pageLimit?: number, filter?: GetSitesFilterParameter, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/mandators`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Authorization required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (sort !== undefined) {
                localVarQueryParameter['sort'] = sort;
            }

            if (pageOffset !== undefined) {
                localVarQueryParameter['page[offset]'] = pageOffset;
            }

            if (pageLimit !== undefined) {
                localVarQueryParameter['page[limit]'] = pageLimit;
            }

            if (filter !== undefined) {
                for (const [key, value] of Object.entries(filter)) {
                    localVarQueryParameter[key] = value;
                }
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Updates a mandator by Id
         * @summary Update a Mandator
         * @param {string} mandatorId 
         * @param {MandatorUpdate} mandatorUpdate 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateMandator: async (mandatorId: string, mandatorUpdate: MandatorUpdate, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'mandatorId' is not null or undefined
            assertParamExists('updateMandator', 'mandatorId', mandatorId)
            // verify required parameter 'mandatorUpdate' is not null or undefined
            assertParamExists('updateMandator', 'mandatorUpdate', mandatorUpdate)
            const localVarPath = `/mandators/{mandator_id}`
                .replace(`{${"mandator_id"}}`, encodeURIComponent(String(mandatorId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Authorization required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(mandatorUpdate, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Updates the status for a list of mandators
         * @summary Update Status for a List of Mandators
         * @param {MandatorUpdateList} mandatorUpdateList 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateMandatorList: async (mandatorUpdateList: MandatorUpdateList, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'mandatorUpdateList' is not null or undefined
            assertParamExists('updateMandatorList', 'mandatorUpdateList', mandatorUpdateList)
            const localVarPath = `/mandators`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Authorization required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(mandatorUpdateList, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * MandatorsApi - functional programming interface
 * @export
 */
export const MandatorsApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = MandatorsApiAxiosParamCreator(configuration)
    return {
        /**
         * Adds a new mandator in the system
         * @summary Add a Mandator
         * @param {MandatorCreate} mandatorCreate mandator object to be created
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async createMandator(mandatorCreate: MandatorCreate, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<CreateMandator201Response>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.createMandator(mandatorCreate, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['MandatorsApi.createMandator']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @summary Deletes a mandator by id
         * @param {string} mandatorId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async deleteMandator(mandatorId: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<UpdateSite200Response>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.deleteMandator(mandatorId, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['MandatorsApi.deleteMandator']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @summary Get Mandator by Id
         * @param {string} mandatorId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getMandator(mandatorId: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<GetMandator200Response>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getMandator(mandatorId, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['MandatorsApi.getMandator']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @summary Returns a list of mandators based on given filters, pagination and sorting options.
         * @param {string} [sort] order ascending or descending the list of mandators by a property. For descending order the sort field should be prefixed with a minus(\&#39;-\&#39;)
         * @param {number} [pageOffset] number of items that should be skipped and not included in the response
         * @param {number} [pageLimit] number of items to be included in the response
         * @param {GetSitesFilterParameter} [filter] Filters should be sent as encoded URI.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getMandators(sort?: string, pageOffset?: number, pageLimit?: number, filter?: GetSitesFilterParameter, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<GetMandators200Response>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getMandators(sort, pageOffset, pageLimit, filter, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['MandatorsApi.getMandators']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * Updates a mandator by Id
         * @summary Update a Mandator
         * @param {string} mandatorId 
         * @param {MandatorUpdate} mandatorUpdate 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updateMandator(mandatorId: string, mandatorUpdate: MandatorUpdate, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<UpdateSite200Response>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.updateMandator(mandatorId, mandatorUpdate, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['MandatorsApi.updateMandator']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * Updates the status for a list of mandators
         * @summary Update Status for a List of Mandators
         * @param {MandatorUpdateList} mandatorUpdateList 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updateMandatorList(mandatorUpdateList: MandatorUpdateList, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<UpdateSite200Response>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.updateMandatorList(mandatorUpdateList, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['MandatorsApi.updateMandatorList']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
    }
};

/**
 * MandatorsApi - factory interface
 * @export
 */
export const MandatorsApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = MandatorsApiFp(configuration)
    return {
        /**
         * Adds a new mandator in the system
         * @summary Add a Mandator
         * @param {MandatorCreate} mandatorCreate mandator object to be created
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createMandator(mandatorCreate: MandatorCreate, options?: RawAxiosRequestConfig): AxiosPromise<CreateMandator201Response> {
            return localVarFp.createMandator(mandatorCreate, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Deletes a mandator by id
         * @param {string} mandatorId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteMandator(mandatorId: string, options?: RawAxiosRequestConfig): AxiosPromise<UpdateSite200Response> {
            return localVarFp.deleteMandator(mandatorId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get Mandator by Id
         * @param {string} mandatorId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getMandator(mandatorId: string, options?: RawAxiosRequestConfig): AxiosPromise<GetMandator200Response> {
            return localVarFp.getMandator(mandatorId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Returns a list of mandators based on given filters, pagination and sorting options.
         * @param {string} [sort] order ascending or descending the list of mandators by a property. For descending order the sort field should be prefixed with a minus(\&#39;-\&#39;)
         * @param {number} [pageOffset] number of items that should be skipped and not included in the response
         * @param {number} [pageLimit] number of items to be included in the response
         * @param {GetSitesFilterParameter} [filter] Filters should be sent as encoded URI.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getMandators(sort?: string, pageOffset?: number, pageLimit?: number, filter?: GetSitesFilterParameter, options?: RawAxiosRequestConfig): AxiosPromise<GetMandators200Response> {
            return localVarFp.getMandators(sort, pageOffset, pageLimit, filter, options).then((request) => request(axios, basePath));
        },
        /**
         * Updates a mandator by Id
         * @summary Update a Mandator
         * @param {string} mandatorId 
         * @param {MandatorUpdate} mandatorUpdate 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateMandator(mandatorId: string, mandatorUpdate: MandatorUpdate, options?: RawAxiosRequestConfig): AxiosPromise<UpdateSite200Response> {
            return localVarFp.updateMandator(mandatorId, mandatorUpdate, options).then((request) => request(axios, basePath));
        },
        /**
         * Updates the status for a list of mandators
         * @summary Update Status for a List of Mandators
         * @param {MandatorUpdateList} mandatorUpdateList 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateMandatorList(mandatorUpdateList: MandatorUpdateList, options?: RawAxiosRequestConfig): AxiosPromise<UpdateSite200Response> {
            return localVarFp.updateMandatorList(mandatorUpdateList, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * MandatorsApi - object-oriented interface
 * @export
 * @class MandatorsApi
 * @extends {BaseAPI}
 */
export class MandatorsApi extends BaseAPI {
    /**
     * Adds a new mandator in the system
     * @summary Add a Mandator
     * @param {MandatorCreate} mandatorCreate mandator object to be created
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MandatorsApi
     */
    public createMandator(mandatorCreate: MandatorCreate, options?: RawAxiosRequestConfig) {
        return MandatorsApiFp(this.configuration).createMandator(mandatorCreate, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Deletes a mandator by id
     * @param {string} mandatorId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MandatorsApi
     */
    public deleteMandator(mandatorId: string, options?: RawAxiosRequestConfig) {
        return MandatorsApiFp(this.configuration).deleteMandator(mandatorId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get Mandator by Id
     * @param {string} mandatorId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MandatorsApi
     */
    public getMandator(mandatorId: string, options?: RawAxiosRequestConfig) {
        return MandatorsApiFp(this.configuration).getMandator(mandatorId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Returns a list of mandators based on given filters, pagination and sorting options.
     * @param {string} [sort] order ascending or descending the list of mandators by a property. For descending order the sort field should be prefixed with a minus(\&#39;-\&#39;)
     * @param {number} [pageOffset] number of items that should be skipped and not included in the response
     * @param {number} [pageLimit] number of items to be included in the response
     * @param {GetSitesFilterParameter} [filter] Filters should be sent as encoded URI.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MandatorsApi
     */
    public getMandators(sort?: string, pageOffset?: number, pageLimit?: number, filter?: GetSitesFilterParameter, options?: RawAxiosRequestConfig) {
        return MandatorsApiFp(this.configuration).getMandators(sort, pageOffset, pageLimit, filter, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Updates a mandator by Id
     * @summary Update a Mandator
     * @param {string} mandatorId 
     * @param {MandatorUpdate} mandatorUpdate 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MandatorsApi
     */
    public updateMandator(mandatorId: string, mandatorUpdate: MandatorUpdate, options?: RawAxiosRequestConfig) {
        return MandatorsApiFp(this.configuration).updateMandator(mandatorId, mandatorUpdate, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Updates the status for a list of mandators
     * @summary Update Status for a List of Mandators
     * @param {MandatorUpdateList} mandatorUpdateList 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MandatorsApi
     */
    public updateMandatorList(mandatorUpdateList: MandatorUpdateList, options?: RawAxiosRequestConfig) {
        return MandatorsApiFp(this.configuration).updateMandatorList(mandatorUpdateList, options).then((request) => request(this.axios, this.basePath));
    }
}



/**
 * ManufacturersApi - axios parameter creator
 * @export
 */
export const ManufacturersApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Add a manufacturer and its device models/types
         * @param {CreateManufacturer} createManufacturer 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createManufacturer: async (createManufacturer: CreateManufacturer, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'createManufacturer' is not null or undefined
            assertParamExists('createManufacturer', 'createManufacturer', createManufacturer)
            const localVarPath = `/manufacturers`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Authorization required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(createManufacturer, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Deletes a manufacturer by id
         * @param {string} manufacturerId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteManufacturer: async (manufacturerId: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'manufacturerId' is not null or undefined
            assertParamExists('deleteManufacturer', 'manufacturerId', manufacturerId)
            const localVarPath = `/manufacturers/{manufacturer_id}`
                .replace(`{${"manufacturer_id"}}`, encodeURIComponent(String(manufacturerId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Authorization required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Returns all manufacturers
         * @param {string} [protocolId] if not set all manufacturers will be returned
         * @param {string} [sort] order ascending or descending the list of manufacturers by a property. For descending order the sort field should be prefixed with a minus(\&#39;-\&#39;)
         * @param {number} [pageOffset] number of items that should be skipped and not included in the response
         * @param {number} [pageLimit] number of items to be included in the response
         * @param {GetSitesFilterParameter} [filter] Filters should be sent as encoded URI. Filterable items: name
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        geManufacturers: async (protocolId?: string, sort?: string, pageOffset?: number, pageLimit?: number, filter?: GetSitesFilterParameter, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/manufacturers`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Authorization required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (protocolId !== undefined) {
                localVarQueryParameter['protocol_id'] = protocolId;
            }

            if (sort !== undefined) {
                localVarQueryParameter['sort'] = sort;
            }

            if (pageOffset !== undefined) {
                localVarQueryParameter['page[offset]'] = pageOffset;
            }

            if (pageLimit !== undefined) {
                localVarQueryParameter['page[limit]'] = pageLimit;
            }

            if (filter !== undefined) {
                for (const [key, value] of Object.entries(filter)) {
                    localVarQueryParameter[key] = value;
                }
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Returns manufacturer details by id
         * @param {string} manufacturerId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getManufacturer: async (manufacturerId: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'manufacturerId' is not null or undefined
            assertParamExists('getManufacturer', 'manufacturerId', manufacturerId)
            const localVarPath = `/manufacturers/{manufacturer_id}`
                .replace(`{${"manufacturer_id"}}`, encodeURIComponent(String(manufacturerId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Authorization required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Returns models for a manufacturer
         * @param {string} manufacturerId 
         * @param {string} [sort] order ascending or descending the list of models by a property. For descending order the sort field should be prefixed with a minus(\&#39;-\&#39;)
         * @param {number} [pageOffset] number of items that should be skipped and not included in the response
         * @param {number} [pageLimit] number of items to be included in the response
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getManufacturerModels: async (manufacturerId: string, sort?: string, pageOffset?: number, pageLimit?: number, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'manufacturerId' is not null or undefined
            assertParamExists('getManufacturerModels', 'manufacturerId', manufacturerId)
            const localVarPath = `/manufacturers/{manufacturer_id}/models`
                .replace(`{${"manufacturer_id"}}`, encodeURIComponent(String(manufacturerId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Authorization required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (sort !== undefined) {
                localVarQueryParameter['sort'] = sort;
            }

            if (pageOffset !== undefined) {
                localVarQueryParameter['page[offset]'] = pageOffset;
            }

            if (pageLimit !== undefined) {
                localVarQueryParameter['page[limit]'] = pageLimit;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Updates manufacturer details, its protocols and device models
         * @param {string} manufacturerId 
         * @param {UpdateManufacturer} updateManufacturer 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateManufacturer: async (manufacturerId: string, updateManufacturer: UpdateManufacturer, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'manufacturerId' is not null or undefined
            assertParamExists('updateManufacturer', 'manufacturerId', manufacturerId)
            // verify required parameter 'updateManufacturer' is not null or undefined
            assertParamExists('updateManufacturer', 'updateManufacturer', updateManufacturer)
            const localVarPath = `/manufacturers/{manufacturer_id}`
                .replace(`{${"manufacturer_id"}}`, encodeURIComponent(String(manufacturerId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Authorization required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(updateManufacturer, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * ManufacturersApi - functional programming interface
 * @export
 */
export const ManufacturersApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = ManufacturersApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary Add a manufacturer and its device models/types
         * @param {CreateManufacturer} createManufacturer 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async createManufacturer(createManufacturer: CreateManufacturer, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<CreateManufacturer200Response>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.createManufacturer(createManufacturer, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['ManufacturersApi.createManufacturer']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @summary Deletes a manufacturer by id
         * @param {string} manufacturerId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async deleteManufacturer(manufacturerId: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<UpdateSite200Response>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.deleteManufacturer(manufacturerId, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['ManufacturersApi.deleteManufacturer']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @summary Returns all manufacturers
         * @param {string} [protocolId] if not set all manufacturers will be returned
         * @param {string} [sort] order ascending or descending the list of manufacturers by a property. For descending order the sort field should be prefixed with a minus(\&#39;-\&#39;)
         * @param {number} [pageOffset] number of items that should be skipped and not included in the response
         * @param {number} [pageLimit] number of items to be included in the response
         * @param {GetSitesFilterParameter} [filter] Filters should be sent as encoded URI. Filterable items: name
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async geManufacturers(protocolId?: string, sort?: string, pageOffset?: number, pageLimit?: number, filter?: GetSitesFilterParameter, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<GeManufacturers200Response>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.geManufacturers(protocolId, sort, pageOffset, pageLimit, filter, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['ManufacturersApi.geManufacturers']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @summary Returns manufacturer details by id
         * @param {string} manufacturerId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getManufacturer(manufacturerId: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<GetManufacturer200Response>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getManufacturer(manufacturerId, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['ManufacturersApi.getManufacturer']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @summary Returns models for a manufacturer
         * @param {string} manufacturerId 
         * @param {string} [sort] order ascending or descending the list of models by a property. For descending order the sort field should be prefixed with a minus(\&#39;-\&#39;)
         * @param {number} [pageOffset] number of items that should be skipped and not included in the response
         * @param {number} [pageLimit] number of items to be included in the response
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getManufacturerModels(manufacturerId: string, sort?: string, pageOffset?: number, pageLimit?: number, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<GetDeviceModels200Response>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getManufacturerModels(manufacturerId, sort, pageOffset, pageLimit, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['ManufacturersApi.getManufacturerModels']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @summary Updates manufacturer details, its protocols and device models
         * @param {string} manufacturerId 
         * @param {UpdateManufacturer} updateManufacturer 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updateManufacturer(manufacturerId: string, updateManufacturer: UpdateManufacturer, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<UpdateSite200Response>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.updateManufacturer(manufacturerId, updateManufacturer, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['ManufacturersApi.updateManufacturer']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
    }
};

/**
 * ManufacturersApi - factory interface
 * @export
 */
export const ManufacturersApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = ManufacturersApiFp(configuration)
    return {
        /**
         * 
         * @summary Add a manufacturer and its device models/types
         * @param {CreateManufacturer} createManufacturer 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createManufacturer(createManufacturer: CreateManufacturer, options?: RawAxiosRequestConfig): AxiosPromise<CreateManufacturer200Response> {
            return localVarFp.createManufacturer(createManufacturer, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Deletes a manufacturer by id
         * @param {string} manufacturerId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteManufacturer(manufacturerId: string, options?: RawAxiosRequestConfig): AxiosPromise<UpdateSite200Response> {
            return localVarFp.deleteManufacturer(manufacturerId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Returns all manufacturers
         * @param {string} [protocolId] if not set all manufacturers will be returned
         * @param {string} [sort] order ascending or descending the list of manufacturers by a property. For descending order the sort field should be prefixed with a minus(\&#39;-\&#39;)
         * @param {number} [pageOffset] number of items that should be skipped and not included in the response
         * @param {number} [pageLimit] number of items to be included in the response
         * @param {GetSitesFilterParameter} [filter] Filters should be sent as encoded URI. Filterable items: name
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        geManufacturers(protocolId?: string, sort?: string, pageOffset?: number, pageLimit?: number, filter?: GetSitesFilterParameter, options?: RawAxiosRequestConfig): AxiosPromise<GeManufacturers200Response> {
            return localVarFp.geManufacturers(protocolId, sort, pageOffset, pageLimit, filter, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Returns manufacturer details by id
         * @param {string} manufacturerId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getManufacturer(manufacturerId: string, options?: RawAxiosRequestConfig): AxiosPromise<GetManufacturer200Response> {
            return localVarFp.getManufacturer(manufacturerId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Returns models for a manufacturer
         * @param {string} manufacturerId 
         * @param {string} [sort] order ascending or descending the list of models by a property. For descending order the sort field should be prefixed with a minus(\&#39;-\&#39;)
         * @param {number} [pageOffset] number of items that should be skipped and not included in the response
         * @param {number} [pageLimit] number of items to be included in the response
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getManufacturerModels(manufacturerId: string, sort?: string, pageOffset?: number, pageLimit?: number, options?: RawAxiosRequestConfig): AxiosPromise<GetDeviceModels200Response> {
            return localVarFp.getManufacturerModels(manufacturerId, sort, pageOffset, pageLimit, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Updates manufacturer details, its protocols and device models
         * @param {string} manufacturerId 
         * @param {UpdateManufacturer} updateManufacturer 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateManufacturer(manufacturerId: string, updateManufacturer: UpdateManufacturer, options?: RawAxiosRequestConfig): AxiosPromise<UpdateSite200Response> {
            return localVarFp.updateManufacturer(manufacturerId, updateManufacturer, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * ManufacturersApi - object-oriented interface
 * @export
 * @class ManufacturersApi
 * @extends {BaseAPI}
 */
export class ManufacturersApi extends BaseAPI {
    /**
     * 
     * @summary Add a manufacturer and its device models/types
     * @param {CreateManufacturer} createManufacturer 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ManufacturersApi
     */
    public createManufacturer(createManufacturer: CreateManufacturer, options?: RawAxiosRequestConfig) {
        return ManufacturersApiFp(this.configuration).createManufacturer(createManufacturer, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Deletes a manufacturer by id
     * @param {string} manufacturerId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ManufacturersApi
     */
    public deleteManufacturer(manufacturerId: string, options?: RawAxiosRequestConfig) {
        return ManufacturersApiFp(this.configuration).deleteManufacturer(manufacturerId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Returns all manufacturers
     * @param {string} [protocolId] if not set all manufacturers will be returned
     * @param {string} [sort] order ascending or descending the list of manufacturers by a property. For descending order the sort field should be prefixed with a minus(\&#39;-\&#39;)
     * @param {number} [pageOffset] number of items that should be skipped and not included in the response
     * @param {number} [pageLimit] number of items to be included in the response
     * @param {GetSitesFilterParameter} [filter] Filters should be sent as encoded URI. Filterable items: name
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ManufacturersApi
     */
    public geManufacturers(protocolId?: string, sort?: string, pageOffset?: number, pageLimit?: number, filter?: GetSitesFilterParameter, options?: RawAxiosRequestConfig) {
        return ManufacturersApiFp(this.configuration).geManufacturers(protocolId, sort, pageOffset, pageLimit, filter, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Returns manufacturer details by id
     * @param {string} manufacturerId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ManufacturersApi
     */
    public getManufacturer(manufacturerId: string, options?: RawAxiosRequestConfig) {
        return ManufacturersApiFp(this.configuration).getManufacturer(manufacturerId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Returns models for a manufacturer
     * @param {string} manufacturerId 
     * @param {string} [sort] order ascending or descending the list of models by a property. For descending order the sort field should be prefixed with a minus(\&#39;-\&#39;)
     * @param {number} [pageOffset] number of items that should be skipped and not included in the response
     * @param {number} [pageLimit] number of items to be included in the response
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ManufacturersApi
     */
    public getManufacturerModels(manufacturerId: string, sort?: string, pageOffset?: number, pageLimit?: number, options?: RawAxiosRequestConfig) {
        return ManufacturersApiFp(this.configuration).getManufacturerModels(manufacturerId, sort, pageOffset, pageLimit, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Updates manufacturer details, its protocols and device models
     * @param {string} manufacturerId 
     * @param {UpdateManufacturer} updateManufacturer 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ManufacturersApi
     */
    public updateManufacturer(manufacturerId: string, updateManufacturer: UpdateManufacturer, options?: RawAxiosRequestConfig) {
        return ManufacturersApiFp(this.configuration).updateManufacturer(manufacturerId, updateManufacturer, options).then((request) => request(this.axios, this.basePath));
    }
}



/**
 * MarketplaceApi - axios parameter creator
 * @export
 */
export const MarketplaceApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Activates or deactivates the module for current mandator
         * @param {string} moduleId Module unique identifier
         * @param {ModuleActivation} moduleActivation 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        activateModule: async (moduleId: string, moduleActivation: ModuleActivation, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'moduleId' is not null or undefined
            assertParamExists('activateModule', 'moduleId', moduleId)
            // verify required parameter 'moduleActivation' is not null or undefined
            assertParamExists('activateModule', 'moduleActivation', moduleActivation)
            const localVarPath = `/modules/{module_id}/activations`
                .replace(`{${"module_id"}}`, encodeURIComponent(String(moduleId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Authorization required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(moduleActivation, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Getting Module details for current mandator
         * @param {string} moduleId Module unique identifier
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getModuleById: async (moduleId: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'moduleId' is not null or undefined
            assertParamExists('getModuleById', 'moduleId', moduleId)
            const localVarPath = `/modules/{module_id}`
                .replace(`{${"module_id"}}`, encodeURIComponent(String(moduleId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Authorization required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Getting all Modules details for current mandator
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getModules: async (options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/modules`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Authorization required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * MarketplaceApi - functional programming interface
 * @export
 */
export const MarketplaceApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = MarketplaceApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary Activates or deactivates the module for current mandator
         * @param {string} moduleId Module unique identifier
         * @param {ModuleActivation} moduleActivation 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async activateModule(moduleId: string, moduleActivation: ModuleActivation, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ModuleActivationResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.activateModule(moduleId, moduleActivation, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['MarketplaceApi.activateModule']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @summary Getting Module details for current mandator
         * @param {string} moduleId Module unique identifier
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getModuleById(moduleId: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Modules>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getModuleById(moduleId, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['MarketplaceApi.getModuleById']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @summary Getting all Modules details for current mandator
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getModules(options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<Modules>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getModules(options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['MarketplaceApi.getModules']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
    }
};

/**
 * MarketplaceApi - factory interface
 * @export
 */
export const MarketplaceApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = MarketplaceApiFp(configuration)
    return {
        /**
         * 
         * @summary Activates or deactivates the module for current mandator
         * @param {string} moduleId Module unique identifier
         * @param {ModuleActivation} moduleActivation 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        activateModule(moduleId: string, moduleActivation: ModuleActivation, options?: RawAxiosRequestConfig): AxiosPromise<ModuleActivationResponse> {
            return localVarFp.activateModule(moduleId, moduleActivation, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Getting Module details for current mandator
         * @param {string} moduleId Module unique identifier
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getModuleById(moduleId: string, options?: RawAxiosRequestConfig): AxiosPromise<Modules> {
            return localVarFp.getModuleById(moduleId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Getting all Modules details for current mandator
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getModules(options?: RawAxiosRequestConfig): AxiosPromise<Array<Modules>> {
            return localVarFp.getModules(options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * MarketplaceApi - object-oriented interface
 * @export
 * @class MarketplaceApi
 * @extends {BaseAPI}
 */
export class MarketplaceApi extends BaseAPI {
    /**
     * 
     * @summary Activates or deactivates the module for current mandator
     * @param {string} moduleId Module unique identifier
     * @param {ModuleActivation} moduleActivation 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MarketplaceApi
     */
    public activateModule(moduleId: string, moduleActivation: ModuleActivation, options?: RawAxiosRequestConfig) {
        return MarketplaceApiFp(this.configuration).activateModule(moduleId, moduleActivation, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Getting Module details for current mandator
     * @param {string} moduleId Module unique identifier
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MarketplaceApi
     */
    public getModuleById(moduleId: string, options?: RawAxiosRequestConfig) {
        return MarketplaceApiFp(this.configuration).getModuleById(moduleId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Getting all Modules details for current mandator
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MarketplaceApi
     */
    public getModules(options?: RawAxiosRequestConfig) {
        return MarketplaceApiFp(this.configuration).getModules(options).then((request) => request(this.axios, this.basePath));
    }
}



/**
 * ParkingApi - axios parameter creator
 * @export
 */
export const ParkingApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Register a new Tariff-Service
         * @param {TariffService} [tariffService] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createService: async (tariffService?: TariffService, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/parking/tariff-router/services`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Authorization required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(tariffService, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Remove the mapping for a mandator
         * @param {string} mandatorId ID of the Mandator, for which the data should be deleted.
         * @param {string} serviceId ID of the Service, from which the mandator will be removed
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteServiceFromMandator: async (mandatorId: string, serviceId: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'mandatorId' is not null or undefined
            assertParamExists('deleteServiceFromMandator', 'mandatorId', mandatorId)
            // verify required parameter 'serviceId' is not null or undefined
            assertParamExists('deleteServiceFromMandator', 'serviceId', serviceId)
            const localVarPath = `/parking/tariff-router/mappings/{service_id}`
                .replace(`{${"service_id"}}`, encodeURIComponent(String(serviceId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Authorization required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (mandatorId !== undefined) {
                localVarQueryParameter['mandator_id'] = mandatorId;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get a List of all Services
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAllTariffService: async (options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/parking/tariff-router/services`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Authorization required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get the mapped TariffService for a Mandator
         * @param {string} mandatorId ID of the Mandator, for which the data is requested.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getTariffServiceByMandator: async (mandatorId: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'mandatorId' is not null or undefined
            assertParamExists('getTariffServiceByMandator', 'mandatorId', mandatorId)
            const localVarPath = `/parking/tariff-router/mappings`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Authorization required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (mandatorId !== undefined) {
                localVarQueryParameter['mandator_id'] = mandatorId;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Create or Update TariffService for a Mandator
         * @param {string} mandatorId ID of the Mandator, for which the data is requested.
         * @param {string} serviceId ID of the Service, to which the mandator will be registered
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        putTariffserviceForMandator: async (mandatorId: string, serviceId: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'mandatorId' is not null or undefined
            assertParamExists('putTariffserviceForMandator', 'mandatorId', mandatorId)
            // verify required parameter 'serviceId' is not null or undefined
            assertParamExists('putTariffserviceForMandator', 'serviceId', serviceId)
            const localVarPath = `/parking/tariff-router/mappings/{service_id}`
                .replace(`{${"service_id"}}`, encodeURIComponent(String(serviceId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Authorization required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (mandatorId !== undefined) {
                localVarQueryParameter['mandator_id'] = mandatorId;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Remove a TariffService from System
         * @param {string} serviceId ID of the Service which should be removed
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        removeTariffService: async (serviceId: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'serviceId' is not null or undefined
            assertParamExists('removeTariffService', 'serviceId', serviceId)
            const localVarPath = `/parking/tariff-router/services/{service_id}`
                .replace(`{${"service_id"}}`, encodeURIComponent(String(serviceId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Authorization required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * ParkingApi - functional programming interface
 * @export
 */
export const ParkingApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = ParkingApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary Register a new Tariff-Service
         * @param {TariffService} [tariffService] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async createService(tariffService?: TariffService, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ServiceResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.createService(tariffService, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['ParkingApi.createService']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @summary Remove the mapping for a mandator
         * @param {string} mandatorId ID of the Mandator, for which the data should be deleted.
         * @param {string} serviceId ID of the Service, from which the mandator will be removed
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async deleteServiceFromMandator(mandatorId: string, serviceId: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.deleteServiceFromMandator(mandatorId, serviceId, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['ParkingApi.deleteServiceFromMandator']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @summary Get a List of all Services
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getAllTariffService(options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ServiceResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getAllTariffService(options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['ParkingApi.getAllTariffService']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @summary Get the mapped TariffService for a Mandator
         * @param {string} mandatorId ID of the Mandator, for which the data is requested.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getTariffServiceByMandator(mandatorId: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<MappingResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getTariffServiceByMandator(mandatorId, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['ParkingApi.getTariffServiceByMandator']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @summary Create or Update TariffService for a Mandator
         * @param {string} mandatorId ID of the Mandator, for which the data is requested.
         * @param {string} serviceId ID of the Service, to which the mandator will be registered
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async putTariffserviceForMandator(mandatorId: string, serviceId: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<MappingResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.putTariffserviceForMandator(mandatorId, serviceId, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['ParkingApi.putTariffserviceForMandator']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @summary Remove a TariffService from System
         * @param {string} serviceId ID of the Service which should be removed
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async removeTariffService(serviceId: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.removeTariffService(serviceId, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['ParkingApi.removeTariffService']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
    }
};

/**
 * ParkingApi - factory interface
 * @export
 */
export const ParkingApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = ParkingApiFp(configuration)
    return {
        /**
         * 
         * @summary Register a new Tariff-Service
         * @param {TariffService} [tariffService] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createService(tariffService?: TariffService, options?: RawAxiosRequestConfig): AxiosPromise<ServiceResponse> {
            return localVarFp.createService(tariffService, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Remove the mapping for a mandator
         * @param {string} mandatorId ID of the Mandator, for which the data should be deleted.
         * @param {string} serviceId ID of the Service, from which the mandator will be removed
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteServiceFromMandator(mandatorId: string, serviceId: string, options?: RawAxiosRequestConfig): AxiosPromise<void> {
            return localVarFp.deleteServiceFromMandator(mandatorId, serviceId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get a List of all Services
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAllTariffService(options?: RawAxiosRequestConfig): AxiosPromise<ServiceResponse> {
            return localVarFp.getAllTariffService(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get the mapped TariffService for a Mandator
         * @param {string} mandatorId ID of the Mandator, for which the data is requested.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getTariffServiceByMandator(mandatorId: string, options?: RawAxiosRequestConfig): AxiosPromise<MappingResponse> {
            return localVarFp.getTariffServiceByMandator(mandatorId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Create or Update TariffService for a Mandator
         * @param {string} mandatorId ID of the Mandator, for which the data is requested.
         * @param {string} serviceId ID of the Service, to which the mandator will be registered
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        putTariffserviceForMandator(mandatorId: string, serviceId: string, options?: RawAxiosRequestConfig): AxiosPromise<MappingResponse> {
            return localVarFp.putTariffserviceForMandator(mandatorId, serviceId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Remove a TariffService from System
         * @param {string} serviceId ID of the Service which should be removed
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        removeTariffService(serviceId: string, options?: RawAxiosRequestConfig): AxiosPromise<void> {
            return localVarFp.removeTariffService(serviceId, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * ParkingApi - object-oriented interface
 * @export
 * @class ParkingApi
 * @extends {BaseAPI}
 */
export class ParkingApi extends BaseAPI {
    /**
     * 
     * @summary Register a new Tariff-Service
     * @param {TariffService} [tariffService] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ParkingApi
     */
    public createService(tariffService?: TariffService, options?: RawAxiosRequestConfig) {
        return ParkingApiFp(this.configuration).createService(tariffService, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Remove the mapping for a mandator
     * @param {string} mandatorId ID of the Mandator, for which the data should be deleted.
     * @param {string} serviceId ID of the Service, from which the mandator will be removed
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ParkingApi
     */
    public deleteServiceFromMandator(mandatorId: string, serviceId: string, options?: RawAxiosRequestConfig) {
        return ParkingApiFp(this.configuration).deleteServiceFromMandator(mandatorId, serviceId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get a List of all Services
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ParkingApi
     */
    public getAllTariffService(options?: RawAxiosRequestConfig) {
        return ParkingApiFp(this.configuration).getAllTariffService(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get the mapped TariffService for a Mandator
     * @param {string} mandatorId ID of the Mandator, for which the data is requested.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ParkingApi
     */
    public getTariffServiceByMandator(mandatorId: string, options?: RawAxiosRequestConfig) {
        return ParkingApiFp(this.configuration).getTariffServiceByMandator(mandatorId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Create or Update TariffService for a Mandator
     * @param {string} mandatorId ID of the Mandator, for which the data is requested.
     * @param {string} serviceId ID of the Service, to which the mandator will be registered
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ParkingApi
     */
    public putTariffserviceForMandator(mandatorId: string, serviceId: string, options?: RawAxiosRequestConfig) {
        return ParkingApiFp(this.configuration).putTariffserviceForMandator(mandatorId, serviceId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Remove a TariffService from System
     * @param {string} serviceId ID of the Service which should be removed
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ParkingApi
     */
    public removeTariffService(serviceId: string, options?: RawAxiosRequestConfig) {
        return ParkingApiFp(this.configuration).removeTariffService(serviceId, options).then((request) => request(this.axios, this.basePath));
    }
}



/**
 * ParkingProductCollectorApi - axios parameter creator
 * @export
 */
export const ParkingProductCollectorApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Getting Products which are available on a dedicated point.
         * @param {string} siteId Id of the site
         * @param {string} pointId Id of the point
         * @param {string} [sort] order ascending or descending the list of products by a property. For descending order the sort field should be prefixed with a minus(\&#39;-\&#39;)
         * @param {number} [pageOffset] number of items that should be skipped and not included in the response 
         * @param {number} [pageLimit] number of items to be included in the response
         * @param {string} [cnp] license_plate of the car, without separators.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        productCollectorGetProductsOfPoint: async (siteId: string, pointId: string, sort?: string, pageOffset?: number, pageLimit?: number, cnp?: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'siteId' is not null or undefined
            assertParamExists('productCollectorGetProductsOfPoint', 'siteId', siteId)
            // verify required parameter 'pointId' is not null or undefined
            assertParamExists('productCollectorGetProductsOfPoint', 'pointId', pointId)
            const localVarPath = `/sites/{site_id}/points/{point_id}/products/parking`
                .replace(`{${"site_id"}}`, encodeURIComponent(String(siteId)))
                .replace(`{${"point_id"}}`, encodeURIComponent(String(pointId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Authorization required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (sort !== undefined) {
                localVarQueryParameter['sort'] = sort;
            }

            if (pageOffset !== undefined) {
                localVarQueryParameter['page[offset]'] = pageOffset;
            }

            if (pageLimit !== undefined) {
                localVarQueryParameter['page[limit]'] = pageLimit;
            }

            if (cnp !== undefined) {
                localVarQueryParameter['cnp'] = cnp;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * ParkingProductCollectorApi - functional programming interface
 * @export
 */
export const ParkingProductCollectorApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = ParkingProductCollectorApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary Getting Products which are available on a dedicated point.
         * @param {string} siteId Id of the site
         * @param {string} pointId Id of the point
         * @param {string} [sort] order ascending or descending the list of products by a property. For descending order the sort field should be prefixed with a minus(\&#39;-\&#39;)
         * @param {number} [pageOffset] number of items that should be skipped and not included in the response 
         * @param {number} [pageLimit] number of items to be included in the response
         * @param {string} [cnp] license_plate of the car, without separators.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async productCollectorGetProductsOfPoint(siteId: string, pointId: string, sort?: string, pageOffset?: number, pageLimit?: number, cnp?: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ParkingAllProductOfferResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.productCollectorGetProductsOfPoint(siteId, pointId, sort, pageOffset, pageLimit, cnp, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['ParkingProductCollectorApi.productCollectorGetProductsOfPoint']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
    }
};

/**
 * ParkingProductCollectorApi - factory interface
 * @export
 */
export const ParkingProductCollectorApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = ParkingProductCollectorApiFp(configuration)
    return {
        /**
         * 
         * @summary Getting Products which are available on a dedicated point.
         * @param {string} siteId Id of the site
         * @param {string} pointId Id of the point
         * @param {string} [sort] order ascending or descending the list of products by a property. For descending order the sort field should be prefixed with a minus(\&#39;-\&#39;)
         * @param {number} [pageOffset] number of items that should be skipped and not included in the response 
         * @param {number} [pageLimit] number of items to be included in the response
         * @param {string} [cnp] license_plate of the car, without separators.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        productCollectorGetProductsOfPoint(siteId: string, pointId: string, sort?: string, pageOffset?: number, pageLimit?: number, cnp?: string, options?: RawAxiosRequestConfig): AxiosPromise<ParkingAllProductOfferResponse> {
            return localVarFp.productCollectorGetProductsOfPoint(siteId, pointId, sort, pageOffset, pageLimit, cnp, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * ParkingProductCollectorApi - object-oriented interface
 * @export
 * @class ParkingProductCollectorApi
 * @extends {BaseAPI}
 */
export class ParkingProductCollectorApi extends BaseAPI {
    /**
     * 
     * @summary Getting Products which are available on a dedicated point.
     * @param {string} siteId Id of the site
     * @param {string} pointId Id of the point
     * @param {string} [sort] order ascending or descending the list of products by a property. For descending order the sort field should be prefixed with a minus(\&#39;-\&#39;)
     * @param {number} [pageOffset] number of items that should be skipped and not included in the response 
     * @param {number} [pageLimit] number of items to be included in the response
     * @param {string} [cnp] license_plate of the car, without separators.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ParkingProductCollectorApi
     */
    public productCollectorGetProductsOfPoint(siteId: string, pointId: string, sort?: string, pageOffset?: number, pageLimit?: number, cnp?: string, options?: RawAxiosRequestConfig) {
        return ParkingProductCollectorApiFp(this.configuration).productCollectorGetProductsOfPoint(siteId, pointId, sort, pageOffset, pageLimit, cnp, options).then((request) => request(this.axios, this.basePath));
    }
}



/**
 * ParkingTariffApi - axios parameter creator
 * @export
 */
export const ParkingTariffApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary create new Assignment
         * @param {ParkingTariffAssignment} [parkingTariffAssignment] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createAssignment: async (parkingTariffAssignment?: ParkingTariffAssignment, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/parking-tariffs/assignments`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Authorization required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(parkingTariffAssignment, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary create a new tariff
         * @param {ParkingTariff} [parkingTariff] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createTariff: async (parkingTariff?: ParkingTariff, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/parking-tariffs`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Authorization required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(parkingTariff, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Deletes a Assignment
         * @param {string} assignmentId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteAssignment: async (assignmentId: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'assignmentId' is not null or undefined
            assertParamExists('deleteAssignment', 'assignmentId', assignmentId)
            const localVarPath = `/parking-tariffs/assignments/{assignment_id}`
                .replace(`{${"assignment_id"}}`, encodeURIComponent(String(assignmentId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Authorization required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary edit an existing tariff
         * @param {string} tariffId 
         * @param {ParkingTariff} [parkingTariff] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        editTariff: async (tariffId: string, parkingTariff?: ParkingTariff, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'tariffId' is not null or undefined
            assertParamExists('editTariff', 'tariffId', tariffId)
            const localVarPath = `/parking-tariffs/{tariff_id}`
                .replace(`{${"tariff_id"}}`, encodeURIComponent(String(tariffId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Authorization required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(parkingTariff, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary return assignments
         * @param {string} mandatorId 
         * @param {string} [tariffId] 
         * @param {string} [siteId] 
         * @param {string} [activeOnDate] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getTariffAssignments: async (mandatorId: string, tariffId?: string, siteId?: string, activeOnDate?: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'mandatorId' is not null or undefined
            assertParamExists('getTariffAssignments', 'mandatorId', mandatorId)
            const localVarPath = `/parking-tariffs/assignments`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Authorization required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (mandatorId !== undefined) {
                localVarQueryParameter['mandator_id'] = mandatorId;
            }

            if (tariffId !== undefined) {
                localVarQueryParameter['tariff_id'] = tariffId;
            }

            if (siteId !== undefined) {
                localVarQueryParameter['site_id'] = siteId;
            }

            if (activeOnDate !== undefined) {
                localVarQueryParameter['active_on_date'] = activeOnDate;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary return assignments
         * @param {string} assignmentId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getTariffAssignmentsById: async (assignmentId: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'assignmentId' is not null or undefined
            assertParamExists('getTariffAssignmentsById', 'assignmentId', assignmentId)
            const localVarPath = `/parking-tariffs/assignments/{assignment_id}`
                .replace(`{${"assignment_id"}}`, encodeURIComponent(String(assignmentId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Authorization required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary get a tariff by its id
         * @param {string} tariffId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getTariffById: async (tariffId: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'tariffId' is not null or undefined
            assertParamExists('getTariffById', 'tariffId', tariffId)
            const localVarPath = `/parking-tariffs/{tariff_id}`
                .replace(`{${"tariff_id"}}`, encodeURIComponent(String(tariffId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Authorization required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get Tariffs for a mandator
         * @param {string} mandatorId ID of the Mandator, for which the data is requested.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getTariffByMandator: async (mandatorId: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'mandatorId' is not null or undefined
            assertParamExists('getTariffByMandator', 'mandatorId', mandatorId)
            const localVarPath = `/parking-tariffs`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Authorization required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (mandatorId !== undefined) {
                localVarQueryParameter['mandator_id'] = mandatorId;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary get history of parking tariffs for a specific mandator
         * @param {string} mandatorId ID of the Mandator, for which the data is requested.
         * @param {boolean} [onlyLatest] only return the latest version of the history
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getTariffHistories: async (mandatorId: string, onlyLatest?: boolean, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'mandatorId' is not null or undefined
            assertParamExists('getTariffHistories', 'mandatorId', mandatorId)
            const localVarPath = `/parking-tariffs/histories`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Authorization required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (mandatorId !== undefined) {
                localVarQueryParameter['mandator_id'] = mandatorId;
            }

            if (onlyLatest !== undefined) {
                localVarQueryParameter['only_latest'] = onlyLatest;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary get the history of an parking tariff
         * @param {string} tariffId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getTariffHistoryById: async (tariffId: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'tariffId' is not null or undefined
            assertParamExists('getTariffHistoryById', 'tariffId', tariffId)
            const localVarPath = `/parking-tariffs/{tariff_id}/history`
                .replace(`{${"tariff_id"}}`, encodeURIComponent(String(tariffId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Authorization required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary update an existing Assignment
         * @param {string} assignmentId 
         * @param {ParkingTariffAssignment} [parkingTariffAssignment] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateAssignment: async (assignmentId: string, parkingTariffAssignment?: ParkingTariffAssignment, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'assignmentId' is not null or undefined
            assertParamExists('updateAssignment', 'assignmentId', assignmentId)
            const localVarPath = `/parking-tariffs/assignments/{assignment_id}`
                .replace(`{${"assignment_id"}}`, encodeURIComponent(String(assignmentId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Authorization required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(parkingTariffAssignment, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * ParkingTariffApi - functional programming interface
 * @export
 */
export const ParkingTariffApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = ParkingTariffApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary create new Assignment
         * @param {ParkingTariffAssignment} [parkingTariffAssignment] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async createAssignment(parkingTariffAssignment?: ParkingTariffAssignment, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ParkingTariffAssignmentResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.createAssignment(parkingTariffAssignment, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['ParkingTariffApi.createAssignment']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @summary create a new tariff
         * @param {ParkingTariff} [parkingTariff] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async createTariff(parkingTariff?: ParkingTariff, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ParkingTariffResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.createTariff(parkingTariff, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['ParkingTariffApi.createTariff']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @summary Deletes a Assignment
         * @param {string} assignmentId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async deleteAssignment(assignmentId: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.deleteAssignment(assignmentId, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['ParkingTariffApi.deleteAssignment']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @summary edit an existing tariff
         * @param {string} tariffId 
         * @param {ParkingTariff} [parkingTariff] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async editTariff(tariffId: string, parkingTariff?: ParkingTariff, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ParkingTariffResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.editTariff(tariffId, parkingTariff, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['ParkingTariffApi.editTariff']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @summary return assignments
         * @param {string} mandatorId 
         * @param {string} [tariffId] 
         * @param {string} [siteId] 
         * @param {string} [activeOnDate] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getTariffAssignments(mandatorId: string, tariffId?: string, siteId?: string, activeOnDate?: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ParkingTariffAssignmentResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getTariffAssignments(mandatorId, tariffId, siteId, activeOnDate, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['ParkingTariffApi.getTariffAssignments']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @summary return assignments
         * @param {string} assignmentId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getTariffAssignmentsById(assignmentId: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ParkingTariffAssignmentResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getTariffAssignmentsById(assignmentId, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['ParkingTariffApi.getTariffAssignmentsById']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @summary get a tariff by its id
         * @param {string} tariffId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getTariffById(tariffId: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ParkingTariffResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getTariffById(tariffId, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['ParkingTariffApi.getTariffById']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @summary Get Tariffs for a mandator
         * @param {string} mandatorId ID of the Mandator, for which the data is requested.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getTariffByMandator(mandatorId: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ParkingTariffResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getTariffByMandator(mandatorId, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['ParkingTariffApi.getTariffByMandator']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @summary get history of parking tariffs for a specific mandator
         * @param {string} mandatorId ID of the Mandator, for which the data is requested.
         * @param {boolean} [onlyLatest] only return the latest version of the history
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getTariffHistories(mandatorId: string, onlyLatest?: boolean, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ParkingTariffHistoryResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getTariffHistories(mandatorId, onlyLatest, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['ParkingTariffApi.getTariffHistories']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @summary get the history of an parking tariff
         * @param {string} tariffId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getTariffHistoryById(tariffId: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ParkingTariffHistoryResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getTariffHistoryById(tariffId, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['ParkingTariffApi.getTariffHistoryById']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @summary update an existing Assignment
         * @param {string} assignmentId 
         * @param {ParkingTariffAssignment} [parkingTariffAssignment] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updateAssignment(assignmentId: string, parkingTariffAssignment?: ParkingTariffAssignment, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ParkingTariffAssignmentResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.updateAssignment(assignmentId, parkingTariffAssignment, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['ParkingTariffApi.updateAssignment']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
    }
};

/**
 * ParkingTariffApi - factory interface
 * @export
 */
export const ParkingTariffApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = ParkingTariffApiFp(configuration)
    return {
        /**
         * 
         * @summary create new Assignment
         * @param {ParkingTariffAssignment} [parkingTariffAssignment] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createAssignment(parkingTariffAssignment?: ParkingTariffAssignment, options?: RawAxiosRequestConfig): AxiosPromise<ParkingTariffAssignmentResponse> {
            return localVarFp.createAssignment(parkingTariffAssignment, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary create a new tariff
         * @param {ParkingTariff} [parkingTariff] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createTariff(parkingTariff?: ParkingTariff, options?: RawAxiosRequestConfig): AxiosPromise<ParkingTariffResponse> {
            return localVarFp.createTariff(parkingTariff, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Deletes a Assignment
         * @param {string} assignmentId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteAssignment(assignmentId: string, options?: RawAxiosRequestConfig): AxiosPromise<void> {
            return localVarFp.deleteAssignment(assignmentId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary edit an existing tariff
         * @param {string} tariffId 
         * @param {ParkingTariff} [parkingTariff] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        editTariff(tariffId: string, parkingTariff?: ParkingTariff, options?: RawAxiosRequestConfig): AxiosPromise<ParkingTariffResponse> {
            return localVarFp.editTariff(tariffId, parkingTariff, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary return assignments
         * @param {string} mandatorId 
         * @param {string} [tariffId] 
         * @param {string} [siteId] 
         * @param {string} [activeOnDate] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getTariffAssignments(mandatorId: string, tariffId?: string, siteId?: string, activeOnDate?: string, options?: RawAxiosRequestConfig): AxiosPromise<ParkingTariffAssignmentResponse> {
            return localVarFp.getTariffAssignments(mandatorId, tariffId, siteId, activeOnDate, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary return assignments
         * @param {string} assignmentId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getTariffAssignmentsById(assignmentId: string, options?: RawAxiosRequestConfig): AxiosPromise<ParkingTariffAssignmentResponse> {
            return localVarFp.getTariffAssignmentsById(assignmentId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary get a tariff by its id
         * @param {string} tariffId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getTariffById(tariffId: string, options?: RawAxiosRequestConfig): AxiosPromise<ParkingTariffResponse> {
            return localVarFp.getTariffById(tariffId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get Tariffs for a mandator
         * @param {string} mandatorId ID of the Mandator, for which the data is requested.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getTariffByMandator(mandatorId: string, options?: RawAxiosRequestConfig): AxiosPromise<ParkingTariffResponse> {
            return localVarFp.getTariffByMandator(mandatorId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary get history of parking tariffs for a specific mandator
         * @param {string} mandatorId ID of the Mandator, for which the data is requested.
         * @param {boolean} [onlyLatest] only return the latest version of the history
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getTariffHistories(mandatorId: string, onlyLatest?: boolean, options?: RawAxiosRequestConfig): AxiosPromise<ParkingTariffHistoryResponse> {
            return localVarFp.getTariffHistories(mandatorId, onlyLatest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary get the history of an parking tariff
         * @param {string} tariffId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getTariffHistoryById(tariffId: string, options?: RawAxiosRequestConfig): AxiosPromise<ParkingTariffHistoryResponse> {
            return localVarFp.getTariffHistoryById(tariffId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary update an existing Assignment
         * @param {string} assignmentId 
         * @param {ParkingTariffAssignment} [parkingTariffAssignment] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateAssignment(assignmentId: string, parkingTariffAssignment?: ParkingTariffAssignment, options?: RawAxiosRequestConfig): AxiosPromise<ParkingTariffAssignmentResponse> {
            return localVarFp.updateAssignment(assignmentId, parkingTariffAssignment, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * ParkingTariffApi - object-oriented interface
 * @export
 * @class ParkingTariffApi
 * @extends {BaseAPI}
 */
export class ParkingTariffApi extends BaseAPI {
    /**
     * 
     * @summary create new Assignment
     * @param {ParkingTariffAssignment} [parkingTariffAssignment] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ParkingTariffApi
     */
    public createAssignment(parkingTariffAssignment?: ParkingTariffAssignment, options?: RawAxiosRequestConfig) {
        return ParkingTariffApiFp(this.configuration).createAssignment(parkingTariffAssignment, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary create a new tariff
     * @param {ParkingTariff} [parkingTariff] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ParkingTariffApi
     */
    public createTariff(parkingTariff?: ParkingTariff, options?: RawAxiosRequestConfig) {
        return ParkingTariffApiFp(this.configuration).createTariff(parkingTariff, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Deletes a Assignment
     * @param {string} assignmentId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ParkingTariffApi
     */
    public deleteAssignment(assignmentId: string, options?: RawAxiosRequestConfig) {
        return ParkingTariffApiFp(this.configuration).deleteAssignment(assignmentId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary edit an existing tariff
     * @param {string} tariffId 
     * @param {ParkingTariff} [parkingTariff] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ParkingTariffApi
     */
    public editTariff(tariffId: string, parkingTariff?: ParkingTariff, options?: RawAxiosRequestConfig) {
        return ParkingTariffApiFp(this.configuration).editTariff(tariffId, parkingTariff, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary return assignments
     * @param {string} mandatorId 
     * @param {string} [tariffId] 
     * @param {string} [siteId] 
     * @param {string} [activeOnDate] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ParkingTariffApi
     */
    public getTariffAssignments(mandatorId: string, tariffId?: string, siteId?: string, activeOnDate?: string, options?: RawAxiosRequestConfig) {
        return ParkingTariffApiFp(this.configuration).getTariffAssignments(mandatorId, tariffId, siteId, activeOnDate, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary return assignments
     * @param {string} assignmentId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ParkingTariffApi
     */
    public getTariffAssignmentsById(assignmentId: string, options?: RawAxiosRequestConfig) {
        return ParkingTariffApiFp(this.configuration).getTariffAssignmentsById(assignmentId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary get a tariff by its id
     * @param {string} tariffId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ParkingTariffApi
     */
    public getTariffById(tariffId: string, options?: RawAxiosRequestConfig) {
        return ParkingTariffApiFp(this.configuration).getTariffById(tariffId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get Tariffs for a mandator
     * @param {string} mandatorId ID of the Mandator, for which the data is requested.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ParkingTariffApi
     */
    public getTariffByMandator(mandatorId: string, options?: RawAxiosRequestConfig) {
        return ParkingTariffApiFp(this.configuration).getTariffByMandator(mandatorId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary get history of parking tariffs for a specific mandator
     * @param {string} mandatorId ID of the Mandator, for which the data is requested.
     * @param {boolean} [onlyLatest] only return the latest version of the history
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ParkingTariffApi
     */
    public getTariffHistories(mandatorId: string, onlyLatest?: boolean, options?: RawAxiosRequestConfig) {
        return ParkingTariffApiFp(this.configuration).getTariffHistories(mandatorId, onlyLatest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary get the history of an parking tariff
     * @param {string} tariffId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ParkingTariffApi
     */
    public getTariffHistoryById(tariffId: string, options?: RawAxiosRequestConfig) {
        return ParkingTariffApiFp(this.configuration).getTariffHistoryById(tariffId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary update an existing Assignment
     * @param {string} assignmentId 
     * @param {ParkingTariffAssignment} [parkingTariffAssignment] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ParkingTariffApi
     */
    public updateAssignment(assignmentId: string, parkingTariffAssignment?: ParkingTariffAssignment, options?: RawAxiosRequestConfig) {
        return ParkingTariffApiFp(this.configuration).updateAssignment(assignmentId, parkingTariffAssignment, options).then((request) => request(this.axios, this.basePath));
    }
}



/**
 * PointsApi - axios parameter creator
 * @export
 */
export const PointsApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Adds a new point with products power to the system
         * @param {Point} point Point object to be created
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createPoint: async (point: Point, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'point' is not null or undefined
            assertParamExists('createPoint', 'point', point)
            const localVarPath = `/points`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Authorization required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(point, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Deletes a point by id
         * @param {string} pointId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deletePoint: async (pointId: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'pointId' is not null or undefined
            assertParamExists('deletePoint', 'pointId', pointId)
            const localVarPath = `/points/{point_id}`
                .replace(`{${"point_id"}}`, encodeURIComponent(String(pointId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Authorization required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Deletes multiple points by their ids
         * @param {Array<string>} requestBody Array of point ids to be deleted
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deletePoints: async (requestBody: Array<string>, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'requestBody' is not null or undefined
            assertParamExists('deletePoints', 'requestBody', requestBody)
            const localVarPath = `/points`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Authorization required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(requestBody, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Returns a point by id
         * @param {string} pointId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getPointById: async (pointId: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'pointId' is not null or undefined
            assertParamExists('getPointById', 'pointId', pointId)
            const localVarPath = `/points/{point_id}`
                .replace(`{${"point_id"}}`, encodeURIComponent(String(pointId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Authorization required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Returns points for a mandator
         * @param {string} mandatorId The id of the mandator for which the points are fetched
         * @param {string} [sort] order ascending or descending the list of points by a field. For descending order the sort field should be prefixed with a minus(\&#39;-\&#39;)
         * @param {number} [pageOffset] number of items that should be skipped and not included in the response
         * @param {number} [pageLimit] number of items to be included in the response
         * @param {GetSitesFilterParameter} [filter] Filters should be sent as encoded URI. Filterable items: name, point_number, type, device_code, site_name and status
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getPointsForAMandator: async (mandatorId: string, sort?: string, pageOffset?: number, pageLimit?: number, filter?: GetSitesFilterParameter, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'mandatorId' is not null or undefined
            assertParamExists('getPointsForAMandator', 'mandatorId', mandatorId)
            const localVarPath = `/points`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Authorization required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (mandatorId !== undefined) {
                localVarQueryParameter['mandator_id'] = mandatorId;
            }

            if (sort !== undefined) {
                localVarQueryParameter['sort'] = sort;
            }

            if (pageOffset !== undefined) {
                localVarQueryParameter['page[offset]'] = pageOffset;
            }

            if (pageLimit !== undefined) {
                localVarQueryParameter['page[limit]'] = pageLimit;
            }

            if (filter !== undefined) {
                for (const [key, value] of Object.entries(filter)) {
                    localVarQueryParameter[key] = value;
                }
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Edit a point with products power to the system
         * @param {string} pointId 
         * @param {PointUpdate} pointUpdate Point object to be updated
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updatePoint: async (pointId: string, pointUpdate: PointUpdate, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'pointId' is not null or undefined
            assertParamExists('updatePoint', 'pointId', pointId)
            // verify required parameter 'pointUpdate' is not null or undefined
            assertParamExists('updatePoint', 'pointUpdate', pointUpdate)
            const localVarPath = `/points/{point_id}`
                .replace(`{${"point_id"}}`, encodeURIComponent(String(pointId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Authorization required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(pointUpdate, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * PointsApi - functional programming interface
 * @export
 */
export const PointsApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = PointsApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary Adds a new point with products power to the system
         * @param {Point} point Point object to be created
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async createPoint(point: Point, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<CreatePoint201Response>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.createPoint(point, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['PointsApi.createPoint']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @summary Deletes a point by id
         * @param {string} pointId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async deletePoint(pointId: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<UpdateSite200Response>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.deletePoint(pointId, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['PointsApi.deletePoint']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @summary Deletes multiple points by their ids
         * @param {Array<string>} requestBody Array of point ids to be deleted
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async deletePoints(requestBody: Array<string>, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<DeleteDevices200Response>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.deletePoints(requestBody, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['PointsApi.deletePoints']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @summary Returns a point by id
         * @param {string} pointId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getPointById(pointId: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<GetPointById200Response>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getPointById(pointId, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['PointsApi.getPointById']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @summary Returns points for a mandator
         * @param {string} mandatorId The id of the mandator for which the points are fetched
         * @param {string} [sort] order ascending or descending the list of points by a field. For descending order the sort field should be prefixed with a minus(\&#39;-\&#39;)
         * @param {number} [pageOffset] number of items that should be skipped and not included in the response
         * @param {number} [pageLimit] number of items to be included in the response
         * @param {GetSitesFilterParameter} [filter] Filters should be sent as encoded URI. Filterable items: name, point_number, type, device_code, site_name and status
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getPointsForAMandator(mandatorId: string, sort?: string, pageOffset?: number, pageLimit?: number, filter?: GetSitesFilterParameter, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<GetPointsForAMandator200Response>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getPointsForAMandator(mandatorId, sort, pageOffset, pageLimit, filter, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['PointsApi.getPointsForAMandator']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @summary Edit a point with products power to the system
         * @param {string} pointId 
         * @param {PointUpdate} pointUpdate Point object to be updated
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updatePoint(pointId: string, pointUpdate: PointUpdate, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<UpdateSite200Response>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.updatePoint(pointId, pointUpdate, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['PointsApi.updatePoint']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
    }
};

/**
 * PointsApi - factory interface
 * @export
 */
export const PointsApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = PointsApiFp(configuration)
    return {
        /**
         * 
         * @summary Adds a new point with products power to the system
         * @param {Point} point Point object to be created
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createPoint(point: Point, options?: RawAxiosRequestConfig): AxiosPromise<CreatePoint201Response> {
            return localVarFp.createPoint(point, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Deletes a point by id
         * @param {string} pointId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deletePoint(pointId: string, options?: RawAxiosRequestConfig): AxiosPromise<UpdateSite200Response> {
            return localVarFp.deletePoint(pointId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Deletes multiple points by their ids
         * @param {Array<string>} requestBody Array of point ids to be deleted
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deletePoints(requestBody: Array<string>, options?: RawAxiosRequestConfig): AxiosPromise<DeleteDevices200Response> {
            return localVarFp.deletePoints(requestBody, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Returns a point by id
         * @param {string} pointId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getPointById(pointId: string, options?: RawAxiosRequestConfig): AxiosPromise<GetPointById200Response> {
            return localVarFp.getPointById(pointId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Returns points for a mandator
         * @param {string} mandatorId The id of the mandator for which the points are fetched
         * @param {string} [sort] order ascending or descending the list of points by a field. For descending order the sort field should be prefixed with a minus(\&#39;-\&#39;)
         * @param {number} [pageOffset] number of items that should be skipped and not included in the response
         * @param {number} [pageLimit] number of items to be included in the response
         * @param {GetSitesFilterParameter} [filter] Filters should be sent as encoded URI. Filterable items: name, point_number, type, device_code, site_name and status
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getPointsForAMandator(mandatorId: string, sort?: string, pageOffset?: number, pageLimit?: number, filter?: GetSitesFilterParameter, options?: RawAxiosRequestConfig): AxiosPromise<GetPointsForAMandator200Response> {
            return localVarFp.getPointsForAMandator(mandatorId, sort, pageOffset, pageLimit, filter, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Edit a point with products power to the system
         * @param {string} pointId 
         * @param {PointUpdate} pointUpdate Point object to be updated
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updatePoint(pointId: string, pointUpdate: PointUpdate, options?: RawAxiosRequestConfig): AxiosPromise<UpdateSite200Response> {
            return localVarFp.updatePoint(pointId, pointUpdate, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * PointsApi - object-oriented interface
 * @export
 * @class PointsApi
 * @extends {BaseAPI}
 */
export class PointsApi extends BaseAPI {
    /**
     * 
     * @summary Adds a new point with products power to the system
     * @param {Point} point Point object to be created
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PointsApi
     */
    public createPoint(point: Point, options?: RawAxiosRequestConfig) {
        return PointsApiFp(this.configuration).createPoint(point, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Deletes a point by id
     * @param {string} pointId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PointsApi
     */
    public deletePoint(pointId: string, options?: RawAxiosRequestConfig) {
        return PointsApiFp(this.configuration).deletePoint(pointId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Deletes multiple points by their ids
     * @param {Array<string>} requestBody Array of point ids to be deleted
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PointsApi
     */
    public deletePoints(requestBody: Array<string>, options?: RawAxiosRequestConfig) {
        return PointsApiFp(this.configuration).deletePoints(requestBody, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Returns a point by id
     * @param {string} pointId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PointsApi
     */
    public getPointById(pointId: string, options?: RawAxiosRequestConfig) {
        return PointsApiFp(this.configuration).getPointById(pointId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Returns points for a mandator
     * @param {string} mandatorId The id of the mandator for which the points are fetched
     * @param {string} [sort] order ascending or descending the list of points by a field. For descending order the sort field should be prefixed with a minus(\&#39;-\&#39;)
     * @param {number} [pageOffset] number of items that should be skipped and not included in the response
     * @param {number} [pageLimit] number of items to be included in the response
     * @param {GetSitesFilterParameter} [filter] Filters should be sent as encoded URI. Filterable items: name, point_number, type, device_code, site_name and status
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PointsApi
     */
    public getPointsForAMandator(mandatorId: string, sort?: string, pageOffset?: number, pageLimit?: number, filter?: GetSitesFilterParameter, options?: RawAxiosRequestConfig) {
        return PointsApiFp(this.configuration).getPointsForAMandator(mandatorId, sort, pageOffset, pageLimit, filter, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Edit a point with products power to the system
     * @param {string} pointId 
     * @param {PointUpdate} pointUpdate Point object to be updated
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PointsApi
     */
    public updatePoint(pointId: string, pointUpdate: PointUpdate, options?: RawAxiosRequestConfig) {
        return PointsApiFp(this.configuration).updatePoint(pointId, pointUpdate, options).then((request) => request(this.axios, this.basePath));
    }
}



/**
 * ProductsApi - axios parameter creator
 * @export
 */
export const ProductsApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Assigns/unassign products to/from a mandator
         * @param {string} mandatorId 
         * @param {MandatorProductsRequest} mandatorProductsRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        assignProductsToMandator: async (mandatorId: string, mandatorProductsRequest: MandatorProductsRequest, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'mandatorId' is not null or undefined
            assertParamExists('assignProductsToMandator', 'mandatorId', mandatorId)
            // verify required parameter 'mandatorProductsRequest' is not null or undefined
            assertParamExists('assignProductsToMandator', 'mandatorProductsRequest', mandatorProductsRequest)
            const localVarPath = `/products/mandators/{mandator_id}`
                .replace(`{${"mandator_id"}}`, encodeURIComponent(String(mandatorId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Authorization required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(mandatorProductsRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Adds a new product to the system for a mandator
         * @param {Product} product Product object to be created
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createProduct: async (product: Product, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'product' is not null or undefined
            assertParamExists('createProduct', 'product', product)
            const localVarPath = `/products`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Authorization required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(product, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Delete a product by id
         * @param {string} productId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteProduct: async (productId: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'productId' is not null or undefined
            assertParamExists('deleteProduct', 'productId', productId)
            const localVarPath = `/products/{product_id}`
                .replace(`{${"product_id"}}`, encodeURIComponent(String(productId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Authorization required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Returns all products
         * @param {string} [sort] order ascending or descending the list of products by a property. For descending order the sort field should be prefixed with a minus(\&#39;-\&#39;)
         * @param {number} [pageOffset] number of items that should be skipped and not included in the response
         * @param {number} [pageLimit] number of items to be included in the response
         * @param {GetSitesFilterParameter} [filter] Filters should be sent as encoded URI. Filterable items: code, name, category, unit_measure and description
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAllProducts: async (sort?: string, pageOffset?: number, pageLimit?: number, filter?: GetSitesFilterParameter, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/products`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Authorization required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (sort !== undefined) {
                localVarQueryParameter['sort'] = sort;
            }

            if (pageOffset !== undefined) {
                localVarQueryParameter['page[offset]'] = pageOffset;
            }

            if (pageLimit !== undefined) {
                localVarQueryParameter['page[limit]'] = pageLimit;
            }

            if (filter !== undefined) {
                for (const [key, value] of Object.entries(filter)) {
                    localVarQueryParameter[key] = value;
                }
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Returns all products for a mandator
         * @param {string} mandatorId 
         * @param {string} [sort] order ascending or descending the list of products by a property. For descending order the sort field should be prefixed with a minus(\&#39;-\&#39;)
         * @param {number} [pageOffset] number of items that should be skipped and not included in the response
         * @param {number} [pageLimit] number of items to be included in the response
         * @param {GetSitesFilterParameter} [filter] Filters should be sent as encoded URI.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getMandatorProducts: async (mandatorId: string, sort?: string, pageOffset?: number, pageLimit?: number, filter?: GetSitesFilterParameter, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'mandatorId' is not null or undefined
            assertParamExists('getMandatorProducts', 'mandatorId', mandatorId)
            const localVarPath = `/products/mandators/{mandator_id}`
                .replace(`{${"mandator_id"}}`, encodeURIComponent(String(mandatorId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Authorization required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (sort !== undefined) {
                localVarQueryParameter['sort'] = sort;
            }

            if (pageOffset !== undefined) {
                localVarQueryParameter['page[offset]'] = pageOffset;
            }

            if (pageLimit !== undefined) {
                localVarQueryParameter['page[limit]'] = pageLimit;
            }

            if (filter !== undefined) {
                for (const [key, value] of Object.entries(filter)) {
                    localVarQueryParameter[key] = value;
                }
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Returns product details by id
         * @param {string} productId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getProductDetails: async (productId: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'productId' is not null or undefined
            assertParamExists('getProductDetails', 'productId', productId)
            const localVarPath = `/products/{product_id}`
                .replace(`{${"product_id"}}`, encodeURIComponent(String(productId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Authorization required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Updates a product by id
         * @param {string} productId 
         * @param {ProductBase} productBase 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateProduct: async (productId: string, productBase: ProductBase, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'productId' is not null or undefined
            assertParamExists('updateProduct', 'productId', productId)
            // verify required parameter 'productBase' is not null or undefined
            assertParamExists('updateProduct', 'productBase', productBase)
            const localVarPath = `/products/{product_id}`
                .replace(`{${"product_id"}}`, encodeURIComponent(String(productId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Authorization required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(productBase, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * ProductsApi - functional programming interface
 * @export
 */
export const ProductsApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = ProductsApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary Assigns/unassign products to/from a mandator
         * @param {string} mandatorId 
         * @param {MandatorProductsRequest} mandatorProductsRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async assignProductsToMandator(mandatorId: string, mandatorProductsRequest: MandatorProductsRequest, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<AssignProductsToMandator201Response>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.assignProductsToMandator(mandatorId, mandatorProductsRequest, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['ProductsApi.assignProductsToMandator']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @summary Adds a new product to the system for a mandator
         * @param {Product} product Product object to be created
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async createProduct(product: Product, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<CreateProduct201Response>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.createProduct(product, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['ProductsApi.createProduct']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @summary Delete a product by id
         * @param {string} productId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async deleteProduct(productId: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<UpdateSite200Response>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.deleteProduct(productId, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['ProductsApi.deleteProduct']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @summary Returns all products
         * @param {string} [sort] order ascending or descending the list of products by a property. For descending order the sort field should be prefixed with a minus(\&#39;-\&#39;)
         * @param {number} [pageOffset] number of items that should be skipped and not included in the response
         * @param {number} [pageLimit] number of items to be included in the response
         * @param {GetSitesFilterParameter} [filter] Filters should be sent as encoded URI. Filterable items: code, name, category, unit_measure and description
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getAllProducts(sort?: string, pageOffset?: number, pageLimit?: number, filter?: GetSitesFilterParameter, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<GetAllProducts200Response>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getAllProducts(sort, pageOffset, pageLimit, filter, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['ProductsApi.getAllProducts']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @summary Returns all products for a mandator
         * @param {string} mandatorId 
         * @param {string} [sort] order ascending or descending the list of products by a property. For descending order the sort field should be prefixed with a minus(\&#39;-\&#39;)
         * @param {number} [pageOffset] number of items that should be skipped and not included in the response
         * @param {number} [pageLimit] number of items to be included in the response
         * @param {GetSitesFilterParameter} [filter] Filters should be sent as encoded URI.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getMandatorProducts(mandatorId: string, sort?: string, pageOffset?: number, pageLimit?: number, filter?: GetSitesFilterParameter, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<GetMandatorProducts200Response>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getMandatorProducts(mandatorId, sort, pageOffset, pageLimit, filter, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['ProductsApi.getMandatorProducts']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @summary Returns product details by id
         * @param {string} productId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getProductDetails(productId: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<CreateProduct201Response>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getProductDetails(productId, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['ProductsApi.getProductDetails']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @summary Updates a product by id
         * @param {string} productId 
         * @param {ProductBase} productBase 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updateProduct(productId: string, productBase: ProductBase, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<UpdateSite200Response>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.updateProduct(productId, productBase, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['ProductsApi.updateProduct']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
    }
};

/**
 * ProductsApi - factory interface
 * @export
 */
export const ProductsApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = ProductsApiFp(configuration)
    return {
        /**
         * 
         * @summary Assigns/unassign products to/from a mandator
         * @param {string} mandatorId 
         * @param {MandatorProductsRequest} mandatorProductsRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        assignProductsToMandator(mandatorId: string, mandatorProductsRequest: MandatorProductsRequest, options?: RawAxiosRequestConfig): AxiosPromise<AssignProductsToMandator201Response> {
            return localVarFp.assignProductsToMandator(mandatorId, mandatorProductsRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Adds a new product to the system for a mandator
         * @param {Product} product Product object to be created
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createProduct(product: Product, options?: RawAxiosRequestConfig): AxiosPromise<CreateProduct201Response> {
            return localVarFp.createProduct(product, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Delete a product by id
         * @param {string} productId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteProduct(productId: string, options?: RawAxiosRequestConfig): AxiosPromise<UpdateSite200Response> {
            return localVarFp.deleteProduct(productId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Returns all products
         * @param {string} [sort] order ascending or descending the list of products by a property. For descending order the sort field should be prefixed with a minus(\&#39;-\&#39;)
         * @param {number} [pageOffset] number of items that should be skipped and not included in the response
         * @param {number} [pageLimit] number of items to be included in the response
         * @param {GetSitesFilterParameter} [filter] Filters should be sent as encoded URI. Filterable items: code, name, category, unit_measure and description
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAllProducts(sort?: string, pageOffset?: number, pageLimit?: number, filter?: GetSitesFilterParameter, options?: RawAxiosRequestConfig): AxiosPromise<GetAllProducts200Response> {
            return localVarFp.getAllProducts(sort, pageOffset, pageLimit, filter, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Returns all products for a mandator
         * @param {string} mandatorId 
         * @param {string} [sort] order ascending or descending the list of products by a property. For descending order the sort field should be prefixed with a minus(\&#39;-\&#39;)
         * @param {number} [pageOffset] number of items that should be skipped and not included in the response
         * @param {number} [pageLimit] number of items to be included in the response
         * @param {GetSitesFilterParameter} [filter] Filters should be sent as encoded URI.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getMandatorProducts(mandatorId: string, sort?: string, pageOffset?: number, pageLimit?: number, filter?: GetSitesFilterParameter, options?: RawAxiosRequestConfig): AxiosPromise<GetMandatorProducts200Response> {
            return localVarFp.getMandatorProducts(mandatorId, sort, pageOffset, pageLimit, filter, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Returns product details by id
         * @param {string} productId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getProductDetails(productId: string, options?: RawAxiosRequestConfig): AxiosPromise<CreateProduct201Response> {
            return localVarFp.getProductDetails(productId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Updates a product by id
         * @param {string} productId 
         * @param {ProductBase} productBase 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateProduct(productId: string, productBase: ProductBase, options?: RawAxiosRequestConfig): AxiosPromise<UpdateSite200Response> {
            return localVarFp.updateProduct(productId, productBase, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * ProductsApi - object-oriented interface
 * @export
 * @class ProductsApi
 * @extends {BaseAPI}
 */
export class ProductsApi extends BaseAPI {
    /**
     * 
     * @summary Assigns/unassign products to/from a mandator
     * @param {string} mandatorId 
     * @param {MandatorProductsRequest} mandatorProductsRequest 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ProductsApi
     */
    public assignProductsToMandator(mandatorId: string, mandatorProductsRequest: MandatorProductsRequest, options?: RawAxiosRequestConfig) {
        return ProductsApiFp(this.configuration).assignProductsToMandator(mandatorId, mandatorProductsRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Adds a new product to the system for a mandator
     * @param {Product} product Product object to be created
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ProductsApi
     */
    public createProduct(product: Product, options?: RawAxiosRequestConfig) {
        return ProductsApiFp(this.configuration).createProduct(product, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Delete a product by id
     * @param {string} productId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ProductsApi
     */
    public deleteProduct(productId: string, options?: RawAxiosRequestConfig) {
        return ProductsApiFp(this.configuration).deleteProduct(productId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Returns all products
     * @param {string} [sort] order ascending or descending the list of products by a property. For descending order the sort field should be prefixed with a minus(\&#39;-\&#39;)
     * @param {number} [pageOffset] number of items that should be skipped and not included in the response
     * @param {number} [pageLimit] number of items to be included in the response
     * @param {GetSitesFilterParameter} [filter] Filters should be sent as encoded URI. Filterable items: code, name, category, unit_measure and description
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ProductsApi
     */
    public getAllProducts(sort?: string, pageOffset?: number, pageLimit?: number, filter?: GetSitesFilterParameter, options?: RawAxiosRequestConfig) {
        return ProductsApiFp(this.configuration).getAllProducts(sort, pageOffset, pageLimit, filter, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Returns all products for a mandator
     * @param {string} mandatorId 
     * @param {string} [sort] order ascending or descending the list of products by a property. For descending order the sort field should be prefixed with a minus(\&#39;-\&#39;)
     * @param {number} [pageOffset] number of items that should be skipped and not included in the response
     * @param {number} [pageLimit] number of items to be included in the response
     * @param {GetSitesFilterParameter} [filter] Filters should be sent as encoded URI.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ProductsApi
     */
    public getMandatorProducts(mandatorId: string, sort?: string, pageOffset?: number, pageLimit?: number, filter?: GetSitesFilterParameter, options?: RawAxiosRequestConfig) {
        return ProductsApiFp(this.configuration).getMandatorProducts(mandatorId, sort, pageOffset, pageLimit, filter, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Returns product details by id
     * @param {string} productId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ProductsApi
     */
    public getProductDetails(productId: string, options?: RawAxiosRequestConfig) {
        return ProductsApiFp(this.configuration).getProductDetails(productId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Updates a product by id
     * @param {string} productId 
     * @param {ProductBase} productBase 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ProductsApi
     */
    public updateProduct(productId: string, productBase: ProductBase, options?: RawAxiosRequestConfig) {
        return ProductsApiFp(this.configuration).updateProduct(productId, productBase, options).then((request) => request(this.axios, this.basePath));
    }
}



/**
 * ProvidersApi - axios parameter creator
 * @export
 */
export const ProvidersApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Adds a new field to the system
         * @param {ProviderFieldsRequest} providerFieldsRequest Field object that needs to be added to the system
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createField: async (providerFieldsRequest: ProviderFieldsRequest, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'providerFieldsRequest' is not null or undefined
            assertParamExists('createField', 'providerFieldsRequest', providerFieldsRequest)
            const localVarPath = `/providers/fields`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Authorization required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(providerFieldsRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Adds a new template for a provider to the system
         * @param {ProviderTemplatesRequest} providerTemplatesRequest Template object that needs to be added to the system
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createTemplate: async (providerTemplatesRequest: ProviderTemplatesRequest, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'providerTemplatesRequest' is not null or undefined
            assertParamExists('createTemplate', 'providerTemplatesRequest', providerTemplatesRequest)
            const localVarPath = `/providers/templates`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Authorization required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(providerTemplatesRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Delete a field by id
         * @param {string} fieldId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteField: async (fieldId: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'fieldId' is not null or undefined
            assertParamExists('deleteField', 'fieldId', fieldId)
            const localVarPath = `/providers/fields/{field_id}`
                .replace(`{${"field_id"}}`, encodeURIComponent(String(fieldId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Authorization required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Deletes a list of fields
         * @param {ProviderData} providerData Object that contains the list of ids and the action that has to be applied to them
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteFields: async (providerData: ProviderData, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'providerData' is not null or undefined
            assertParamExists('deleteFields', 'providerData', providerData)
            const localVarPath = `/providers/fields`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Authorization required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(providerData, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Delete a template by id
         * @param {string} templateId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteTemplate: async (templateId: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'templateId' is not null or undefined
            assertParamExists('deleteTemplate', 'templateId', templateId)
            const localVarPath = `/providers/templates/{template_id}`
                .replace(`{${"template_id"}}`, encodeURIComponent(String(templateId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Authorization required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Deletes a list of templates
         * @param {ProviderData} providerData Object that contains the list of ids and the action that has to be applied to them
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteTemplates: async (providerData: ProviderData, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'providerData' is not null or undefined
            assertParamExists('deleteTemplates', 'providerData', providerData)
            const localVarPath = `/providers/templates`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Authorization required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(providerData, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Returns a single field details
         * @param {string} fieldId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getField: async (fieldId: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'fieldId' is not null or undefined
            assertParamExists('getField', 'fieldId', fieldId)
            const localVarPath = `/providers/fields/{field_id}`
                .replace(`{${"field_id"}}`, encodeURIComponent(String(fieldId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Authorization required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Returns all the fields
         * @param {string} [sort] order ascending or descending the list of fields by a property. For descending order the sort field should be prefixed with a minus(\&#39;-\&#39;)
         * @param {number} [pageOffset] number of items that should be skipped and not included in the response
         * @param {number} [pageLimit] number of items to be included in the response
         * @param {GetSitesFilterParameter} [filter] Filters should be sent as encoded URI. Filterable items: name, key
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getFields: async (sort?: string, pageOffset?: number, pageLimit?: number, filter?: GetSitesFilterParameter, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/providers/fields`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Authorization required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (sort !== undefined) {
                localVarQueryParameter['sort'] = sort;
            }

            if (pageOffset !== undefined) {
                localVarQueryParameter['page[offset]'] = pageOffset;
            }

            if (pageLimit !== undefined) {
                localVarQueryParameter['page[limit]'] = pageLimit;
            }

            if (filter !== undefined) {
                for (const [key, value] of Object.entries(filter)) {
                    localVarQueryParameter[key] = value;
                }
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Returns all the templates for a mandator
         * @param {string} mandatorId 
         * @param {string} [provider] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getMandatorTemplates: async (mandatorId: string, provider?: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'mandatorId' is not null or undefined
            assertParamExists('getMandatorTemplates', 'mandatorId', mandatorId)
            const localVarPath = `/mandators/{mandator_id}/providers/templates`
                .replace(`{${"mandator_id"}}`, encodeURIComponent(String(mandatorId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Authorization required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (provider !== undefined) {
                localVarQueryParameter['provider'] = provider;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Returns all templates assigned to a mandator for a provider
         * @param {string} [provider] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getProviderTemplates: async (provider?: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/mandators/providers/templates`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Authorization required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (provider !== undefined) {
                localVarQueryParameter['provider'] = provider;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Returns all providers from the system
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getProviders: async (options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/providers`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Authorization required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Returns a single template details
         * @param {string} templateId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getTemplate: async (templateId: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'templateId' is not null or undefined
            assertParamExists('getTemplate', 'templateId', templateId)
            const localVarPath = `/providers/templates/{template_id}`
                .replace(`{${"template_id"}}`, encodeURIComponent(String(templateId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Authorization required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Returns all the templates
         * @param {string} [sort] order ascending or descending the list of templates by a property. For descending order the sort field should be prefixed with a minus(\&#39;-\&#39;)
         * @param {number} [pageOffset] number of items that should be skipped and not included in the response
         * @param {number} [pageLimit] number of items to be included in the response
         * @param {GetSitesFilterParameter} [filter] Filters should be sent as encoded URI. Filterable items: name
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getTemplates: async (sort?: string, pageOffset?: number, pageLimit?: number, filter?: GetSitesFilterParameter, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/providers/templates`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Authorization required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (sort !== undefined) {
                localVarQueryParameter['sort'] = sort;
            }

            if (pageOffset !== undefined) {
                localVarQueryParameter['page[offset]'] = pageOffset;
            }

            if (pageLimit !== undefined) {
                localVarQueryParameter['page[limit]'] = pageLimit;
            }

            if (filter !== undefined) {
                for (const [key, value] of Object.entries(filter)) {
                    localVarQueryParameter[key] = value;
                }
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Updates a field by id
         * @param {string} fieldId 
         * @param {ProviderFieldsRequest} providerFieldsRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateField: async (fieldId: string, providerFieldsRequest: ProviderFieldsRequest, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'fieldId' is not null or undefined
            assertParamExists('updateField', 'fieldId', fieldId)
            // verify required parameter 'providerFieldsRequest' is not null or undefined
            assertParamExists('updateField', 'providerFieldsRequest', providerFieldsRequest)
            const localVarPath = `/providers/fields/{field_id}`
                .replace(`{${"field_id"}}`, encodeURIComponent(String(fieldId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Authorization required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(providerFieldsRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Updates a template by id
         * @param {string} templateId 
         * @param {ProviderTemplatesRequest} providerTemplatesRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateTemplate: async (templateId: string, providerTemplatesRequest: ProviderTemplatesRequest, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'templateId' is not null or undefined
            assertParamExists('updateTemplate', 'templateId', templateId)
            // verify required parameter 'providerTemplatesRequest' is not null or undefined
            assertParamExists('updateTemplate', 'providerTemplatesRequest', providerTemplatesRequest)
            const localVarPath = `/providers/templates/{template_id}`
                .replace(`{${"template_id"}}`, encodeURIComponent(String(templateId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Authorization required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(providerTemplatesRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * ProvidersApi - functional programming interface
 * @export
 */
export const ProvidersApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = ProvidersApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary Adds a new field to the system
         * @param {ProviderFieldsRequest} providerFieldsRequest Field object that needs to be added to the system
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async createField(providerFieldsRequest: ProviderFieldsRequest, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<CreateField201Response>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.createField(providerFieldsRequest, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['ProvidersApi.createField']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @summary Adds a new template for a provider to the system
         * @param {ProviderTemplatesRequest} providerTemplatesRequest Template object that needs to be added to the system
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async createTemplate(providerTemplatesRequest: ProviderTemplatesRequest, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<CreateTemplate201Response>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.createTemplate(providerTemplatesRequest, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['ProvidersApi.createTemplate']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @summary Delete a field by id
         * @param {string} fieldId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async deleteField(fieldId: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<UpdateSite200Response>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.deleteField(fieldId, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['ProvidersApi.deleteField']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @summary Deletes a list of fields
         * @param {ProviderData} providerData Object that contains the list of ids and the action that has to be applied to them
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async deleteFields(providerData: ProviderData, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<UpdateSite200Response>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.deleteFields(providerData, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['ProvidersApi.deleteFields']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @summary Delete a template by id
         * @param {string} templateId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async deleteTemplate(templateId: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<UpdateSite200Response>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.deleteTemplate(templateId, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['ProvidersApi.deleteTemplate']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @summary Deletes a list of templates
         * @param {ProviderData} providerData Object that contains the list of ids and the action that has to be applied to them
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async deleteTemplates(providerData: ProviderData, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<UpdateSite200Response>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.deleteTemplates(providerData, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['ProvidersApi.deleteTemplates']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @summary Returns a single field details
         * @param {string} fieldId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getField(fieldId: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<CreateField201Response>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getField(fieldId, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['ProvidersApi.getField']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @summary Returns all the fields
         * @param {string} [sort] order ascending or descending the list of fields by a property. For descending order the sort field should be prefixed with a minus(\&#39;-\&#39;)
         * @param {number} [pageOffset] number of items that should be skipped and not included in the response
         * @param {number} [pageLimit] number of items to be included in the response
         * @param {GetSitesFilterParameter} [filter] Filters should be sent as encoded URI. Filterable items: name, key
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getFields(sort?: string, pageOffset?: number, pageLimit?: number, filter?: GetSitesFilterParameter, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<GetFields200Response>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getFields(sort, pageOffset, pageLimit, filter, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['ProvidersApi.getFields']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @summary Returns all the templates for a mandator
         * @param {string} mandatorId 
         * @param {string} [provider] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getMandatorTemplates(mandatorId: string, provider?: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<GetMandatorTemplates200Response>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getMandatorTemplates(mandatorId, provider, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['ProvidersApi.getMandatorTemplates']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @summary Returns all templates assigned to a mandator for a provider
         * @param {string} [provider] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getProviderTemplates(provider?: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<GetMandatorTemplates200Response>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getProviderTemplates(provider, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['ProvidersApi.getProviderTemplates']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @summary Returns all providers from the system
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getProviders(options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<GetProviders200Response>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getProviders(options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['ProvidersApi.getProviders']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @summary Returns a single template details
         * @param {string} templateId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getTemplate(templateId: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<CreateTemplate201Response>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getTemplate(templateId, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['ProvidersApi.getTemplate']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @summary Returns all the templates
         * @param {string} [sort] order ascending or descending the list of templates by a property. For descending order the sort field should be prefixed with a minus(\&#39;-\&#39;)
         * @param {number} [pageOffset] number of items that should be skipped and not included in the response
         * @param {number} [pageLimit] number of items to be included in the response
         * @param {GetSitesFilterParameter} [filter] Filters should be sent as encoded URI. Filterable items: name
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getTemplates(sort?: string, pageOffset?: number, pageLimit?: number, filter?: GetSitesFilterParameter, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<GetTemplates200Response>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getTemplates(sort, pageOffset, pageLimit, filter, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['ProvidersApi.getTemplates']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @summary Updates a field by id
         * @param {string} fieldId 
         * @param {ProviderFieldsRequest} providerFieldsRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updateField(fieldId: string, providerFieldsRequest: ProviderFieldsRequest, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<UpdateSite200Response>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.updateField(fieldId, providerFieldsRequest, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['ProvidersApi.updateField']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @summary Updates a template by id
         * @param {string} templateId 
         * @param {ProviderTemplatesRequest} providerTemplatesRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updateTemplate(templateId: string, providerTemplatesRequest: ProviderTemplatesRequest, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<UpdateSite200Response>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.updateTemplate(templateId, providerTemplatesRequest, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['ProvidersApi.updateTemplate']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
    }
};

/**
 * ProvidersApi - factory interface
 * @export
 */
export const ProvidersApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = ProvidersApiFp(configuration)
    return {
        /**
         * 
         * @summary Adds a new field to the system
         * @param {ProviderFieldsRequest} providerFieldsRequest Field object that needs to be added to the system
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createField(providerFieldsRequest: ProviderFieldsRequest, options?: RawAxiosRequestConfig): AxiosPromise<CreateField201Response> {
            return localVarFp.createField(providerFieldsRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Adds a new template for a provider to the system
         * @param {ProviderTemplatesRequest} providerTemplatesRequest Template object that needs to be added to the system
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createTemplate(providerTemplatesRequest: ProviderTemplatesRequest, options?: RawAxiosRequestConfig): AxiosPromise<CreateTemplate201Response> {
            return localVarFp.createTemplate(providerTemplatesRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Delete a field by id
         * @param {string} fieldId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteField(fieldId: string, options?: RawAxiosRequestConfig): AxiosPromise<UpdateSite200Response> {
            return localVarFp.deleteField(fieldId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Deletes a list of fields
         * @param {ProviderData} providerData Object that contains the list of ids and the action that has to be applied to them
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteFields(providerData: ProviderData, options?: RawAxiosRequestConfig): AxiosPromise<UpdateSite200Response> {
            return localVarFp.deleteFields(providerData, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Delete a template by id
         * @param {string} templateId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteTemplate(templateId: string, options?: RawAxiosRequestConfig): AxiosPromise<UpdateSite200Response> {
            return localVarFp.deleteTemplate(templateId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Deletes a list of templates
         * @param {ProviderData} providerData Object that contains the list of ids and the action that has to be applied to them
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteTemplates(providerData: ProviderData, options?: RawAxiosRequestConfig): AxiosPromise<UpdateSite200Response> {
            return localVarFp.deleteTemplates(providerData, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Returns a single field details
         * @param {string} fieldId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getField(fieldId: string, options?: RawAxiosRequestConfig): AxiosPromise<CreateField201Response> {
            return localVarFp.getField(fieldId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Returns all the fields
         * @param {string} [sort] order ascending or descending the list of fields by a property. For descending order the sort field should be prefixed with a minus(\&#39;-\&#39;)
         * @param {number} [pageOffset] number of items that should be skipped and not included in the response
         * @param {number} [pageLimit] number of items to be included in the response
         * @param {GetSitesFilterParameter} [filter] Filters should be sent as encoded URI. Filterable items: name, key
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getFields(sort?: string, pageOffset?: number, pageLimit?: number, filter?: GetSitesFilterParameter, options?: RawAxiosRequestConfig): AxiosPromise<GetFields200Response> {
            return localVarFp.getFields(sort, pageOffset, pageLimit, filter, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Returns all the templates for a mandator
         * @param {string} mandatorId 
         * @param {string} [provider] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getMandatorTemplates(mandatorId: string, provider?: string, options?: RawAxiosRequestConfig): AxiosPromise<GetMandatorTemplates200Response> {
            return localVarFp.getMandatorTemplates(mandatorId, provider, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Returns all templates assigned to a mandator for a provider
         * @param {string} [provider] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getProviderTemplates(provider?: string, options?: RawAxiosRequestConfig): AxiosPromise<GetMandatorTemplates200Response> {
            return localVarFp.getProviderTemplates(provider, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Returns all providers from the system
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getProviders(options?: RawAxiosRequestConfig): AxiosPromise<GetProviders200Response> {
            return localVarFp.getProviders(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Returns a single template details
         * @param {string} templateId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getTemplate(templateId: string, options?: RawAxiosRequestConfig): AxiosPromise<CreateTemplate201Response> {
            return localVarFp.getTemplate(templateId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Returns all the templates
         * @param {string} [sort] order ascending or descending the list of templates by a property. For descending order the sort field should be prefixed with a minus(\&#39;-\&#39;)
         * @param {number} [pageOffset] number of items that should be skipped and not included in the response
         * @param {number} [pageLimit] number of items to be included in the response
         * @param {GetSitesFilterParameter} [filter] Filters should be sent as encoded URI. Filterable items: name
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getTemplates(sort?: string, pageOffset?: number, pageLimit?: number, filter?: GetSitesFilterParameter, options?: RawAxiosRequestConfig): AxiosPromise<GetTemplates200Response> {
            return localVarFp.getTemplates(sort, pageOffset, pageLimit, filter, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Updates a field by id
         * @param {string} fieldId 
         * @param {ProviderFieldsRequest} providerFieldsRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateField(fieldId: string, providerFieldsRequest: ProviderFieldsRequest, options?: RawAxiosRequestConfig): AxiosPromise<UpdateSite200Response> {
            return localVarFp.updateField(fieldId, providerFieldsRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Updates a template by id
         * @param {string} templateId 
         * @param {ProviderTemplatesRequest} providerTemplatesRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateTemplate(templateId: string, providerTemplatesRequest: ProviderTemplatesRequest, options?: RawAxiosRequestConfig): AxiosPromise<UpdateSite200Response> {
            return localVarFp.updateTemplate(templateId, providerTemplatesRequest, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * ProvidersApi - object-oriented interface
 * @export
 * @class ProvidersApi
 * @extends {BaseAPI}
 */
export class ProvidersApi extends BaseAPI {
    /**
     * 
     * @summary Adds a new field to the system
     * @param {ProviderFieldsRequest} providerFieldsRequest Field object that needs to be added to the system
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ProvidersApi
     */
    public createField(providerFieldsRequest: ProviderFieldsRequest, options?: RawAxiosRequestConfig) {
        return ProvidersApiFp(this.configuration).createField(providerFieldsRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Adds a new template for a provider to the system
     * @param {ProviderTemplatesRequest} providerTemplatesRequest Template object that needs to be added to the system
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ProvidersApi
     */
    public createTemplate(providerTemplatesRequest: ProviderTemplatesRequest, options?: RawAxiosRequestConfig) {
        return ProvidersApiFp(this.configuration).createTemplate(providerTemplatesRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Delete a field by id
     * @param {string} fieldId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ProvidersApi
     */
    public deleteField(fieldId: string, options?: RawAxiosRequestConfig) {
        return ProvidersApiFp(this.configuration).deleteField(fieldId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Deletes a list of fields
     * @param {ProviderData} providerData Object that contains the list of ids and the action that has to be applied to them
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ProvidersApi
     */
    public deleteFields(providerData: ProviderData, options?: RawAxiosRequestConfig) {
        return ProvidersApiFp(this.configuration).deleteFields(providerData, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Delete a template by id
     * @param {string} templateId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ProvidersApi
     */
    public deleteTemplate(templateId: string, options?: RawAxiosRequestConfig) {
        return ProvidersApiFp(this.configuration).deleteTemplate(templateId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Deletes a list of templates
     * @param {ProviderData} providerData Object that contains the list of ids and the action that has to be applied to them
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ProvidersApi
     */
    public deleteTemplates(providerData: ProviderData, options?: RawAxiosRequestConfig) {
        return ProvidersApiFp(this.configuration).deleteTemplates(providerData, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Returns a single field details
     * @param {string} fieldId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ProvidersApi
     */
    public getField(fieldId: string, options?: RawAxiosRequestConfig) {
        return ProvidersApiFp(this.configuration).getField(fieldId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Returns all the fields
     * @param {string} [sort] order ascending or descending the list of fields by a property. For descending order the sort field should be prefixed with a minus(\&#39;-\&#39;)
     * @param {number} [pageOffset] number of items that should be skipped and not included in the response
     * @param {number} [pageLimit] number of items to be included in the response
     * @param {GetSitesFilterParameter} [filter] Filters should be sent as encoded URI. Filterable items: name, key
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ProvidersApi
     */
    public getFields(sort?: string, pageOffset?: number, pageLimit?: number, filter?: GetSitesFilterParameter, options?: RawAxiosRequestConfig) {
        return ProvidersApiFp(this.configuration).getFields(sort, pageOffset, pageLimit, filter, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Returns all the templates for a mandator
     * @param {string} mandatorId 
     * @param {string} [provider] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ProvidersApi
     */
    public getMandatorTemplates(mandatorId: string, provider?: string, options?: RawAxiosRequestConfig) {
        return ProvidersApiFp(this.configuration).getMandatorTemplates(mandatorId, provider, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Returns all templates assigned to a mandator for a provider
     * @param {string} [provider] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ProvidersApi
     */
    public getProviderTemplates(provider?: string, options?: RawAxiosRequestConfig) {
        return ProvidersApiFp(this.configuration).getProviderTemplates(provider, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Returns all providers from the system
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ProvidersApi
     */
    public getProviders(options?: RawAxiosRequestConfig) {
        return ProvidersApiFp(this.configuration).getProviders(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Returns a single template details
     * @param {string} templateId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ProvidersApi
     */
    public getTemplate(templateId: string, options?: RawAxiosRequestConfig) {
        return ProvidersApiFp(this.configuration).getTemplate(templateId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Returns all the templates
     * @param {string} [sort] order ascending or descending the list of templates by a property. For descending order the sort field should be prefixed with a minus(\&#39;-\&#39;)
     * @param {number} [pageOffset] number of items that should be skipped and not included in the response
     * @param {number} [pageLimit] number of items to be included in the response
     * @param {GetSitesFilterParameter} [filter] Filters should be sent as encoded URI. Filterable items: name
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ProvidersApi
     */
    public getTemplates(sort?: string, pageOffset?: number, pageLimit?: number, filter?: GetSitesFilterParameter, options?: RawAxiosRequestConfig) {
        return ProvidersApiFp(this.configuration).getTemplates(sort, pageOffset, pageLimit, filter, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Updates a field by id
     * @param {string} fieldId 
     * @param {ProviderFieldsRequest} providerFieldsRequest 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ProvidersApi
     */
    public updateField(fieldId: string, providerFieldsRequest: ProviderFieldsRequest, options?: RawAxiosRequestConfig) {
        return ProvidersApiFp(this.configuration).updateField(fieldId, providerFieldsRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Updates a template by id
     * @param {string} templateId 
     * @param {ProviderTemplatesRequest} providerTemplatesRequest 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ProvidersApi
     */
    public updateTemplate(templateId: string, providerTemplatesRequest: ProviderTemplatesRequest, options?: RawAxiosRequestConfig) {
        return ProvidersApiFp(this.configuration).updateTemplate(templateId, providerTemplatesRequest, options).then((request) => request(this.axios, this.basePath));
    }
}



/**
 * RemoteControlActionsHistoryApi - axios parameter creator
 * @export
 */
export const RemoteControlActionsHistoryApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Adds a new action history in the system
         * @param {RemoteControlHistoryBase} remoteControlHistoryBase Remote Control History Action object that needs to be added to the system
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createRemoteControlActionHistory: async (remoteControlHistoryBase: RemoteControlHistoryBase, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'remoteControlHistoryBase' is not null or undefined
            assertParamExists('createRemoteControlActionHistory', 'remoteControlHistoryBase', remoteControlHistoryBase)
            const localVarPath = `/remote_control/actions`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Authorization required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(remoteControlHistoryBase, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Returns details regarding remote control actions history for the requesting user with his mandator (from token)
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getRemoteControlActionsHistory: async (options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/remote_control/actions`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Authorization required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Updates an action history in the system with the \'error\' action state
         * @param {string} actionId 
         * @param {UpdateRemoteControlActionHistoryRequest} updateRemoteControlActionHistoryRequest Remote Control History Action with the state to update
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateRemoteControlActionHistory: async (actionId: string, updateRemoteControlActionHistoryRequest: UpdateRemoteControlActionHistoryRequest, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'actionId' is not null or undefined
            assertParamExists('updateRemoteControlActionHistory', 'actionId', actionId)
            // verify required parameter 'updateRemoteControlActionHistoryRequest' is not null or undefined
            assertParamExists('updateRemoteControlActionHistory', 'updateRemoteControlActionHistoryRequest', updateRemoteControlActionHistoryRequest)
            const localVarPath = `/remote_control/actions/{action_id}`
                .replace(`{${"action_id"}}`, encodeURIComponent(String(actionId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Authorization required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(updateRemoteControlActionHistoryRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * RemoteControlActionsHistoryApi - functional programming interface
 * @export
 */
export const RemoteControlActionsHistoryApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = RemoteControlActionsHistoryApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary Adds a new action history in the system
         * @param {RemoteControlHistoryBase} remoteControlHistoryBase Remote Control History Action object that needs to be added to the system
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async createRemoteControlActionHistory(remoteControlHistoryBase: RemoteControlHistoryBase, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<RemoteControlHistoryResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.createRemoteControlActionHistory(remoteControlHistoryBase, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['RemoteControlActionsHistoryApi.createRemoteControlActionHistory']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @summary Returns details regarding remote control actions history for the requesting user with his mandator (from token)
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getRemoteControlActionsHistory(options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<GetRemoteControlActionsHistory200Response>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getRemoteControlActionsHistory(options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['RemoteControlActionsHistoryApi.getRemoteControlActionsHistory']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @summary Updates an action history in the system with the \'error\' action state
         * @param {string} actionId 
         * @param {UpdateRemoteControlActionHistoryRequest} updateRemoteControlActionHistoryRequest Remote Control History Action with the state to update
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updateRemoteControlActionHistory(actionId: string, updateRemoteControlActionHistoryRequest: UpdateRemoteControlActionHistoryRequest, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<UpdateRemoteControlActionResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.updateRemoteControlActionHistory(actionId, updateRemoteControlActionHistoryRequest, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['RemoteControlActionsHistoryApi.updateRemoteControlActionHistory']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
    }
};

/**
 * RemoteControlActionsHistoryApi - factory interface
 * @export
 */
export const RemoteControlActionsHistoryApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = RemoteControlActionsHistoryApiFp(configuration)
    return {
        /**
         * 
         * @summary Adds a new action history in the system
         * @param {RemoteControlHistoryBase} remoteControlHistoryBase Remote Control History Action object that needs to be added to the system
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createRemoteControlActionHistory(remoteControlHistoryBase: RemoteControlHistoryBase, options?: RawAxiosRequestConfig): AxiosPromise<RemoteControlHistoryResponse> {
            return localVarFp.createRemoteControlActionHistory(remoteControlHistoryBase, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Returns details regarding remote control actions history for the requesting user with his mandator (from token)
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getRemoteControlActionsHistory(options?: RawAxiosRequestConfig): AxiosPromise<GetRemoteControlActionsHistory200Response> {
            return localVarFp.getRemoteControlActionsHistory(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Updates an action history in the system with the \'error\' action state
         * @param {string} actionId 
         * @param {UpdateRemoteControlActionHistoryRequest} updateRemoteControlActionHistoryRequest Remote Control History Action with the state to update
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateRemoteControlActionHistory(actionId: string, updateRemoteControlActionHistoryRequest: UpdateRemoteControlActionHistoryRequest, options?: RawAxiosRequestConfig): AxiosPromise<UpdateRemoteControlActionResponse> {
            return localVarFp.updateRemoteControlActionHistory(actionId, updateRemoteControlActionHistoryRequest, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * RemoteControlActionsHistoryApi - object-oriented interface
 * @export
 * @class RemoteControlActionsHistoryApi
 * @extends {BaseAPI}
 */
export class RemoteControlActionsHistoryApi extends BaseAPI {
    /**
     * 
     * @summary Adds a new action history in the system
     * @param {RemoteControlHistoryBase} remoteControlHistoryBase Remote Control History Action object that needs to be added to the system
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof RemoteControlActionsHistoryApi
     */
    public createRemoteControlActionHistory(remoteControlHistoryBase: RemoteControlHistoryBase, options?: RawAxiosRequestConfig) {
        return RemoteControlActionsHistoryApiFp(this.configuration).createRemoteControlActionHistory(remoteControlHistoryBase, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Returns details regarding remote control actions history for the requesting user with his mandator (from token)
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof RemoteControlActionsHistoryApi
     */
    public getRemoteControlActionsHistory(options?: RawAxiosRequestConfig) {
        return RemoteControlActionsHistoryApiFp(this.configuration).getRemoteControlActionsHistory(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Updates an action history in the system with the \'error\' action state
     * @param {string} actionId 
     * @param {UpdateRemoteControlActionHistoryRequest} updateRemoteControlActionHistoryRequest Remote Control History Action with the state to update
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof RemoteControlActionsHistoryApi
     */
    public updateRemoteControlActionHistory(actionId: string, updateRemoteControlActionHistoryRequest: UpdateRemoteControlActionHistoryRequest, options?: RawAxiosRequestConfig) {
        return RemoteControlActionsHistoryApiFp(this.configuration).updateRemoteControlActionHistory(actionId, updateRemoteControlActionHistoryRequest, options).then((request) => request(this.axios, this.basePath));
    }
}



/**
 * ServicesApi - axios parameter creator
 * @export
 */
export const ServicesApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * Returns the list of services
         * @summary Get Services
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getServices: async (options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/users/services`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Authorization required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * ServicesApi - functional programming interface
 * @export
 */
export const ServicesApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = ServicesApiAxiosParamCreator(configuration)
    return {
        /**
         * Returns the list of services
         * @summary Get Services
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getServices(options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<GetServices200Response>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getServices(options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['ServicesApi.getServices']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
    }
};

/**
 * ServicesApi - factory interface
 * @export
 */
export const ServicesApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = ServicesApiFp(configuration)
    return {
        /**
         * Returns the list of services
         * @summary Get Services
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getServices(options?: RawAxiosRequestConfig): AxiosPromise<GetServices200Response> {
            return localVarFp.getServices(options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * ServicesApi - object-oriented interface
 * @export
 * @class ServicesApi
 * @extends {BaseAPI}
 */
export class ServicesApi extends BaseAPI {
    /**
     * Returns the list of services
     * @summary Get Services
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ServicesApi
     */
    public getServices(options?: RawAxiosRequestConfig) {
        return ServicesApiFp(this.configuration).getServices(options).then((request) => request(this.axios, this.basePath));
    }
}



/**
 * SitesApi - axios parameter creator
 * @export
 */
export const SitesApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Adds a new site to the system
         * @param {CreateSite} createSite Site object that needs to be added to the system
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createSite: async (createSite: CreateSite, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'createSite' is not null or undefined
            assertParamExists('createSite', 'createSite', createSite)
            const localVarPath = `/sites`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Authorization required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(createSite, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Deletes a site by id
         * @param {string} siteId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteSite: async (siteId: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'siteId' is not null or undefined
            assertParamExists('deleteSite', 'siteId', siteId)
            const localVarPath = `/sites/{site_id}`
                .replace(`{${"site_id"}}`, encodeURIComponent(String(siteId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Authorization required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Getting List of Devices on a Site. Paginated.
         * @param {string} siteId The id of the site for which we want to receive the devices
         * @param {string} [sort] order ascending or descending the list of devices by a property. For descending order the sort field should be prefixed with a minus(\&#39;-\&#39;)
         * @param {number} [pageOffset] number of items that should be skipped and not included in the response
         * @param {number} [pageLimit] number of items to be included in the response
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getDevicesOfSite: async (siteId: string, sort?: string, pageOffset?: number, pageLimit?: number, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'siteId' is not null or undefined
            assertParamExists('getDevicesOfSite', 'siteId', siteId)
            const localVarPath = `/sites/{site_id}/devices`
                .replace(`{${"site_id"}}`, encodeURIComponent(String(siteId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Authorization required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (sort !== undefined) {
                localVarQueryParameter['sort'] = sort;
            }

            if (pageOffset !== undefined) {
                localVarQueryParameter['page[offset]'] = pageOffset;
            }

            if (pageLimit !== undefined) {
                localVarQueryParameter['page[limit]'] = pageLimit;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Returns a list of history for different resources
         * @param {string} resourceId The id of the resource (the id of the site/point/device etc)
         * @param {string} resourceName The name of the resource (eg. site, point, device, price, etc)
         * @param {number} [pageOffset] number of items that should be skipped and not included in the response
         * @param {number} [pageLimit] number of items to be included in the response
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getHistory: async (resourceId: string, resourceName: string, pageOffset?: number, pageLimit?: number, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'resourceId' is not null or undefined
            assertParamExists('getHistory', 'resourceId', resourceId)
            // verify required parameter 'resourceName' is not null or undefined
            assertParamExists('getHistory', 'resourceName', resourceName)
            const localVarPath = `/history`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Authorization required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (resourceId !== undefined) {
                localVarQueryParameter['resource_id'] = resourceId;
            }

            if (resourceName !== undefined) {
                localVarQueryParameter['resource_name'] = resourceName;
            }

            if (pageOffset !== undefined) {
                localVarQueryParameter['page[offset]'] = pageOffset;
            }

            if (pageLimit !== undefined) {
                localVarQueryParameter['page[limit]'] = pageLimit;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Getting List of Points on a Site. Pagination
         * @param {string} siteId The id of the site for which we want to receive the points
         * @param {string} [sort] order ascending or descending the list of points by a property. For descending order the sort field should be prefixed with a minus(\&#39;-\&#39;)
         * @param {number} [pageOffset] number of items that should be skipped and not included in the response
         * @param {number} [pageLimit] number of items to be included in the response
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getPointsOfSite: async (siteId: string, sort?: string, pageOffset?: number, pageLimit?: number, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'siteId' is not null or undefined
            assertParamExists('getPointsOfSite', 'siteId', siteId)
            const localVarPath = `/sites/{site_id}/points`
                .replace(`{${"site_id"}}`, encodeURIComponent(String(siteId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Authorization required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (sort !== undefined) {
                localVarQueryParameter['sort'] = sort;
            }

            if (pageOffset !== undefined) {
                localVarQueryParameter['page[offset]'] = pageOffset;
            }

            if (pageLimit !== undefined) {
                localVarQueryParameter['page[limit]'] = pageLimit;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Returns prices for a site
         * @param {string} siteId The if of the site
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getPricesForASite: async (siteId: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'siteId' is not null or undefined
            assertParamExists('getPricesForASite', 'siteId', siteId)
            const localVarPath = `/sites/{site_id}/prices`
                .replace(`{${"site_id"}}`, encodeURIComponent(String(siteId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Authorization required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Returns prices for all active sites of a mandator
         * @param {string} mandatorId Mandator id for which the active sites and their prices are requested
         * @param {string} [sort] order ascending or descending the list of prices by a field. For descending order the sort field should be prefixed with a minus(\&#39;-\&#39;)
         * @param {number} [pageOffset] number of items that should be skipped and not included in the response
         * @param {number} [pageLimit] number of items to be included in the response
         * @param {GetSitesFilterParameter} [filter] Filters should be sent as encoded URI. Filterable items: name
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getPricesForActiveSites: async (mandatorId: string, sort?: string, pageOffset?: number, pageLimit?: number, filter?: GetSitesFilterParameter, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'mandatorId' is not null or undefined
            assertParamExists('getPricesForActiveSites', 'mandatorId', mandatorId)
            const localVarPath = `/sites/prices`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Authorization required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (mandatorId !== undefined) {
                localVarQueryParameter['mandator_id'] = mandatorId;
            }

            if (sort !== undefined) {
                localVarQueryParameter['sort'] = sort;
            }

            if (pageOffset !== undefined) {
                localVarQueryParameter['page[offset]'] = pageOffset;
            }

            if (pageLimit !== undefined) {
                localVarQueryParameter['page[limit]'] = pageLimit;
            }

            if (filter !== undefined) {
                for (const [key, value] of Object.entries(filter)) {
                    localVarQueryParameter[key] = value;
                }
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Getting Products which are available on a dedicated point.
         * @param {string} siteId Id of the site
         * @param {string} pointId Id of the point
         * @param {string} [sort] order ascending or descending the list of products by a property. For descending order the sort field should be prefixed with a minus(\&#39;-\&#39;)
         * @param {number} [pageOffset] number of items that should be skipped and not included in the response
         * @param {number} [pageLimit] number of items to be included in the response
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getProductsOfPoint: async (siteId: string, pointId: string, sort?: string, pageOffset?: number, pageLimit?: number, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'siteId' is not null or undefined
            assertParamExists('getProductsOfPoint', 'siteId', siteId)
            // verify required parameter 'pointId' is not null or undefined
            assertParamExists('getProductsOfPoint', 'pointId', pointId)
            const localVarPath = `/sites/{site_id}/points/{point_id}/products`
                .replace(`{${"site_id"}}`, encodeURIComponent(String(siteId)))
                .replace(`{${"point_id"}}`, encodeURIComponent(String(pointId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Authorization required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (sort !== undefined) {
                localVarQueryParameter['sort'] = sort;
            }

            if (pageOffset !== undefined) {
                localVarQueryParameter['page[offset]'] = pageOffset;
            }

            if (pageLimit !== undefined) {
                localVarQueryParameter['page[limit]'] = pageLimit;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Getting the list of Products for a site. Paginated
         * @param {string} siteId The id of the site for which we want to receive the products
         * @param {string} [sort] order ascending or descending the list of products by a property. For descending order the sort field should be prefixed with a minus(\&#39;-\&#39;)
         * @param {number} [pageOffset] number of items that should be skipped and not included in the response
         * @param {number} [pageLimit] number of items to be included in the response
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getProductsOfSite: async (siteId: string, sort?: string, pageOffset?: number, pageLimit?: number, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'siteId' is not null or undefined
            assertParamExists('getProductsOfSite', 'siteId', siteId)
            const localVarPath = `/sites/{site_id}/products`
                .replace(`{${"site_id"}}`, encodeURIComponent(String(siteId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Authorization required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (sort !== undefined) {
                localVarQueryParameter['sort'] = sort;
            }

            if (pageOffset !== undefined) {
                localVarQueryParameter['page[offset]'] = pageOffset;
            }

            if (pageLimit !== undefined) {
                localVarQueryParameter['page[limit]'] = pageLimit;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Getting the list of products with their relations for a site
         * @param {string} siteId The id of the site
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getRelatedProducts: async (siteId: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'siteId' is not null or undefined
            assertParamExists('getRelatedProducts', 'siteId', siteId)
            const localVarPath = `/sites/{site_id}/products/relations`
                .replace(`{${"site_id"}}`, encodeURIComponent(String(siteId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Authorization required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Getting a Product for a site.
         * @param {string} siteId The id of the site
         * @param {string} productId The id of the product
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getSingleProductOfSite: async (siteId: string, productId: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'siteId' is not null or undefined
            assertParamExists('getSingleProductOfSite', 'siteId', siteId)
            // verify required parameter 'productId' is not null or undefined
            assertParamExists('getSingleProductOfSite', 'productId', productId)
            const localVarPath = `/sites/{site_id}/products/{product_id}`
                .replace(`{${"site_id"}}`, encodeURIComponent(String(siteId)))
                .replace(`{${"product_id"}}`, encodeURIComponent(String(productId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Authorization required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Return single Site.
         * @param {string} siteId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getSiteById: async (siteId: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'siteId' is not null or undefined
            assertParamExists('getSiteById', 'siteId', siteId)
            const localVarPath = `/sites/{site_id}`
                .replace(`{${"site_id"}}`, encodeURIComponent(String(siteId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Authorization required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Return a list of all accessible Sites. This is a paginated request.
         * @param {string} [sort] order ascending or descending the list of sites by a property. For descending order the sort field should be prefixed with a minus(\&#39;-\&#39;)
         * @param {number} [pageOffset] number of items that should be skipped and not included in the response
         * @param {number} [pageLimit] number of items to be included in the response
         * @param {GetSitesFilterParameter} [filter] Filters should be sent as encoded URI. Filterable items: name, code, city, street, country, currency, site_state
         * @param {string} [mandatorId] in case only the sites of a mandator are required
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getSites: async (sort?: string, pageOffset?: number, pageLimit?: number, filter?: GetSitesFilterParameter, mandatorId?: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/sites`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Authorization required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (sort !== undefined) {
                localVarQueryParameter['sort'] = sort;
            }

            if (pageOffset !== undefined) {
                localVarQueryParameter['page[offset]'] = pageOffset;
            }

            if (pageLimit !== undefined) {
                localVarQueryParameter['page[limit]'] = pageLimit;
            }

            if (filter !== undefined) {
                for (const [key, value] of Object.entries(filter)) {
                    localVarQueryParameter[key] = value;
                }
            }

            if (mandatorId !== undefined) {
                localVarQueryParameter['mandator_id'] = mandatorId;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Returns location for sites
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getSitesLocation: async (options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/sites/location`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Authorization required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Getting the list of Products for a site. Paginated
         * @param {string} siteId The id of the site for which we want to receive the products
         * @param {string} [sort] order ascending or descending the list of products by a property. For descending order the sort field should be prefixed with a minus(\&#39;-\&#39;)
         * @param {number} [pageOffset] number of items that should be skipped and not included in the response
         * @param {number} [pageLimit] number of items to be included in the response
         * @param {string} [cnp] license_plate of the car, without separators.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        productCollectorGetProductsOfSite: async (siteId: string, sort?: string, pageOffset?: number, pageLimit?: number, cnp?: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'siteId' is not null or undefined
            assertParamExists('productCollectorGetProductsOfSite', 'siteId', siteId)
            const localVarPath = `/sites/{site_id}/products/parking`
                .replace(`{${"site_id"}}`, encodeURIComponent(String(siteId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Authorization required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (sort !== undefined) {
                localVarQueryParameter['sort'] = sort;
            }

            if (pageOffset !== undefined) {
                localVarQueryParameter['page[offset]'] = pageOffset;
            }

            if (pageLimit !== undefined) {
                localVarQueryParameter['page[limit]'] = pageLimit;
            }

            if (cnp !== undefined) {
                localVarQueryParameter['cnp'] = cnp;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Update the product relations for a product on a site
         * @param {string} siteId The id of the site
         * @param {Array<ProductRelationsInner>} productRelationsInner 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateRelatedProducts: async (siteId: string, productRelationsInner: Array<ProductRelationsInner>, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'siteId' is not null or undefined
            assertParamExists('updateRelatedProducts', 'siteId', siteId)
            // verify required parameter 'productRelationsInner' is not null or undefined
            assertParamExists('updateRelatedProducts', 'productRelationsInner', productRelationsInner)
            const localVarPath = `/sites/{site_id}/products/relations`
                .replace(`{${"site_id"}}`, encodeURIComponent(String(siteId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Authorization required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(productRelationsInner, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Updates a site by id
         * @param {string} siteId 
         * @param {SiteExtended} siteExtended 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateSite: async (siteId: string, siteExtended: SiteExtended, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'siteId' is not null or undefined
            assertParamExists('updateSite', 'siteId', siteId)
            // verify required parameter 'siteExtended' is not null or undefined
            assertParamExists('updateSite', 'siteExtended', siteExtended)
            const localVarPath = `/sites/{site_id}`
                .replace(`{${"site_id"}}`, encodeURIComponent(String(siteId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Authorization required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(siteExtended, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Updates prices for a site
         * @param {string} siteId 
         * @param {UpdateSitePricesRequest} updateSitePricesRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateSitePrices: async (siteId: string, updateSitePricesRequest: UpdateSitePricesRequest, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'siteId' is not null or undefined
            assertParamExists('updateSitePrices', 'siteId', siteId)
            // verify required parameter 'updateSitePricesRequest' is not null or undefined
            assertParamExists('updateSitePrices', 'updateSitePricesRequest', updateSitePricesRequest)
            const localVarPath = `/sites/{site_id}/prices`
                .replace(`{${"site_id"}}`, encodeURIComponent(String(siteId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Authorization required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(updateSitePricesRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * SitesApi - functional programming interface
 * @export
 */
export const SitesApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = SitesApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary Adds a new site to the system
         * @param {CreateSite} createSite Site object that needs to be added to the system
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async createSite(createSite: CreateSite, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<CreateSite201Response>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.createSite(createSite, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['SitesApi.createSite']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @summary Deletes a site by id
         * @param {string} siteId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async deleteSite(siteId: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<UpdateSite200Response>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.deleteSite(siteId, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['SitesApi.deleteSite']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @summary Getting List of Devices on a Site. Paginated.
         * @param {string} siteId The id of the site for which we want to receive the devices
         * @param {string} [sort] order ascending or descending the list of devices by a property. For descending order the sort field should be prefixed with a minus(\&#39;-\&#39;)
         * @param {number} [pageOffset] number of items that should be skipped and not included in the response
         * @param {number} [pageLimit] number of items to be included in the response
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getDevicesOfSite(siteId: string, sort?: string, pageOffset?: number, pageLimit?: number, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<GetDevicesOfSite200Response>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getDevicesOfSite(siteId, sort, pageOffset, pageLimit, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['SitesApi.getDevicesOfSite']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @summary Returns a list of history for different resources
         * @param {string} resourceId The id of the resource (the id of the site/point/device etc)
         * @param {string} resourceName The name of the resource (eg. site, point, device, price, etc)
         * @param {number} [pageOffset] number of items that should be skipped and not included in the response
         * @param {number} [pageLimit] number of items to be included in the response
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getHistory(resourceId: string, resourceName: string, pageOffset?: number, pageLimit?: number, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<GetHistory200Response>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getHistory(resourceId, resourceName, pageOffset, pageLimit, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['SitesApi.getHistory']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @summary Getting List of Points on a Site. Pagination
         * @param {string} siteId The id of the site for which we want to receive the points
         * @param {string} [sort] order ascending or descending the list of points by a property. For descending order the sort field should be prefixed with a minus(\&#39;-\&#39;)
         * @param {number} [pageOffset] number of items that should be skipped and not included in the response
         * @param {number} [pageLimit] number of items to be included in the response
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getPointsOfSite(siteId: string, sort?: string, pageOffset?: number, pageLimit?: number, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<GetPointsOfSite200Response>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getPointsOfSite(siteId, sort, pageOffset, pageLimit, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['SitesApi.getPointsOfSite']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @summary Returns prices for a site
         * @param {string} siteId The if of the site
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getPricesForASite(siteId: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<GetPricesForASite200Response>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getPricesForASite(siteId, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['SitesApi.getPricesForASite']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @summary Returns prices for all active sites of a mandator
         * @param {string} mandatorId Mandator id for which the active sites and their prices are requested
         * @param {string} [sort] order ascending or descending the list of prices by a field. For descending order the sort field should be prefixed with a minus(\&#39;-\&#39;)
         * @param {number} [pageOffset] number of items that should be skipped and not included in the response
         * @param {number} [pageLimit] number of items to be included in the response
         * @param {GetSitesFilterParameter} [filter] Filters should be sent as encoded URI. Filterable items: name
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getPricesForActiveSites(mandatorId: string, sort?: string, pageOffset?: number, pageLimit?: number, filter?: GetSitesFilterParameter, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<GetPricesForActiveSites200Response>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getPricesForActiveSites(mandatorId, sort, pageOffset, pageLimit, filter, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['SitesApi.getPricesForActiveSites']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @summary Getting Products which are available on a dedicated point.
         * @param {string} siteId Id of the site
         * @param {string} pointId Id of the point
         * @param {string} [sort] order ascending or descending the list of products by a property. For descending order the sort field should be prefixed with a minus(\&#39;-\&#39;)
         * @param {number} [pageOffset] number of items that should be skipped and not included in the response
         * @param {number} [pageLimit] number of items to be included in the response
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getProductsOfPoint(siteId: string, pointId: string, sort?: string, pageOffset?: number, pageLimit?: number, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<GetProductsOfSite200Response>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getProductsOfPoint(siteId, pointId, sort, pageOffset, pageLimit, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['SitesApi.getProductsOfPoint']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @summary Getting the list of Products for a site. Paginated
         * @param {string} siteId The id of the site for which we want to receive the products
         * @param {string} [sort] order ascending or descending the list of products by a property. For descending order the sort field should be prefixed with a minus(\&#39;-\&#39;)
         * @param {number} [pageOffset] number of items that should be skipped and not included in the response
         * @param {number} [pageLimit] number of items to be included in the response
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getProductsOfSite(siteId: string, sort?: string, pageOffset?: number, pageLimit?: number, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<GetProductsOfSite200Response>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getProductsOfSite(siteId, sort, pageOffset, pageLimit, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['SitesApi.getProductsOfSite']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @summary Getting the list of products with their relations for a site
         * @param {string} siteId The id of the site
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getRelatedProducts(siteId: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<GetRelatedProducts200Response>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getRelatedProducts(siteId, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['SitesApi.getRelatedProducts']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @summary Getting a Product for a site.
         * @param {string} siteId The id of the site
         * @param {string} productId The id of the product
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getSingleProductOfSite(siteId: string, productId: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<GetProductsOfSite200Response>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getSingleProductOfSite(siteId, productId, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['SitesApi.getSingleProductOfSite']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @summary Return single Site.
         * @param {string} siteId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getSiteById(siteId: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<GetSiteById200Response>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getSiteById(siteId, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['SitesApi.getSiteById']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @summary Return a list of all accessible Sites. This is a paginated request.
         * @param {string} [sort] order ascending or descending the list of sites by a property. For descending order the sort field should be prefixed with a minus(\&#39;-\&#39;)
         * @param {number} [pageOffset] number of items that should be skipped and not included in the response
         * @param {number} [pageLimit] number of items to be included in the response
         * @param {GetSitesFilterParameter} [filter] Filters should be sent as encoded URI. Filterable items: name, code, city, street, country, currency, site_state
         * @param {string} [mandatorId] in case only the sites of a mandator are required
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getSites(sort?: string, pageOffset?: number, pageLimit?: number, filter?: GetSitesFilterParameter, mandatorId?: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<GetSites200Response>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getSites(sort, pageOffset, pageLimit, filter, mandatorId, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['SitesApi.getSites']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @summary Returns location for sites
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getSitesLocation(options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<GetSitesLocation200Response>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getSitesLocation(options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['SitesApi.getSitesLocation']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @summary Getting the list of Products for a site. Paginated
         * @param {string} siteId The id of the site for which we want to receive the products
         * @param {string} [sort] order ascending or descending the list of products by a property. For descending order the sort field should be prefixed with a minus(\&#39;-\&#39;)
         * @param {number} [pageOffset] number of items that should be skipped and not included in the response
         * @param {number} [pageLimit] number of items to be included in the response
         * @param {string} [cnp] license_plate of the car, without separators.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async productCollectorGetProductsOfSite(siteId: string, sort?: string, pageOffset?: number, pageLimit?: number, cnp?: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<GetProductsOfSite200Response>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.productCollectorGetProductsOfSite(siteId, sort, pageOffset, pageLimit, cnp, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['SitesApi.productCollectorGetProductsOfSite']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @summary Update the product relations for a product on a site
         * @param {string} siteId The id of the site
         * @param {Array<ProductRelationsInner>} productRelationsInner 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updateRelatedProducts(siteId: string, productRelationsInner: Array<ProductRelationsInner>, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<UpdateSite200Response>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.updateRelatedProducts(siteId, productRelationsInner, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['SitesApi.updateRelatedProducts']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @summary Updates a site by id
         * @param {string} siteId 
         * @param {SiteExtended} siteExtended 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updateSite(siteId: string, siteExtended: SiteExtended, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<UpdateSite200Response>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.updateSite(siteId, siteExtended, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['SitesApi.updateSite']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @summary Updates prices for a site
         * @param {string} siteId 
         * @param {UpdateSitePricesRequest} updateSitePricesRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updateSitePrices(siteId: string, updateSitePricesRequest: UpdateSitePricesRequest, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<UpdateSite200Response>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.updateSitePrices(siteId, updateSitePricesRequest, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['SitesApi.updateSitePrices']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
    }
};

/**
 * SitesApi - factory interface
 * @export
 */
export const SitesApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = SitesApiFp(configuration)
    return {
        /**
         * 
         * @summary Adds a new site to the system
         * @param {CreateSite} createSite Site object that needs to be added to the system
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createSite(createSite: CreateSite, options?: RawAxiosRequestConfig): AxiosPromise<CreateSite201Response> {
            return localVarFp.createSite(createSite, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Deletes a site by id
         * @param {string} siteId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteSite(siteId: string, options?: RawAxiosRequestConfig): AxiosPromise<UpdateSite200Response> {
            return localVarFp.deleteSite(siteId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Getting List of Devices on a Site. Paginated.
         * @param {string} siteId The id of the site for which we want to receive the devices
         * @param {string} [sort] order ascending or descending the list of devices by a property. For descending order the sort field should be prefixed with a minus(\&#39;-\&#39;)
         * @param {number} [pageOffset] number of items that should be skipped and not included in the response
         * @param {number} [pageLimit] number of items to be included in the response
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getDevicesOfSite(siteId: string, sort?: string, pageOffset?: number, pageLimit?: number, options?: RawAxiosRequestConfig): AxiosPromise<GetDevicesOfSite200Response> {
            return localVarFp.getDevicesOfSite(siteId, sort, pageOffset, pageLimit, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Returns a list of history for different resources
         * @param {string} resourceId The id of the resource (the id of the site/point/device etc)
         * @param {string} resourceName The name of the resource (eg. site, point, device, price, etc)
         * @param {number} [pageOffset] number of items that should be skipped and not included in the response
         * @param {number} [pageLimit] number of items to be included in the response
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getHistory(resourceId: string, resourceName: string, pageOffset?: number, pageLimit?: number, options?: RawAxiosRequestConfig): AxiosPromise<GetHistory200Response> {
            return localVarFp.getHistory(resourceId, resourceName, pageOffset, pageLimit, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Getting List of Points on a Site. Pagination
         * @param {string} siteId The id of the site for which we want to receive the points
         * @param {string} [sort] order ascending or descending the list of points by a property. For descending order the sort field should be prefixed with a minus(\&#39;-\&#39;)
         * @param {number} [pageOffset] number of items that should be skipped and not included in the response
         * @param {number} [pageLimit] number of items to be included in the response
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getPointsOfSite(siteId: string, sort?: string, pageOffset?: number, pageLimit?: number, options?: RawAxiosRequestConfig): AxiosPromise<GetPointsOfSite200Response> {
            return localVarFp.getPointsOfSite(siteId, sort, pageOffset, pageLimit, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Returns prices for a site
         * @param {string} siteId The if of the site
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getPricesForASite(siteId: string, options?: RawAxiosRequestConfig): AxiosPromise<GetPricesForASite200Response> {
            return localVarFp.getPricesForASite(siteId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Returns prices for all active sites of a mandator
         * @param {string} mandatorId Mandator id for which the active sites and their prices are requested
         * @param {string} [sort] order ascending or descending the list of prices by a field. For descending order the sort field should be prefixed with a minus(\&#39;-\&#39;)
         * @param {number} [pageOffset] number of items that should be skipped and not included in the response
         * @param {number} [pageLimit] number of items to be included in the response
         * @param {GetSitesFilterParameter} [filter] Filters should be sent as encoded URI. Filterable items: name
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getPricesForActiveSites(mandatorId: string, sort?: string, pageOffset?: number, pageLimit?: number, filter?: GetSitesFilterParameter, options?: RawAxiosRequestConfig): AxiosPromise<GetPricesForActiveSites200Response> {
            return localVarFp.getPricesForActiveSites(mandatorId, sort, pageOffset, pageLimit, filter, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Getting Products which are available on a dedicated point.
         * @param {string} siteId Id of the site
         * @param {string} pointId Id of the point
         * @param {string} [sort] order ascending or descending the list of products by a property. For descending order the sort field should be prefixed with a minus(\&#39;-\&#39;)
         * @param {number} [pageOffset] number of items that should be skipped and not included in the response
         * @param {number} [pageLimit] number of items to be included in the response
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getProductsOfPoint(siteId: string, pointId: string, sort?: string, pageOffset?: number, pageLimit?: number, options?: RawAxiosRequestConfig): AxiosPromise<GetProductsOfSite200Response> {
            return localVarFp.getProductsOfPoint(siteId, pointId, sort, pageOffset, pageLimit, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Getting the list of Products for a site. Paginated
         * @param {string} siteId The id of the site for which we want to receive the products
         * @param {string} [sort] order ascending or descending the list of products by a property. For descending order the sort field should be prefixed with a minus(\&#39;-\&#39;)
         * @param {number} [pageOffset] number of items that should be skipped and not included in the response
         * @param {number} [pageLimit] number of items to be included in the response
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getProductsOfSite(siteId: string, sort?: string, pageOffset?: number, pageLimit?: number, options?: RawAxiosRequestConfig): AxiosPromise<GetProductsOfSite200Response> {
            return localVarFp.getProductsOfSite(siteId, sort, pageOffset, pageLimit, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Getting the list of products with their relations for a site
         * @param {string} siteId The id of the site
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getRelatedProducts(siteId: string, options?: RawAxiosRequestConfig): AxiosPromise<GetRelatedProducts200Response> {
            return localVarFp.getRelatedProducts(siteId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Getting a Product for a site.
         * @param {string} siteId The id of the site
         * @param {string} productId The id of the product
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getSingleProductOfSite(siteId: string, productId: string, options?: RawAxiosRequestConfig): AxiosPromise<GetProductsOfSite200Response> {
            return localVarFp.getSingleProductOfSite(siteId, productId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Return single Site.
         * @param {string} siteId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getSiteById(siteId: string, options?: RawAxiosRequestConfig): AxiosPromise<GetSiteById200Response> {
            return localVarFp.getSiteById(siteId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Return a list of all accessible Sites. This is a paginated request.
         * @param {string} [sort] order ascending or descending the list of sites by a property. For descending order the sort field should be prefixed with a minus(\&#39;-\&#39;)
         * @param {number} [pageOffset] number of items that should be skipped and not included in the response
         * @param {number} [pageLimit] number of items to be included in the response
         * @param {GetSitesFilterParameter} [filter] Filters should be sent as encoded URI. Filterable items: name, code, city, street, country, currency, site_state
         * @param {string} [mandatorId] in case only the sites of a mandator are required
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getSites(sort?: string, pageOffset?: number, pageLimit?: number, filter?: GetSitesFilterParameter, mandatorId?: string, options?: RawAxiosRequestConfig): AxiosPromise<GetSites200Response> {
            return localVarFp.getSites(sort, pageOffset, pageLimit, filter, mandatorId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Returns location for sites
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getSitesLocation(options?: RawAxiosRequestConfig): AxiosPromise<GetSitesLocation200Response> {
            return localVarFp.getSitesLocation(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Getting the list of Products for a site. Paginated
         * @param {string} siteId The id of the site for which we want to receive the products
         * @param {string} [sort] order ascending or descending the list of products by a property. For descending order the sort field should be prefixed with a minus(\&#39;-\&#39;)
         * @param {number} [pageOffset] number of items that should be skipped and not included in the response
         * @param {number} [pageLimit] number of items to be included in the response
         * @param {string} [cnp] license_plate of the car, without separators.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        productCollectorGetProductsOfSite(siteId: string, sort?: string, pageOffset?: number, pageLimit?: number, cnp?: string, options?: RawAxiosRequestConfig): AxiosPromise<GetProductsOfSite200Response> {
            return localVarFp.productCollectorGetProductsOfSite(siteId, sort, pageOffset, pageLimit, cnp, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Update the product relations for a product on a site
         * @param {string} siteId The id of the site
         * @param {Array<ProductRelationsInner>} productRelationsInner 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateRelatedProducts(siteId: string, productRelationsInner: Array<ProductRelationsInner>, options?: RawAxiosRequestConfig): AxiosPromise<UpdateSite200Response> {
            return localVarFp.updateRelatedProducts(siteId, productRelationsInner, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Updates a site by id
         * @param {string} siteId 
         * @param {SiteExtended} siteExtended 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateSite(siteId: string, siteExtended: SiteExtended, options?: RawAxiosRequestConfig): AxiosPromise<UpdateSite200Response> {
            return localVarFp.updateSite(siteId, siteExtended, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Updates prices for a site
         * @param {string} siteId 
         * @param {UpdateSitePricesRequest} updateSitePricesRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateSitePrices(siteId: string, updateSitePricesRequest: UpdateSitePricesRequest, options?: RawAxiosRequestConfig): AxiosPromise<UpdateSite200Response> {
            return localVarFp.updateSitePrices(siteId, updateSitePricesRequest, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * SitesApi - object-oriented interface
 * @export
 * @class SitesApi
 * @extends {BaseAPI}
 */
export class SitesApi extends BaseAPI {
    /**
     * 
     * @summary Adds a new site to the system
     * @param {CreateSite} createSite Site object that needs to be added to the system
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SitesApi
     */
    public createSite(createSite: CreateSite, options?: RawAxiosRequestConfig) {
        return SitesApiFp(this.configuration).createSite(createSite, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Deletes a site by id
     * @param {string} siteId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SitesApi
     */
    public deleteSite(siteId: string, options?: RawAxiosRequestConfig) {
        return SitesApiFp(this.configuration).deleteSite(siteId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Getting List of Devices on a Site. Paginated.
     * @param {string} siteId The id of the site for which we want to receive the devices
     * @param {string} [sort] order ascending or descending the list of devices by a property. For descending order the sort field should be prefixed with a minus(\&#39;-\&#39;)
     * @param {number} [pageOffset] number of items that should be skipped and not included in the response
     * @param {number} [pageLimit] number of items to be included in the response
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SitesApi
     */
    public getDevicesOfSite(siteId: string, sort?: string, pageOffset?: number, pageLimit?: number, options?: RawAxiosRequestConfig) {
        return SitesApiFp(this.configuration).getDevicesOfSite(siteId, sort, pageOffset, pageLimit, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Returns a list of history for different resources
     * @param {string} resourceId The id of the resource (the id of the site/point/device etc)
     * @param {string} resourceName The name of the resource (eg. site, point, device, price, etc)
     * @param {number} [pageOffset] number of items that should be skipped and not included in the response
     * @param {number} [pageLimit] number of items to be included in the response
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SitesApi
     */
    public getHistory(resourceId: string, resourceName: string, pageOffset?: number, pageLimit?: number, options?: RawAxiosRequestConfig) {
        return SitesApiFp(this.configuration).getHistory(resourceId, resourceName, pageOffset, pageLimit, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Getting List of Points on a Site. Pagination
     * @param {string} siteId The id of the site for which we want to receive the points
     * @param {string} [sort] order ascending or descending the list of points by a property. For descending order the sort field should be prefixed with a minus(\&#39;-\&#39;)
     * @param {number} [pageOffset] number of items that should be skipped and not included in the response
     * @param {number} [pageLimit] number of items to be included in the response
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SitesApi
     */
    public getPointsOfSite(siteId: string, sort?: string, pageOffset?: number, pageLimit?: number, options?: RawAxiosRequestConfig) {
        return SitesApiFp(this.configuration).getPointsOfSite(siteId, sort, pageOffset, pageLimit, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Returns prices for a site
     * @param {string} siteId The if of the site
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SitesApi
     */
    public getPricesForASite(siteId: string, options?: RawAxiosRequestConfig) {
        return SitesApiFp(this.configuration).getPricesForASite(siteId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Returns prices for all active sites of a mandator
     * @param {string} mandatorId Mandator id for which the active sites and their prices are requested
     * @param {string} [sort] order ascending or descending the list of prices by a field. For descending order the sort field should be prefixed with a minus(\&#39;-\&#39;)
     * @param {number} [pageOffset] number of items that should be skipped and not included in the response
     * @param {number} [pageLimit] number of items to be included in the response
     * @param {GetSitesFilterParameter} [filter] Filters should be sent as encoded URI. Filterable items: name
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SitesApi
     */
    public getPricesForActiveSites(mandatorId: string, sort?: string, pageOffset?: number, pageLimit?: number, filter?: GetSitesFilterParameter, options?: RawAxiosRequestConfig) {
        return SitesApiFp(this.configuration).getPricesForActiveSites(mandatorId, sort, pageOffset, pageLimit, filter, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Getting Products which are available on a dedicated point.
     * @param {string} siteId Id of the site
     * @param {string} pointId Id of the point
     * @param {string} [sort] order ascending or descending the list of products by a property. For descending order the sort field should be prefixed with a minus(\&#39;-\&#39;)
     * @param {number} [pageOffset] number of items that should be skipped and not included in the response
     * @param {number} [pageLimit] number of items to be included in the response
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SitesApi
     */
    public getProductsOfPoint(siteId: string, pointId: string, sort?: string, pageOffset?: number, pageLimit?: number, options?: RawAxiosRequestConfig) {
        return SitesApiFp(this.configuration).getProductsOfPoint(siteId, pointId, sort, pageOffset, pageLimit, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Getting the list of Products for a site. Paginated
     * @param {string} siteId The id of the site for which we want to receive the products
     * @param {string} [sort] order ascending or descending the list of products by a property. For descending order the sort field should be prefixed with a minus(\&#39;-\&#39;)
     * @param {number} [pageOffset] number of items that should be skipped and not included in the response
     * @param {number} [pageLimit] number of items to be included in the response
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SitesApi
     */
    public getProductsOfSite(siteId: string, sort?: string, pageOffset?: number, pageLimit?: number, options?: RawAxiosRequestConfig) {
        return SitesApiFp(this.configuration).getProductsOfSite(siteId, sort, pageOffset, pageLimit, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Getting the list of products with their relations for a site
     * @param {string} siteId The id of the site
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SitesApi
     */
    public getRelatedProducts(siteId: string, options?: RawAxiosRequestConfig) {
        return SitesApiFp(this.configuration).getRelatedProducts(siteId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Getting a Product for a site.
     * @param {string} siteId The id of the site
     * @param {string} productId The id of the product
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SitesApi
     */
    public getSingleProductOfSite(siteId: string, productId: string, options?: RawAxiosRequestConfig) {
        return SitesApiFp(this.configuration).getSingleProductOfSite(siteId, productId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Return single Site.
     * @param {string} siteId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SitesApi
     */
    public getSiteById(siteId: string, options?: RawAxiosRequestConfig) {
        return SitesApiFp(this.configuration).getSiteById(siteId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Return a list of all accessible Sites. This is a paginated request.
     * @param {string} [sort] order ascending or descending the list of sites by a property. For descending order the sort field should be prefixed with a minus(\&#39;-\&#39;)
     * @param {number} [pageOffset] number of items that should be skipped and not included in the response
     * @param {number} [pageLimit] number of items to be included in the response
     * @param {GetSitesFilterParameter} [filter] Filters should be sent as encoded URI. Filterable items: name, code, city, street, country, currency, site_state
     * @param {string} [mandatorId] in case only the sites of a mandator are required
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SitesApi
     */
    public getSites(sort?: string, pageOffset?: number, pageLimit?: number, filter?: GetSitesFilterParameter, mandatorId?: string, options?: RawAxiosRequestConfig) {
        return SitesApiFp(this.configuration).getSites(sort, pageOffset, pageLimit, filter, mandatorId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Returns location for sites
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SitesApi
     */
    public getSitesLocation(options?: RawAxiosRequestConfig) {
        return SitesApiFp(this.configuration).getSitesLocation(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Getting the list of Products for a site. Paginated
     * @param {string} siteId The id of the site for which we want to receive the products
     * @param {string} [sort] order ascending or descending the list of products by a property. For descending order the sort field should be prefixed with a minus(\&#39;-\&#39;)
     * @param {number} [pageOffset] number of items that should be skipped and not included in the response
     * @param {number} [pageLimit] number of items to be included in the response
     * @param {string} [cnp] license_plate of the car, without separators.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SitesApi
     */
    public productCollectorGetProductsOfSite(siteId: string, sort?: string, pageOffset?: number, pageLimit?: number, cnp?: string, options?: RawAxiosRequestConfig) {
        return SitesApiFp(this.configuration).productCollectorGetProductsOfSite(siteId, sort, pageOffset, pageLimit, cnp, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Update the product relations for a product on a site
     * @param {string} siteId The id of the site
     * @param {Array<ProductRelationsInner>} productRelationsInner 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SitesApi
     */
    public updateRelatedProducts(siteId: string, productRelationsInner: Array<ProductRelationsInner>, options?: RawAxiosRequestConfig) {
        return SitesApiFp(this.configuration).updateRelatedProducts(siteId, productRelationsInner, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Updates a site by id
     * @param {string} siteId 
     * @param {SiteExtended} siteExtended 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SitesApi
     */
    public updateSite(siteId: string, siteExtended: SiteExtended, options?: RawAxiosRequestConfig) {
        return SitesApiFp(this.configuration).updateSite(siteId, siteExtended, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Updates prices for a site
     * @param {string} siteId 
     * @param {UpdateSitePricesRequest} updateSitePricesRequest 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SitesApi
     */
    public updateSitePrices(siteId: string, updateSitePricesRequest: UpdateSitePricesRequest, options?: RawAxiosRequestConfig) {
        return SitesApiFp(this.configuration).updateSitePrices(siteId, updateSitePricesRequest, options).then((request) => request(this.axios, this.basePath));
    }
}



/**
 * TWINTApi - axios parameter creator
 * @export
 */
export const TWINTApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Creates a link between module and mandator
         * @param {ActivationRequest} activationRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        activateTWINTModuleForAMandator: async (activationRequest: ActivationRequest, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'activationRequest' is not null or undefined
            assertParamExists('activateTWINTModuleForAMandator', 'activationRequest', activationRequest)
            const localVarPath = `/twint/config/mandators/activations`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Authorization required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(activationRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Deletes the link between module and mandator
         * @param {string} mandatorId Mandator id to deactivate
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deactivateTWINTForAMandator: async (mandatorId: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'mandatorId' is not null or undefined
            assertParamExists('deactivateTWINTForAMandator', 'mandatorId', mandatorId)
            const localVarPath = `/twint/config/mandators/activations/{mandator_id}`
                .replace(`{${"mandator_id"}}`, encodeURIComponent(String(mandatorId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Authorization required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Returns all points with their QR codes
         * @summary Get the list of all points with their QR codes for a site
         * @param {string} siteId Site unique identifier
         * @param {string} [sort] order ascending or descending the list of points by a property (name, point_number). For descending order the sort field should be prefixed with a minus(\&#39;-\&#39;)
         * @param {number} [pageOffset] number of items that should be skipped and not included in the response
         * @param {number} [pageLimit] number of items to be included in the response
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getPointsQRCodes: async (siteId: string, sort?: string, pageOffset?: number, pageLimit?: number, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'siteId' is not null or undefined
            assertParamExists('getPointsQRCodes', 'siteId', siteId)
            const localVarPath = `/twint/sites/{site_id}/points`
                .replace(`{${"site_id"}}`, encodeURIComponent(String(siteId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Authorization required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (sort !== undefined) {
                localVarQueryParameter['sort'] = sort;
            }

            if (pageOffset !== undefined) {
                localVarQueryParameter['page[offset]'] = pageOffset;
            }

            if (pageLimit !== undefined) {
                localVarQueryParameter['page[limit]'] = pageLimit;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Returns the site and points for the QR
         * @summary Get the the site and points based on the scanned QR code
         * @param {string} qRCode The scanned QR code
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getSitePointsByQRCode: async (qRCode: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'qRCode' is not null or undefined
            assertParamExists('getSitePointsByQRCode', 'qRCode', qRCode)
            const localVarPath = `/twint/webshop/sites`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (qRCode !== undefined) {
                localVarQueryParameter['QR_code'] = qRCode;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Returns the site details
         * @summary Get the details for a site
         * @param {string} siteId Site unique identifier
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getSiteTwint: async (siteId: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'siteId' is not null or undefined
            assertParamExists('getSiteTwint', 'siteId', siteId)
            const localVarPath = `/twint/sites/{site_id}`
                .replace(`{${"site_id"}}`, encodeURIComponent(String(siteId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Authorization required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Returns all sites with their QR codes
         * @summary Get the list of all sites for current mandator with their QR codes
         * @param {string} [sort] order ascending or descending the list of sites by a property (name, code). For descending order the sort field should be prefixed with a minus(\&#39;-\&#39;)
         * @param {number} [pageOffset] number of items that should be skipped and not included in the response
         * @param {number} [pageLimit] number of items to be included in the response
         * @param {GetSitesFilterParameter} [filter] Filters should be sent as encoded URI. Filterable items: name, code
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getSitesQRCodes: async (sort?: string, pageOffset?: number, pageLimit?: number, filter?: GetSitesFilterParameter, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/twint/sites`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Authorization required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (sort !== undefined) {
                localVarQueryParameter['sort'] = sort;
            }

            if (pageOffset !== undefined) {
                localVarQueryParameter['page[offset]'] = pageOffset;
            }

            if (pageLimit !== undefined) {
                localVarQueryParameter['page[limit]'] = pageLimit;
            }

            if (filter !== undefined) {
                for (const [key, value] of Object.entries(filter)) {
                    localVarQueryParameter[key] = value;
                }
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Getting TWINT fields for a mandator.
         * @param {string} mandatorId Mandator unique identifier
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getTWINTFieldsForAMandator: async (mandatorId: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'mandatorId' is not null or undefined
            assertParamExists('getTWINTFieldsForAMandator', 'mandatorId', mandatorId)
            const localVarPath = `/twint/config/mandators/fields/{mandator_id}`
                .replace(`{${"mandator_id"}}`, encodeURIComponent(String(mandatorId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Authorization required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Getting Module details.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getTWINTModule: async (options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/twint/module`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Authorization required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Getting TWINT module status for a mandator.
         * @param {string} mandatorId Mandator unique identifier
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getTWINTStatusForAMandator: async (mandatorId: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'mandatorId' is not null or undefined
            assertParamExists('getTWINTStatusForAMandator', 'mandatorId', mandatorId)
            const localVarPath = `/twint/config/mandators/activations/{mandator_id}`
                .replace(`{${"mandator_id"}}`, encodeURIComponent(String(mandatorId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Authorization required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Returns the transaction details
         * @summary Get the details for a transaction
         * @param {string} transactionId Transaction unique identifier
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getTransactionTwint: async (transactionId: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'transactionId' is not null or undefined
            assertParamExists('getTransactionTwint', 'transactionId', transactionId)
            const localVarPath = `/twint/transactions/{transaction_id}`
                .replace(`{${"transaction_id"}}`, encodeURIComponent(String(transactionId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Save the fields values for a mandator
         * @param {string} mandatorId Mandator id to save fields for
         * @param {FieldsConfig} fieldsConfig 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        patchFieldsForAMandator: async (mandatorId: string, fieldsConfig: FieldsConfig, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'mandatorId' is not null or undefined
            assertParamExists('patchFieldsForAMandator', 'mandatorId', mandatorId)
            // verify required parameter 'fieldsConfig' is not null or undefined
            assertParamExists('patchFieldsForAMandator', 'fieldsConfig', fieldsConfig)
            const localVarPath = `/twint/config/mandators/fields/{mandator_id}`
                .replace(`{${"mandator_id"}}`, encodeURIComponent(String(mandatorId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Authorization required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(fieldsConfig, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Returns the site details
         * @summary Enrolls the site in twint system
         * @param {string} siteId Site unique identifier
         * @param {PatchSiteRequest} [patchSiteRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        patchSiteTwint: async (siteId: string, patchSiteRequest?: PatchSiteRequest, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'siteId' is not null or undefined
            assertParamExists('patchSiteTwint', 'siteId', siteId)
            const localVarPath = `/twint/sites/{site_id}`
                .replace(`{${"site_id"}}`, encodeURIComponent(String(siteId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Authorization required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(patchSiteRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Creates the transaction in our system an starts it in twint service
         * @summary Starts a twint transaction
         * @param {StartTransaction} startTransaction 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        startTwintTransaction: async (startTransaction: StartTransaction, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'startTransaction' is not null or undefined
            assertParamExists('startTwintTransaction', 'startTransaction', startTransaction)
            const localVarPath = `/twint/transactions`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(startTransaction, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Stops a transaction
         * @param {string} transactionId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        stopTwintTransaction: async (transactionId: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'transactionId' is not null or undefined
            assertParamExists('stopTwintTransaction', 'transactionId', transactionId)
            const localVarPath = `/twint/transactions/{transaction_id}`
                .replace(`{${"transaction_id"}}`, encodeURIComponent(String(transactionId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * TWINTApi - functional programming interface
 * @export
 */
export const TWINTApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = TWINTApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary Creates a link between module and mandator
         * @param {ActivationRequest} activationRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async activateTWINTModuleForAMandator(activationRequest: ActivationRequest, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ActivationResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.activateTWINTModuleForAMandator(activationRequest, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['TWINTApi.activateTWINTModuleForAMandator']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @summary Deletes the link between module and mandator
         * @param {string} mandatorId Mandator id to deactivate
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async deactivateTWINTForAMandator(mandatorId: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<number>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.deactivateTWINTForAMandator(mandatorId, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['TWINTApi.deactivateTWINTForAMandator']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * Returns all points with their QR codes
         * @summary Get the list of all points with their QR codes for a site
         * @param {string} siteId Site unique identifier
         * @param {string} [sort] order ascending or descending the list of points by a property (name, point_number). For descending order the sort field should be prefixed with a minus(\&#39;-\&#39;)
         * @param {number} [pageOffset] number of items that should be skipped and not included in the response
         * @param {number} [pageLimit] number of items to be included in the response
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getPointsQRCodes(siteId: string, sort?: string, pageOffset?: number, pageLimit?: number, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<GetPointsQRCodes200Response>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getPointsQRCodes(siteId, sort, pageOffset, pageLimit, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['TWINTApi.getPointsQRCodes']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * Returns the site and points for the QR
         * @summary Get the the site and points based on the scanned QR code
         * @param {string} qRCode The scanned QR code
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getSitePointsByQRCode(qRCode: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<GetSitePointsByQRCode200Response>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getSitePointsByQRCode(qRCode, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['TWINTApi.getSitePointsByQRCode']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * Returns the site details
         * @summary Get the details for a site
         * @param {string} siteId Site unique identifier
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getSiteTwint(siteId: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<GetSiteTwint200Response>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getSiteTwint(siteId, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['TWINTApi.getSiteTwint']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * Returns all sites with their QR codes
         * @summary Get the list of all sites for current mandator with their QR codes
         * @param {string} [sort] order ascending or descending the list of sites by a property (name, code). For descending order the sort field should be prefixed with a minus(\&#39;-\&#39;)
         * @param {number} [pageOffset] number of items that should be skipped and not included in the response
         * @param {number} [pageLimit] number of items to be included in the response
         * @param {GetSitesFilterParameter} [filter] Filters should be sent as encoded URI. Filterable items: name, code
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getSitesQRCodes(sort?: string, pageOffset?: number, pageLimit?: number, filter?: GetSitesFilterParameter, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<GetSitesQRCodes200Response>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getSitesQRCodes(sort, pageOffset, pageLimit, filter, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['TWINTApi.getSitesQRCodes']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @summary Getting TWINT fields for a mandator.
         * @param {string} mandatorId Mandator unique identifier
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getTWINTFieldsForAMandator(mandatorId: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<ConfigurationFieldMandator>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getTWINTFieldsForAMandator(mandatorId, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['TWINTApi.getTWINTFieldsForAMandator']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @summary Getting Module details.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getTWINTModule(options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Module>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getTWINTModule(options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['TWINTApi.getTWINTModule']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @summary Getting TWINT module status for a mandator.
         * @param {string} mandatorId Mandator unique identifier
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getTWINTStatusForAMandator(mandatorId: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Activation>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getTWINTStatusForAMandator(mandatorId, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['TWINTApi.getTWINTStatusForAMandator']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * Returns the transaction details
         * @summary Get the details for a transaction
         * @param {string} transactionId Transaction unique identifier
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getTransactionTwint(transactionId: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<GetTransactionTwint200Response>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getTransactionTwint(transactionId, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['TWINTApi.getTransactionTwint']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @summary Save the fields values for a mandator
         * @param {string} mandatorId Mandator id to save fields for
         * @param {FieldsConfig} fieldsConfig 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async patchFieldsForAMandator(mandatorId: string, fieldsConfig: FieldsConfig, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<number>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.patchFieldsForAMandator(mandatorId, fieldsConfig, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['TWINTApi.patchFieldsForAMandator']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * Returns the site details
         * @summary Enrolls the site in twint system
         * @param {string} siteId Site unique identifier
         * @param {PatchSiteRequest} [patchSiteRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async patchSiteTwint(siteId: string, patchSiteRequest?: PatchSiteRequest, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PatchSiteTwint200Response>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.patchSiteTwint(siteId, patchSiteRequest, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['TWINTApi.patchSiteTwint']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * Creates the transaction in our system an starts it in twint service
         * @summary Starts a twint transaction
         * @param {StartTransaction} startTransaction 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async startTwintTransaction(startTransaction: StartTransaction, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<StartTwintTransaction200Response>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.startTwintTransaction(startTransaction, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['TWINTApi.startTwintTransaction']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @summary Stops a transaction
         * @param {string} transactionId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async stopTwintTransaction(transactionId: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<number>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.stopTwintTransaction(transactionId, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['TWINTApi.stopTwintTransaction']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
    }
};

/**
 * TWINTApi - factory interface
 * @export
 */
export const TWINTApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = TWINTApiFp(configuration)
    return {
        /**
         * 
         * @summary Creates a link between module and mandator
         * @param {ActivationRequest} activationRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        activateTWINTModuleForAMandator(activationRequest: ActivationRequest, options?: RawAxiosRequestConfig): AxiosPromise<ActivationResponse> {
            return localVarFp.activateTWINTModuleForAMandator(activationRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Deletes the link between module and mandator
         * @param {string} mandatorId Mandator id to deactivate
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deactivateTWINTForAMandator(mandatorId: string, options?: RawAxiosRequestConfig): AxiosPromise<number> {
            return localVarFp.deactivateTWINTForAMandator(mandatorId, options).then((request) => request(axios, basePath));
        },
        /**
         * Returns all points with their QR codes
         * @summary Get the list of all points with their QR codes for a site
         * @param {string} siteId Site unique identifier
         * @param {string} [sort] order ascending or descending the list of points by a property (name, point_number). For descending order the sort field should be prefixed with a minus(\&#39;-\&#39;)
         * @param {number} [pageOffset] number of items that should be skipped and not included in the response
         * @param {number} [pageLimit] number of items to be included in the response
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getPointsQRCodes(siteId: string, sort?: string, pageOffset?: number, pageLimit?: number, options?: RawAxiosRequestConfig): AxiosPromise<GetPointsQRCodes200Response> {
            return localVarFp.getPointsQRCodes(siteId, sort, pageOffset, pageLimit, options).then((request) => request(axios, basePath));
        },
        /**
         * Returns the site and points for the QR
         * @summary Get the the site and points based on the scanned QR code
         * @param {string} qRCode The scanned QR code
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getSitePointsByQRCode(qRCode: string, options?: RawAxiosRequestConfig): AxiosPromise<GetSitePointsByQRCode200Response> {
            return localVarFp.getSitePointsByQRCode(qRCode, options).then((request) => request(axios, basePath));
        },
        /**
         * Returns the site details
         * @summary Get the details for a site
         * @param {string} siteId Site unique identifier
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getSiteTwint(siteId: string, options?: RawAxiosRequestConfig): AxiosPromise<GetSiteTwint200Response> {
            return localVarFp.getSiteTwint(siteId, options).then((request) => request(axios, basePath));
        },
        /**
         * Returns all sites with their QR codes
         * @summary Get the list of all sites for current mandator with their QR codes
         * @param {string} [sort] order ascending or descending the list of sites by a property (name, code). For descending order the sort field should be prefixed with a minus(\&#39;-\&#39;)
         * @param {number} [pageOffset] number of items that should be skipped and not included in the response
         * @param {number} [pageLimit] number of items to be included in the response
         * @param {GetSitesFilterParameter} [filter] Filters should be sent as encoded URI. Filterable items: name, code
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getSitesQRCodes(sort?: string, pageOffset?: number, pageLimit?: number, filter?: GetSitesFilterParameter, options?: RawAxiosRequestConfig): AxiosPromise<GetSitesQRCodes200Response> {
            return localVarFp.getSitesQRCodes(sort, pageOffset, pageLimit, filter, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Getting TWINT fields for a mandator.
         * @param {string} mandatorId Mandator unique identifier
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getTWINTFieldsForAMandator(mandatorId: string, options?: RawAxiosRequestConfig): AxiosPromise<Array<ConfigurationFieldMandator>> {
            return localVarFp.getTWINTFieldsForAMandator(mandatorId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Getting Module details.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getTWINTModule(options?: RawAxiosRequestConfig): AxiosPromise<Module> {
            return localVarFp.getTWINTModule(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Getting TWINT module status for a mandator.
         * @param {string} mandatorId Mandator unique identifier
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getTWINTStatusForAMandator(mandatorId: string, options?: RawAxiosRequestConfig): AxiosPromise<Activation> {
            return localVarFp.getTWINTStatusForAMandator(mandatorId, options).then((request) => request(axios, basePath));
        },
        /**
         * Returns the transaction details
         * @summary Get the details for a transaction
         * @param {string} transactionId Transaction unique identifier
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getTransactionTwint(transactionId: string, options?: RawAxiosRequestConfig): AxiosPromise<GetTransactionTwint200Response> {
            return localVarFp.getTransactionTwint(transactionId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Save the fields values for a mandator
         * @param {string} mandatorId Mandator id to save fields for
         * @param {FieldsConfig} fieldsConfig 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        patchFieldsForAMandator(mandatorId: string, fieldsConfig: FieldsConfig, options?: RawAxiosRequestConfig): AxiosPromise<number> {
            return localVarFp.patchFieldsForAMandator(mandatorId, fieldsConfig, options).then((request) => request(axios, basePath));
        },
        /**
         * Returns the site details
         * @summary Enrolls the site in twint system
         * @param {string} siteId Site unique identifier
         * @param {PatchSiteRequest} [patchSiteRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        patchSiteTwint(siteId: string, patchSiteRequest?: PatchSiteRequest, options?: RawAxiosRequestConfig): AxiosPromise<PatchSiteTwint200Response> {
            return localVarFp.patchSiteTwint(siteId, patchSiteRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * Creates the transaction in our system an starts it in twint service
         * @summary Starts a twint transaction
         * @param {StartTransaction} startTransaction 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        startTwintTransaction(startTransaction: StartTransaction, options?: RawAxiosRequestConfig): AxiosPromise<StartTwintTransaction200Response> {
            return localVarFp.startTwintTransaction(startTransaction, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Stops a transaction
         * @param {string} transactionId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        stopTwintTransaction(transactionId: string, options?: RawAxiosRequestConfig): AxiosPromise<number> {
            return localVarFp.stopTwintTransaction(transactionId, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * TWINTApi - object-oriented interface
 * @export
 * @class TWINTApi
 * @extends {BaseAPI}
 */
export class TWINTApi extends BaseAPI {
    /**
     * 
     * @summary Creates a link between module and mandator
     * @param {ActivationRequest} activationRequest 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TWINTApi
     */
    public activateTWINTModuleForAMandator(activationRequest: ActivationRequest, options?: RawAxiosRequestConfig) {
        return TWINTApiFp(this.configuration).activateTWINTModuleForAMandator(activationRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Deletes the link between module and mandator
     * @param {string} mandatorId Mandator id to deactivate
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TWINTApi
     */
    public deactivateTWINTForAMandator(mandatorId: string, options?: RawAxiosRequestConfig) {
        return TWINTApiFp(this.configuration).deactivateTWINTForAMandator(mandatorId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Returns all points with their QR codes
     * @summary Get the list of all points with their QR codes for a site
     * @param {string} siteId Site unique identifier
     * @param {string} [sort] order ascending or descending the list of points by a property (name, point_number). For descending order the sort field should be prefixed with a minus(\&#39;-\&#39;)
     * @param {number} [pageOffset] number of items that should be skipped and not included in the response
     * @param {number} [pageLimit] number of items to be included in the response
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TWINTApi
     */
    public getPointsQRCodes(siteId: string, sort?: string, pageOffset?: number, pageLimit?: number, options?: RawAxiosRequestConfig) {
        return TWINTApiFp(this.configuration).getPointsQRCodes(siteId, sort, pageOffset, pageLimit, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Returns the site and points for the QR
     * @summary Get the the site and points based on the scanned QR code
     * @param {string} qRCode The scanned QR code
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TWINTApi
     */
    public getSitePointsByQRCode(qRCode: string, options?: RawAxiosRequestConfig) {
        return TWINTApiFp(this.configuration).getSitePointsByQRCode(qRCode, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Returns the site details
     * @summary Get the details for a site
     * @param {string} siteId Site unique identifier
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TWINTApi
     */
    public getSiteTwint(siteId: string, options?: RawAxiosRequestConfig) {
        return TWINTApiFp(this.configuration).getSiteTwint(siteId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Returns all sites with their QR codes
     * @summary Get the list of all sites for current mandator with their QR codes
     * @param {string} [sort] order ascending or descending the list of sites by a property (name, code). For descending order the sort field should be prefixed with a minus(\&#39;-\&#39;)
     * @param {number} [pageOffset] number of items that should be skipped and not included in the response
     * @param {number} [pageLimit] number of items to be included in the response
     * @param {GetSitesFilterParameter} [filter] Filters should be sent as encoded URI. Filterable items: name, code
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TWINTApi
     */
    public getSitesQRCodes(sort?: string, pageOffset?: number, pageLimit?: number, filter?: GetSitesFilterParameter, options?: RawAxiosRequestConfig) {
        return TWINTApiFp(this.configuration).getSitesQRCodes(sort, pageOffset, pageLimit, filter, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Getting TWINT fields for a mandator.
     * @param {string} mandatorId Mandator unique identifier
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TWINTApi
     */
    public getTWINTFieldsForAMandator(mandatorId: string, options?: RawAxiosRequestConfig) {
        return TWINTApiFp(this.configuration).getTWINTFieldsForAMandator(mandatorId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Getting Module details.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TWINTApi
     */
    public getTWINTModule(options?: RawAxiosRequestConfig) {
        return TWINTApiFp(this.configuration).getTWINTModule(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Getting TWINT module status for a mandator.
     * @param {string} mandatorId Mandator unique identifier
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TWINTApi
     */
    public getTWINTStatusForAMandator(mandatorId: string, options?: RawAxiosRequestConfig) {
        return TWINTApiFp(this.configuration).getTWINTStatusForAMandator(mandatorId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Returns the transaction details
     * @summary Get the details for a transaction
     * @param {string} transactionId Transaction unique identifier
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TWINTApi
     */
    public getTransactionTwint(transactionId: string, options?: RawAxiosRequestConfig) {
        return TWINTApiFp(this.configuration).getTransactionTwint(transactionId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Save the fields values for a mandator
     * @param {string} mandatorId Mandator id to save fields for
     * @param {FieldsConfig} fieldsConfig 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TWINTApi
     */
    public patchFieldsForAMandator(mandatorId: string, fieldsConfig: FieldsConfig, options?: RawAxiosRequestConfig) {
        return TWINTApiFp(this.configuration).patchFieldsForAMandator(mandatorId, fieldsConfig, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Returns the site details
     * @summary Enrolls the site in twint system
     * @param {string} siteId Site unique identifier
     * @param {PatchSiteRequest} [patchSiteRequest] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TWINTApi
     */
    public patchSiteTwint(siteId: string, patchSiteRequest?: PatchSiteRequest, options?: RawAxiosRequestConfig) {
        return TWINTApiFp(this.configuration).patchSiteTwint(siteId, patchSiteRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Creates the transaction in our system an starts it in twint service
     * @summary Starts a twint transaction
     * @param {StartTransaction} startTransaction 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TWINTApi
     */
    public startTwintTransaction(startTransaction: StartTransaction, options?: RawAxiosRequestConfig) {
        return TWINTApiFp(this.configuration).startTwintTransaction(startTransaction, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Stops a transaction
     * @param {string} transactionId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TWINTApi
     */
    public stopTwintTransaction(transactionId: string, options?: RawAxiosRequestConfig) {
        return TWINTApiFp(this.configuration).stopTwintTransaction(transactionId, options).then((request) => request(this.axios, this.basePath));
    }
}



/**
 * TransactionApi - axios parameter creator
 * @export
 */
export const TransactionApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Add Payment to an existing transaction
         * @param {string} transactionId 
         * @param {PaymentRequest} [paymentRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createPayment: async (transactionId: string, paymentRequest?: PaymentRequest, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'transactionId' is not null or undefined
            assertParamExists('createPayment', 'transactionId', transactionId)
            const localVarPath = `/transactions/{transaction_id}/payments`
                .replace(`{${"transaction_id"}}`, encodeURIComponent(String(transactionId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Authorization required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(paymentRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Create a new Transaction
         * @param {TransactionRequest} transactionRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createTransaction: async (transactionRequest: TransactionRequest, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'transactionRequest' is not null or undefined
            assertParamExists('createTransaction', 'transactionRequest', transactionRequest)
            const localVarPath = `/transactions`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Authorization required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(transactionRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get existing transactions
         * @param {GetSitesFilterParameter} [filter] created_at, transaction_type, tenant_id, transaction_state, point_id
         * @param {string} [filterStartTime] 
         * @param {string} [filterEndTime] 
         * @param {PaymentMedium} [filterTransactionType] 
         * @param {Array<string>} [filterTenantId] 
         * @param {Array<TransactionState>} [filterTransactionState] 
         * @param {number} [pageOffset] 
         * @param {number} [pageLimit] 
         * @param {GetTransactionSortEnum} [sort] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getTransaction: async (filter?: GetSitesFilterParameter, filterStartTime?: string, filterEndTime?: string, filterTransactionType?: PaymentMedium, filterTenantId?: Array<string>, filterTransactionState?: Array<TransactionState>, pageOffset?: number, pageLimit?: number, sort?: GetTransactionSortEnum, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/transactions`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Authorization required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (filter !== undefined) {
                for (const [key, value] of Object.entries(filter)) {
                    localVarQueryParameter[key] = value;
                }
            }

            if (filterStartTime !== undefined) {
                localVarQueryParameter['filter[start_time]'] = (filterStartTime as any instanceof Date) ?
                    (filterStartTime as any).toISOString() :
                    filterStartTime;
            }

            if (filterEndTime !== undefined) {
                localVarQueryParameter['filter[end_time]'] = (filterEndTime as any instanceof Date) ?
                    (filterEndTime as any).toISOString() :
                    filterEndTime;
            }

            if (filterTransactionType !== undefined) {
                localVarQueryParameter['filter[transaction_type]'] = filterTransactionType;
            }

            if (filterTenantId) {
                localVarQueryParameter['filter[tenant_id]'] = filterTenantId;
            }

            if (filterTransactionState) {
                localVarQueryParameter['filter[transaction_state]'] = filterTransactionState;
            }

            if (pageOffset !== undefined) {
                localVarQueryParameter['page[offset]'] = pageOffset;
            }

            if (pageLimit !== undefined) {
                localVarQueryParameter['page[limit]'] = pageLimit;
            }

            if (sort !== undefined) {
                localVarQueryParameter['sort'] = sort;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get existing transaction
         * @param {string} transactionId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getTransactionByID: async (transactionId: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'transactionId' is not null or undefined
            assertParamExists('getTransactionByID', 'transactionId', transactionId)
            const localVarPath = `/transactions/{transaction_id}`
                .replace(`{${"transaction_id"}}`, encodeURIComponent(String(transactionId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Authorization required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get statistics to existent transactions
         * @param {GetSitesFilterParameter} [filter] created_at, transaction_type, tenant_id, transaction_state, point_id
         * @param {string} [filterStartTime] 
         * @param {string} [filterEndTime] 
         * @param {PaymentMedium} [filterTransactionType] 
         * @param {Array<string>} [filterTenantId] 
         * @param {Array<TransactionState>} [filterTransactionState] 
         * @param {Array<GetTransactionStatisticsFieldsStatisticEnum>} [fieldsStatistic] as comma seperated list. Only fields added will be include in request
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getTransactionStatistics: async (filter?: GetSitesFilterParameter, filterStartTime?: string, filterEndTime?: string, filterTransactionType?: PaymentMedium, filterTenantId?: Array<string>, filterTransactionState?: Array<TransactionState>, fieldsStatistic?: Array<GetTransactionStatisticsFieldsStatisticEnum>, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/transactions/statistics`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Authorization required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (filter !== undefined) {
                for (const [key, value] of Object.entries(filter)) {
                    localVarQueryParameter[key] = value;
                }
            }

            if (filterStartTime !== undefined) {
                localVarQueryParameter['filter[start_time]'] = (filterStartTime as any instanceof Date) ?
                    (filterStartTime as any).toISOString() :
                    filterStartTime;
            }

            if (filterEndTime !== undefined) {
                localVarQueryParameter['filter[end_time]'] = (filterEndTime as any instanceof Date) ?
                    (filterEndTime as any).toISOString() :
                    filterEndTime;
            }

            if (filterTransactionType !== undefined) {
                localVarQueryParameter['filter[transaction_type]'] = filterTransactionType;
            }

            if (filterTenantId) {
                localVarQueryParameter['filter[tenant_id]'] = filterTenantId;
            }

            if (filterTransactionState) {
                localVarQueryParameter['filter[transaction_state]'] = filterTransactionState;
            }

            if (fieldsStatistic) {
                localVarQueryParameter['fields[statistic]'] = fieldsStatistic;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Change Payment to an existing transaction
         * @param {string} transactionId 
         * @param {string} paymentId 
         * @param {PaymentRequest} [paymentRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updatePayment: async (transactionId: string, paymentId: string, paymentRequest?: PaymentRequest, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'transactionId' is not null or undefined
            assertParamExists('updatePayment', 'transactionId', transactionId)
            // verify required parameter 'paymentId' is not null or undefined
            assertParamExists('updatePayment', 'paymentId', paymentId)
            const localVarPath = `/transactions/{transaction_id}/payments/{payment_id}`
                .replace(`{${"transaction_id"}}`, encodeURIComponent(String(transactionId)))
                .replace(`{${"payment_id"}}`, encodeURIComponent(String(paymentId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Authorization required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(paymentRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Update existing transaction
         * @param {string} transactionId 
         * @param {TransactionRequest} transactionRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateTransactionByID: async (transactionId: string, transactionRequest: TransactionRequest, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'transactionId' is not null or undefined
            assertParamExists('updateTransactionByID', 'transactionId', transactionId)
            // verify required parameter 'transactionRequest' is not null or undefined
            assertParamExists('updateTransactionByID', 'transactionRequest', transactionRequest)
            const localVarPath = `/transactions/{transaction_id}`
                .replace(`{${"transaction_id"}}`, encodeURIComponent(String(transactionId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Authorization required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(transactionRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * TransactionApi - functional programming interface
 * @export
 */
export const TransactionApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = TransactionApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary Add Payment to an existing transaction
         * @param {string} transactionId 
         * @param {PaymentRequest} [paymentRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async createPayment(transactionId: string, paymentRequest?: PaymentRequest, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<TransactionResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.createPayment(transactionId, paymentRequest, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['TransactionApi.createPayment']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @summary Create a new Transaction
         * @param {TransactionRequest} transactionRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async createTransaction(transactionRequest: TransactionRequest, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<TransactionResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.createTransaction(transactionRequest, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['TransactionApi.createTransaction']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @summary Get existing transactions
         * @param {GetSitesFilterParameter} [filter] created_at, transaction_type, tenant_id, transaction_state, point_id
         * @param {string} [filterStartTime] 
         * @param {string} [filterEndTime] 
         * @param {PaymentMedium} [filterTransactionType] 
         * @param {Array<string>} [filterTenantId] 
         * @param {Array<TransactionState>} [filterTransactionState] 
         * @param {number} [pageOffset] 
         * @param {number} [pageLimit] 
         * @param {GetTransactionSortEnum} [sort] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getTransaction(filter?: GetSitesFilterParameter, filterStartTime?: string, filterEndTime?: string, filterTransactionType?: PaymentMedium, filterTenantId?: Array<string>, filterTransactionState?: Array<TransactionState>, pageOffset?: number, pageLimit?: number, sort?: GetTransactionSortEnum, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<TransactionListResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getTransaction(filter, filterStartTime, filterEndTime, filterTransactionType, filterTenantId, filterTransactionState, pageOffset, pageLimit, sort, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['TransactionApi.getTransaction']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @summary Get existing transaction
         * @param {string} transactionId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getTransactionByID(transactionId: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<TransactionResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getTransactionByID(transactionId, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['TransactionApi.getTransactionByID']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @summary Get statistics to existent transactions
         * @param {GetSitesFilterParameter} [filter] created_at, transaction_type, tenant_id, transaction_state, point_id
         * @param {string} [filterStartTime] 
         * @param {string} [filterEndTime] 
         * @param {PaymentMedium} [filterTransactionType] 
         * @param {Array<string>} [filterTenantId] 
         * @param {Array<TransactionState>} [filterTransactionState] 
         * @param {Array<GetTransactionStatisticsFieldsStatisticEnum>} [fieldsStatistic] as comma seperated list. Only fields added will be include in request
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getTransactionStatistics(filter?: GetSitesFilterParameter, filterStartTime?: string, filterEndTime?: string, filterTransactionType?: PaymentMedium, filterTenantId?: Array<string>, filterTransactionState?: Array<TransactionState>, fieldsStatistic?: Array<GetTransactionStatisticsFieldsStatisticEnum>, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<TransactionStatisticResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getTransactionStatistics(filter, filterStartTime, filterEndTime, filterTransactionType, filterTenantId, filterTransactionState, fieldsStatistic, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['TransactionApi.getTransactionStatistics']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @summary Change Payment to an existing transaction
         * @param {string} transactionId 
         * @param {string} paymentId 
         * @param {PaymentRequest} [paymentRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updatePayment(transactionId: string, paymentId: string, paymentRequest?: PaymentRequest, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<TransactionResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.updatePayment(transactionId, paymentId, paymentRequest, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['TransactionApi.updatePayment']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @summary Update existing transaction
         * @param {string} transactionId 
         * @param {TransactionRequest} transactionRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updateTransactionByID(transactionId: string, transactionRequest: TransactionRequest, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<TransactionResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.updateTransactionByID(transactionId, transactionRequest, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['TransactionApi.updateTransactionByID']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
    }
};

/**
 * TransactionApi - factory interface
 * @export
 */
export const TransactionApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = TransactionApiFp(configuration)
    return {
        /**
         * 
         * @summary Add Payment to an existing transaction
         * @param {string} transactionId 
         * @param {PaymentRequest} [paymentRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createPayment(transactionId: string, paymentRequest?: PaymentRequest, options?: RawAxiosRequestConfig): AxiosPromise<TransactionResponse> {
            return localVarFp.createPayment(transactionId, paymentRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Create a new Transaction
         * @param {TransactionRequest} transactionRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createTransaction(transactionRequest: TransactionRequest, options?: RawAxiosRequestConfig): AxiosPromise<TransactionResponse> {
            return localVarFp.createTransaction(transactionRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get existing transactions
         * @param {GetSitesFilterParameter} [filter] created_at, transaction_type, tenant_id, transaction_state, point_id
         * @param {string} [filterStartTime] 
         * @param {string} [filterEndTime] 
         * @param {PaymentMedium} [filterTransactionType] 
         * @param {Array<string>} [filterTenantId] 
         * @param {Array<TransactionState>} [filterTransactionState] 
         * @param {number} [pageOffset] 
         * @param {number} [pageLimit] 
         * @param {GetTransactionSortEnum} [sort] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getTransaction(filter?: GetSitesFilterParameter, filterStartTime?: string, filterEndTime?: string, filterTransactionType?: PaymentMedium, filterTenantId?: Array<string>, filterTransactionState?: Array<TransactionState>, pageOffset?: number, pageLimit?: number, sort?: GetTransactionSortEnum, options?: RawAxiosRequestConfig): AxiosPromise<TransactionListResponse> {
            return localVarFp.getTransaction(filter, filterStartTime, filterEndTime, filterTransactionType, filterTenantId, filterTransactionState, pageOffset, pageLimit, sort, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get existing transaction
         * @param {string} transactionId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getTransactionByID(transactionId: string, options?: RawAxiosRequestConfig): AxiosPromise<TransactionResponse> {
            return localVarFp.getTransactionByID(transactionId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get statistics to existent transactions
         * @param {GetSitesFilterParameter} [filter] created_at, transaction_type, tenant_id, transaction_state, point_id
         * @param {string} [filterStartTime] 
         * @param {string} [filterEndTime] 
         * @param {PaymentMedium} [filterTransactionType] 
         * @param {Array<string>} [filterTenantId] 
         * @param {Array<TransactionState>} [filterTransactionState] 
         * @param {Array<GetTransactionStatisticsFieldsStatisticEnum>} [fieldsStatistic] as comma seperated list. Only fields added will be include in request
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getTransactionStatistics(filter?: GetSitesFilterParameter, filterStartTime?: string, filterEndTime?: string, filterTransactionType?: PaymentMedium, filterTenantId?: Array<string>, filterTransactionState?: Array<TransactionState>, fieldsStatistic?: Array<GetTransactionStatisticsFieldsStatisticEnum>, options?: RawAxiosRequestConfig): AxiosPromise<TransactionStatisticResponse> {
            return localVarFp.getTransactionStatistics(filter, filterStartTime, filterEndTime, filterTransactionType, filterTenantId, filterTransactionState, fieldsStatistic, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Change Payment to an existing transaction
         * @param {string} transactionId 
         * @param {string} paymentId 
         * @param {PaymentRequest} [paymentRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updatePayment(transactionId: string, paymentId: string, paymentRequest?: PaymentRequest, options?: RawAxiosRequestConfig): AxiosPromise<TransactionResponse> {
            return localVarFp.updatePayment(transactionId, paymentId, paymentRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Update existing transaction
         * @param {string} transactionId 
         * @param {TransactionRequest} transactionRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateTransactionByID(transactionId: string, transactionRequest: TransactionRequest, options?: RawAxiosRequestConfig): AxiosPromise<TransactionResponse> {
            return localVarFp.updateTransactionByID(transactionId, transactionRequest, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * TransactionApi - object-oriented interface
 * @export
 * @class TransactionApi
 * @extends {BaseAPI}
 */
export class TransactionApi extends BaseAPI {
    /**
     * 
     * @summary Add Payment to an existing transaction
     * @param {string} transactionId 
     * @param {PaymentRequest} [paymentRequest] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TransactionApi
     */
    public createPayment(transactionId: string, paymentRequest?: PaymentRequest, options?: RawAxiosRequestConfig) {
        return TransactionApiFp(this.configuration).createPayment(transactionId, paymentRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Create a new Transaction
     * @param {TransactionRequest} transactionRequest 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TransactionApi
     */
    public createTransaction(transactionRequest: TransactionRequest, options?: RawAxiosRequestConfig) {
        return TransactionApiFp(this.configuration).createTransaction(transactionRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get existing transactions
     * @param {GetSitesFilterParameter} [filter] created_at, transaction_type, tenant_id, transaction_state, point_id
     * @param {string} [filterStartTime] 
     * @param {string} [filterEndTime] 
     * @param {PaymentMedium} [filterTransactionType] 
     * @param {Array<string>} [filterTenantId] 
     * @param {Array<TransactionState>} [filterTransactionState] 
     * @param {number} [pageOffset] 
     * @param {number} [pageLimit] 
     * @param {GetTransactionSortEnum} [sort] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TransactionApi
     */
    public getTransaction(filter?: GetSitesFilterParameter, filterStartTime?: string, filterEndTime?: string, filterTransactionType?: PaymentMedium, filterTenantId?: Array<string>, filterTransactionState?: Array<TransactionState>, pageOffset?: number, pageLimit?: number, sort?: GetTransactionSortEnum, options?: RawAxiosRequestConfig) {
        return TransactionApiFp(this.configuration).getTransaction(filter, filterStartTime, filterEndTime, filterTransactionType, filterTenantId, filterTransactionState, pageOffset, pageLimit, sort, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get existing transaction
     * @param {string} transactionId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TransactionApi
     */
    public getTransactionByID(transactionId: string, options?: RawAxiosRequestConfig) {
        return TransactionApiFp(this.configuration).getTransactionByID(transactionId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get statistics to existent transactions
     * @param {GetSitesFilterParameter} [filter] created_at, transaction_type, tenant_id, transaction_state, point_id
     * @param {string} [filterStartTime] 
     * @param {string} [filterEndTime] 
     * @param {PaymentMedium} [filterTransactionType] 
     * @param {Array<string>} [filterTenantId] 
     * @param {Array<TransactionState>} [filterTransactionState] 
     * @param {Array<GetTransactionStatisticsFieldsStatisticEnum>} [fieldsStatistic] as comma seperated list. Only fields added will be include in request
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TransactionApi
     */
    public getTransactionStatistics(filter?: GetSitesFilterParameter, filterStartTime?: string, filterEndTime?: string, filterTransactionType?: PaymentMedium, filterTenantId?: Array<string>, filterTransactionState?: Array<TransactionState>, fieldsStatistic?: Array<GetTransactionStatisticsFieldsStatisticEnum>, options?: RawAxiosRequestConfig) {
        return TransactionApiFp(this.configuration).getTransactionStatistics(filter, filterStartTime, filterEndTime, filterTransactionType, filterTenantId, filterTransactionState, fieldsStatistic, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Change Payment to an existing transaction
     * @param {string} transactionId 
     * @param {string} paymentId 
     * @param {PaymentRequest} [paymentRequest] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TransactionApi
     */
    public updatePayment(transactionId: string, paymentId: string, paymentRequest?: PaymentRequest, options?: RawAxiosRequestConfig) {
        return TransactionApiFp(this.configuration).updatePayment(transactionId, paymentId, paymentRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Update existing transaction
     * @param {string} transactionId 
     * @param {TransactionRequest} transactionRequest 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TransactionApi
     */
    public updateTransactionByID(transactionId: string, transactionRequest: TransactionRequest, options?: RawAxiosRequestConfig) {
        return TransactionApiFp(this.configuration).updateTransactionByID(transactionId, transactionRequest, options).then((request) => request(this.axios, this.basePath));
    }
}

/**
 * @export
 */
export const GetTransactionSortEnum = {
    CreatedAt: 'created_at',
    CreatedAt2: '-created_at',
    SiteName: 'site_name',
    SiteName2: '-site_name',
    DeviceName: 'device_name',
    DeviceName2: '-device_name'
} as const;
export type GetTransactionSortEnum = typeof GetTransactionSortEnum[keyof typeof GetTransactionSortEnum];
/**
 * @export
 */
export const GetTransactionStatisticsFieldsStatisticEnum = {
    TransactionsTotalAmount: 'transactions_total_amount',
    TransactionsCount: 'transactions_count',
    HourlyTransactions: 'hourly_transactions',
    SitesRanking: 'sites_ranking',
    PaymentMethodsRanking: 'payment_methods_ranking'
} as const;
export type GetTransactionStatisticsFieldsStatisticEnum = typeof GetTransactionStatisticsFieldsStatisticEnum[keyof typeof GetTransactionStatisticsFieldsStatisticEnum];


/**
 * TransactionsApi - axios parameter creator
 * @export
 */
export const TransactionsApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Returns a link to download the exported transactions.
         * @param {ExportFormatEnum} format 
         * @param {string} mandatorId 
         * @param {GetSitesFilterParameter} filter Filters should be sent as encoded URI. All general fields can be filtered and for nested object only the created_at from invoice.
         * @param {string} [email] For receiving the exported transactions via email.
         * @param {string} [sort] Order ascending or descending the list of sites by a property. For descending order the sort field should be prefixed with a minus(\&#39;-\&#39;). All general fileds can be sorted and for nested objects only the created_at from invoice, site_name and device_name
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        _export: async (format: ExportFormatEnum, mandatorId: string, filter: GetSitesFilterParameter, email?: string, sort?: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'format' is not null or undefined
            assertParamExists('_export', 'format', format)
            // verify required parameter 'mandatorId' is not null or undefined
            assertParamExists('_export', 'mandatorId', mandatorId)
            // verify required parameter 'filter' is not null or undefined
            assertParamExists('_export', 'filter', filter)
            const localVarPath = `/files/transactions/exports`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Authorization required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (format !== undefined) {
                localVarQueryParameter['format'] = format;
            }

            if (mandatorId !== undefined) {
                localVarQueryParameter['mandator_id'] = mandatorId;
            }

            if (email !== undefined) {
                localVarQueryParameter['email'] = email;
            }

            if (sort !== undefined) {
                localVarQueryParameter['sort'] = sort;
            }

            if (filter !== undefined) {
                for (const [key, value] of Object.entries(filter)) {
                    localVarQueryParameter[key] = value;
                }
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * After creation the transaction is sent to SNS with action: \"create\" and affected:\'\' 
         * @summary Adds a new transaction to the system
         * @param {TransactionV3Request} transactionV3Request Transaction object that needs to be added to the system
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createTransactionV3: async (transactionV3Request: TransactionV3Request, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'transactionV3Request' is not null or undefined
            assertParamExists('createTransactionV3', 'transactionV3Request', transactionV3Request)
            const localVarPath = `/v3/transactions`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Authorization required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(transactionV3Request, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Returns transaction statistics for a mandator
         * @param {string} mandatorId 
         * @param {GetSitesFilterParameter} [filter] Filters should be sent as encoded URI. All general fields can be filtered and for nested object only the created_at from invoice.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getStatisticsV3: async (mandatorId: string, filter?: GetSitesFilterParameter, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'mandatorId' is not null or undefined
            assertParamExists('getStatisticsV3', 'mandatorId', mandatorId)
            const localVarPath = `/v3/transactions/statistics`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Authorization required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (mandatorId !== undefined) {
                localVarQueryParameter['mandator_id'] = mandatorId;
            }

            if (filter !== undefined) {
                for (const [key, value] of Object.entries(filter)) {
                    localVarQueryParameter[key] = value;
                }
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Returns a transaction.
         * @param {string} transactionId 
         * @param {boolean} [additionalInfo] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getTransactionByIdV3: async (transactionId: string, additionalInfo?: boolean, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'transactionId' is not null or undefined
            assertParamExists('getTransactionByIdV3', 'transactionId', transactionId)
            const localVarPath = `/v3/transactions/{transaction_id}`
                .replace(`{${"transaction_id"}}`, encodeURIComponent(String(transactionId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Authorization required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (additionalInfo !== undefined) {
                localVarQueryParameter['additional_info'] = additionalInfo;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Return a list of all transactions
         * @param {string} mandatorId 
         * @param {string} [sort] order ascending or descending the list of sites by a property. For descending order the sort field should be prefixed with a minus(\&#39;-\&#39;). All general fileds can be sorted and for nested objects only the created_at from invoice, site_name and device_name
         * @param {number} [pageOffset] number of items that should be skipped and not included in the response
         * @param {number} [pageLimit] number of items to be included in the response
         * @param {GetSitesFilterParameter} [filter] Filters should be sent as encoded URI. All general fields can be filtered and for nested object only the created_at from invoice.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getTransactionsV3: async (mandatorId: string, sort?: string, pageOffset?: number, pageLimit?: number, filter?: GetSitesFilterParameter, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'mandatorId' is not null or undefined
            assertParamExists('getTransactionsV3', 'mandatorId', mandatorId)
            const localVarPath = `/v3/transactions`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Authorization required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (mandatorId !== undefined) {
                localVarQueryParameter['mandator_id'] = mandatorId;
            }

            if (sort !== undefined) {
                localVarQueryParameter['sort'] = sort;
            }

            if (pageOffset !== undefined) {
                localVarQueryParameter['page[offset]'] = pageOffset;
            }

            if (pageLimit !== undefined) {
                localVarQueryParameter['page[limit]'] = pageLimit;
            }

            if (filter !== undefined) {
                for (const [key, value] of Object.entries(filter)) {
                    localVarQueryParameter[key] = value;
                }
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * <br> The updated transaction is sent to SNS with action: \"create/update\" and affected object depends on what was changed <br> <br> example for articles -> affected: {\"articles\": [\"charging/refuelling/parking\"]} <br> <br> example for payments -> affected: {\"payments\":[\"identification\"]} <br> 
         * @summary Adds or updates a payment or article to a transaction
         * @param {string} transactionId 
         * @param {PatchTransactionRequest} patchTransactionRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateTransactionV3: async (transactionId: string, patchTransactionRequest: PatchTransactionRequest, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'transactionId' is not null or undefined
            assertParamExists('updateTransactionV3', 'transactionId', transactionId)
            // verify required parameter 'patchTransactionRequest' is not null or undefined
            assertParamExists('updateTransactionV3', 'patchTransactionRequest', patchTransactionRequest)
            const localVarPath = `/v3/transactions/{transaction_id}`
                .replace(`{${"transaction_id"}}`, encodeURIComponent(String(transactionId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Authorization required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(patchTransactionRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * TransactionsApi - functional programming interface
 * @export
 */
export const TransactionsApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = TransactionsApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary Returns a link to download the exported transactions.
         * @param {ExportFormatEnum} format 
         * @param {string} mandatorId 
         * @param {GetSitesFilterParameter} filter Filters should be sent as encoded URI. All general fields can be filtered and for nested object only the created_at from invoice.
         * @param {string} [email] For receiving the exported transactions via email.
         * @param {string} [sort] Order ascending or descending the list of sites by a property. For descending order the sort field should be prefixed with a minus(\&#39;-\&#39;). All general fileds can be sorted and for nested objects only the created_at from invoice, site_name and device_name
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async _export(format: ExportFormatEnum, mandatorId: string, filter: GetSitesFilterParameter, email?: string, sort?: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Export200Response>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator._export(format, mandatorId, filter, email, sort, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['TransactionsApi._export']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * After creation the transaction is sent to SNS with action: \"create\" and affected:\'\' 
         * @summary Adds a new transaction to the system
         * @param {TransactionV3Request} transactionV3Request Transaction object that needs to be added to the system
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async createTransactionV3(transactionV3Request: TransactionV3Request, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<CreateTransactionV3201Response>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.createTransactionV3(transactionV3Request, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['TransactionsApi.createTransactionV3']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @summary Returns transaction statistics for a mandator
         * @param {string} mandatorId 
         * @param {GetSitesFilterParameter} [filter] Filters should be sent as encoded URI. All general fields can be filtered and for nested object only the created_at from invoice.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getStatisticsV3(mandatorId: string, filter?: GetSitesFilterParameter, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<GetStatisticsV3200Response>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getStatisticsV3(mandatorId, filter, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['TransactionsApi.getStatisticsV3']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @summary Returns a transaction.
         * @param {string} transactionId 
         * @param {boolean} [additionalInfo] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getTransactionByIdV3(transactionId: string, additionalInfo?: boolean, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<TransactionsResponseResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getTransactionByIdV3(transactionId, additionalInfo, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['TransactionsApi.getTransactionByIdV3']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @summary Return a list of all transactions
         * @param {string} mandatorId 
         * @param {string} [sort] order ascending or descending the list of sites by a property. For descending order the sort field should be prefixed with a minus(\&#39;-\&#39;). All general fileds can be sorted and for nested objects only the created_at from invoice, site_name and device_name
         * @param {number} [pageOffset] number of items that should be skipped and not included in the response
         * @param {number} [pageLimit] number of items to be included in the response
         * @param {GetSitesFilterParameter} [filter] Filters should be sent as encoded URI. All general fields can be filtered and for nested object only the created_at from invoice.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getTransactionsV3(mandatorId: string, sort?: string, pageOffset?: number, pageLimit?: number, filter?: GetSitesFilterParameter, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<GetTransactionsV3200Response>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getTransactionsV3(mandatorId, sort, pageOffset, pageLimit, filter, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['TransactionsApi.getTransactionsV3']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * <br> The updated transaction is sent to SNS with action: \"create/update\" and affected object depends on what was changed <br> <br> example for articles -> affected: {\"articles\": [\"charging/refuelling/parking\"]} <br> <br> example for payments -> affected: {\"payments\":[\"identification\"]} <br> 
         * @summary Adds or updates a payment or article to a transaction
         * @param {string} transactionId 
         * @param {PatchTransactionRequest} patchTransactionRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updateTransactionV3(transactionId: string, patchTransactionRequest: PatchTransactionRequest, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<CreateTransactionV3201Response>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.updateTransactionV3(transactionId, patchTransactionRequest, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['TransactionsApi.updateTransactionV3']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
    }
};

/**
 * TransactionsApi - factory interface
 * @export
 */
export const TransactionsApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = TransactionsApiFp(configuration)
    return {
        /**
         * 
         * @summary Returns a link to download the exported transactions.
         * @param {ExportFormatEnum} format 
         * @param {string} mandatorId 
         * @param {GetSitesFilterParameter} filter Filters should be sent as encoded URI. All general fields can be filtered and for nested object only the created_at from invoice.
         * @param {string} [email] For receiving the exported transactions via email.
         * @param {string} [sort] Order ascending or descending the list of sites by a property. For descending order the sort field should be prefixed with a minus(\&#39;-\&#39;). All general fileds can be sorted and for nested objects only the created_at from invoice, site_name and device_name
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        _export(format: ExportFormatEnum, mandatorId: string, filter: GetSitesFilterParameter, email?: string, sort?: string, options?: RawAxiosRequestConfig): AxiosPromise<Export200Response> {
            return localVarFp._export(format, mandatorId, filter, email, sort, options).then((request) => request(axios, basePath));
        },
        /**
         * After creation the transaction is sent to SNS with action: \"create\" and affected:\'\' 
         * @summary Adds a new transaction to the system
         * @param {TransactionV3Request} transactionV3Request Transaction object that needs to be added to the system
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createTransactionV3(transactionV3Request: TransactionV3Request, options?: RawAxiosRequestConfig): AxiosPromise<CreateTransactionV3201Response> {
            return localVarFp.createTransactionV3(transactionV3Request, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Returns transaction statistics for a mandator
         * @param {string} mandatorId 
         * @param {GetSitesFilterParameter} [filter] Filters should be sent as encoded URI. All general fields can be filtered and for nested object only the created_at from invoice.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getStatisticsV3(mandatorId: string, filter?: GetSitesFilterParameter, options?: RawAxiosRequestConfig): AxiosPromise<GetStatisticsV3200Response> {
            return localVarFp.getStatisticsV3(mandatorId, filter, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Returns a transaction.
         * @param {string} transactionId 
         * @param {boolean} [additionalInfo] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getTransactionByIdV3(transactionId: string, additionalInfo?: boolean, options?: RawAxiosRequestConfig): AxiosPromise<TransactionsResponseResponse> {
            return localVarFp.getTransactionByIdV3(transactionId, additionalInfo, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Return a list of all transactions
         * @param {string} mandatorId 
         * @param {string} [sort] order ascending or descending the list of sites by a property. For descending order the sort field should be prefixed with a minus(\&#39;-\&#39;). All general fileds can be sorted and for nested objects only the created_at from invoice, site_name and device_name
         * @param {number} [pageOffset] number of items that should be skipped and not included in the response
         * @param {number} [pageLimit] number of items to be included in the response
         * @param {GetSitesFilterParameter} [filter] Filters should be sent as encoded URI. All general fields can be filtered and for nested object only the created_at from invoice.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getTransactionsV3(mandatorId: string, sort?: string, pageOffset?: number, pageLimit?: number, filter?: GetSitesFilterParameter, options?: RawAxiosRequestConfig): AxiosPromise<GetTransactionsV3200Response> {
            return localVarFp.getTransactionsV3(mandatorId, sort, pageOffset, pageLimit, filter, options).then((request) => request(axios, basePath));
        },
        /**
         * <br> The updated transaction is sent to SNS with action: \"create/update\" and affected object depends on what was changed <br> <br> example for articles -> affected: {\"articles\": [\"charging/refuelling/parking\"]} <br> <br> example for payments -> affected: {\"payments\":[\"identification\"]} <br> 
         * @summary Adds or updates a payment or article to a transaction
         * @param {string} transactionId 
         * @param {PatchTransactionRequest} patchTransactionRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateTransactionV3(transactionId: string, patchTransactionRequest: PatchTransactionRequest, options?: RawAxiosRequestConfig): AxiosPromise<CreateTransactionV3201Response> {
            return localVarFp.updateTransactionV3(transactionId, patchTransactionRequest, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * TransactionsApi - object-oriented interface
 * @export
 * @class TransactionsApi
 * @extends {BaseAPI}
 */
export class TransactionsApi extends BaseAPI {
    /**
     * 
     * @summary Returns a link to download the exported transactions.
     * @param {ExportFormatEnum} format 
     * @param {string} mandatorId 
     * @param {GetSitesFilterParameter} filter Filters should be sent as encoded URI. All general fields can be filtered and for nested object only the created_at from invoice.
     * @param {string} [email] For receiving the exported transactions via email.
     * @param {string} [sort] Order ascending or descending the list of sites by a property. For descending order the sort field should be prefixed with a minus(\&#39;-\&#39;). All general fileds can be sorted and for nested objects only the created_at from invoice, site_name and device_name
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TransactionsApi
     */
    public _export(format: ExportFormatEnum, mandatorId: string, filter: GetSitesFilterParameter, email?: string, sort?: string, options?: RawAxiosRequestConfig) {
        return TransactionsApiFp(this.configuration)._export(format, mandatorId, filter, email, sort, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * After creation the transaction is sent to SNS with action: \"create\" and affected:\'\' 
     * @summary Adds a new transaction to the system
     * @param {TransactionV3Request} transactionV3Request Transaction object that needs to be added to the system
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TransactionsApi
     */
    public createTransactionV3(transactionV3Request: TransactionV3Request, options?: RawAxiosRequestConfig) {
        return TransactionsApiFp(this.configuration).createTransactionV3(transactionV3Request, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Returns transaction statistics for a mandator
     * @param {string} mandatorId 
     * @param {GetSitesFilterParameter} [filter] Filters should be sent as encoded URI. All general fields can be filtered and for nested object only the created_at from invoice.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TransactionsApi
     */
    public getStatisticsV3(mandatorId: string, filter?: GetSitesFilterParameter, options?: RawAxiosRequestConfig) {
        return TransactionsApiFp(this.configuration).getStatisticsV3(mandatorId, filter, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Returns a transaction.
     * @param {string} transactionId 
     * @param {boolean} [additionalInfo] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TransactionsApi
     */
    public getTransactionByIdV3(transactionId: string, additionalInfo?: boolean, options?: RawAxiosRequestConfig) {
        return TransactionsApiFp(this.configuration).getTransactionByIdV3(transactionId, additionalInfo, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Return a list of all transactions
     * @param {string} mandatorId 
     * @param {string} [sort] order ascending or descending the list of sites by a property. For descending order the sort field should be prefixed with a minus(\&#39;-\&#39;). All general fileds can be sorted and for nested objects only the created_at from invoice, site_name and device_name
     * @param {number} [pageOffset] number of items that should be skipped and not included in the response
     * @param {number} [pageLimit] number of items to be included in the response
     * @param {GetSitesFilterParameter} [filter] Filters should be sent as encoded URI. All general fields can be filtered and for nested object only the created_at from invoice.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TransactionsApi
     */
    public getTransactionsV3(mandatorId: string, sort?: string, pageOffset?: number, pageLimit?: number, filter?: GetSitesFilterParameter, options?: RawAxiosRequestConfig) {
        return TransactionsApiFp(this.configuration).getTransactionsV3(mandatorId, sort, pageOffset, pageLimit, filter, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * <br> The updated transaction is sent to SNS with action: \"create/update\" and affected object depends on what was changed <br> <br> example for articles -> affected: {\"articles\": [\"charging/refuelling/parking\"]} <br> <br> example for payments -> affected: {\"payments\":[\"identification\"]} <br> 
     * @summary Adds or updates a payment or article to a transaction
     * @param {string} transactionId 
     * @param {PatchTransactionRequest} patchTransactionRequest 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TransactionsApi
     */
    public updateTransactionV3(transactionId: string, patchTransactionRequest: PatchTransactionRequest, options?: RawAxiosRequestConfig) {
        return TransactionsApiFp(this.configuration).updateTransactionV3(transactionId, patchTransactionRequest, options).then((request) => request(this.axios, this.basePath));
    }
}

/**
 * @export
 */
export const ExportFormatEnum = {
    Csv: 'csv',
    Xlsx: 'xlsx'
} as const;
export type ExportFormatEnum = typeof ExportFormatEnum[keyof typeof ExportFormatEnum];


/**
 * UsersApi - axios parameter creator
 * @export
 */
export const UsersApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Change the current mandator for a user
         * @param {string} userId 
         * @param {ChangeMandator} [changeMandator] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        changeUserMandator: async (userId: string, changeMandator?: ChangeMandator, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'userId' is not null or undefined
            assertParamExists('changeUserMandator', 'userId', userId)
            const localVarPath = `/users/{user_id}/current_mandator`
                .replace(`{${"user_id"}}`, encodeURIComponent(String(userId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Authorization required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(changeMandator, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Adds a new permission group to the system
         * @param {CreatePermissionGroup} createPermissionGroup Permission group object that needs to be added to the system
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createPermissionGroup: async (createPermissionGroup: CreatePermissionGroup, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'createPermissionGroup' is not null or undefined
            assertParamExists('createPermissionGroup', 'createPermissionGroup', createPermissionGroup)
            const localVarPath = `/users/rights`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Authorization required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(createPermissionGroup, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Adds a new user to the system
         * @param {UserBasePost} userBasePost User object that needs to be added to the system
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createUser: async (userBasePost: UserBasePost, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'userBasePost' is not null or undefined
            assertParamExists('createUser', 'userBasePost', userBasePost)
            const localVarPath = `/users`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Authorization required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(userBasePost, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Adds or updates the layouts for dashboard charts for an user
         * @param {string} userId The user id
         * @param {UserLayouts} userLayouts User object that needs to be added to the system
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createUserLayout: async (userId: string, userLayouts: UserLayouts, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'userId' is not null or undefined
            assertParamExists('createUserLayout', 'userId', userId)
            // verify required parameter 'userLayouts' is not null or undefined
            assertParamExists('createUserLayout', 'userLayouts', userLayouts)
            const localVarPath = `/users/{user_id}/layouts`
                .replace(`{${"user_id"}}`, encodeURIComponent(String(userId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Authorization required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(userLayouts, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Delete a permission group by id
         * @param {string} roleId The id that needs to be deleted
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deletePermissionGroup: async (roleId: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'roleId' is not null or undefined
            assertParamExists('deletePermissionGroup', 'roleId', roleId)
            const localVarPath = `/users/roles/{role_id}`
                .replace(`{${"role_id"}}`, encodeURIComponent(String(roleId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Authorization required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Delete a user by id
         * @param {string} userId The id that needs to be deleted
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteUser: async (userId: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'userId' is not null or undefined
            assertParamExists('deleteUser', 'userId', userId)
            const localVarPath = `/users/{user_id}`
                .replace(`{${"user_id"}}`, encodeURIComponent(String(userId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Authorization required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Returns a list of permission groups
         * @param {string} [sort] order ascending or descending the list of permission groups by a field. For descending order the sort field should be prefixed with a minus(\&#39;-\&#39;)
         * @param {number} [pageOffset] number of items that should be skipped and not included in the response
         * @param {number} [pageLimit] number of items to be included in the response
         * @param {GetSitesFilterParameter} [filter] Filters should be sent as encoded URI. Filterable items: name
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAllPermissionGroups: async (sort?: string, pageOffset?: number, pageLimit?: number, filter?: GetSitesFilterParameter, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/users/roles`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Authorization required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (sort !== undefined) {
                localVarQueryParameter['sort'] = sort;
            }

            if (pageOffset !== undefined) {
                localVarQueryParameter['page[offset]'] = pageOffset;
            }

            if (pageLimit !== undefined) {
                localVarQueryParameter['page[limit]'] = pageLimit;
            }

            if (filter !== undefined) {
                for (const [key, value] of Object.entries(filter)) {
                    localVarQueryParameter[key] = value;
                }
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Returns a list of all components available in the system
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getComponents: async (options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/users/components`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Authorization required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Returns a list of rights
         * @param {string} mandatorId The id of the mandator for which we want to get the rights
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getPermissionGroup: async (mandatorId: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'mandatorId' is not null or undefined
            assertParamExists('getPermissionGroup', 'mandatorId', mandatorId)
            const localVarPath = `/users/rights`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Authorization required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (mandatorId !== undefined) {
                localVarQueryParameter['mandator_id'] = mandatorId;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Returns a permission groups by id
         * @param {string} roleId The id of the permission group
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getPermissionGroupById: async (roleId: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'roleId' is not null or undefined
            assertParamExists('getPermissionGroupById', 'roleId', roleId)
            const localVarPath = `/users/roles/{role_id}`
                .replace(`{${"role_id"}}`, encodeURIComponent(String(roleId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Authorization required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Returns a single user by id
         * @param {string} userId The email of the user used as username in cognito
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getUserById: async (userId: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'userId' is not null or undefined
            assertParamExists('getUserById', 'userId', userId)
            const localVarPath = `/users/{user_id}`
                .replace(`{${"user_id"}}`, encodeURIComponent(String(userId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Authorization required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Returns a list of user charts
         * @param {string} userId The user id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getUserCharts: async (userId: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'userId' is not null or undefined
            assertParamExists('getUserCharts', 'userId', userId)
            const localVarPath = `/users/{user_id}/charts`
                .replace(`{${"user_id"}}`, encodeURIComponent(String(userId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Authorization required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Returns a list of layouts for a user
         * @param {string} userId The user id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getUserLayouts: async (userId: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'userId' is not null or undefined
            assertParamExists('getUserLayouts', 'userId', userId)
            const localVarPath = `/users/{user_id}/layouts`
                .replace(`{${"user_id"}}`, encodeURIComponent(String(userId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Authorization required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Returns a list of user permission groups
         * @param {string} userId The user id
         * @param {string} mandatorId The id of the mandator
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getUserPermissionGroup: async (userId: string, mandatorId: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'userId' is not null or undefined
            assertParamExists('getUserPermissionGroup', 'userId', userId)
            // verify required parameter 'mandatorId' is not null or undefined
            assertParamExists('getUserPermissionGroup', 'mandatorId', mandatorId)
            const localVarPath = `/users/{user_id}/roles`
                .replace(`{${"user_id"}}`, encodeURIComponent(String(userId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Authorization required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (mandatorId !== undefined) {
                localVarQueryParameter['mandator_id'] = mandatorId;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Returns a list of users
         * @param {string} [sort] order ascending or descending the list of users by a property. For descending order the sort field should be prefixed with a minus(\&#39;-\&#39;)
         * @param {number} [pageOffset] number of items that should be skipped and not included in the response
         * @param {number} [pageLimit] number of items to be included in the response
         * @param {GetSitesFilterParameter} [filter] Filters should be sent as encoded URI. Filterable items: first_name, last_name, email
         * @param {string} [mandatorId] if sent, only the users assigned to this mandator will be sent
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getUsers: async (sort?: string, pageOffset?: number, pageLimit?: number, filter?: GetSitesFilterParameter, mandatorId?: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/users`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Authorization required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (sort !== undefined) {
                localVarQueryParameter['sort'] = sort;
            }

            if (pageOffset !== undefined) {
                localVarQueryParameter['page[offset]'] = pageOffset;
            }

            if (pageLimit !== undefined) {
                localVarQueryParameter['page[limit]'] = pageLimit;
            }

            if (filter !== undefined) {
                for (const [key, value] of Object.entries(filter)) {
                    localVarQueryParameter[key] = value;
                }
            }

            if (mandatorId !== undefined) {
                localVarQueryParameter['mandator_id'] = mandatorId;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Inserts or updates user charts configurations
         * @param {string} userId The user id
         * @param {UpdateCharts} updateCharts User charts object that needs to be added to the system
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        saveUserCharts: async (userId: string, updateCharts: UpdateCharts, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'userId' is not null or undefined
            assertParamExists('saveUserCharts', 'userId', userId)
            // verify required parameter 'updateCharts' is not null or undefined
            assertParamExists('saveUserCharts', 'updateCharts', updateCharts)
            const localVarPath = `/users/{user_id}/charts`
                .replace(`{${"user_id"}}`, encodeURIComponent(String(userId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Authorization required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(updateCharts, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Updates eula when the user accepts it
         * @summary Updates eula for a user
         * @param {string} userId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateEulaForUser: async (userId: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'userId' is not null or undefined
            assertParamExists('updateEulaForUser', 'userId', userId)
            const localVarPath = `/users/{user_id}/eula`
                .replace(`{${"user_id"}}`, encodeURIComponent(String(userId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Authorization required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Updates a permission group by id
         * @param {string} permissionGroupId 
         * @param {CreatePermissionGroup} createPermissionGroup Updated permission group object
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updatePermissionGroup: async (permissionGroupId: string, createPermissionGroup: CreatePermissionGroup, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'permissionGroupId' is not null or undefined
            assertParamExists('updatePermissionGroup', 'permissionGroupId', permissionGroupId)
            // verify required parameter 'createPermissionGroup' is not null or undefined
            assertParamExists('updatePermissionGroup', 'createPermissionGroup', createPermissionGroup)
            const localVarPath = `/users/rights/{permission_group_id}`
                .replace(`{${"permission_group_id"}}`, encodeURIComponent(String(permissionGroupId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Authorization required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(createPermissionGroup, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * This can only be done by the logged in user.
         * @summary Updates a user by id
         * @param {string} userId The email address used for registration by the user
         * @param {UpdateUser} updateUser Updated user object
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateUser: async (userId: string, updateUser: UpdateUser, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'userId' is not null or undefined
            assertParamExists('updateUser', 'userId', userId)
            // verify required parameter 'updateUser' is not null or undefined
            assertParamExists('updateUser', 'updateUser', updateUser)
            const localVarPath = `/users/{user_id}`
                .replace(`{${"user_id"}}`, encodeURIComponent(String(userId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Authorization required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(updateUser, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Updates a list of users
         * @param {UpdateUserList} updateUserList Updates status for a list of users
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateUserList: async (updateUserList: UpdateUserList, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'updateUserList' is not null or undefined
            assertParamExists('updateUserList', 'updateUserList', updateUserList)
            const localVarPath = `/users`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Authorization required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(updateUserList, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * UsersApi - functional programming interface
 * @export
 */
export const UsersApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = UsersApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary Change the current mandator for a user
         * @param {string} userId 
         * @param {ChangeMandator} [changeMandator] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async changeUserMandator(userId: string, changeMandator?: ChangeMandator, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<UpdateSite200Response>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.changeUserMandator(userId, changeMandator, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['UsersApi.changeUserMandator']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @summary Adds a new permission group to the system
         * @param {CreatePermissionGroup} createPermissionGroup Permission group object that needs to be added to the system
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async createPermissionGroup(createPermissionGroup: CreatePermissionGroup, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<CreatePermissionGroup201Response>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.createPermissionGroup(createPermissionGroup, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['UsersApi.createPermissionGroup']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @summary Adds a new user to the system
         * @param {UserBasePost} userBasePost User object that needs to be added to the system
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async createUser(userBasePost: UserBasePost, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<CreateUser201Response>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.createUser(userBasePost, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['UsersApi.createUser']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @summary Adds or updates the layouts for dashboard charts for an user
         * @param {string} userId The user id
         * @param {UserLayouts} userLayouts User object that needs to be added to the system
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async createUserLayout(userId: string, userLayouts: UserLayouts, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<GetUserLayouts200Response>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.createUserLayout(userId, userLayouts, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['UsersApi.createUserLayout']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @summary Delete a permission group by id
         * @param {string} roleId The id that needs to be deleted
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async deletePermissionGroup(roleId: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<UpdateSite200Response>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.deletePermissionGroup(roleId, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['UsersApi.deletePermissionGroup']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @summary Delete a user by id
         * @param {string} userId The id that needs to be deleted
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async deleteUser(userId: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<DeleteUser200Response>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.deleteUser(userId, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['UsersApi.deleteUser']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @summary Returns a list of permission groups
         * @param {string} [sort] order ascending or descending the list of permission groups by a field. For descending order the sort field should be prefixed with a minus(\&#39;-\&#39;)
         * @param {number} [pageOffset] number of items that should be skipped and not included in the response
         * @param {number} [pageLimit] number of items to be included in the response
         * @param {GetSitesFilterParameter} [filter] Filters should be sent as encoded URI. Filterable items: name
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getAllPermissionGroups(sort?: string, pageOffset?: number, pageLimit?: number, filter?: GetSitesFilterParameter, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<GetAllPermissionGroups200Response>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getAllPermissionGroups(sort, pageOffset, pageLimit, filter, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['UsersApi.getAllPermissionGroups']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @summary Returns a list of all components available in the system
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getComponents(options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<GetComponents200Response>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getComponents(options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['UsersApi.getComponents']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @summary Returns a list of rights
         * @param {string} mandatorId The id of the mandator for which we want to get the rights
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getPermissionGroup(mandatorId: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<GetPermissionGroup200Response>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getPermissionGroup(mandatorId, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['UsersApi.getPermissionGroup']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @summary Returns a permission groups by id
         * @param {string} roleId The id of the permission group
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getPermissionGroupById(roleId: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<GetPermissionGroupById200Response>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getPermissionGroupById(roleId, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['UsersApi.getPermissionGroupById']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @summary Returns a single user by id
         * @param {string} userId The email of the user used as username in cognito
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getUserById(userId: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<GetUserById200Response>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getUserById(userId, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['UsersApi.getUserById']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @summary Returns a list of user charts
         * @param {string} userId The user id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getUserCharts(userId: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<GetUserCharts200Response>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getUserCharts(userId, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['UsersApi.getUserCharts']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @summary Returns a list of layouts for a user
         * @param {string} userId The user id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getUserLayouts(userId: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<GetUserLayouts200Response>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getUserLayouts(userId, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['UsersApi.getUserLayouts']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @summary Returns a list of user permission groups
         * @param {string} userId The user id
         * @param {string} mandatorId The id of the mandator
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getUserPermissionGroup(userId: string, mandatorId: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<GetUserPermissionGroup200Response>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getUserPermissionGroup(userId, mandatorId, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['UsersApi.getUserPermissionGroup']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @summary Returns a list of users
         * @param {string} [sort] order ascending or descending the list of users by a property. For descending order the sort field should be prefixed with a minus(\&#39;-\&#39;)
         * @param {number} [pageOffset] number of items that should be skipped and not included in the response
         * @param {number} [pageLimit] number of items to be included in the response
         * @param {GetSitesFilterParameter} [filter] Filters should be sent as encoded URI. Filterable items: first_name, last_name, email
         * @param {string} [mandatorId] if sent, only the users assigned to this mandator will be sent
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getUsers(sort?: string, pageOffset?: number, pageLimit?: number, filter?: GetSitesFilterParameter, mandatorId?: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<GetUsers200Response>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getUsers(sort, pageOffset, pageLimit, filter, mandatorId, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['UsersApi.getUsers']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @summary Inserts or updates user charts configurations
         * @param {string} userId The user id
         * @param {UpdateCharts} updateCharts User charts object that needs to be added to the system
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async saveUserCharts(userId: string, updateCharts: UpdateCharts, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<SaveUserCharts200Response>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.saveUserCharts(userId, updateCharts, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['UsersApi.saveUserCharts']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * Updates eula when the user accepts it
         * @summary Updates eula for a user
         * @param {string} userId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updateEulaForUser(userId: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<UpdateSite200Response>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.updateEulaForUser(userId, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['UsersApi.updateEulaForUser']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @summary Updates a permission group by id
         * @param {string} permissionGroupId 
         * @param {CreatePermissionGroup} createPermissionGroup Updated permission group object
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updatePermissionGroup(permissionGroupId: string, createPermissionGroup: CreatePermissionGroup, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<UpdateSite200Response>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.updatePermissionGroup(permissionGroupId, createPermissionGroup, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['UsersApi.updatePermissionGroup']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * This can only be done by the logged in user.
         * @summary Updates a user by id
         * @param {string} userId The email address used for registration by the user
         * @param {UpdateUser} updateUser Updated user object
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updateUser(userId: string, updateUser: UpdateUser, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<UpdateSite200Response>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.updateUser(userId, updateUser, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['UsersApi.updateUser']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @summary Updates a list of users
         * @param {UpdateUserList} updateUserList Updates status for a list of users
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updateUserList(updateUserList: UpdateUserList, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<UpdateUserList200Response>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.updateUserList(updateUserList, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['UsersApi.updateUserList']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
    }
};

/**
 * UsersApi - factory interface
 * @export
 */
export const UsersApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = UsersApiFp(configuration)
    return {
        /**
         * 
         * @summary Change the current mandator for a user
         * @param {string} userId 
         * @param {ChangeMandator} [changeMandator] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        changeUserMandator(userId: string, changeMandator?: ChangeMandator, options?: RawAxiosRequestConfig): AxiosPromise<UpdateSite200Response> {
            return localVarFp.changeUserMandator(userId, changeMandator, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Adds a new permission group to the system
         * @param {CreatePermissionGroup} createPermissionGroup Permission group object that needs to be added to the system
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createPermissionGroup(createPermissionGroup: CreatePermissionGroup, options?: RawAxiosRequestConfig): AxiosPromise<CreatePermissionGroup201Response> {
            return localVarFp.createPermissionGroup(createPermissionGroup, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Adds a new user to the system
         * @param {UserBasePost} userBasePost User object that needs to be added to the system
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createUser(userBasePost: UserBasePost, options?: RawAxiosRequestConfig): AxiosPromise<CreateUser201Response> {
            return localVarFp.createUser(userBasePost, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Adds or updates the layouts for dashboard charts for an user
         * @param {string} userId The user id
         * @param {UserLayouts} userLayouts User object that needs to be added to the system
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createUserLayout(userId: string, userLayouts: UserLayouts, options?: RawAxiosRequestConfig): AxiosPromise<GetUserLayouts200Response> {
            return localVarFp.createUserLayout(userId, userLayouts, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Delete a permission group by id
         * @param {string} roleId The id that needs to be deleted
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deletePermissionGroup(roleId: string, options?: RawAxiosRequestConfig): AxiosPromise<UpdateSite200Response> {
            return localVarFp.deletePermissionGroup(roleId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Delete a user by id
         * @param {string} userId The id that needs to be deleted
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteUser(userId: string, options?: RawAxiosRequestConfig): AxiosPromise<DeleteUser200Response> {
            return localVarFp.deleteUser(userId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Returns a list of permission groups
         * @param {string} [sort] order ascending or descending the list of permission groups by a field. For descending order the sort field should be prefixed with a minus(\&#39;-\&#39;)
         * @param {number} [pageOffset] number of items that should be skipped and not included in the response
         * @param {number} [pageLimit] number of items to be included in the response
         * @param {GetSitesFilterParameter} [filter] Filters should be sent as encoded URI. Filterable items: name
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAllPermissionGroups(sort?: string, pageOffset?: number, pageLimit?: number, filter?: GetSitesFilterParameter, options?: RawAxiosRequestConfig): AxiosPromise<GetAllPermissionGroups200Response> {
            return localVarFp.getAllPermissionGroups(sort, pageOffset, pageLimit, filter, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Returns a list of all components available in the system
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getComponents(options?: RawAxiosRequestConfig): AxiosPromise<GetComponents200Response> {
            return localVarFp.getComponents(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Returns a list of rights
         * @param {string} mandatorId The id of the mandator for which we want to get the rights
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getPermissionGroup(mandatorId: string, options?: RawAxiosRequestConfig): AxiosPromise<GetPermissionGroup200Response> {
            return localVarFp.getPermissionGroup(mandatorId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Returns a permission groups by id
         * @param {string} roleId The id of the permission group
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getPermissionGroupById(roleId: string, options?: RawAxiosRequestConfig): AxiosPromise<GetPermissionGroupById200Response> {
            return localVarFp.getPermissionGroupById(roleId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Returns a single user by id
         * @param {string} userId The email of the user used as username in cognito
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getUserById(userId: string, options?: RawAxiosRequestConfig): AxiosPromise<GetUserById200Response> {
            return localVarFp.getUserById(userId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Returns a list of user charts
         * @param {string} userId The user id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getUserCharts(userId: string, options?: RawAxiosRequestConfig): AxiosPromise<GetUserCharts200Response> {
            return localVarFp.getUserCharts(userId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Returns a list of layouts for a user
         * @param {string} userId The user id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getUserLayouts(userId: string, options?: RawAxiosRequestConfig): AxiosPromise<GetUserLayouts200Response> {
            return localVarFp.getUserLayouts(userId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Returns a list of user permission groups
         * @param {string} userId The user id
         * @param {string} mandatorId The id of the mandator
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getUserPermissionGroup(userId: string, mandatorId: string, options?: RawAxiosRequestConfig): AxiosPromise<GetUserPermissionGroup200Response> {
            return localVarFp.getUserPermissionGroup(userId, mandatorId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Returns a list of users
         * @param {string} [sort] order ascending or descending the list of users by a property. For descending order the sort field should be prefixed with a minus(\&#39;-\&#39;)
         * @param {number} [pageOffset] number of items that should be skipped and not included in the response
         * @param {number} [pageLimit] number of items to be included in the response
         * @param {GetSitesFilterParameter} [filter] Filters should be sent as encoded URI. Filterable items: first_name, last_name, email
         * @param {string} [mandatorId] if sent, only the users assigned to this mandator will be sent
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getUsers(sort?: string, pageOffset?: number, pageLimit?: number, filter?: GetSitesFilterParameter, mandatorId?: string, options?: RawAxiosRequestConfig): AxiosPromise<GetUsers200Response> {
            return localVarFp.getUsers(sort, pageOffset, pageLimit, filter, mandatorId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Inserts or updates user charts configurations
         * @param {string} userId The user id
         * @param {UpdateCharts} updateCharts User charts object that needs to be added to the system
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        saveUserCharts(userId: string, updateCharts: UpdateCharts, options?: RawAxiosRequestConfig): AxiosPromise<SaveUserCharts200Response> {
            return localVarFp.saveUserCharts(userId, updateCharts, options).then((request) => request(axios, basePath));
        },
        /**
         * Updates eula when the user accepts it
         * @summary Updates eula for a user
         * @param {string} userId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateEulaForUser(userId: string, options?: RawAxiosRequestConfig): AxiosPromise<UpdateSite200Response> {
            return localVarFp.updateEulaForUser(userId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Updates a permission group by id
         * @param {string} permissionGroupId 
         * @param {CreatePermissionGroup} createPermissionGroup Updated permission group object
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updatePermissionGroup(permissionGroupId: string, createPermissionGroup: CreatePermissionGroup, options?: RawAxiosRequestConfig): AxiosPromise<UpdateSite200Response> {
            return localVarFp.updatePermissionGroup(permissionGroupId, createPermissionGroup, options).then((request) => request(axios, basePath));
        },
        /**
         * This can only be done by the logged in user.
         * @summary Updates a user by id
         * @param {string} userId The email address used for registration by the user
         * @param {UpdateUser} updateUser Updated user object
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateUser(userId: string, updateUser: UpdateUser, options?: RawAxiosRequestConfig): AxiosPromise<UpdateSite200Response> {
            return localVarFp.updateUser(userId, updateUser, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Updates a list of users
         * @param {UpdateUserList} updateUserList Updates status for a list of users
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateUserList(updateUserList: UpdateUserList, options?: RawAxiosRequestConfig): AxiosPromise<UpdateUserList200Response> {
            return localVarFp.updateUserList(updateUserList, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * UsersApi - object-oriented interface
 * @export
 * @class UsersApi
 * @extends {BaseAPI}
 */
export class UsersApi extends BaseAPI {
    /**
     * 
     * @summary Change the current mandator for a user
     * @param {string} userId 
     * @param {ChangeMandator} [changeMandator] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UsersApi
     */
    public changeUserMandator(userId: string, changeMandator?: ChangeMandator, options?: RawAxiosRequestConfig) {
        return UsersApiFp(this.configuration).changeUserMandator(userId, changeMandator, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Adds a new permission group to the system
     * @param {CreatePermissionGroup} createPermissionGroup Permission group object that needs to be added to the system
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UsersApi
     */
    public createPermissionGroup(createPermissionGroup: CreatePermissionGroup, options?: RawAxiosRequestConfig) {
        return UsersApiFp(this.configuration).createPermissionGroup(createPermissionGroup, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Adds a new user to the system
     * @param {UserBasePost} userBasePost User object that needs to be added to the system
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UsersApi
     */
    public createUser(userBasePost: UserBasePost, options?: RawAxiosRequestConfig) {
        return UsersApiFp(this.configuration).createUser(userBasePost, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Adds or updates the layouts for dashboard charts for an user
     * @param {string} userId The user id
     * @param {UserLayouts} userLayouts User object that needs to be added to the system
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UsersApi
     */
    public createUserLayout(userId: string, userLayouts: UserLayouts, options?: RawAxiosRequestConfig) {
        return UsersApiFp(this.configuration).createUserLayout(userId, userLayouts, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Delete a permission group by id
     * @param {string} roleId The id that needs to be deleted
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UsersApi
     */
    public deletePermissionGroup(roleId: string, options?: RawAxiosRequestConfig) {
        return UsersApiFp(this.configuration).deletePermissionGroup(roleId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Delete a user by id
     * @param {string} userId The id that needs to be deleted
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UsersApi
     */
    public deleteUser(userId: string, options?: RawAxiosRequestConfig) {
        return UsersApiFp(this.configuration).deleteUser(userId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Returns a list of permission groups
     * @param {string} [sort] order ascending or descending the list of permission groups by a field. For descending order the sort field should be prefixed with a minus(\&#39;-\&#39;)
     * @param {number} [pageOffset] number of items that should be skipped and not included in the response
     * @param {number} [pageLimit] number of items to be included in the response
     * @param {GetSitesFilterParameter} [filter] Filters should be sent as encoded URI. Filterable items: name
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UsersApi
     */
    public getAllPermissionGroups(sort?: string, pageOffset?: number, pageLimit?: number, filter?: GetSitesFilterParameter, options?: RawAxiosRequestConfig) {
        return UsersApiFp(this.configuration).getAllPermissionGroups(sort, pageOffset, pageLimit, filter, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Returns a list of all components available in the system
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UsersApi
     */
    public getComponents(options?: RawAxiosRequestConfig) {
        return UsersApiFp(this.configuration).getComponents(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Returns a list of rights
     * @param {string} mandatorId The id of the mandator for which we want to get the rights
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UsersApi
     */
    public getPermissionGroup(mandatorId: string, options?: RawAxiosRequestConfig) {
        return UsersApiFp(this.configuration).getPermissionGroup(mandatorId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Returns a permission groups by id
     * @param {string} roleId The id of the permission group
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UsersApi
     */
    public getPermissionGroupById(roleId: string, options?: RawAxiosRequestConfig) {
        return UsersApiFp(this.configuration).getPermissionGroupById(roleId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Returns a single user by id
     * @param {string} userId The email of the user used as username in cognito
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UsersApi
     */
    public getUserById(userId: string, options?: RawAxiosRequestConfig) {
        return UsersApiFp(this.configuration).getUserById(userId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Returns a list of user charts
     * @param {string} userId The user id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UsersApi
     */
    public getUserCharts(userId: string, options?: RawAxiosRequestConfig) {
        return UsersApiFp(this.configuration).getUserCharts(userId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Returns a list of layouts for a user
     * @param {string} userId The user id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UsersApi
     */
    public getUserLayouts(userId: string, options?: RawAxiosRequestConfig) {
        return UsersApiFp(this.configuration).getUserLayouts(userId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Returns a list of user permission groups
     * @param {string} userId The user id
     * @param {string} mandatorId The id of the mandator
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UsersApi
     */
    public getUserPermissionGroup(userId: string, mandatorId: string, options?: RawAxiosRequestConfig) {
        return UsersApiFp(this.configuration).getUserPermissionGroup(userId, mandatorId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Returns a list of users
     * @param {string} [sort] order ascending or descending the list of users by a property. For descending order the sort field should be prefixed with a minus(\&#39;-\&#39;)
     * @param {number} [pageOffset] number of items that should be skipped and not included in the response
     * @param {number} [pageLimit] number of items to be included in the response
     * @param {GetSitesFilterParameter} [filter] Filters should be sent as encoded URI. Filterable items: first_name, last_name, email
     * @param {string} [mandatorId] if sent, only the users assigned to this mandator will be sent
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UsersApi
     */
    public getUsers(sort?: string, pageOffset?: number, pageLimit?: number, filter?: GetSitesFilterParameter, mandatorId?: string, options?: RawAxiosRequestConfig) {
        return UsersApiFp(this.configuration).getUsers(sort, pageOffset, pageLimit, filter, mandatorId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Inserts or updates user charts configurations
     * @param {string} userId The user id
     * @param {UpdateCharts} updateCharts User charts object that needs to be added to the system
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UsersApi
     */
    public saveUserCharts(userId: string, updateCharts: UpdateCharts, options?: RawAxiosRequestConfig) {
        return UsersApiFp(this.configuration).saveUserCharts(userId, updateCharts, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Updates eula when the user accepts it
     * @summary Updates eula for a user
     * @param {string} userId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UsersApi
     */
    public updateEulaForUser(userId: string, options?: RawAxiosRequestConfig) {
        return UsersApiFp(this.configuration).updateEulaForUser(userId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Updates a permission group by id
     * @param {string} permissionGroupId 
     * @param {CreatePermissionGroup} createPermissionGroup Updated permission group object
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UsersApi
     */
    public updatePermissionGroup(permissionGroupId: string, createPermissionGroup: CreatePermissionGroup, options?: RawAxiosRequestConfig) {
        return UsersApiFp(this.configuration).updatePermissionGroup(permissionGroupId, createPermissionGroup, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * This can only be done by the logged in user.
     * @summary Updates a user by id
     * @param {string} userId The email address used for registration by the user
     * @param {UpdateUser} updateUser Updated user object
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UsersApi
     */
    public updateUser(userId: string, updateUser: UpdateUser, options?: RawAxiosRequestConfig) {
        return UsersApiFp(this.configuration).updateUser(userId, updateUser, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Updates a list of users
     * @param {UpdateUserList} updateUserList Updates status for a list of users
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UsersApi
     */
    public updateUserList(updateUserList: UpdateUserList, options?: RawAxiosRequestConfig) {
        return UsersApiFp(this.configuration).updateUserList(updateUserList, options).then((request) => request(this.axios, this.basePath));
    }
}



