import StandardPageLayout from "./StandardPageLayout";
import {ProductOfferResponse, SitePoints} from "../../service/hecone/gen";
import {MouseEventHandler, useEffect, useState} from "react";
import {fetchAuthSession} from "aws-amplify/auth";
import {AxiosHeaders} from "axios";
import {siteApi} from "../../service/hecone/siteApi";
import {Card, Grid, Heading, useTheme} from "@aws-amplify/ui-react";

interface Probs {
    point: SitePoints | null;
    onAbort?: MouseEventHandler<HTMLButtonElement>
}

function SelectProductPage({point, onAbort}: Probs) {
    const {tokens} = useTheme();
    const [productList, setProductList] = useState<ProductOfferResponse[]>([]);
    useEffect(() => {
        const fetchData = async () => {
            const session = await fetchAuthSession()

            const headers = new AxiosHeaders()
            const token = session.tokens?.accessToken.toString()
            if (token === undefined) {
                return
            }
            headers.setAuthorization(token)

            if (point != null && point.site_id !== undefined && point.id !== undefined) {
                const response = await siteApi.getProductsOfPoint(point.site_id,
                    point.id,
                    undefined,
                    undefined,
                    undefined, {
                        validateStatus: () => true,
                        headers: headers
                    })


                const pointListResponse = response.data?.data ?? []

                setProductList(pointListResponse);
            }
        }

        fetchData().catch((e) => console.error(e))
    }, [point]);

    return (
        <StandardPageLayout header={"Select Product"} footer={{
            reject: {
                children: "abort",
                onClick: onAbort
            }
        }}>
            <Grid
                templateColumns="1fr 1fr"
                templateRows="1fr 1fr 1fr 1fr"
                width={"466px"}
                height={"100%"}
            >

                {productList.map((product: ProductOfferResponse) => (
                    <Card
                        key={product.id}
                        variation={"outlined"}
                        backgroundColor={tokens.colors.green[10]}
                        //onClick={() => selectPoint(point)}
                    >
                        <Heading
                            level={5}
                        >{product.name}
                        </Heading>
                        <p>{product.description}
                        </p>
                        <p>{product.unit_price} {product.currency}
                        </p>
                    </Card>))}
            </Grid>
        </StandardPageLayout>
    )
}

export default SelectProductPage;