/* tslint:disable */
/* eslint-disable */
/**
 * HecOne 360 WebAPI
 *  The Hectronic HecOne-WebAPI allows third-parties to access the HecOne ecosystem.<br> <p> <b>Terminology</b><br > <ul> <li> <b>Site:</b> A Site is a limited geographical area. Examples: CityCenter (Parking), Refuelling-Station</li> <li> <b>Device:</b> Each Device is a Point of Sale. Customer using this to buy Products</li> <li> <b>Point</b> A Point is used to consume a Product. One Point can only be used by one customer at a time. Example: ParkingLot, Gas-Pump, Charging-Station</li> <li> <b>Product</b> A Product is a consumable resource. Example: Parking-Tariff, Diesel, AC, DC, ... </li> </ul> </p> 
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import type { Configuration } from './configuration';
// Some imports not used depending on template conditions
// @ts-ignore
import type { AxiosPromise, AxiosInstance, RawAxiosRequestConfig } from 'axios';
import globalAxios from 'axios';

export const BASE_PATH = "https://api.develop.hectronic.cloud/v1".replace(/\/+$/, "");

/**
 *
 * @export
 */
export const COLLECTION_FORMATS = {
    csv: ",",
    ssv: " ",
    tsv: "\t",
    pipes: "|",
};

/**
 *
 * @export
 * @interface RequestArgs
 */
export interface RequestArgs {
    url: string;
    options: RawAxiosRequestConfig;
}

/**
 *
 * @export
 * @class BaseAPI
 */
export class BaseAPI {
    protected configuration: Configuration | undefined;

    constructor(configuration?: Configuration, protected basePath: string = BASE_PATH, protected axios: AxiosInstance = globalAxios) {
        if (configuration) {
            this.configuration = configuration;
            this.basePath = configuration.basePath ?? basePath;
        }
    }
};

/**
 *
 * @export
 * @class RequiredError
 * @extends {Error}
 */
export class RequiredError extends Error {
    constructor(public field: string, msg?: string) {
        super(msg);
        this.name = "RequiredError"
    }
}

interface ServerMap {
    [key: string]: {
        url: string,
        description: string,
    }[];
}

/**
 *
 * @export
 */
export const operationServerMap: ServerMap = {
    "TransactionsApi._export": [
        {
            url: "https://upload.develop.hectronic.cloud",
            description: "Development",
        },
        {
            url: "https://upload.test.hectronic.cloud",
            description: "Test",
        },
        {
            url: "https://upload.one.hectronic.cloud",
            description: "Production",
        }
    ],
    "TransactionsApi.createTransactionV3": [
        {
            url: "https://api.develop.hectronic.cloud",
            description: "Development",
        },
        {
            url: "https://api.test.hectronic.cloud",
            description: "Test",
        },
        {
            url: "https://api.one.hectronic.cloud",
            description: "Production",
        }
    ],
    "TransactionsApi.getStatisticsV3": [
        {
            url: "https://api.develop.hectronic.cloud",
            description: "Development",
        },
        {
            url: "https://api.test.hectronic.cloud",
            description: "Test",
        },
        {
            url: "https://api.one.hectronic.cloud",
            description: "Production",
        }
    ],
    "TransactionsApi.getTransactionByIdV3": [
        {
            url: "https://api.develop.hectronic.cloud",
            description: "Development",
        },
        {
            url: "https://api.test.hectronic.cloud",
            description: "Test",
        },
        {
            url: "https://api.one.hectronic.cloud",
            description: "Production",
        }
    ],
    "TransactionsApi.getTransactionsV3": [
        {
            url: "https://api.develop.hectronic.cloud",
            description: "Development",
        },
        {
            url: "https://api.test.hectronic.cloud",
            description: "Test",
        },
        {
            url: "https://api.one.hectronic.cloud",
            description: "Production",
        }
    ],
    "TransactionsApi.updateTransactionV3": [
        {
            url: "https://api.develop.hectronic.cloud",
            description: "Development",
        },
        {
            url: "https://api.test.hectronic.cloud",
            description: "Test",
        },
        {
            url: "https://api.one.hectronic.cloud",
            description: "Production",
        }
    ],
}
