import {AuthUser, fetchAuthSession} from "aws-amplify/auth";
import {UseAuthenticator} from "@aws-amplify/ui-react-core";
import {Accordion, Button, Heading, SelectField} from "@aws-amplify/ui-react";
import {CompGetSitesResponse} from "../service/hecone/gen";
import {useEffect, useState} from "react";
import {AxiosHeaders} from "axios";
import {siteApi} from "../service/hecone/siteApi";

type SignOut = UseAuthenticator['signOut'];

interface Probs {
    signOut: SignOut | undefined
    user: AuthUser | undefined
    onSelectSideId: (id: string) => void
}


function Configuration({signOut, user, onSelectSideId}: Probs) {
    const [siteList, setSiteList] = useState<CompGetSitesResponse[]>([]);

    const storageSitedId = localStorage.getItem(`configuration.${user?.username}.siteId`)
    if (storageSitedId != null) {
        onSelectSideId(storageSitedId)
    }
    const [selectedSiteId, setSelectedSiteId] = useState<string | undefined>(storageSitedId ?? undefined);
    useEffect(() => {

        const fetchData = async () => {
            const session = await fetchAuthSession()

            const headers = new AxiosHeaders()
            const token = session.tokens?.accessToken.toString()
            if (token === undefined) {
                return
            }
            headers.setAuthorization(token)

            const response = await siteApi.getSites(undefined, undefined, undefined, undefined, undefined, {
                validateStatus: () => true,
                headers: headers
            })


            setSiteList(response.data?.data ?? []);
        }

        fetchData().catch((e) => console.error(e))
    }, []);
    useEffect(() => {
        if (selectedSiteId !== undefined) {
            localStorage.setItem(`configuration.${user?.username}.siteId`, selectedSiteId)
            onSelectSideId(selectedSiteId)
        }
    }, [selectedSiteId,user,onSelectSideId]);
    return (
        <Accordion.Container defaultValue={storageSitedId === undefined ? ['site-id'] : []}>
            <Accordion.Item value="site-id">
                <Accordion.Trigger>
                    <Heading level={1}>Logged in with device {user?.username}</Heading>
                    <Button variation="primary" onClick={signOut}>Sign out</Button>
                    <Accordion.Icon/>
                </Accordion.Trigger>
                <Accordion.Content>
                    <SelectField
                        placeholder="no Site Selected"
                        label="Site"
                        descriptiveText="On Which Site do the device stand"
                        onChange={(change) => {
                            setSelectedSiteId(change.target.value)
                        }}
                        value={selectedSiteId}
                    >

                        {siteList.map((site: CompGetSitesResponse) => (
                            <option key={site.id} value={site.id}>{site.name}</option>))}
                    </SelectField>
                </Accordion.Content>
            </Accordion.Item>
        </Accordion.Container>
    )
}

export default Configuration