import StandardPageLayout from "./StandardPageLayout";
import {SitePoints} from "../../service/hecone/gen";
import {useEffect, useState} from "react";
import {Card, Grid, Heading, useTheme} from "@aws-amplify/ui-react";
import {fetchAuthSession} from "aws-amplify/auth";
import {AxiosHeaders} from "axios";
import {siteApi} from "../../service/hecone/siteApi";

interface Probs {
    siteId: string | null;
    selectPoint: (pointId: SitePoints) => void;
}

function SelectPointPage({siteId, selectPoint}: Probs) {
    const {tokens} = useTheme();
    const [pointList, setPointList] = useState<SitePoints[]>([]);
    useEffect(() => {
        const fetchData = async () => {
            const session = await fetchAuthSession()

            const headers = new AxiosHeaders()
            const token = session.tokens?.accessToken.toString()
            if (token === undefined) {
                return
            }
            headers.setAuthorization(token)

            if (siteId != null) {
                const response = await siteApi.getPointsOfSite(siteId, undefined, undefined, undefined, {
                    validateStatus: () => true,
                    headers: headers
                })


                const pointListResponse = response.data?.data ?? []
                if (pointListResponse.length === 1 && pointListResponse[0] !== undefined) {
                    selectPoint(pointListResponse[0])
                }
                setPointList(pointListResponse);


            }
        }

        fetchData().catch((e) => console.error(e))
    }, [siteId, selectPoint]);
    return (
        <StandardPageLayout header={"Select Point"}>
            <Grid
                templateColumns="1fr 1fr"
                templateRows="1fr 1fr 1fr 1fr"
                width={"466px"}
                height={"100%"}
            >

                {pointList.map((point: SitePoints) => (
                    <Card
                        key={point.id}
                        variation={"outlined"}
                        backgroundColor={tokens.colors.green[10]}
                        onClick={() => selectPoint(point)}
                    >
                        <Heading
                            level={5}
                        >{point.point_number}
                        </Heading>
                        <Heading
                            level={6}
                        >{point.name}
                        </Heading>
                    </Card>))}
            </Grid>
        </StandardPageLayout>

    )
}

export default SelectPointPage;