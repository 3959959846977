import theme from "../../misc/TerminalTheme";
import {ThemeProvider, View} from "@aws-amplify/ui-react";
import {CSSProperties, ReactNode} from "react";

interface Probs {
    children?: ReactNode;
}

function PossContainer(props: Probs) {
    const styleRatio: CSSProperties = {
        aspectRatio: "9/16",
        width: "100%",
        maxWidth: "466px"
    }
    return (
        <ThemeProvider theme={theme} colorMode="light">
            <View style={styleRatio} border={"1px solid"}>
                {props.children}
            </View>
        </ThemeProvider>
    )
}

export default PossContainer;