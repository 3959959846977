import {Card, Flex,} from "@aws-amplify/ui-react";
import {AuthUser} from "aws-amplify/auth";
import {UseAuthenticator} from "@aws-amplify/ui-react-core";

import Configuration from "./Configuration";
import Poss from "./poss/Poss";
import {useState} from "react";

type SignOut = UseAuthenticator['signOut'];

interface Probs {
    signOut: SignOut | undefined
    user: AuthUser | undefined
}

function Main({signOut, user}: Probs) {

    const [siteId, setSiteId] = useState<string | null>(null)

    return (
        <Card>
            <Flex direction="column" alignItems="center">
                <Configuration signOut={signOut} user={user} onSelectSideId={setSiteId}/>
                <Poss siteId={siteId}/>
            </Flex>
        </Card>
    )
}

export default Main;