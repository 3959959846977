import {MouseEventHandler, MouseEvent, useCallback, useState} from "react";
import PossContainer from "./PossContainer";
import WelcomePage from "./WelcomePage";
import SelectPointPage from "./SelectPointPage";
import SelectProductPage from "./SelectProductPage";
import {SitePoints} from "../../service/hecone/gen";

enum Page {
    welcome,
    selectPoint,
    selectProduct
}

interface Probs {
    siteId: string | null;
}

function Poss({siteId}: Probs) {

    const [page, setPage] = useState<Page>(Page.welcome)
    const [point, setPoint] = useState<SitePoints | null>(null)
    const selectPoint = useCallback((sPoint: SitePoints) => {
        setPoint(sPoint)
        setPage(Page.selectProduct)
    }, [])

    const setWelcomePage: MouseEventHandler = useCallback((_event: MouseEvent) => {
        setPage(Page.welcome)
    }, [])
    const setPointSelectionPage: MouseEventHandler = useCallback((_event: MouseEvent) => {
        setPage(Page.selectPoint)
    }, [])

    switch (page) {
        case Page.selectProduct:
            return (
                <PossContainer>
                    <SelectProductPage point={point} onAbort={setWelcomePage}/>
                </PossContainer>)
        case Page.selectPoint:
            return (
                <PossContainer>
                    <SelectPointPage siteId={siteId} selectPoint={selectPoint}/>
                </PossContainer>)

        case Page.welcome:
            return (
                <PossContainer>
                    <WelcomePage onStart={setPointSelectionPage}/>
                </PossContainer>)
        default:
            throw new Error("Page not found")
    }

}

export default Poss;